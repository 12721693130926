import React from 'react';

export default function CustomLogoIcon(props) {
  return (
    <img
      width="1"
      height="1"
      viewBox="0 0 65.61 64"
      {...props}
      src={props.logo}
    />
  );
}
