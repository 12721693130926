import React from 'react';
import styled from '@emotion/styled';

import { CloseButton } from '../../components/CloseButton';

export const Header = ({ title, language, onClose }) => {
  const rtl = language === 'ar' ? 'rtl' : null;

  return (
    <Row>
      {title && <Title className={rtl}>{title}</Title>}
      <AlignedCloseButton onClick={onClose} />
    </Row>
  );
};

const Row = styled.div`
  display: flex;
`;

const Title = styled.h4`
  max-width: 60ch;
  word-break: break-word;
`;

const AlignedCloseButton = styled(CloseButton)`
  align-self: baseline;
  margin-top: 0.5rem; /* Match the title's padding */
`;
