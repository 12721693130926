import _ from 'lodash';
import { scaleBand, scaleLinear } from 'd3-scale';
import { sum, min } from 'd3-array';
import React from 'react';
import PropTypes from 'prop-types';

import { Colors } from '../styles';
import { getLongestTextLength } from './utils';
import { Label } from './Label';

/**
 * Clusters are colored by index. Colors come from Figma mockup.
 */
const clusterColorScheme = [
  { major: Colors.blue2, minor: Colors.blue3 },
  { major: Colors.green3, minor: Colors.green4 },
  { major: Colors.yellow1, minor: Colors.yellow2 },
  { major: Colors.orange2, minor: Colors.orange3 },
  { major: Colors.red2, minor: Colors.red3 },
  { major: Colors.pink2, minor: Colors.pink3 },
  { major: Colors.purple2, minor: Colors.purple3 }
];

const LEGEND_BAR_WIDTH = 3;
const LABEL_BAR_GAP = 5;

export function ClustersVisualization({ width, height, clusters }) {
  const columnGap = 16;
  const longestLabelWidth = getLongestTextLength(
    _.flatten(clusters).map(({ name }) => name),
    '0.875rem',
    'bold'
  );
  const legendWidth = min([
    180,
    longestLabelWidth + LEGEND_BAR_WIDTH + LABEL_BAR_GAP
  ]);
  const chartStart = legendWidth + columnGap;
  const chartWidth = width - chartStart;

  // Should we have a better way of handling the case of width or height
  // being 0?
  if (width <= 0 || height <= 0) {
    return null;
  }

  return (
    <svg
      display="block"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="none"
    >
      <Legend
        x={0}
        y={0}
        width={legendWidth}
        height={height}
        clusters={clusters}
      />
      <Chart
        x={chartStart}
        y={0}
        width={chartWidth}
        height={height}
        clusters={clusters}
      />
    </svg>
  );
}

ClustersVisualization.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  clusters: PropTypes.array.isRequired
};

function Legend({ clusters, x, y, width, height }) {
  const yScale = scaleBand()
    .domain(clusters.map((d, i) => i))
    .rangeRound([y, y + height])
    .padding(0.125);

  return (
    <g>
      {clusters.map((cluster, index) => {
        return (
          <LegendCluster
            key={index}
            x={x}
            y={y + yScale(index)}
            width={width}
            height={yScale.bandwidth()}
            cluster={cluster}
            colors={clusterColorScheme[index]}
          />
        );
      })}
    </g>
  );
}

Legend.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  clusters: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        matchCount: PropTypes.number.isRequired
      }).isRequired
    ).isRequired
  ).isRequired
};

function LegendCluster({ cluster, x, y, width, height, colors }) {
  const colorBarRadius = 2;
  const yScale = scaleBand()
    .domain(cluster.map((d, i) => i))
    .rangeRound([y, y + height]);

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={LEGEND_BAR_WIDTH}
        height={height}
        rx={colorBarRadius}
        ry={colorBarRadius}
        fill={colors.major}
      />
      {cluster.map((concept, index) => {
        return (
          <Label
            key={concept.hash()}
            x={x + LEGEND_BAR_WIDTH + LABEL_BAR_GAP}
            y={yScale(index)}
            name={concept.name}
            dominantBaseline="hanging"
            fontWeight="bold"
            maxWidth={width}
          />
        );
      })}
    </g>
  );
}

LegendCluster.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  cluster: PropTypes.arrayOf(
    PropTypes.shape({
      matchCount: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  colors: PropTypes.shape({
    major: PropTypes.string.isRequired,
    minor: PropTypes.string.isRequired
  }).isRequired
};

function Chart({ clusters, x, y, width, height }) {
  const conceptCount = concept => concept.matchCount;
  const clusterCount = cluster => sum(cluster.map(conceptCount));

  const yScale = scaleLinear()
    .domain([0, sum(clusters.map(clusterCount))])
    .range([y, y + height]);

  let yOffset = 0;
  return (
    <g>
      {clusters.map((cluster, index) => {
        const colors = clusterColorScheme[index];

        return (
          <g key={cluster[0].clusterLabel}>
            {cluster.map(concept => {
              const conceptHeight = yScale(conceptCount(concept));
              const oldYOffset = yOffset;
              yOffset += conceptHeight;

              return (
                <ChartConceptBar
                  key={concept.hash()}
                  colors={colors}
                  x={x}
                  y={oldYOffset}
                  width={width}
                  height={conceptHeight}
                />
              );
            })}
          </g>
        );
      })}
    </g>
  );
}

Chart.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  clusters: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        matchCount: PropTypes.number.isRequired
      }).isRequired
    ).isRequired
  ).isRequired
};

function ChartConceptBar({ colors, x, y, width, height }) {
  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      fill={colors.major}
      stroke={colors.minor}
    />
  );
}

ChartConceptBar.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  colors: PropTypes.shape({
    major: PropTypes.string.isRequired,
    minor: PropTypes.string.isRequired
  }).isRequired
};
