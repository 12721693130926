import _ from 'lodash';

import naturalSort from '../utils/NaturalSort';
import { MetadataTypes } from '../constants';

export class ColumnGroup {
  static groupColumns(columns, existingMetadata) {
    const metadataMap = new Map(
      existingMetadata.map(f => [f.name.toLowerCase(), f])
    );

    const groups = Object.entries(_.groupBy(columns, 'group'))
      .map(([group, columns]) => {
        let originalTypes = _.uniq(columns.map(c => c.originalType));
        let name = columns[0].name;
        let type = columns[0].type;
        let frozenTypeReason = null;

        if (metadataMap.has(name.toLowerCase())) {
          const existingField = metadataMap.get(name.toLowerCase());
          name = existingField.name;
          type = existingField.type;
          frozenTypeReason =
            'This field already exists in the project, so its data type cannot be changed.';
        }

        const contentsBlank = columns.every(c => c.empty);
        const nameBlank = name === '';
        if (nameBlank && contentsBlank) {
          return null;
        } else if (contentsBlank) {
          type = 'skip';
          frozenTypeReason = 'Columns with no data are always skipped.';
        } else if (nameBlank) {
          type = 'skip';
          frozenTypeReason = 'Columns without headers are always skipped.';
        }

        const indices = columns.map(c => c.index);
        return new ColumnGroup(
          group,
          name,
          type,
          frozenTypeReason,
          indices,
          originalTypes
        );
      })
      .filter(cg => cg != null);

    for (let group of groups) {
      if (group.name !== 'text') columns = group.standardize(columns);
    }

    return { columns, groups };
  }

  constructor(id, name, type, frozenTypeReason, indices, originalTypes) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.frozenTypeReason = frozenTypeReason;
    this.indices = indices;
    this.originalTypes = originalTypes;
    this.key = `${type}-${indices}`;
  }

  get typeCanBeChanged() {
    return this.frozenTypeReason == null;
  }

  changeTypeOfColumns(newType, columns) {
    return columns.map(column => {
      if (column.group === this.id) {
        return column.update({ type: newType, originalType: newType });
      }
      return column;
    });
  }

  standardize(columns) {
    const standardizedColumns = [...columns];
    for (let i of this.indices) {
      standardizedColumns[i] = columns[i].update({
        name: this.name,
        type: this.type
      });
    }
    return standardizedColumns;
  }

  get isMetadata() {
    return Object.values(MetadataTypes).includes(this.type);
  }

  get isOrderedType() {
    return ['number', 'score', 'date'].includes(this.type);
  }

  collectStats(rows) {
    // To collect stats, we define several callback functions which will be
    // called with this group's cells from each row of the dataset.  Each
    // callback is responsible for collecting its own portion of the overall
    // group stats.

    let unparsableValuesCount = 0;
    const unparsableValuesCountCallback = cells => {
      if (this.isMetadata) {
        for (const cell of cells) {
          unparsableValuesCount += cell.unparsableValuesAs(this.type).length;
        }
      }
    };

    let emptyRowsCount = 0;
    const emptyRowsCountCallback = cells => {
      if (cells.every(cell => !cell.hasValue)) {
        emptyRowsCount++;
      }
    };

    let tooLongValuesCount = 0;
    const tooLongTextCallback = cells => {
      if (this.type === 'text') {
        let rowLength = 0;
        for (const cell of cells) {
          rowLength += cell.rawValue.length;
        }
        // Note: this is an inaccurate way to check how long the text is because
        // we're measuring the length of UTF-16 strings, but the backend looks
        // at these strings encoded in UTF-8.
        // Additionally, this will be off by (number of cells - 1) * (length of
        // ' ¶ '), but unless someone has a very strange dataset, that won't
        // matter.
        if (rowLength > 500000) {
          tooLongValuesCount++;
        }
      }
    };

    const valueCountMap = new Map();
    const valueCountMapCallback = cells => {
      if (this.isMetadata) {
        let values = new Set();
        for (let cell of cells) {
          for (let value of cell.valuesAs(this.type)) {
            values.add(value);
          }
        }
        for (let value of values) {
          valueCountMap.set(value, (valueCountMap.get(value) ?? 0) + 1);
        }
      }
    };

    this._forEachRow(rows, [
      unparsableValuesCountCallback,
      emptyRowsCountCallback,
      tooLongTextCallback,
      valueCountMapCallback
    ]);

    let uniqueValues = [],
      minimum = null,
      maximum = null;
    if (
      this.isMetadata &&
      // if there are no values, there's no need to format or sort them
      valueCountMap.size !== 0
    ) {
      uniqueValues = [...valueCountMap.entries()]
        .map(([value, count]) => {
          return { value, count };
        })
        .sort((a, b) => SORT_FUNCTIONS[this.type](a.value, b.value));
      if (this.type === 'date') {
        for (let uniqueValue of uniqueValues) {
          uniqueValue.value = uniqueValue.value.format('MMM D, YYYY');
        }
      }
      if (this.isOrderedType) {
        minimum = uniqueValues[0].value;
        maximum = uniqueValues[uniqueValues.length - 1].value;
      }
    }

    return {
      uniqueValues,
      minimum,
      maximum,
      unparsableValuesCount,
      tooLongValuesCount,
      emptyRowsCount,
      nonEmptyRowsCount: rows.length - emptyRowsCount
    };
  }

  _forEachRow(rows, callbacks) {
    for (const row of rows) {
      const cells = this.cellsFrom(row);
      callbacks.forEach(callback => callback(cells));
    }
  }

  cellsFrom(row) {
    const cells = this.indices
      .map(index => row[index])
      .filter(cell => cell != null);
    return cells;
  }
}

const SORT_FUNCTIONS = {
  string: naturalSort,
  number: (a, b) => a - b,
  score: (a, b) => a - b,
  date: (a, b) => a.valueOf() - b.valueOf()
};
