import React from 'react';
import styled from '@emotion/styled';

import { Colors } from '../styles';
import { ProjectBasicsSection } from './ProjectBasicsSection';
import { NextBuildSection } from './NextBuildSection';
import { ScienceAssertionsSection } from './ScienceAssertionsSection';
import { LanguageSection } from './LanguageSection';
import { Footer } from './Footer';
import { Trademark } from './Trademark';
import { Card } from '../components/Card';
import CopyConceptListSection from './CopyConceptListSection';

export const ProjectManagement = () => {
  return (
    <Container>
      <Row>
        <ProjectBasicsSection />
        <RowContainer>
          <NextBuildSection />
          <CopyConceptListSection />
        </RowContainer>
      </Row>
      <Row>
        <ScienceAssertionsSection />
        <LanguageSection />
      </Row>
      <Footer />
      <Trademark />
    </Container>
  );
};

const Container = styled.div`
  background: ${Colors.gray0};
  box-sizing: border-box;
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  > ${Card} {
    min-width: 25rem;
    height: fit-content;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;

  > ${Card} {
    flex: 1;
  }
`;

const RowContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;
