import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { css } from '@emotion/core';
import { useParams } from 'react-router-dom';

import { StoreContext } from '../../StoreContext';
import { useCurrentView, View, viewsAreEqual } from './view';
import InlineEditor from '../../components/InlineEditor';
import { OverlayAlert } from '../../components/core/Alert';
import { Button } from '../../components/core/Button';
import { ViewDetails } from './ViewDetails';
import { deleteSharedView, saveSharedView } from '../../actions';
import {
  UnsharedChangesAlert,
  useActiveConceptListHasUnsharedChanges
} from './UnsharedChangesAlert';
import { useDispatchUndoBanner } from '../../UndoBanner';
import { Checkbox } from '../../components/core/Checkbox';
import { useUniqueId } from '../../utils/hooks';
import { Icon, IconTypes } from '../../components/icons';
import EditableTextArea from '../../components/EditableTextArea';

export function EditPanel({
  viewName,
  onSave,
  alertController,
  closePopover,
  viewNote
}) {
  const dispatchUndoBanner = useDispatchUndoBanner();
  const { sharedViews } = useContext(StoreContext);
  const currentView = useCurrentView();
  const { projectId } = useParams();
  const [showDiff, setShowDiff] = useState(true);
  const activeConceptListHasUnsharedChanges = useActiveConceptListHasUnsharedChanges();

  const existingView = _.find(sharedViews, { name: viewName });
  const viewToSave = currentView.update({
    name: viewName,
    note: viewNote || ' ',
    shared_view_id: existingView?.shared_view_id
  });
  const hasNoChanges = viewsAreEqual(viewToSave.view, existingView);

  const viewDependsOnUnsharedChanges =
    activeConceptListHasUnsharedChanges && currentView.dependsOnConceptList;

  useEffect(() => {
    if (!existingView) {
      onSave(viewToSave.view);
    }
  }, []);
  const id = useUniqueId();

  const updateButtonStyles = css`
    align-self: flex-start;
    margin-bottom: 0.75rem;
  `;

  return (
    <div>
      <div
        css={css`
          padding: 0.5rem;
        `}
      >
        {/*Wrap top section for horizontal alignment with ViewDetails*/}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-left: 1.5rem;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              /* Align to name in edit panel and add some spacing before button */
              height: 2rem;
              margin-top: 0.25rem;
              margin-bottom: 0.75rem;
            `}
          >
            <span
              css={css`
                margin-right: 0.5rem;
              `}
            >
              Name:
            </span>
            <InlineEditor
              onChange={name => onSave({ ...existingView, name })}
              value={viewName}
              name="view name"
              required
            />
          </div>
          <span
            css={css`
              font-style: italic;
              font-size: 12px;
            `}
          >
            Notes:
          </span>
          <EditableTextArea
            initialValue={viewNote}
            onChange={note => onSave({ ...existingView, note })}
          />

          <OverlayAlert controller={alertController} css={updateButtonStyles}>
            {viewDependsOnUnsharedChanges ? (
              <UnsharedChangesAlert />
            ) : (
              <Button
                onClick={() => onSave(viewToSave.view)}
                disabled={hasNoChanges}
                css={updateButtonStyles}
                trackingItem="shared-views_update-view"
              >
                Update "{viewName}" with current view
              </Button>
            )}
          </OverlayAlert>
          <div
            css={css`
              display: flex;
              margin-bottom: 1.75rem;
            `}
          >
            <Checkbox
              id={id}
              outlined={true}
              checked={showDiff}
              onChange={() => setShowDiff(showDiff => !showDiff)}
            />
            <label
              css={css`
                margin-left: 0.5rem;
              `}
              htmlFor={id}
            >
              Show differences between the current view and this one
            </label>
          </div>
        </div>
        <div data-test-id="edit-panel-view-details">
          {viewDependsOnUnsharedChanges || !showDiff ? (
            existingView && <ViewDetails view={new View(existingView)} />
          ) : (
            <ViewDetails
              comparisonView={existingView && new View(existingView)}
              view={viewToSave}
            />
          )}
        </div>
      </div>
      <DeleteSharedViewButton
        existingView={existingView}
        disabled={!existingView?.shared_view_id}
        onClick={() => {
          deleteSharedView(projectId, existingView.shared_view_id)
            .then(() => {
              dispatchUndoBanner({
                message: `Deleted "${existingView.name}"`,
                onUndo: () => saveSharedView(projectId, existingView),
                trackingItem: 'undo-delete-view'
              });
              closePopover();
            })
            .catch(() => {
              alertController.showWarning('Unable to delete view');
            });
        }}
      />
    </div>
  );
}

function DeleteSharedViewButton({ onClick, disabled }) {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      `}
    >
      <Button
        flavor="subtle"
        palette="red"
        onClick={onClick}
        disabled={disabled}
        hiddenLabel="Delete shared view for all users"
        css={css`
          padding: 0;
        `}
        trackingItem="shared-views_delete"
      >
        <Icon type={IconTypes.TRASH} />
      </Button>
    </div>
  );
}
