import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import { ZIndexes, Mixins } from '../../styles';
import { PlainTextButton } from './Button';

export default class Menu extends React.Component {
  constructor() {
    super();
    this.containerRef = React.createRef();
    this.flyoutRef = React.createRef();
    this.onMenuBlur = this.onMenuBlur.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen === true && prevProps.isOpen === false) {
      this.flyoutRef.current.focus();
    }
  }

  onMenuBlur(event) {
    if (!this.containerRef.current.contains(event.relatedTarget)) {
      this.props.updateIsOpen(false);
    }
  }

  render() {
    return (
      <div
        ref={this.containerRef}
        css={css`
          position: relative;
        `}
      >
        <PlainTextButton
          className={this.props.buttonClassName}
          css={this.props.buttonCss}
          onClick={() => this.props.updateIsOpen(!this.props.isOpen)}
          disabled={this.props.disabled}
          data-tracking-item={this.props.trackingItem}
          aria-label={this.props.label}
        >
          {this.props.buttonContent}
        </PlainTextButton>
        {this.props.isOpen && (
          <Flyout
            ref={this.flyoutRef}
            onBlur={this.onMenuBlur}
            css={this.props.menuCSS}
            data-test-id="menu-flyout"
          >
            {this.props.children}
          </Flyout>
        )}
      </div>
    );
  }
}

Menu.propTypes = {
  buttonClassName: PropTypes.string,
  buttonCss: PropTypes.object,
  buttonContent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  /** A css object from emotion.*/
  menuCSS: PropTypes.any,
  updateIsOpen: PropTypes.func.isRequired,
  trackingItem: PropTypes.string,
  label: PropTypes.string
};

const Flyout = React.forwardRef(({ children, className, onBlur }, ref) => {
  return (
    <div
      ref={ref}
      onBlur={onBlur}
      tabIndex={0}
      className={className}
      css={css`
        position: absolute;
        z-index: ${ZIndexes.flyout};
        background: white;
        white-space: nowrap;
        border-radius: 0.125rem;
        ${Mixins.shadowOutsetHeavy};
      `}
    >
      {children}
    </div>
  );
});

Flyout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onBlur: PropTypes.func.isRequired
};
