import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Combobox as ReachCombobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxButton
} from '@reach/combobox';

import { Button } from './Button';
import { Colors, Mixins, ZIndexes } from '../../styles';
import { Icon, IconTypes } from '../icons';

export default function Combobox({ onChange, containerCss, ...props }) {
  return (
    <ComboboxRoot onChange={onChange} additionalCss={containerCss}>
      <ComboboxGuts {...props} onChange={onChange} />
    </ComboboxRoot>
  );
}

Combobox.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string.isRequired),
  optionsDescription: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  containerCss: PropTypes.object
};

export function ReadOnlyCombobox({ onSelect, containerCss, ...props }) {
  const [value, setValue] = useState('');

  return (
    <ComboboxRoot
      onChange={value => {
        onSelect(value);
        setValue('');
      }}
      additionalCss={containerCss}
    >
      <ComboboxGuts value={value} onChange={setValue} {...props} />
    </ComboboxRoot>
  );
}

ReadOnlyCombobox.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string.isRequired),
  optionsDescription: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  containerCss: PropTypes.object
};

function ComboboxRoot({ onChange, additionalCss, children }) {
  return (
    <ReachCombobox
      onSelect={onChange}
      css={css`
        display: inline-block;
        position: relative;
        ${additionalCss}
      `}
    >
      {children}
    </ReachCombobox>
  );
}

function ComboboxGuts({
  options,
  value,
  optionsDescription = 'options',
  ...inputProps
}) {
  const filteredOptions = options?.filter(option =>
    option.toLowerCase().includes(value.toLowerCase())
  );

  return (
    <>
      <LayoutInputWithInsetButton>
        <Input {...inputProps} value={value} />
        {options?.length > 0 && (
          <ShowButton label={`Show ${optionsDescription}`} />
        )}
      </LayoutInputWithInsetButton>
      {filteredOptions && filteredOptions.length > 0 && (
        <OptionsFlyout>
          {filteredOptions.map(option => {
            return <ComboboxOption value={option} key={option} />;
          })}
        </OptionsFlyout>
      )}
    </>
  );
}

function LayoutInputWithInsetButton({ children }) {
  return <div css={Mixins.inputWithInsetIcon}>{children}</div>;
}

function Input({ onChange, value, ...props }) {
  return (
    <ComboboxInput
      {...props}
      css={css`
        ${Mixins.roundedCorners};
        width: 100%;
      `}
      // Disable the browser's built-in autocomplete since we provide our own
      autoComplete="off"
      value={value}
      onChange={event => {
        onChange(event.target.value);
      }}
    />
  );
}

function ShowButton({ label }) {
  return (
    <ComboboxButton
      as={Button}
      flavor="subtle"
      tabIndex="-1"
      aria-label={label}
    >
      <Icon type={IconTypes.CARET_STROKE} direction="down" />
    </ComboboxButton>
  );
}

function OptionsFlyout({ children }) {
  return (
    <ComboboxPopover
      css={css`
        position: absolute;
        width: 100%;
        ${Mixins.roundedCorners};
        ${Mixins.shadowOutset};
        /* Make the list scroll if it gets too long */
        max-height: 8rem;
        overflow: auto;
        /* Reach applies a border to options by default */
        border: none;
        /* Don't let ordinary elements cover up the flyout */
        z-index: ${ZIndexes.flyout};
        /* Highlight selected/hovered options */
        [data-reach-combobox-option] {
          font-size: 0.875rem;
          &[data-highlighted],
          &:hover {
            background: ${Colors.blue0};
          }
        }
      `}
      portal={false}
    >
      <ComboboxList>{children}</ComboboxList>
    </ComboboxPopover>
  );
}
