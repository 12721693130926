import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Concept } from '../../classes/Concepts';
import { Doc } from '../../classes/Docs';
import { Modal } from './Modal';
import { Metadata } from './Metadata';
import { NavigationArrows } from './NavigationArrows';
import { DocumentText } from './DocumentText';
import { Header } from './Header';
import { MetadataField } from '../../classes/MetadataFields';

export function DocumentViewer({
  doc,
  selection,
  language,
  onNext,
  onPrev,
  nextDisabled,
  prevDisabled,
  onClose,
  sidePanelRef,
  fieldsToDisplay
}) {
  return createPortal(
    <Modal sidePanelRef={sidePanelRef} onClose={onClose}>
      <Row data-test-id="document-viewer">
        <NavigationArrows
          onNext={onNext}
          onPrev={onPrev}
          nextDisabled={nextDisabled}
          prevDisabled={prevDisabled}
        />
        <Column>
          <Header title={doc.title} language={language} onClose={onClose} />
          <Row>
            <DocumentText doc={doc} selection={selection} language={language} />
            <Metadata
              docMetadata={doc.metadata}
              fieldsToDisplay={fieldsToDisplay}
            />
          </Row>
        </Column>
      </Row>
    </Modal>,
    document.body
  );
}

DocumentViewer.propTypes = {
  doc: PropTypes.instanceOf(Doc).isRequired,
  selection: PropTypes.instanceOf(Concept),
  language: PropTypes.string.isRequired,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  nextDisabled: PropTypes.bool.isRequired,
  prevDisabled: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sidePanelRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  fieldsToDisplay: PropTypes.arrayOf(PropTypes.instanceOf(MetadataField))
    .isRequired
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  min-height: 0;
  height: 100%;
  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;
