import React from 'react';
import PropTypes from 'prop-types';

import { useDefaultFontSize } from '../utils/hooks';
import getTextWidth from '../utils/getTextWidth';

export function VisualizationLegend({
  width,
  y,
  leftKeyColor,
  leftKeyLabel,
  rightKeyColor,
  rightKeyLabel
}) {
  const fontSize = `${useDefaultFontSize()}px`;
  const spacing =
    (width - getLegendWidth(fontSize, leftKeyLabel, rightKeyLabel)) / 2;

  return (
    <g>
      <LeftKey
        leftSpacing={spacing}
        y={y}
        color={leftKeyColor}
        label={leftKeyLabel}
      />
      <RightKey
        rightSpacing={spacing}
        visualizationWidth={width}
        y={y}
        color={rightKeyColor}
        label={rightKeyLabel}
      />
    </g>
  );
}

VisualizationLegend.propTypes = {
  width: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  leftKeyColor: PropTypes.string.isRequired,
  leftKeyLabel: PropTypes.string.isRequired,
  rightKeyColor: PropTypes.string.isRequired,
  rightKeyLabel: PropTypes.string.isRequired
};

function LeftKey({ leftSpacing, y, color, label }) {
  return <VisualizationKey color={color} label={label} x={leftSpacing} y={y} />;
}

LeftKey.propTypes = {
  leftSpacing: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

function RightKey({ rightSpacing, visualizationWidth, y, color, label }) {
  const fontSize = `${useDefaultFontSize()}px`;
  const keyWidth = getVisualizationKeyWidth(label, fontSize);
  const x = visualizationWidth - rightSpacing - keyWidth;

  return <VisualizationKey color={color} label={label} x={x} y={y} />;
}

RightKey.propTypes = {
  rightSpacing: PropTypes.number.isRequired,
  visualizationWidth: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

function getVisualizationKeyWidth(label, fontSize) {
  const radius = 8;
  const padding = 10;

  return 2 * radius + padding + getTextWidth(label, fontSize);
}

function VisualizationKey({ color, label, x, y }) {
  const radius = 8;
  const padding = 10;

  return (
    <g>
      <circle cx={x + radius} cy={y} r={radius} fill={color} />
      <text x={x + 2 * radius + padding} y={y} dominantBaseline="central">
        {label}
      </text>
    </g>
  );
}

VisualizationKey.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired
};

function getLegendWidth(fontSize, leftText, rightText) {
  const paddingBetweenKeys = 10;
  const leftTextWidth = getVisualizationKeyWidth(leftText, fontSize);
  const rightTextWidth = getVisualizationKeyWidth(rightText, fontSize);
  return leftTextWidth + rightTextWidth + paddingBetweenKeys;
}
