import React, { useState, useContext } from 'react';
import { css } from '@emotion/core';
import { useParams } from 'react-router-dom';

import { Card } from '../../components/Card';
import { InlineChangeArrow, Title } from '../components';
import { CurrentBuildCard, NextBuildCard } from './ConceptsCard';
import Spinner from '../../components/core/Spinner';
import EditAssertionsModal from './EditAssertionsModal';
import { Button } from '../../components/core/Button';
import { useFetch } from '../../utils/hooks';
import { getFilterInfo } from '../../utils/ApiUtilsV5';
import { StoreContext } from '../../StoreContext';
import { useConceptManagement } from '../../data_hooks';

export const IgnoreAssertions = () => {
  const { projectId } = useParams();

  // We need the list of the top 500 (unfiltered) concepts to populate the
  // Autosuggest options. Note that calling getFilterInfo without a filter
  // argument is basically equivalent to fetching the top 500 unfiltered
  // concepts.
  const { response } = useFetch(getFilterInfo, projectId);
  const topConcepts = response ? response.concepts.map(c => c.name) : [];

  const { project, workspaces } = useContext(StoreContext);
  const workspace = workspaces.find(
    w => w.workspace_id === project.workspace_id
  );
  const canEditAssertions = workspace.permissions.includes('account_manage');
  const { conceptManagement, updateNextBuild } = useConceptManagement(project);

  return (
    <IgnoreAssertionsInner
      conceptManagement={conceptManagement}
      showEditButton={canEditAssertions}
      topConcepts={topConcepts}
      updateNextBuild={updateNextBuild}
    />
  );
};

export const IgnoreAssertionsInner = ({
  conceptManagement,
  showEditButton,
  topConcepts,
  updateNextBuild
}) => {
  const [showEditModal, setShowEditModal] = useState(false);

  return conceptManagement === undefined ? (
    <LoadingIgnoreAssertions />
  ) : (
    <>
      <IgnoreTitle
        editOnClick={() => setShowEditModal(true)}
        showEditButton={showEditButton}
      />
      <Card.Separator />
      <AssertionsInformation conceptManagement={conceptManagement} />
      <EditAssertionsModal
        isOpen={showEditModal}
        onHide={() => setShowEditModal(false)}
        apiConceptManagement={conceptManagement}
        topConcepts={topConcepts}
        updateNextBuild={updateNextBuild}
      />
    </>
  );
};

const LoadingIgnoreAssertions = () => (
  <Card.Section
    css={css`
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    `}
  >
    <Spinner />
    <div> Loading ignore assertions... </div>
  </Card.Section>
);

const AssertionsInformation = ({ conceptManagement }) => (
  <Card.Section
    css={css`
      display: flex;
      flex-direction: row;
      max-height: 20rem;
    `}
  >
    <CurrentBuildCard currentConcepts={conceptManagement.currentBuild.ignore} />

    <div
      css={css`
        padding: 0.5rem;
      `}
    >
      <InlineChangeArrow />
    </div>

    <NextBuildCard
      hasChanges={conceptManagement.hasIgnoreChanges}
      conceptsDiff={conceptManagement.ignoreDiff}
    />
  </Card.Section>
);

const IgnoreTitle = ({ editOnClick, showEditButton }) => {
  return (
    <>
      <Card.Section
        css={css`
          display: flex;
          flex-direction: row;
        `}
      >
        <Title
          css={css`
            margin: auto 0;
            font-size: 1.25rem;
          `}
        >
          Ignore
        </Title>
        {showEditButton && (
          <Button
            palette="green"
            css={css`
              margin-left: auto;
            `}
            onClick={editOnClick}
          >
            Edit
          </Button>
        )}
      </Card.Section>
    </>
  );
};
