import React from 'react';
import { css } from '@emotion/core';

import { Icon, IconTypes } from '../icons';

export default function ExternalLink({ children, ...passedProps }) {
  return (
    <a {...passedProps} rel="noopener noreferrer" target="_blank">
      {children}
      <Icon
        type={IconTypes.LAUNCHER}
        size="16"
        css={css`
          // Add space between text and icon that won't get an underline
          margin-left: 0.1rem;
          vertical-align: text-bottom;
        `}
      />
    </a>
  );
}
