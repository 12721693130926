import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import css from '@emotion/css';

import { GridLineXAxis } from '../components/GridLineXAxis';
import { StoreContext } from '../StoreContext';
import { Colors } from '../styles';
import { Concept } from '../classes/Concepts';
import {
  Body,
  Cell,
  Header,
  HeaderCell,
  LoadingTableBody,
  Row,
  ScrollableTable
} from '../components/core/Table';
import { selectConcept } from '../actions';
import ConceptName from '../components/Concept';
import { thousandify } from '../utils/NumFmtUtils';
import { DisplaySameToggle } from './DisplaySameToggle';
import { sortConcepts } from './utils';
import { NumericField, ScoreField } from '../classes/MetadataFields';
import getTextWidth from '../utils/getTextWidth';
import { DriverContext } from './DriverContext';
import * as gtm from '../utils/gtm';
import { AlertTypes, RequestStatuses } from '../constants';
import Alert from '../components/core/Alert';
import { useBoundingClientRect } from '../utils/hooks';
import { useFilter, useSearchParams } from '../search_params';
import { Overlay } from './Overlay';
import { useCurrentView } from '../side_panel/views/view';
import { useTotalMatches } from '../utils/hooks/useTotalMatches';
import { getDrivers } from '../utils/ApiUtilsV5';

export function ConceptList({ projectId, scoreField, isComparisonList }) {
  const tableRef = useRef();
  const { searchParams, updateSearch } = useSearchParams();
  const matchType = searchParams.match_type;
  const displaySame = isComparisonList
    ? searchParams.display_same === 'true'
    : undefined;
  const [column, direction] = searchParams.sortby.split('-');
  const { activeConcepts } = useContext(StoreContext);
  const { drivers, status } = useContext(DriverContext);
  const sortedDrivers = useMemo(
    () => sortConcepts(drivers, activeConcepts, column, direction),
    [column, direction, activeConcepts, drivers]
  );
  const bodyRef = useRef();
  const { height: bodyHeight } = useBoundingClientRect(bodyRef);
  const baseline = drivers && drivers.length ? drivers[0].baseline : 0;

  function onToggleSame() {
    updateSearch({ display_same: (!displaySame).toString() });
  }

  const isLoading = status === RequestStatuses.PENDING;

  return (
    <div
      css={css`
        flex: 1;
        min-height: 15rem;
        height: 100%;
        display: flex;
        flex-direction: column;
      `}
    >
      <DisplaySameToggle
        displaySame={displaySame}
        onToggleSame={onToggleSame}
      />
      <ScrollableTable
        ref={tableRef}
        onSortChange={(column, direction) => {
          updateSearch({ sortby: `${column}-${direction}` });
        }}
        sortDirection={direction}
        sortedColumn={column}
        header={
          <ConceptListHeader
            baseline={baseline}
            scoreField={scoreField}
            bodyHeight={bodyHeight}
          />
        }
        body={
          status === RequestStatuses.FULFILLED ? (
            <ConceptListBody
              ref={bodyRef}
              projectId={projectId}
              concepts={sortedDrivers}
              scoreField={scoreField}
              baseline={baseline}
              matchType={matchType}
            />
          ) : status === RequestStatuses.PENDING ? (
            <LoadingTableBody />
          ) : (
            <Alert type={AlertTypes.ERROR}>Something went wrong!</Alert>
          )
        }
      />
      <Overlay
        isLoading={isLoading}
        fieldName={searchParams.field}
        hasNoData={!sortedDrivers || sortedDrivers.length === 0}
        comparing={isComparisonList}
      />
    </div>
  );
}

ConceptList.propTypes = {
  projectId: PropTypes.string.isRequired,
  scoreField: PropTypes.oneOfType([
    PropTypes.instanceOf(ScoreField),
    PropTypes.instanceOf(NumericField)
  ]),
  isComparisonList: PropTypes.bool
};

function ConceptListHeader({ baseline, scoreField, bodyHeight }) {
  const barCellHeaderRef = useRef();
  const isTotalMatches = useTotalMatches();
  const currentHeader = isTotalMatches ? 'Total matches' : 'Exact matches';
  const { height: headerHeight, width } = useBoundingClientRect(
    barCellHeaderRef
  );
  return (
    <Header borderColor={Colors.gray1}>
      <Row
        css={css`
          grid-template-columns: 20% 15% 10% 10% auto 1rem;
          padding: 0 0.5rem;
        `}
      >
        <HeaderCell sortingKey="name" invertedSort>
          Concepts
        </HeaderCell>
        <HeaderCell sortingKey="matchCount">{currentHeader}</HeaderCell>
        <HeaderCell sortingKey="averageScore">Average score</HeaderCell>
        <HeaderCell sortingKey="absoluteDifference">
          Absolute difference
        </HeaderCell>
        <Cell
          css={css`
            position: relative;
          `}
          ref={barCellHeaderRef}
        >
          <OverallAverageScore
            baseline={baseline}
            scoreField={scoreField}
            height={headerHeight + bodyHeight}
            width={width}
            headerHeight={headerHeight}
          />
        </Cell>
      </Row>
    </Header>
  );
}

ConceptListHeader.propTypes = {
  bodyHeight: PropTypes.number.isRequired,
  baseline: PropTypes.number,
  scoreField: PropTypes.oneOfType([
    PropTypes.instanceOf(ScoreField),
    PropTypes.instanceOf(NumericField)
  ])
};

const ConceptListBody = React.forwardRef(function ConceptListBody(
  { projectId, concepts, scoreField, baseline, matchType },
  ref
) {
  const filter = useFilter();
  const { selection } = useContext(StoreContext);
  const { conceptSelector } = useCurrentView();

  useEffect(() => {
    matchType === 'exact' &&
      getDrivers(projectId, filter, scoreField, conceptSelector, matchType);
  }, [matchType]);

  return (
    <Body ref={ref}>
      {concepts.map(concept => (
        <ConceptListRow
          key={concept.hash()}
          concept={concept}
          matchType={matchType}
          selection={selection}
          projectId={projectId}
          filter={filter}
          baseline={baseline}
          scoreField={scoreField}
        />
      ))}
    </Body>
  );
});

ConceptListBody.propTypes = {
  projectId: PropTypes.string.isRequired,
  concepts: PropTypes.array.isRequired,
  scoreField: PropTypes.oneOfType([
    PropTypes.instanceOf(ScoreField),
    PropTypes.instanceOf(NumericField)
  ]),
  baseline: PropTypes.number.isRequired,
  matchType: PropTypes.string
};

function ConceptListRow({
  concept,
  selection,
  projectId,
  filter,
  baseline,
  scoreField
}) {
  const selected = Concept.areTextsEqual(selection, concept);
  const isTotalMatches = useTotalMatches();
  return (
    <Row
      onClick={() => {
        selectConcept(projectId, concept, filter);
        gtm.trigger('concept-list-table_selectable-concept-row');
      }}
      active={selected}
      bordered={selected}
      css={css`
        grid-template-columns: 20% 15% 10% 10% auto 1rem;
        padding: 0 0.5rem;
      `}
    >
      <Cell>
        <ConceptName concept={concept} stopPropagation />
      </Cell>
      <Cell>
        {thousandify(
          !isTotalMatches ? concept.exactMatchCount : concept.matchCount
        )}
      </Cell>
      <Cell>{concept.averageScore.toFixed(2)}</Cell>
      <Cell>{Math.abs(concept.impact).toFixed(2)}</Cell>
      <Cell>
        <ScoreChart
          scoreValue={concept.averageScore}
          baseline={baseline}
          scoreField={scoreField}
        />
      </Cell>
    </Row>
  );
}

const ScoreChart = ({ scoreValue, baseline, scoreField }) => {
  if (!scoreField) return null;

  const width =
    ((scoreValue.toFixed(2) - scoreField.minimum) /
      (scoreField.maximum - scoreField.minimum)) *
    100;
  const fill = scoreValue > baseline ? Colors.blue2 : Colors.red2;
  return (
    <svg
      css={css`
        height: 2rem;
        width: 100%;
      `}
    >
      <rect width={`${width}%`} height="95%" y="2.5%" x="0%" fill={fill} />
    </svg>
  );
};

const OverallAverageScore = ({
  baseline,
  scoreField,
  width,
  height,
  headerHeight
}) => {
  if (!scoreField || !baseline) {
    return null;
  }

  const x =
    (width * (baseline - scoreField.minimum)) /
    (scoreField.maximum - scoreField.minimum);

  const baselineLabel = ['Overall average score: ', baseline.toFixed(2)];
  const baselineLabelWidth =
    getTextWidth(baselineLabel[0], '0.875rem') +
    getTextWidth(baselineLabel[1], '0.875rem', 'bold');

  const labelStart = x - baselineLabelWidth / 2;
  const labelEnd = x + baselineLabelWidth / 2;
  let labelTranslation = '';
  if (labelEnd > width) {
    labelTranslation = `translate(-${Math.min(
      labelEnd - width,
      baselineLabelWidth / 2
    )}, 0)`;
  } else if (labelStart < 0) {
    labelTranslation = `translate(${Math.min(
      -labelStart,
      baselineLabelWidth / 2
    )}, 0)`;
  }

  return (
    <svg
      css={css`
        position: absolute;
        width: 100%;
        top: 0;
        height: ${height}px;
        pointer-events: none;
        z-index: 1;
      `}
    >
      <GridLineXAxis
        domain={[scoreField.minimum, scoreField.maximum]}
        x1={0}
        x2={width}
        y1={headerHeight / 2}
        y2={height}
        lineMinY={headerHeight}
      />
      <text
        x={x}
        y={headerHeight / 2 - 4}
        textAnchor="middle"
        css={css`
          font-weight: normal;
          font-size: 0.875rem;
        `}
        transform={`${labelTranslation}`}
      >
        {baselineLabel[0]}
        <tspan fontWeight="bold">{baselineLabel[1]}</tspan>
      </text>

      <line
        x1={x}
        y1={headerHeight / 2 + 4}
        x2={x}
        y2={height}
        stroke={Colors.gray9}
        strokeOpacity="0.6"
        strokeDasharray="5"
        strokeWidth="2"
      />
    </svg>
  );
};

OverallAverageScore.propTypes = {
  baseline: PropTypes.number,
  scoreField: PropTypes.oneOfType([
    PropTypes.instanceOf(NumericField),
    PropTypes.instanceOf(ScoreField)
  ]),
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  headerHeight: PropTypes.number.isRequired
};
