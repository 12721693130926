import React from 'react';
import { css } from '@emotion/core';

import InlineEditor from '../components/InlineEditor';

export default function EditableName({ namedThing }) {
  return (
    <InlineEditor
      onChange={newName => namedThing.modifyName(newName)}
      name={`${namedThing.identifier} name`}
      value={namedThing.name}
      css={css`
        // Match the vertical padding of 1.5 times an h2 as in the original
        // EditableName
        padding: 1.6875rem 0.05rem;
        // Set font to match an h2
        span,
        input {
          font-size: 2.25rem;
          font-weight: 300;
        }
        // Match styling of original EditableName
        input {
          margin: 0px;
          display: inline;
          padding: 0.25rem 0.5rem;
        }
        button {
          margin-top: 0.375rem;
        }
      `}
    />
  );
}
