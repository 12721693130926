import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import { AXIS_LABEL_OFFSETS } from './constants';
import { Colors } from '../styles';
import { useUniqueId } from '../utils/hooks';

export const XAxis = props => (
  <g className="scatter-plot__x-axis">
    {props.scale.ticks().map(tick => {
      const x = props.scale(tick);
      return (
        <g key={tick}>
          <GridLine tick={tick} x1={x} y1={props.yMin} x2={x} y2={props.yMax} />
          <text
            className="scatter-plot__label"
            x={x}
            y={props.yMax}
            dy={AXIS_LABEL_OFFSETS.y}
            textAnchor="middle"
          >
            {props.getTickLabel(tick)}
          </text>
        </g>
      );
    })}
    <text
      className="scatter-plot__axis-label"
      x={(props.xMin + props.xMax) / 2}
      y={props.yMax + 60}
      textAnchor="middle"
    >
      {props.axisTitle}
    </text>
  </g>
);

export const YAxis = props => (
  <g className="scatter-plot__y-axis">
    {props.scale.ticks().map(tick => {
      const y = props.scale(tick);
      return (
        <g key={tick}>
          <GridLine tick={tick} x1={props.xMin} y1={y} x2={props.xMax} y2={y} />
          <text
            className="scatter-plot__label"
            x={props.xMin}
            y={y}
            dx={-AXIS_LABEL_OFFSETS.x}
            alignmentBaseline="middle"
            textAnchor="end"
          >
            {props.getTickLabel(tick)}
          </text>
        </g>
      );
    })}
    <text
      className="scatter-plot__axis-label"
      x={-((props.yMin + props.yMax) / 2)}
      y={props.xMin - 60}
      transform="rotate(-90)"
      textAnchor="middle"
    >
      {props.axisTitle}
    </text>
  </g>
);

export function Baseline({
  mainClipPathId,
  label,
  labelWidth,
  baseline,
  xMin,
  xMax,
  yMin,
  yMax,
  scale
}) {
  const labelClipPathId = 'label-clip-path' + useUniqueId();
  const [clippedYMin, clippedYMax] = scale.range();

  const yPosition = scale(baseline);
  const labelStart = yPosition - labelWidth / 2;
  const labelEnd = yPosition + labelWidth / 2;

  let labelTranslation;
  if (labelStart < yMin) {
    labelTranslation = `translate(0, ${Math.min(
      yMin - labelStart,
      labelWidth / 2
    )})`;
  } else if (labelEnd > yMax) {
    labelTranslation = `translate(0, ${Math.max(
      yMax - labelEnd,
      -labelWidth / 2
    )})`;
  } else {
    labelTranslation = '';
  }

  return (
    <g>
      <defs>
        <clipPath id={labelClipPathId}>
          <rect
            x={xMin}
            // we only care about clipping the label vertically
            width={100 + xMax - xMin}
            y={clippedYMax}
            height={clippedYMin - clippedYMax}
          />
        </clipPath>
      </defs>
      <g clipPath={`url(#${mainClipPathId})`}>
        <line
          x1={xMin}
          x2={xMax}
          y1={yPosition}
          y2={yPosition}
          css={css`
            stroke: ${Colors.gray9};
            stroke-opacity: 0.6;
            stroke-width: 2px;
            stroke-dasharray: 4 6;
            stroke-linecap: round;
          `}
        />
      </g>
      <g clipPath={`url(#${labelClipPathId})`}>
        <text
          x={yPosition}
          y={-xMax - 7}
          fontSize="0.875rem"
          transform={`${labelTranslation} rotate(90)`}
          textAnchor="middle"
        >
          {label}
        </text>
      </g>
    </g>
  );
}

Baseline.propTypes = {
  mainClipPathId: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  labelWidth: PropTypes.number.isRequired,
  baseline: PropTypes.number.isRequired,
  xMin: PropTypes.number.isRequired,
  xMax: PropTypes.number.isRequired,
  yMin: PropTypes.number.isRequired,
  yMax: PropTypes.number.isRequired,
  scale: PropTypes.func.isRequired
};

const GridLine = ({ tick, ...otherProps }) => (
  <line
    className={cx('scatter-plot-grid__line', {
      'scatter-plot-grid__line--dashed': tick === 0
    })}
    {...otherProps}
  />
);
