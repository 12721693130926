import React, { useContext, useState } from 'react';
import css from '@emotion/css';

import { StoreContext } from '../StoreContext';
import { DeleteProjectButton } from '../highlights/DeleteProjectButton';
import { Card } from '../components/Card';
import {
  BuildTimeEstimateWithContext,
  RebuildModalWithContext
} from './RebuildModal';
import { objHasPerm } from '../utils/common';
import { Button } from '../components/core/Button';
import { Icon, IconTypes } from '../components/icons';
import { AuthContext } from '../settings/model';
import { getBuildChanges } from './utils';
import { useConceptManagement } from '../data_hooks';

export const Footer = () => {
  const { serverStatus } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const { project } = useContext(StoreContext);
  const { conceptManagement } = useConceptManagement(project);
  const { canRebuild } = getBuildChanges(
    project,
    serverStatus,
    conceptManagement
  );

  // Both the delete button and the rebuild button (so basically, the whole footer)
  // are contingent on the user having at least the 'write' permission.
  //
  // Note in the gui spec a user with a read-only permission is specified as
  // seeing a footer with just the trademark in it.
  //
  // However we have the trademark showing up as a part of the project management
  // page itself, so for the footer it is sufficient to just remove the footer
  // altogether for a user who has only the 'read' permission on the project.
  if (!objHasPerm(project, 'write')) {
    return null;
  }

  return (
    <>
      <FooterPanel
        onClick={() => {
          setShowModal(true);
        }}
        canRebuild={canRebuild}
      />
      <RebuildModalWithContext
        isOpen={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
      />
    </>
  );
};

export const FooterPanel = ({ onClick, canRebuild }) => {
  return (
    <Card data-test-id="footer">
      <Card.Section
        css={css`
          display: flex;
          flex-direction: row;
          gap: 0.5rem;
        `}
      >
        <Button palette="green" onClick={onClick} disabled={!canRebuild}>
          <Icon type={IconTypes.CLOCK} size="18" />
          Rebuild
        </Button>
        {canRebuild && (
          <div
            css={css`
              margin: auto 0px;
            `}
          >
            <BuildTimeEstimateWithContext boldEstimate />
          </div>
        )}
        <div
          css={css`
            margin-left: auto;
          `}
        >
          <DeleteProjectButton />
        </div>
      </Card.Section>
    </Card>
  );
};
