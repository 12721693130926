import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const List = React.forwardRef(function List(
  {
    bordered = true,
    hoverable = true,
    scrollable = true,
    lined = true,
    className,
    ...passedProps
  },
  ref
) {
  return (
    <div
      ref={ref}
      {...passedProps}
      className={cx(className, 'list', {
        'list--bordered': bordered,
        'list--hoverable': hoverable,
        'list--scrollable': scrollable,
        'list--lined': lined
      })}
    />
  );
});

List.propTypes = {
  className: PropTypes.string,
  hoverable: PropTypes.bool,
  scrollable: PropTypes.bool,
  lined: PropTypes.bool
};

export default List;
