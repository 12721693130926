import React from 'react';

import { FieldSelector } from './FieldSelector';
import { WhichConceptsToVisualize } from './WhichConceptsToVisualize';
import { SyncScoreFields } from '../../drivers/SyncScoreFields';
import { ConfigSectionWrapper } from './ConfigWrapper';

export function DriversSettings() {
  return (
    <>
      <WhichConceptsToVisualize />
      <ConfigSectionWrapper>
        <FieldSelector />
      </ConfigSectionWrapper>
      <ConfigSectionWrapper>
        <SyncScoreFields />
      </ConfigSectionWrapper>
    </>
  );
}
