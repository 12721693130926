import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export default class Progress extends React.Component {
  render() {
    const { className, ...passedProps } = this.props;
    return (
      <progress {...passedProps} className={cx('lumi-progress', className)} />
    );
  }
}

Progress.propTypes = {
  className: PropTypes.string,
  max: PropTypes.number,
  value: PropTypes.number.isRequired
};
