import GroupStatsWorker from './GroupStatsWorker.worker';
import { simplifyMessage } from './GroupStatsWorkerEngine';

let nextUniqueId = 1;

/**
 * Interface for passing messages to the GroupStatsWorker, and defining a
 * handler to process the messages it sends back.  It's important to go through
 * this interface, rather than using GroupStatsWorker directly, because this
 * has a mock implementation that can be used in the Jest tests.
 */
export default class GroupStatsWorkerInterface {
  constructor(messageHandler) {
    this.id = nextUniqueId++;
    this.worker = new GroupStatsWorker();
    this.messageHandler = messageHandler;
    this.worker.onmessage = this._onmessage.bind(this);
    this._onerror = this._onerror.bind(this);
    this.worker.onerror = this._onerror;
    this.worker.onmessageerror = this._onerror;
  }

  _onmessage(message) {
    this.messageHandler(message.data);
  }

  postMessage(data) {
    this.worker.postMessage(simplifyMessage(data));
  }

  _onerror(error) {
    console.error('Error from GroupStatsWorker: ', error);
    this.handleError?.(this.id, error);
  }

  registerErrorHandler(handleError) {
    this.handleError = handleError;
  }

  terminate() {
    // Unsetting onmessage is probably redundant -- terminate() should take
    // care of that.  But since we can't test this code, let's play it extra
    // safe.
    this.worker.onmessage = null;
    this.worker.terminate();
  }
}
