import React from 'react';
import PropTypes from 'prop-types';

import ActiveConceptSelector from './ActiveConceptSelector';
import Tooltip from '../components/core/Tooltip';
import { Button } from '../components/core/Button';
import { StoreContext } from '../StoreContext';
import { Icon, IconTypes } from '../components/icons';

export default function AxisSelector({ axis, selectedConceptId, setAxis }) {
  const { activeConcepts } = React.useContext(StoreContext);
  const [isOpen, setIsOpen] = React.useState(false);

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);
  const label = selectedConceptId
    ? activeConcepts.find(ac => ac.sharedConceptId === selectedConceptId).name
    : 'Auto';

  return (
    <div className="cloud-axis-selector">
      <Tooltip
        anchor={
          <Button
            className="cloud-axis-btn"
            onClick={() => {
              if (isOpen) {
                close();
              } else {
                open();
              }
            }}
            data-tracking-item={`galaxy_${axis}-axis-button`}
            disabled={activeConcepts.length === 0}
          >
            <Icon type={IconTypes.COORDINATE} plane={axis} />
            {label}
          </Button>
        }
      >
        {activeConcepts.length > 0
          ? `Select ${axis}-axis`
          : "You don't have any active concepts"}
      </Tooltip>
      {isOpen && (
        <ActiveConceptSelector
          concepts={activeConcepts.filter(concept => !concept.boolean)}
          selectedConceptId={selectedConceptId}
          onBlur={close}
          onConceptSelect={setAxis}
          placeholder="Select axis"
          axis={axis}
        />
      )}
    </div>
  );
}

AxisSelector.propTypes = {
  axis: PropTypes.oneOf(['x', 'y']).isRequired,
  selectedConceptId: PropTypes.string,
  setAxis: PropTypes.func.isRequired
};
