import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

import { Concept } from '../../classes/Concepts';
import PlaceholderText from '../../components/core/PlaceholderText';
import { Colors } from '../../styles';
import { percentify, thousandify } from '../../utils/NumFmtUtils';
import DetailsListRow from './DetailsListRow';

export function NonACSDetails({ selection, ...matchCountProps }) {
  const total = selection?.matchCount;
  const exact = selection?.exactMatchCount;
  const conceptual = total - exact;

  return (
    <>
      <MatchCount
        label="Exact"
        count={exact}
        selection={selection}
        {...matchCountProps}
      />
      <MatchCount
        label="Conceptual"
        count={conceptual}
        selection={selection}
        {...matchCountProps}
      />
      <MatchCount
        label="Total"
        count={total}
        selection={selection}
        {...matchCountProps}
      />
    </>
  );
}

NonACSDetails.propTypes = {
  selection: PropTypes.instanceOf(Concept)
};

function MatchCount({ label, count, documentCount, selection, loading }) {
  return (
    <DetailsListRow
      data-test-id="match-count-row"
      left={<span>{label}</span>}
      right={
        loading ? (
          <PlaceholderText style={{ width: '5rem' }} />
        ) : selection ? (
          <>
            {thousandify(count)}{' '}
            <LightText>({percentify(count, documentCount)})</LightText>
          </>
        ) : (
          <LightText>N/A</LightText>
        )
      }
    />
  );
}

MatchCount.propTypes = {
  label: PropTypes.string.isRequired,
  count: PropTypes.number,
  documentCount: PropTypes.number.isRequired,
  selection: PropTypes.instanceOf(Concept)
};

const LightText = styled.span`
  color: ${Colors.gray5};
`;
