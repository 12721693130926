import _ from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

const getWeeksSurroundingDate = dateInMonth =>
  [0, 1, 2, 3, 4, 5].map(weekNumber =>
    [0, 1, 2, 3, 4, 5, 6].map(dayNumber =>
      dateInMonth.clone().startOf('month').startOf('week').add({
        weeks: weekNumber,
        days: dayNumber
      })
    )
  );

export default function Calendar({
  dateInMonth = moment.utc(),
  enabledDatesStart,
  enabledDatesEnd,
  startDate = null,
  endDate = null,
  onSelectStart,
  onSelectEnd
}) {
  const handleDateSelect = date => {
    if (date.isSame(endDate, 'day')) {
      onSelectEnd(null);
    } else if (date.isSame(startDate, 'day')) {
      onSelectStart(null);
    } else if (startDate != null) {
      if (date.isBefore(startDate, 'day')) {
        onSelectStart(date);
      } else {
        onSelectEnd(date);
      }
    } else if (date.isAfter(endDate, 'day')) {
      onSelectEnd(date);
    } else {
      onSelectStart(date);
    }
  };

  return (
    <div className="calendar">
      <table className="calendar__table">
        <thead className="calendar__header">
          <tr>
            <td className="calendar__current-month" colSpan={7}>
              {dateInMonth.format('MMMM YYYY')}
            </td>
          </tr>
          <tr className="calendar__week-names">
            {[0, 1, 2, 3, 4, 5, 6].map(day => {
              const weekdayName = moment().day(day).format('dd');

              return (
                <td className="calendar__week-name" key={weekdayName}>
                  {weekdayName}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {getWeeksSurroundingDate(dateInMonth).map(week => {
            return (
              <tr className="calendar__row" key={_.first(week).toISOString()}>
                {week.map(date => {
                  const isSelectedStart = date.isSame(startDate, 'day');
                  const isSelectedEnd = date.isSame(endDate, 'day');

                  const isInAllowedRange = !(
                    enabledDatesStart != null &&
                    enabledDatesEnd != null &&
                    !date.isBetween(
                      enabledDatesStart,
                      enabledDatesEnd,
                      'day',
                      '[]'
                    )
                  );

                  const isInSelectedRange = date.isBetween(
                    startDate,
                    endDate,
                    'day'
                  );
                  const isInMonth = date.isSame(dateInMonth, 'month');

                  return (
                    <td
                      key={date.toISOString()}
                      className={cx('calendar__cell', {
                        'calendar__cell--selected':
                          (isSelectedStart || isSelectedEnd) && isInMonth,
                        'calendar__cell--inside-selected-range':
                          isInSelectedRange && isInMonth,
                        'calendar__cell--different-month': !isInMonth,
                        'calendar__cell--disabled': !isInAllowedRange
                      })}
                    >
                      {isInMonth ? (
                        <div
                          className={cx('calendar__day-container', {
                            'calendar__day-container--start': isSelectedStart,
                            'calendar__day-container--end': isSelectedEnd
                          })}
                        >
                          <button
                            type="button"
                            className={cx('calendar__day', {
                              'calendar__day--selected':
                                isSelectedStart || isSelectedEnd,
                              'calendar__day--inside-selected-range':
                                isInAllowedRange && isInSelectedRange
                            })}
                            disabled={!isInAllowedRange}
                            data-date={date.format('YYYY-MM-DD')}
                            onClick={handleDateSelect.bind(null, date)}
                          >
                            {date.format('D')}
                          </button>
                        </div>
                      ) : (
                        <div className="calendar__day-container" />
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

Calendar.propTypes = {
  dateInMonth: PropTypes.instanceOf(moment),
  enabledDatesStart: PropTypes.instanceOf(moment),
  enabledDatesEnd: PropTypes.instanceOf(moment),
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
  onSelectStart: PropTypes.func.isRequired,
  onSelectEnd: PropTypes.func.isRequired
};
