import buildRoutePath from '../utils/buildRoutePath';
import * as SearchUtils from '../search_params/SearchUtils';
import getTextWidth from '../utils/getTextWidth';

export function getMoreDetailLink(
  workspaceId,
  projectId,
  routePattern,
  searchParams = {}
) {
  return {
    pathname: buildRoutePath(routePattern, { workspaceId, projectId }),
    search: SearchUtils.stringify(searchParams)
  };
}

/** Roughly centers <text> element in a row */
export function centerTextY(index, yScale) {
  return yScale(index) + 2 + yScale.bandwidth() / 2;
}

export function getLongestTextLength(texts, fontSize, fontWeight) {
  return Math.max(
    ...texts.map(text => getTextWidth(text, fontSize, fontWeight))
  );
}
