import _ from 'lodash';
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { naturalSortByName } from '../../utils/NaturalSort';
import { Checkbox } from '../../components/core/Checkbox';
import { MetadataField } from '../../classes/MetadataFields';
import { selectedFieldsProps } from './useMetadataFilter';

export const FieldTable = ({ metadata, selectedFields }) => {
  return (
    <Table>
      <Header selectedFields={selectedFields} />
      <tbody>
        {metadata.sort(naturalSortByName).map(field => (
          <Row key={field.name} field={field} selectedFields={selectedFields} />
        ))}
      </tbody>
    </Table>
  );
};

FieldTable.propTypes = {
  metadata: PropTypes.arrayOf(PropTypes.instanceOf(MetadataField)).isRequired,
  selectedFields: selectedFieldsProps.isRequired
};

const Table = styled.table`
  text-align: left;
  display: block;
  overflow: scroll;

  /* Make header sticky and hide content that scrolls underneath it */
  thead {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
  }

  td,
  th {
    padding: 0.5rem;
    max-width: 60ch;
  }

  td:not(:last-child),
  th:not(:last-child) {
    padding-right: 1rem;
  }

  td:first-child {
    word-break: break-word;
  }
`;

const SelectAllRow = ({ selectedFields }) => {
  return (
    <tr>
      <th />
      <th />
      <th>
        <SelectAllCheckbox selections={selectedFields.sidePanel} />
      </th>
      <th>
        <SelectAllCheckbox selections={selectedFields.viewer} />
      </th>
    </tr>
  );
};

const Header = ({ selectedFields }) => {
  return (
    <thead>
      <tr>
        <th>Field</th>
        <th>Type</th>
        <th>Show in side panel</th>
        <th>Show in viewer</th>
      </tr>
      <SelectAllRow selectedFields={selectedFields} />
    </thead>
  );
};

const Row = ({ field, selectedFields: { viewer, sidePanel } }) => {
  return (
    <tr>
      <td>{field.name}</td>
      <td>{_.capitalize(field.type)}</td>
      <td>
        <Checkbox
          data-tracking-item="documents-pane-field-checkbox"
          aria-label={`Show "${field.name}" in side panel`}
          checked={sidePanel.fields.includes(field)}
          onChange={() => sidePanel.toggleField(field)}
        />
      </td>
      <td>
        <Checkbox
          data-tracking-item="documents-pane-field-checkbox"
          aria-label={`Show "${field.name}" in viewer`}
          checked={viewer.fields.includes(field)}
          onChange={() => viewer.toggleField(field)}
        />
      </td>
    </tr>
  );
};

const SelectAllCheckbox = ({ selections }) => {
  return (
    <Label>
      <Checkbox
        data-tracking-item="documents-pane-field-checkbox"
        checked={selections.all}
        indeterminate={selections.any && !selections.all}
        onChange={selections.toggleAllFields}
      />
      <span>Select all</span>
    </Label>
  );
};

const Label = styled.label`
  cursor: pointer;
  > :last-child {
    margin-left: 0.5rem;
  }
`;
