// This is based on the "drop target for native files" section of react-dnd's FAQ
// https://react-dnd.github.io/react-dnd/docs-faq.html
import React from 'react';
import PropTypes from 'prop-types';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTarget } from 'react-dnd';

const fileTarget = {
  drop(props, monitor) {
    props.onDrop(monitor.getItem().files);
  }
};

function FileDropZone({ connectDropTarget, onDrop, ...passedProps }) {
  return connectDropTarget(<div {...passedProps} />);
}

FileDropZone.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired
};

export default DropTarget(NativeTypes.FILE, fileTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}))(FileDropZone);
