import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import css from '@emotion/css';

import Tooltip from '../components/core/Tooltip';
import getTextWidth from '../utils/getTextWidth';
import { labelOrientation } from './utils';

export const DataPoint = ({
  cell,
  xScale,
  yScale,
  d,
  mouseEnterHandler,
  selected,
  hovered,
  id,
  onKeyPress,
  onFocus,
  isZooming
}) => {
  const x = xScale(d.x);
  const y = yScale(d.y);
  const [xMin, xMax] = xScale.range();
  const [yMin, yMax] = yScale.range();

  const visible =
    !isZooming &&
    hovered &&
    _.inRange(x, xMin, xMax) &&
    _.inRange(y, yMin, yMax);

  const text = d.name;
  const textWidth = useMemo(() => getTextWidth(text, '1rem'), [text]);
  // This is an approximation since we don't have an easy way of
  // measuring the height of the text.
  const textHeight = 14;

  return (
    <Tooltip
      forSVG
      delayed={false}
      visible={visible}
      anchor={
        <g
          className={cx('scatter-plot__datapoint-tip', {
            'scatter-plot__datapoint-tip--selected': selected || hovered
          })}
          onMouseEnter={mouseEnterHandler}
          id={id}
          role="button"
          tabIndex={0}
          onFocus={onFocus}
          onKeyPress={event => {
            if (event.key === 'Enter' || event.key === ' ') {
              onKeyPress();
            }
          }}
          data-tracking-item="drivers_scatterplot-point"
        >
          <Circle
            x={x}
            y={y}
            selected={hovered || selected}
            color={d.color}
            selectedBorderColor={d.selectedBorderColor}
          />
          <text
            x={x}
            y={y}
            {...labelOrientation(
              cell,
              x,
              y,
              { width: textWidth, height: textHeight },
              { left: xMin, right: xMax, top: yMax, bottom: yMin }
            )}
          >
            {text}
          </text>
        </g>
      }
    >
      {d.tip}
    </Tooltip>
  );
};

DataPoint.propTypes = {
  cell: PropTypes.any,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  d: PropTypes.object.isRequired,
  mouseEnterHandler: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  hovered: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onKeyPress: PropTypes.func,
  onFocus: PropTypes.func
};

const Circle = memo(function Circle({
  x,
  y,
  selected,
  color,
  selectedBorderColor
}) {
  return (
    <circle
      cx={x}
      cy={y}
      r="6"
      css={css`
        fill: ${color};

        ${selected &&
        css`
          stroke: ${selectedBorderColor};
          stroke-width: 3px;
        `}
      `}
    />
  );
});

Circle.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  selectedBorderColor: PropTypes.string.isRequired
};
