import React from 'react';
import PropTypes from 'prop-types';

import Alert from '../../components/core/Alert';

export const Issue = React.forwardRef(function Issue(
  { issue, onDismiss, ...props },
  ref
) {
  return (
    <Alert
      {...props}
      ref={ref}
      type={issue.type}
      cancellable={issue.type === 'warning'}
      showing={issue.showing}
      onHide={() => {
        onDismiss(issue.groupKey, issue.messageId);
      }}
      dismissTrackingItem="upload-page_dismiss-warning"
    >
      {issue.message}
    </Alert>
  );
});

Issue.propTypes = {
  issue: PropTypes.shape({
    type: PropTypes.oneOf(['warning', 'error']).isRequired,
    showing: PropTypes.bool.isRequired,
    messageId: PropTypes.string,
    groupKey: PropTypes.string,
    message: PropTypes.node.isRequired
  }).isRequired,
  onDismiss: PropTypes.func.isRequired
};
