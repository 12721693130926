import { css } from '@emotion/core';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import { DateField } from '../classes/MetadataFields';
import { DateRangeConstraint } from '../classes/Constraints';
import Datepicker from './core/Datepicker';
import Tooltip from './core/Tooltip';
import {
  isValidRangeInput,
  isRangeValid,
  fetchFilterResults
} from './FilterSelectorDate';
import { Button } from './core/Button';
import { Icon, IconTypes } from './icons';

export function DateRangeSelectorFlyout({
  anchor,
  enteredMinimum,
  enteredMaximum,
  constraint,
  field,
  updateConstraint,
  flyoutIsOpen,
  minimumInputRef,
  maximumInputRef,
  onCloseFlyout,
  onChange,
  position = 'right'
}) {
  const flyout = React.useRef();
  const hasEnteredMinimum = !_.isEmpty(enteredMinimum);
  const hasEnteredMaximum = !_.isEmpty(enteredMaximum);
  const minimumIsInvalid = !isValidRangeInput(enteredMinimum);
  const maximumIsInvalid = !isValidRangeInput(enteredMaximum);
  const rangeIsInvalid = !isRangeValid(enteredMinimum, enteredMaximum);

  function onSubmit() {
    onCloseFlyout();
    fetchFilterResults(
      constraint,
      enteredMinimum,
      enteredMaximum,
      updateConstraint
    );
  }

  return (
    <Tooltip
      visible={flyoutIsOpen}
      position={position}
      contentHoverable
      anchor={anchor}
    >
      <div
        ref={flyout}
        className="filter-selector__flyout"
        data-test-id="flyout"
        onBlur={evt => {
          if (!flyout.current.contains(evt.relatedTarget)) {
            onCloseFlyout();
          }
        }}
        tabIndex={0}
      >
        <Datepicker
          numberOfMonths={2}
          enabledDatesStart={field.minimum}
          enabledDatesEnd={field.maximum}
          startDate={
            hasEnteredMinimum
              ? moment.utc(enteredMinimum, 'MMMM D, Y')
              : undefined
          }
          endDate={
            hasEnteredMaximum
              ? moment.utc(enteredMaximum, 'MMMM D, Y')
              : undefined
          }
          onSelectStart={date => {
            onChange({
              minimum: date ? date.format('MMMM D, Y') : '',
              maximum: enteredMaximum
            });
          }}
          onSelectEnd={date => {
            onChange({
              minimum: enteredMinimum,
              maximum: date ? date.format('MMMM D, Y') : ''
            });
          }}
        />
        <div className="filter-selector__fields">
          <Input
            ref={minimumInputRef}
            inputIdPrefix="filter-selector_minimum-"
            value={enteredMinimum}
            label="From"
            rangeIsInvalid={rangeIsInvalid}
            onChange={evt =>
              onChange({ minimum: evt.target.value, maximum: enteredMaximum })
            }
            onKeyDown={evt => {
              switch (evt.key) {
                case 'Enter':
                  onSubmit();
                  break;
                case 'Escape':
                  onCloseFlyout();
                  break;
              }
            }}
            onClear={() => onChange({ minimum: '', maximum: enteredMaximum })}
            invalidRangeMessage="Lower limit must be less than or equal to upper limit."
          />
          <Input
            ref={maximumInputRef}
            inputIdPrefix="filter-selector_maximum-"
            value={enteredMaximum}
            label="To"
            rangeIsInvalid={rangeIsInvalid}
            onChange={evt =>
              onChange({ minimum: enteredMinimum, maximum: evt.target.value })
            }
            onKeyDown={evt => {
              switch (evt.key) {
                case 'Enter':
                  onSubmit();
                  break;
                case 'Escape':
                  onCloseFlyout();
                  break;
              }
            }}
            onClear={() => onChange({ minimum: enteredMinimum, maximum: '' })}
            invalidRangeMessage="Upper limit must be greater than or equal to lower limit."
          />

          <Button
            palette="green"
            css={css`
              margin-right: 0.5rem;
            `}
            disabled={minimumIsInvalid || maximumIsInvalid || rangeIsInvalid}
            onClick={onSubmit}
          >
            Apply
          </Button>
          <Button onClick={onCloseFlyout}>Close</Button>
        </div>
      </div>
    </Tooltip>
  );
}

DateRangeSelectorFlyout.propTypes = {
  anchor: PropTypes.node.isRequired,
  enteredMinimum: PropTypes.string,
  enteredMaximum: PropTypes.string,
  constraint: PropTypes.instanceOf(DateRangeConstraint),
  field: PropTypes.instanceOf(DateField).isRequired,
  updateConstraint: PropTypes.func.isRequired,
  flyoutIsOpen: PropTypes.bool.isRequired,
  minimumInputRef: PropTypes.shape({ current: PropTypes.any }),
  maximumInputRef: PropTypes.shape({ current: PropTypes.any }),
  onCloseFlyout: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  position: PropTypes.oneOf(['above', 'below', 'left', 'right'])
};

const Input = React.forwardRef(function Input(
  {
    inputIdPrefix,
    value,
    label,
    rangeIsInvalid,
    onChange,
    onKeyDown,
    onClear,
    invalidRangeMessage
  },
  ref
) {
  const inputId = React.useRef(_.uniqueId(inputIdPrefix));
  const hasEnteredValue = !_.isEmpty(value);
  const valueIsInvalid = !isValidRangeInput(value);

  return (
    <>
      <label
        className="filter-selector__label filter-selector__label--date"
        htmlFor={inputId.current}
      >
        <span className="filter-selector__field-name">{label}</span>
      </label>
      <div
        className={cx('filter-selector__input-container', {
          'filter-selector__input-container--invalid':
            hasEnteredValue && (valueIsInvalid || rangeIsInvalid)
        })}
      >
        <input
          className="filter-selector__range-input"
          id={inputId.current}
          ref={ref}
          autoComplete="off"
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        {hasEnteredValue && <ClearButton onClear={onClear} />}
      </div>
      <div className="err-text">
        {hasEnteredValue && valueIsInvalid
          ? 'Value must be a valid date.'
          : hasEnteredValue && rangeIsInvalid && invalidRangeMessage}
      </div>
    </>
  );
});

function ClearButton({ onClear }) {
  return (
    <Button onClick={onClear} flavor="subtle">
      <Icon type={IconTypes.CIRCULAR_CLOSE} alt="clear" />
    </Button>
  );
}

ClearButton.propTypes = {
  onClear: PropTypes.func.isRequired
};
