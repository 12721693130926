import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Redirect, Route, Switch } from 'react-router-dom';

import { RoutePatterns, RequestStatuses } from './constants';
import * as ApiUtilsV5 from './utils/ApiUtilsV5';
import buildRoutePath from './utils/buildRoutePath';
import * as gtm from './utils/gtm';
import * as SearchUtils from './search_params/SearchUtils';
import { getWorkspaces } from './actions';
import Header from './components/Header';
import PageNotFound, { CapturePageNotFound } from './components/PageNotFound';
import ProjectExplorer from './components/ProjectExplorer';
import UploadPage from './upload_page/UploadPage';
import { StoreContext } from './StoreContext';
import { FeatureFlagsContext } from './FeatureFlagsContext';
import Banners from './components/Banners';
import Settings from './settings/Settings';
import { getFeatureFlags, setFeatureFlags } from './featureFlagsSingleton';
import { SystemStatusProvider } from './systemStatus';
import { AuthContextProvider } from './settings/model';
import { UndoBannerArea } from './UndoBanner';
import { SearchProvider } from './search_params';

function App() {
  const [flagsRequestStatus, setFlagsRequestStatus] = useState(
    RequestStatuses.PENDING
  );
  const [streamData, setStreamData] = useState(false);

  useEffect(() => {
    getWorkspaces();
    ApiUtilsV5.getUserFlags()
      .then(flags => {
        gtm.set({ scoreDriversEnabled: true });
        gtm.set({ featureFlags: _.keys(_.pickBy(flags)).join(',') });
        setFeatureFlags(flags);
        setStreamData(flags.stream_data);
        setFlagsRequestStatus(RequestStatuses.FULFILLED);
      })
      .catch(error => {
        setFlagsRequestStatus(RequestStatuses.REJECTED);
        throw error;
      });
  }, []);

  if (flagsRequestStatus !== RequestStatuses.FULFILLED) {
    return null;
  }

  return (
    <SearchProvider>
      <SystemStatusProvider>
        <FeatureFlagsContext.Provider value={getFeatureFlags()}>
          <UndoBannerArea>
            <AuthContextProvider>
              <StoreContext.Consumer>
                {({ userDefaultWorkspaceId, project, username }) => (
                  <div className="app-container">
                    <Banners />
                    <Header username={username} />
                    <CapturePageNotFound>
                      <Switch>
                        <Route path={RoutePatterns.PROJECT_EXPLORER}>
                          <ProjectExplorer />
                        </Route>
                        <Route
                          path="/upload"
                          render={props => {
                            const searchParams = SearchUtils.parse(
                              props.location.search
                            );
                            if (
                              searchParams.workspaceId != null &&
                              searchParams.projectId != null
                            ) {
                              return (
                                <Redirect
                                  to={buildRoutePath(
                                    RoutePatterns.UPLOAD_DATA,
                                    searchParams
                                  )}
                                />
                              );
                            }

                            return (
                              <Switch>
                                <Route
                                  exact
                                  path={RoutePatterns.UPLOAD_DATA}
                                  render={({ match: { params }, history }) => (
                                    <UploadPage
                                      userDefaultWorkspaceId={
                                        userDefaultWorkspaceId
                                      }
                                      uploadToWorkspaceId={params.workspaceId}
                                      uploadToProjectId={params.projectId}
                                      project={project}
                                      history={history}
                                    />
                                  )}
                                />
                                <Route
                                  exact
                                  path={RoutePatterns.CREATE_PROJECT}
                                  render={({ history }) => (
                                    <UploadPage
                                      userDefaultWorkspaceId={
                                        userDefaultWorkspaceId
                                      }
                                      history={history}
                                    />
                                  )}
                                />
                                {streamData && (
                                  <Route
                                    path={RoutePatterns.UPLOAD_STREAM_DATA}
                                    render={({ history }) => (
                                      <UploadPage
                                        userDefaultWorkspaceId={
                                          userDefaultWorkspaceId
                                        }
                                        history={history}
                                      />
                                    )}
                                  />
                                )}
                                <PageNotFound />
                              </Switch>
                            );
                          }}
                        />
                        <Route path={RoutePatterns.SETTINGS}>
                          <Settings />
                        </Route>
                        <PageNotFound />
                      </Switch>
                    </CapturePageNotFound>
                  </div>
                )}
              </StoreContext.Consumer>
            </AuthContextProvider>
          </UndoBannerArea>
        </FeatureFlagsContext.Provider>
      </SystemStatusProvider>
    </SearchProvider>
  );
}

export default DragDropContext(HTML5Backend)(App);
