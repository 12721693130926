import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import buildRoutePath from '../utils/buildRoutePath';
import { RoutePatterns } from '../constants';
import { AuthContext, getUserRowsAndColumns } from './model';
import { NavigationDropdown } from './NavigationDropdown';
import PlaceholderText from '../components/core/PlaceholderText';
import DeleteUsersButton from './DeleteUsersButton';
import { NotificationArea } from './notifications';
import {
  ControlBar,
  ManagementPageContainer,
  TableAndControlsContainer,
  Subtitle
} from './managementPageStyles';
import EditableName from './EditableName';
import EditUsersButton from './EditUsersButton';
import { AdminList } from './AdminList';
import MultiSelectTable, {
  useClickableSelections
} from '../components/MultiSelectTable';
import AddWorkspaceButton from './AddWorkspaceButton';

export function OrganizationSettings({ organizationId }) {
  const { profile } = useContext(AuthContext);
  const organizations = profile.organizations;
  const organization = profile.organizationsById[organizationId];
  const users = organization?.getUsers();
  const { rows, columns } = getUserRowsAndColumns(users, profile.username);
  const primaryKey = 'username';
  const selections = useClickableSelections(rows, primaryKey, organizationId);

  if (organizations == null) {
    return null;
  }

  return (
    <ManagementPageContainer>
      <NavigationDropdown
        message="Switch your organization"
        options={organizations.map(o => ({
          name: o.name,
          id: o.organization_id
        }))}
        buildPath={organizationId =>
          buildRoutePath(RoutePatterns.ORGANIZATION_SETTINGS, {
            organizationId
          })
        }
      />
      <EditableName
        key={organization.organization_id}
        namedThing={organization}
      />
      <h4>Organization users</h4>
      <Subtitle>
        {users == null ? (
          <PlaceholderText style={{ width: '8rem' }} />
        ) : users.length === 0 ? (
          'This organization contains no users. You can add users from the workspace management page.'
        ) : (
          <>
            Below is a list of users in this organization.{' '}
            <AdminList users={users} level={organization.identifier} />
          </>
        )}
      </Subtitle>
      <TableAndControlsContainer>
        <ControlBar>
          <DeleteUsersButton
            organization={organization}
            selections={selections}
          />
          <EditUsersButton editable={organization} selections={selections} />
          <NotificationArea />
          <AddWorkspaceButton organization={organization} className="right" />
        </ControlBar>
        {users?.length !== 0 && (
          <MultiSelectTable
            rows={rows}
            columns={columns}
            primaryKey={primaryKey}
            selections={selections}
            editable={organization.editable}
          />
        )}
      </TableAndControlsContainer>
    </ManagementPageContainer>
  );
}

OrganizationSettings.propTypes = {
  organizationId: PropTypes.string.isRequired
};
