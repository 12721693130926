import React, { useContext, useState } from 'react';

const HoverContext = React.createContext();

export function useHover() {
  return useContext(HoverContext);
}

export function HoverProvider({ children }) {
  const [hovered, setHovered] = useState();

  return (
    <HoverContext.Provider value={[hovered, setHovered]}>
      {children}
    </HoverContext.Provider>
  );
}
