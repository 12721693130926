import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';

import { Mixins, Colors } from '../../styles';
import { BubbleInput } from './BubbleInput';

export function Radio(props) {
  return (
    <div
      css={css`
        display: inline-block;
        vertical-align: bottom;
        position: relative;
        /* To prevent the overlay from becoming stretched out in flex boxes
         * align to the top of the box by default 
         */
        align-self: flex-start;
      `}
    >
      <input
        {...props}
        type="radio"
        css={css`
          opacity: 0;
          margin: 0;
          height: 1rem;
          width: 1rem;

          &:disabled ~ ${Circle} {
            background: ${Colors.gray0};
          }
          &:disabled ~ ${CheckedDot} {
            background: ${Colors.gray5};
          }
          &:checked ~ ${CheckedDot} {
            visibility: visible;
          }
        `}
      />
      <Circle />
      <CheckedDot />
    </div>
  );
}

const inputOverlayStyles = css`
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
`;

const Circle = styled.div`
  ${inputOverlayStyles};
  ${Mixins.shadowOutset};
  border-radius: 50%;
  border: 1px solid ${Colors.gray1};
`;

const CheckedDot = styled.div`
  ${inputOverlayStyles};
  visibility: hidden;
  background: ${Colors.blue4};
  margin: 3px;
  border-radius: 50%;
`;

export function BubbleRadio(props) {
  return <BubbleInput as={Radio} {...props} />;
}
