// import styles so that webpack builds them
import '@reach/tabs/styles.css';
import '@reach/combobox/styles.css';
import '../styles/index.styl';
import '../../polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ReactModal from 'react-modal';
import { QueryClient, QueryClientProvider } from 'react-query';

import * as gtm from './utils/gtm';
import { AlertTypes, REACT_QUERY_CLIENT_CONFIG } from './constants';
import App from './app';
import HashRedirect from './components/HashRedirect';
import Alert from './components/core/Alert';
import ErrorBoundary from './components/core/ErrorBoundary';
import UnknownErrorMsg from './components/UnknownErrorMsg';
import { StoreProvider } from './StoreContext';
import { runMigrations } from './compatibility/storageMigrations';
import setUpGitRevsWindowAttribute from './gitRevsWindowAttribute';

const queryClient = new QueryClient(REACT_QUERY_CLIENT_CONFIG);

// Required for react-modal to work
ReactModal.setAppElement('#app');

runMigrations();
setUpGitRevsWindowAttribute();

ReactDOM.render(
  <BrowserRouter basename="/app">
    <ErrorBoundary
      errorMessage={
        <div className="global-error">
          <Alert type={AlertTypes.ERROR}>
            <UnknownErrorMsg />
          </Alert>
        </div>
      }
    >
      <QueryClientProvider client={queryClient}>
        <HashRedirect>
          {/* We render the StoreProvider above our main App component to guard
            against App firing actions before the StoreProvider has subscribed
            to store changes */}
          <StoreProvider>
            <App />
          </StoreProvider>
        </HashRedirect>
      </QueryClientProvider>
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById('app')
);

// Mark user internal or external based on login email
const userName = localStorage.getItem('username');
let userType = 'external';
if (
  userName &&
  (!userName.includes('@') || userName.endsWith('@luminoso.com'))
) {
  if (userName.startsWith('selenium')) {
    userType = 'testing';
  } else {
    userType = 'internal';
  }
}
gtm.set({ luminosoUserType: userType });
