import _ from 'lodash';
import { css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';

import { thousandify } from '../../utils/NumFmtUtils';
import { Colors, Mixins } from '../../styles';
import { Bubbles } from './Bubbles';
import { Icon, IconTypes } from '../../components/icons';

export function IssuesSummary({
  bubbles,
  onClickNextError,
  onClickNextWarning
}) {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        > :not(:first-child) {
          margin-left: 0.5rem;
        }
      `}
    >
      <IssueBox
        type="error"
        count={bubbles.errorCount}
        onClickNext={onClickNextError}
      />
      <IssueBox
        type="warning"
        count={bubbles.warningCount}
        onClickNext={onClickNextWarning}
      />
    </div>
  );
}

IssuesSummary.propTypes = {
  bubbles: PropTypes.instanceOf(Bubbles).isRequired,
  onClickNextError: PropTypes.func.isRequired,
  onClickNextWarning: PropTypes.func.isRequired
};

function IssueBox({ type, count, onClickNext }) {
  const formattedType = `${type}${count === 1 ? '' : 's'}`;
  const formattedCount = thousandify(count);
  const hasIssues = count !== 0;
  const formattedMessage = hasIssues
    ? `${formattedCount} ${_.upperFirst(formattedType)}`
    : `No ${formattedType}`;

  return (
    <CycleButton onClick={onClickNext} hasIssues={hasIssues}>
      <TypeBox type={type} hasIssues={hasIssues} />
      {formattedMessage}
      {hasIssues && <DownArrow />}
    </CycleButton>
  );
}

function CycleButton({ hasIssues, ...props }) {
  return (
    <button
      disabled={!hasIssues}
      type="button"
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        ${hasIssues &&
        css`
          ${Mixins.shadowOutset};
          ${Mixins.roundedCorners};
          background: ${Colors.blue0};
          &:active,
          &:hover {
            background: ${Colors.blue1};
            color: ${Colors.gray9};
          }
          // FIXME: This is to adjust the fa icon size. Remove once we start
          // using new icons
          i {
            font-size: 16px;
          }
        `}
      `}
      {...props}
    />
  );
}

function TypeBox({ type, hasIssues }) {
  let iconType, background, alt;
  if (!hasIssues) {
    iconType = IconTypes.CIRCULAR_CHECK;
    background = Colors.green4;
    alt = 'check';
  } else if (type === 'warning') {
    iconType = IconTypes.CIRCULAR_EXCLAMATION;
    background = Colors.yellow2;
    alt = 'warning';
  } else {
    iconType = IconTypes.CIRCULAR_CLOSE;
    background = Colors.red3;
    alt = 'error';
  }

  return (
    <Icon
      type={iconType}
      alt={alt}
      css={css`
        ${Mixins.roundedCorners};
        ${hasIssues
          ? css`
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            `
          : Mixins.shadowOutset};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        color: white;
        background: ${background};
        margin-right: 0.5rem;
      `}
    />
  );
}

function DownArrow() {
  return (
    <Icon
      type={IconTypes.ARROW}
      direction="down"
      theme="primary"
      css={css`
        margin: 0.5rem;
      `}
    />
  );
}
