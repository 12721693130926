import _ from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useParams } from 'react-router-dom';

import { getConceptCounts } from '../../utils/ApiUtilsV5';
import { useConceptManagement } from '../../data_hooks';
import { StoreContext } from '../../StoreContext';

export const ConceptCountsContext = createContext({
  conceptCounts: {},
  requestCount: () => {}
});

export const useConceptCountContext = () => {
  return useContext(ConceptCountsContext);
};

export const ConceptCountProvider = ({ children }) => {
  const { projectId } = useParams();
  const { project } = useContext(StoreContext);
  const { conceptManagement } = useConceptManagement(project);
  const [conceptCounts, setConceptCounts] = useState({});
  const updateConceptCounts = useCallback(
    concepts => {
      if (concepts.length === 0) {
        return;
      }
      getSpecifiedConcepts(projectId, concepts).then(matchCounts =>
        setConceptCounts(conceptCounts => ({
          ...conceptCounts,
          ...getCountMap(matchCounts)
        }))
      );
    },
    [projectId]
  );

  useEffect(() => {
    if (conceptManagement && _.isEmpty(conceptCounts)) {
      updateConceptCounts(conceptManagement.conceptsToIgnoreTexts);
    }
  }, [projectId, conceptManagement]);

  const requestCount = useCallback(
    conceptText => updateConceptCounts([conceptText]),
    [updateConceptCounts]
  );

  return (
    <ConceptCountsContext.Provider value={{ conceptCounts, requestCount }}>
      {children}
    </ConceptCountsContext.Provider>
  );
};

const getSpecifiedConcepts = (projectId, conceptTexts) =>
  getConceptCounts(projectId, {
    type: 'specified',
    concepts: conceptTexts.map(concept => ({ texts: [concept] }))
  }).then(response => response.matchCounts);

const getCountMap = matchCounts =>
  matchCounts.reduce((conceptCounts, { exactMatchCount, name }) => {
    conceptCounts[name] = exactMatchCount;
    return conceptCounts;
  }, {});
