import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import Dropdown from './core/Dropdown';
import { Button } from './core/Button';
import SimpleModal from './core/SimpleModal';
import DialogFooter from './DialogFooter';
import { useUniqueId } from '../utils/hooks';
import { StoreContext } from '../StoreContext';

const FILE_EXTENSIONS = ['xlsx', 'csv'];

export default function DocDownloadModal({ onHide, showing, startDownload }) {
  const { selection } = useContext(StoreContext);
  const [extension, setExtension] = useState(FILE_EXTENSIONS[0]);
  const [matchType, setMatchType] = useState('both');

  // Reset state when modal is re-opened
  useEffect(() => {
    if (showing) {
      setExtension(FILE_EXTENSIONS[0]);
      setMatchType('both');
    }
  }, [showing]);

  const handleExtensionSelect = ({ target: { value } }) => {
    setExtension(value);
  };

  const handleMatchTypeSelect = ({ target: { value } }) => {
    setMatchType(value);
  };

  const handleDownload = event => {
    event.preventDefault();
    startDownload(extension, selection ? matchType : undefined);
  };

  return (
    <SimpleModal
      className="doc-download-modal"
      onHide={onHide}
      isOpen={showing}
      trackingPrefix="concept-detail-pane_download-matching-documents"
      header={
        <h5>
          {'Download documents matching ' +
            (selection ? `"${selection.name}"` : 'filter')}
        </h5>
      }
    >
      <form
        onSubmit={handleDownload}
        data-tracking-item="concept-detail-pane_download-matching-documents_download-submit"
      >
        <p>
          Matching document download limited to 20,000 documents maximum. To
          download more than 20,000 documents you must use the API.
        </p>
        <LabeledDropdown
          label="Download as "
          onChange={handleExtensionSelect}
          value={extension}
        >
          {FILE_EXTENSIONS.map(extension => (
            <option key={extension} value={extension}>
              {extension.toUpperCase()}
            </option>
          ))}
        </LabeledDropdown>
        {selection && (
          <LabeledDropdown
            label="Select match type"
            onChange={handleMatchTypeSelect}
            value={matchType}
          >
            <option value="both">Total matches</option>
            {selection.exactMatchCount > 0 && (
              <option value="exact">Exact matches only</option>
            )}
          </LabeledDropdown>
        )}
        <SubmitButton />
      </form>
    </SimpleModal>
  );
}

DocDownloadModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  showing: PropTypes.bool.isRequired,
  startDownload: PropTypes.func.isRequired
};

const LabeledDropdown = ({ children, value, onChange, label }) => {
  const id = useUniqueId();
  return (
    <p>
      <label
        htmlFor={id}
        css={css`
          display: inline;
          margin: 0;
          margin-right: 0.5rem;
        `}
      >
        {label}
      </label>
      <Dropdown id={id} onChange={onChange} value={value}>
        {children}
      </Dropdown>
    </p>
  );
};

const SubmitButton = () => (
  <DialogFooter>
    <Button
      type="submit"
      css={css`
        margin-left: 0;
      `}
    >
      Download
    </Button>
  </DialogFooter>
);
