/**
 * Generate the next default numbered name for a new shared concept list
 *
 * @param {[Object]} sharedConceptLists - a list of shared concept lists from
 *                                        the api
 * @return {string} - The default name for the newest shared concept list
 * */
export const getNewListName = sharedConceptLists => {
  const newListNameRegex = /^New list (\d+)$/;

  const prevCount = getHighestNumberedNameInList(
    sharedConceptLists,
    newListNameRegex
  );
  return `New list ${prevCount + 1}`;
};

/**
 * Generate the next default numbered name for a copy of a shared concept list
 *
 * @param {[Object]} sharedConceptLists - a list of shared concept lists from
 *                                        the api
 * @param {string} copiedListName - the name of the list to copy from
 *
 * @return {string} - The default name for the newest shared concept list
 * */
export const getCopiedListName = (sharedConceptLists, copiedListName) => {
  const copiedListNameRegex = copiedListName =>
    new RegExp(`^Copy (\\d+) of ${copiedListName}$`);

  const prevCount = getHighestNumberedNameInList(
    sharedConceptLists,
    copiedListNameRegex(copiedListName)
  );
  return `Copy ${prevCount + 1} of ${copiedListName}`;
};

const getHighestNumberedNameInList = (sharedConceptLists, nameRegex) =>
  Math.max(
    0,
    ...sharedConceptLists
      .map(({ name }) => nameRegex.exec(name))
      .filter(result => result !== null)
      .map(result => parseInt(result[1]))
  );
