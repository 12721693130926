import { css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';

import { Mixins, Colors } from '../../styles';
import { Button } from './Button';

const tabKeyType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

export function Tabs({ activeTabKey, onTabKeyChange, ...passedProps }) {
  return (
    <TabContext.Provider value={{ activeTabKey, onTabKeyChange }}>
      <div
        {...passedProps}
        css={css`
          display: flex;
          flex-direction: column;
          filter: drop-shadow(0px 1.5px 1.5px rgba(0, 0, 0, 0.3));
        `}
      />
    </TabContext.Provider>
  );
}

Tabs.propTypes = {
  activeTabKey: tabKeyType.isRequired,
  onTabKeyChange: PropTypes.func.isRequired
};

export function TabList({ children }) {
  return (
    <div
      css={css`
        padding-left: 0.5rem;
        z-index: 1;
      `}
    >
      {children}
    </div>
  );
}

export function Tab({ tabKey, children, trackingItem }) {
  const { activeTabKey, onTabKeyChange } = React.useContext(TabContext);
  const active = activeTabKey === tabKey;
  return (
    <Button
      data-tracking-item={trackingItem}
      onClick={() => {
        onTabKeyChange(tabKey);
      }}
      css={css`
        box-shadow: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background: white;
        padding: 0.5rem;

        &:hover {
          background: white;
        }

        ${!active &&
        css`
          background: ${Colors.blue0};
          color: ${Colors.gray9};

          &:disabled,
          &:disabled:hover {
            background: ${Colors.gray0};
            color: ${Colors.gray5};
          }

          &:hover {
            background: ${Colors.blue1};
            color: ${Colors.gray9};
            text-decoration: none;
          }
        `}

        &:not(:first-of-type) {
          margin-left: 0.5rem;
        }
      `}
    >
      {children}
    </Button>
  );
}

Tab.propTypes = {
  tabKey: tabKeyType.isRequired,
  children: PropTypes.node.isRequired,
  trackingItem: PropTypes.string
};

export function TabPanels({ children }) {
  return (
    <div
      css={css`
        ${Mixins.roundedCorners};
        overflow: hidden;
        position: relative;
        flex: 1;
        height: 100%;
      `}
    >
      {children}
    </div>
  );
}

TabPanels.propTypes = {
  children: PropTypes.node.isRequired
};

export function TabPanel({ tabKey, children }) {
  const { activeTabKey } = React.useContext(TabContext);
  const active = activeTabKey === tabKey;
  return <div style={{ display: active ? 'initial' : 'none' }}>{children}</div>;
}

TabPanel.propTypes = {
  tabKey: tabKeyType.isRequired,
  children: PropTypes.node.isRequired
};

const TabContext = React.createContext();
