import React from 'react';
import { css } from '@emotion/core';

import { DeleteProjectButton } from './DeleteProjectButton';
import { ProjectDescription, ProjectName } from '../components/ProjectDetail';
import {
  CopyProjectButton,
  UploadMoreButton
} from '../components/ProjectButtons';

export function ProjectTitle() {
  return (
    <div
      css={css`
        margin: 0 0.5rem;
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      `}
    >
      <div
        css={css`
          /* allow the description to ellipsify correctly */
          min-width: 0;
          /* account for padding inline editors */
          margin-left: -0.5rem;
          width: 100%;
        `}
      >
        <ProjectName />
        <ProjectDescription />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          margin-left: 0.5rem;
          > :not(:last-child) {
            margin-right: 0.5rem;
          }
        `}
      >
        <UploadMoreButton />
        <ButtonStack>
          <CopyProjectButton />
          <DeleteProjectButton />
        </ButtonStack>
      </div>
    </div>
  );
}

function ButtonStack({ children }) {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        > * {
          display: block;
          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      `}
    >
      {children}
    </div>
  );
}
