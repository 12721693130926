import React from 'react';

import Alert from '../components/core/Alert';
import { AlertTypes } from '../constants';
import Spinner from '../components/core/Spinner';

export function ProjectCalculatingPage() {
  return (
    <div className="upload-page__result-container">
      <Alert type={AlertTypes.SUCCESS}>
        Your dataset was uploaded successfully!
      </Alert>
      <p className="upload-page__result-msg upload-page__result-msg--header">
        <Spinner /> We're building your project.
      </p>
      <p className="upload-page__result-msg">
        This may take a few minutes. In the meantime, you can{' '}
        <a href="/">go back</a> to the Projects page and check the status there,
        or wait here and we'll take you to your project when it's ready.
      </p>
    </div>
  );
}
