/**
 * This module holds the parts of the GroupStatsWorker code that are the same
 * across both the real-life worker-based implementation and the mock
 * implementation used in tests.
 */
import { ColumnGroup } from '../ColumnGroup';
import { Cell } from '../Cell';

export function handleMessage(message, postMessage) {
  const { groups, rows } = restoreMessage(message);
  for (let group of groups) {
    const stats = group.collectStats(rows);
    postMessage({ key: group.key, stats });
  }
}

/**
 * Prepare a message to be sent to a worker, by transforming ColumnGroup and
 * Cell instances into plain serializable objects.
 */
export function simplifyMessage({ groups, rows }) {
  return {
    groups,
    rows: rows.map(row => row.map(cell => cell.rawValue))
  };
}

/**
 * Restore objects from simplifyMessage to full ColumnGroup and Cell instances.
 */
export function restoreMessage({ groups, rows }) {
  return {
    groups: groups.map(group => {
      return new ColumnGroup(
        group.id,
        group.name,
        group.type,
        group.frozenTypeReason,
        group.indices,
        group.originalTypes
      );
    }),
    rows: rows.map(row => row.map(rawCellValue => new Cell(rawCellValue)))
  };
}
