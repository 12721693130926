import React from 'react';
import styled from '@emotion/styled';

import { Colors, Mixins, ZIndexes } from '../../styles';
import { useBoundingClientRect } from '../../utils/hooks';
import { CutoutOverlay } from '../../components/CutoutOverlay';

export function Modal({ children, sidePanelRef, onClose }) {
  const rect = useBoundingClientRect(sidePanelRef);
  return (
    <>
      <Overlay rect={rect} onClick={onClose} />
      <ContentWrapper left={rect.right}>{children}</ContentWrapper>
    </>
  );
}

const Overlay = styled(CutoutOverlay)`
  background: ${Colors.gray11};
  opacity: 0.5;
`;

const ContentWrapper = styled.div`
  height: 75%;
  /* Take up the entire screen minus the side panel,the left property below,
  the left property on the Container, and the horizontal padding below */
  max-width: calc(100vw - ${props => props.left}px - 5rem);
  position: absolute;
  top: 15%;
  padding: 1rem;
  background-color: white;
  ${Mixins.roundedCorners};
  ${Mixins.shadowOutsetHeavy};
  z-index: ${ZIndexes.modal};
  /* Left-align the modal content with the workspace area */
  left: calc(${props => props.left}px + 1.5rem);
`;
