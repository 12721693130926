import _ from 'lodash';
import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { AlertTypes } from '../constants';
import Alert from './core/Alert';

export default class NotificationToast extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isShowing: false };
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  show() {
    this.clearTimer();

    this.setState({ isShowing: true });

    // start a timer to hide the toast after some time has elapsed
    this.timer = setTimeout(() => {
      this.timer = null;
      this.setState({ isShowing: false });
    }, 3000);
  }

  componentDidMount() {
    if (this.props.notification) {
      this.show();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.notification &&
      this.props.notification !== prevProps.notification
    ) {
      this.show();
    }
  }

  render() {
    const { notification } = this.props;

    return (
      <div
        className={cx(
          'notification-toast',
          {
            'notification-toast--is-active': this.state.isShowing
          },
          this.props.className
        )}
      >
        {notification && (
          <Alert type={notification.type}>{notification.description}</Alert>
        )}
      </div>
    );
  }
}

NotificationToast.propTypes = {
  className: PropTypes.string,
  notification: PropTypes.shape({
    type: PropTypes.oneOf(_.values(AlertTypes)).isRequired,
    description: PropTypes.string.isRequired
  })
};
