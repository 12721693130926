// This is based off of the Sortable example from react-dnd
// https://github.com/gaearon/react-dnd/tree/v1.1.8/examples/04%20Sortable/Simple
import ReactDOM from 'react-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';

const source = {
  endDrag(props, monitor) {
    const draggedItemIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    props.endDrag(draggedItemIndex, hoverIndex);
  },

  beginDrag(props) {
    props.startDrag();

    return { index: props.index };
  }
};

const target = {
  hover(props, monitor, component) {
    const draggedItemIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Determine rectangle on the screen
    const hoverBoundingRect = ReactDOM.findDOMNode(
      component
    ).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (draggedItemIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (draggedItemIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveListItem(draggedItemIndex, hoverIndex);
  }
};

function DraggableConceptWrapper({
  connectDragSource,
  connectDropTarget,
  isDragging,
  children
}) {
  const kids = React.Children.toArray(children);
  const opacity = isDragging ? 0 : 1;

  return (
    <div>
      {connectDragSource(
        connectDropTarget(<div style={{ opacity }}>{kids[0]}</div>)
      )}
      {kids.slice(1)}
    </div>
  );
}

DraggableConceptWrapper.propTypes = {
  endDrag: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  moveListItem: PropTypes.func.isRequired,
  startDrag: PropTypes.func.isRequired,

  // these props are provided by react-dnd
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired
};

const draggableType = 'draggable-concept-wrapper';

export default DropTarget(draggableType, target, connect => ({
  connectDropTarget: connect.dropTarget()
}))(
  DragSource(draggableType, source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))(DraggableConceptWrapper)
);
