import React, { useContext } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { StoreContext } from '../../StoreContext';
import { useDispatchUndoBanner } from '../../UndoBanner';
import { shareActiveConceptList, undoUpdateConceptList } from '../../actions';
import { Icon, IconTypes } from '../../components/icons';
import { Button } from '../../components/core/Button';
import { useActiveListName } from './activeListName';
import { getNewListName } from './activeConceptsUtils';

export default function SaveActiveListButton() {
  const { projectId } = useParams();
  const { activeConceptListName, sharedConceptLists } = useContext(
    StoreContext
  );
  const dispatchUndoBanner = useDispatchUndoBanner();

  const { hasUnsavedChanges } = useActiveListName();

  return (
    <Button
      palette="green"
      disabled={!hasUnsavedChanges}
      onClick={() => {
        const nameToSaveWith =
          activeConceptListName !== null
            ? activeConceptListName
            : getNewListName(sharedConceptLists);

        shareActiveConceptList(projectId, nameToSaveWith).then(() => {
          // If we're updating a pre-existing list, spawn an undo banner to
          // undo the changes made to it
          if (activeConceptListName !== null) {
            const oldSharedList = _.find(sharedConceptLists, {
              name: activeConceptListName
            });
            dispatchUndoBanner({
              message: `Updated "${oldSharedList.name}"`,
              onUndo: () =>
                undoUpdateConceptList(
                  projectId,
                  oldSharedList.name,
                  oldSharedList.concepts
                ),
              trackingItem: 'undo-update-concept-list'
            });
          }
        });
      }}
    >
      <Icon type={IconTypes.FLOPPY} /> Save
    </Button>
  );
}
