import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Category } from '../classes/MetadataFields';
import { thousandify } from '../utils/NumFmtUtils';
import { Colors } from '../styles';

const ViewContainer = styled.span`
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
`;

const ViewValue = styled.span`
  flex: 1;
  overflow: ellipsis;
`;

const ViewCount = styled.span`
  color: ${Colors.gray5};
`;

export default function CategoryView({ category }) {
  const { value, count } = category;

  return (
    <ViewContainer data-test-id="category-view">
      <ViewValue
        data-test-id="category-view-value"
        // TODO replace with Tooltip
        title={value}
      >
        {value}
      </ViewValue>
      <ViewCount>({thousandify(count)})</ViewCount>
    </ViewContainer>
  );
}

CategoryView.propTypes = {
  category: PropTypes.instanceOf(Category).isRequired
};
