import React from 'react';
import PropTypes from 'prop-types';

import { RoutePatterns } from '../constants';
import buildRoutePath from '../utils/buildRoutePath';
import {
  buildProject,
  createProject,
  editProject,
  getBuildStatus,
  uploadDocs
} from '../utils/ApiUtilsV5';
import DocumentTitle from '../components/DocumentTitle';
import { UploadErrorPage } from './UploadErrorPage';
import { BuildTimeoutPage } from './BuildTimeoutPage';
import { ProjectCalculatingPage } from './ProjectCalculatingPage';
import { UploadProgressPage } from './UploadProgressPage';
import { UploadAndValidateDatasetPage } from './UploadAndValidateDatasetPage';
import ProjectErrorModal from '../components/ProjectErrorModal';

export default class UploadPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: '',
      selectedWorkspaceId: null,
      filename: '',
      totalDocs: 0,
      numUploaded: 0,
      uploadStatus: '', // '', 'uploading', 'calculating', 'timeout', 'error', 'error: no docs'
      buildErrorReason: '',
      projectLoadError: null
    };

    this.uploadDocs = this.uploadDocs.bind(this);
    this.recalcProject = this.recalcProject.bind(this);
    this.pollRecalcJob = this.pollRecalcJob.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  shouldCreateProject() {
    return !(this.props.uploadToWorkspaceId && this.props.uploadToProjectId);
  }

  createProject(formState) {
    return createProject(
      formState.workspaceId,
      formState.name,
      formState.language,
      formState.description
    ).then(({ project_id: projectId }) => {
      this.setState({ projectId });
    });
  }

  uploadDocs(documents) {
    const progressCallback = numUploaded => {
      this.setState({ numUploaded });
    };
    return uploadDocs(this.state.projectId, documents, progressCallback);
  }

  recalcProject(sendEmail, skipSentiment, dashboardBuild) {
    this.setState({ uploadStatus: 'calculating' });
    return buildProject(
      this.state.projectId,
      sendEmail,
      skipSentiment,
      dashboardBuild
    );
  }

  pollRecalcJob() {
    const { projectId } = this.state;

    let counter = 0;

    const poll = () => {
      counter += 1;

      return getBuildStatus(projectId).then(result => {
        if (result.status !== 'PENDING') {
          return result;
        }
        if (counter < 360) {
          return new Promise(resolve => {
            setTimeout(() => {
              resolve(poll());
            }, 5000);
          });
        } else {
          return { status: 'POLL_TIMEOUT' };
        }
      });
    };

    return poll();
  }

  onSubmit(formState) {
    this.setState({
      projectId: formState.projectId,
      filename: formState.filename,
      totalDocs: formState.documents.length,
      selectedWorkspaceId: formState.workspaceId,
      uploadStatus: 'uploading'
    });
    Promise.resolve(this.shouldCreateProject() && this.createProject(formState))
      .then(() => {
        if (!this.shouldCreateProject()) {
          return editProject(this.state.projectId, {
            name: formState.name,
            language: formState.language,
            description: formState.description
          });
        }
      })
      .then(() => this.uploadDocs(formState.documents))
      .then(() =>
        this.recalcProject(
          formState.sendEmail,
          formState.sentiment,
          formState.dashboardBuild
        )
      )
      .then(this.pollRecalcJob)
      .then(({ status: buildStatus, reason }) => {
        if (buildStatus === 'OK') {
          this.props.history.replace(
            buildRoutePath(RoutePatterns.PROJECT_EXPLORER, {
              workspaceId: formState.workspaceId,
              projectId: this.state.projectId
            })
          );
          return;
        }
        this.setState({
          uploadStatus:
            buildStatus === 'POLL_TIMEOUT'
              ? 'timeout'
              : // if we somehow upload no docs at all or only docs w/o text
              reason.match(/no documents/i) != null ||
                reason.match(/not enough text/i) != null
              ? 'error: no docs'
              : 'error',
          buildErrorReason: reason
        });
      })
      .catch(error => {
        console.warn('Caught error:', error);
        this.setState({ uploadStatus: 'error' });
      });
  }

  render() {
    const shouldCreateProject = this.shouldCreateProject();

    return (
      <DocumentTitle
        title={
          shouldCreateProject
            ? 'New Project | Luminoso Daylight'
            : this.props.project?.name
            ? `${this.props.project.name} | Luminoso Daylight`
            : 'Luminoso Daylight'
        }
      >
        {this.state.uploadStatus === '' ? (
          <UploadAndValidateDatasetPage
            creating={shouldCreateProject}
            userDefaultWorkspaceId={this.props.userDefaultWorkspaceId}
            uploadToWorkspaceId={this.props.uploadToWorkspaceId}
            uploadToProjectId={this.props.uploadToProjectId}
            project={this.props.project}
            history={this.props.history}
            onSubmit={this.onSubmit}
            onFatalError={() => this.setState({ uploadStatus: 'error' })}
            onProjectLoadError={projectLoadError => {
              this.setState({
                uploadStatus: 'projectLoadError',
                projectLoadError
              });
            }}
          />
        ) : this.state.uploadStatus === 'uploading' ? (
          <UploadProgressPage
            filename={this.state.filename}
            totalDocs={this.state.totalDocs}
            uploadedDocs={this.state.numUploaded}
          />
        ) : this.state.uploadStatus === 'calculating' ? (
          <ProjectCalculatingPage />
        ) : this.state.uploadStatus === 'timeout' ? (
          <BuildTimeoutPage />
        ) : this.state.uploadStatus === 'projectLoadError' ? (
          <ProjectErrorModal
            projectId={this.props.uploadToProjectId}
            workspaceId={this.props.uploadToWorkspaceId}
            error={this.state.projectLoadError}
          />
        ) : (
          <UploadErrorPage
            uploadStatus={this.state.uploadStatus}
            buildErrorReason={this.state.buildErrorReason}
            selectedWorkspace={this.state.selectedWorkspaceId}
            projectId={this.state.projectId}
            onRestartUploadProcess={() => {
              // We'll be staying on the same instance of the UploadPage
              // component, so we need to clear its state
              this.setState({
                uploadStatus: '',
                buildErrorReason: ''
              });
            }}
          />
        )}
      </DocumentTitle>
    );
  }
}

UploadPage.propTypes = {
  userDefaultWorkspaceId: PropTypes.string,
  uploadToWorkspaceId: PropTypes.string,
  uploadToProjectId: PropTypes.string,
  project: PropTypes.object,
  history: PropTypes.object
};
