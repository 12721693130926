import { Delaunay } from 'd3-delaunay';
import React from 'react';
import PropTypes from 'prop-types';

import { areCollinear, hasEqualId } from './utils';
import { DataPoint } from './DataPoint';
import { useUniqueId } from '../utils/hooks';

export function DataPoints({
  xScale,
  yScale,
  dataPoints,
  selectedDataPointId,
  mouseX,
  mouseY,
  mouseIn,
  svgRef,
  onClick,
  isZooming,
  hoveredDataPoint,
  setHoveredDataPoint
}) {
  const idSuffix = useUniqueId();
  const delaunay = getDelaunay(dataPoints, xScale, yScale);
  const voronoi = getVoronoi(delaunay, xScale, yScale);
  const selectedDataPoint = dataPoints.find(hasEqualId(selectedDataPointId));

  return (
    <g className="scatter-plot__data-points">
      <rect
        width="100%"
        height="100%"
        pointerEvents="all"
        fill="none"
        onMouseMove={() => {
          if (delaunay && mouseIn) {
            const boundingClientRect = svgRef.getBoundingClientRect();
            const hoveredDataPointIndex = delaunay.find(
              mouseX - boundingClientRect.left,
              mouseY - boundingClientRect.top
            );
            if (hoveredDataPointIndex >= 0) {
              setHoveredDataPoint(dataPoints[hoveredDataPointIndex]);
            }
          }
        }}
        onClick={() => {
          if (hoveredDataPoint !== undefined) {
            onClick(hoveredDataPoint);
          }
        }}
      />
      {dataPoints.map(data => (
        <DataPoint
          key={data.id}
          xScale={xScale}
          yScale={yScale}
          d={data}
          mouseEnterHandler={() => setHoveredDataPoint(data)}
          selected={hasEqualId(selectedDataPointId, data)}
          cell={voronoi && voronoi.cellPolygon(dataPoints.indexOf(data))}
          hovered={
            hoveredDataPoint && data.id === hoveredDataPoint.id && mouseIn
          }
          id={data.id + idSuffix}
          onFocus={() => setHoveredDataPoint(data)}
          onKeyPress={() => onClick(hoveredDataPoint)}
          isZooming={isZooming}
        />
      ))}
      {selectedDataPoint && (
        <use
          data-test-id="selected-data-point"
          href={'#' + selectedDataPoint.id + idSuffix}
        />
      )}
      {hoveredDataPoint && (
        <use
          data-test-id="hovered-data-point"
          href={'#' + hoveredDataPoint.id + idSuffix}
          onMouseEnter={() => setHoveredDataPoint(hoveredDataPoint)}
          onClick={event => {
            event.stopPropagation();
            onClick(hoveredDataPoint);
          }}
        />
      )}
    </g>
  );
}

DataPoints.propTypes = {
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  dataPoints: PropTypes.array.isRequired,
  selectedDataPointId: PropTypes.string,
  mouseX: PropTypes.number,
  mouseY: PropTypes.number,
  mouseIn: PropTypes.bool,
  svgRef: PropTypes.any,
  onClick: PropTypes.func.isRequired,
  isZooming: PropTypes.bool,
  hoveredDataPoint: PropTypes.object,
  setHoveredDataPoint: PropTypes.func.isRequired
};

function getDelaunay(dataPoints, xScale, yScale) {
  // d3 throws an error if the data points are collinear
  if (areCollinear(dataPoints)) {
    return null;
  }

  const points = dataPoints.map(({ x, y }) => [xScale(x), yScale(y)]);
  return Delaunay.from(points);
}

function getVoronoi(delaunay, xScale, yScale) {
  if (!delaunay) {
    return null;
  }

  const [xMin, xMax] = xScale.range();
  const [yMax, yMin] = yScale.range();

  return delaunay.voronoi([xMin, yMin, xMax, yMax]);
}
