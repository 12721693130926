import React from 'react';
import PropTypes from 'prop-types';

import TextList from '../components/TextList';

export function AdminList({ users, level }) {
  const admins = users
    .filter(user => user.role === `${level}_admin`)
    .map(user => (
      <strong key={user.username}>{user.full_name || user.username}</strong>
    ));
  if (admins.length === 0) {
    return null;
  }
  return (
    <>
      <TextList items={admins} />
      {admins.length > 1
        ? ` are the administrators for the ${level}.`
        : ` is the administrator for the ${level}.`}
    </>
  );
}
AdminList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string.isRequired,
      full_name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  level: PropTypes.oneOf(['workspace', 'organization']).isRequired
};
