import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Category } from '../classes/MetadataFields';
import Autosuggest from './core/Autosuggest';
import List from './core/List';
import { Button } from './core/Button';
import CategoryView from './CategoryView';
import { Icon, IconTypes } from './icons';
import { Colors } from '../styles';

const CategoryAutosuggestDiv = styled.div`
  margin: 0.25rem 0.5rem;
`;

const Selections = styled.div`
  margin-top: 0.25rem;
`;

const NoSelection = styled.span`
  color: ${Colors.gray5};
  font-style: italic;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  padding-left: 0.5rem;
`;

const Selection = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
  font-size: 0.875rem;
  padding-left: 0.5rem;
`;

const SelectionCategory = styled.span`
  flex: 1;
  overflow-x: hidden;
`;

export default function CategoryAutosuggest({
  onDeselect,
  onSelect,
  selectedCategories,
  unselectedCategories
}) {
  return (
    <CategoryAutosuggestDiv>
      <Autosuggest
        fillWidth={true}
        onSelect={onSelect}
        options={unselectedCategories.map(({ value }) => value)}
        placeholder="Search to select"
        trackingPrefix="filter-pane_selector-input"
        renderSuggestion={({ suggestion: value }) => (
          <CategoryView category={_.find(unselectedCategories, { value })} />
        )}
      />
      <Selections>
        {_.isEmpty(selectedCategories) ? (
          <NoSelection>Nothing selected</NoSelection>
        ) : (
          <List>
            {selectedCategories.map(category => (
              <Selection key={category.value}>
                <SelectionCategory>
                  <CategoryView category={category} />
                </SelectionCategory>
                <Button
                  aria-label={`Deselect ${category.value}`}
                  flavor="subtle"
                  onClick={() => onDeselect(category.value)}
                  data-tracking-item="filter-pane_selector-input_search-input-remove-option"
                >
                  <Icon type={IconTypes.CLOSE}></Icon>
                </Button>
              </Selection>
            ))}
          </List>
        )}
      </Selections>
    </CategoryAutosuggestDiv>
  );
}

CategoryAutosuggest.propTypes = {
  onDeselect: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.instanceOf(Category))
    .isRequired,
  unselectedCategories: PropTypes.arrayOf(PropTypes.instanceOf(Category))
    .isRequired
};
