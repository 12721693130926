import React from 'react';
import css from '@emotion/css';

import TileHeader from '../TileHeader';
import { IconTypes } from '../../../components/icons';
import { ToolCard } from '../../../highlights/Cards';

const Surveys = () => {
  return (
    <div
      css={css`
        > * {
          max-height: 30rem;
        }
      `}
    >
      <ToolCard
        header={<TileHeader label="Surveys" icon={IconTypes.SURVEYS} />}
        blurb={<div>Surveys content</div>}
        visualization={<div></div>}
      />
    </div>
  );
};

export default Surveys;
