import React, { useCallback, useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';
import { uniqueId } from 'lodash';
import css from '@emotion/css';

import { RoutePatterns } from '../../../constants';
import {
  createProject,
  createRedditTaskDashboard
} from '../../../utils/ApiUtilsV5';
import StreamSourceLayout from '../StreamSourceLayout';
import { OptionsPage } from '../OptionsPage';
import { Button } from '../../../components/core/Button';
import RedditUrlImportModal from './RedditUrlImportModal';
import RedditInputDataForm from './RedditInputDataForm';

const INPUT_URLS_PATHNAME = `${RoutePatterns.UPLOAD_STREAM_DATA}/select/reddit/input-urls`;
const SELECT_INTERVAL_PATHNAME = `${RoutePatterns.UPLOAD_STREAM_DATA}/select/reddit/select-interval`;

export const RedditPage = ({
  projectData,
  onAlert,
  inputs,
  setInputs,
  initialInputs,
  repeat,
  repeatInterval,
  setRepeat,
  setRepeatInterval
}) => {
  const history = useHistory();
  const location = useLocation();
  const successHandler = message => {
    onAlert({
      type: 'success',
      message
    });
    history.push(RoutePatterns.UPLOAD_STREAM_DATA);
  };

  const errorHandler = message => {
    onAlert({
      type: 'error',
      message
    });
  };

  const [step, setStep] = useState(1);

  const [projectDataErrors, setProjectDataErrors] = useState([]);
  const [requestInProcess, setRequestInProcess] = useState(false);
  const [isImportUrlsOpen, setIsImportUrlsOpen] = useState(false);

  const inputChangeHandler = event => {
    const newInputs = [...inputs];
    const existedInputIndex = newInputs.findIndex(
      input => input.name === event.target.name
    );
    newInputs[existedInputIndex] = {
      ...newInputs[existedInputIndex],
      value: event.target.value
    };
    setInputs(newInputs);
  };

  const addInputHandler = () => {
    setInputs(prevState => [
      ...prevState,
      { ...initialInputs[0], name: `url${uniqueId()}` }
    ]);
  };

  const removeInputHandler = name => {
    setInputs(prevState => prevState.filter(input => input.name !== name));
  };

  const removeAllInputsHandler = () => {
    setInputs(initialInputs);
  };

  const checkProjectData = useCallback(() => {
    setProjectDataErrors([]);
    if (!projectData.language) {
      setProjectDataErrors(prevState => [
        ...prevState,
        "Please, select the dataset's language to continue"
      ]);
    }
    if (!projectData.workspaceId) {
      setProjectDataErrors(prevState => [
        ...prevState,
        'Please, select the workspace to continue'
      ]);
    }
  }, [projectData.language, projectData.workspaceId]);

  const openImportHandler = () => {
    setIsImportUrlsOpen(true);
  };
  const closeImportHandler = () => {
    setIsImportUrlsOpen(false);
  };

  const validateUrl = url => {
    const urlRegex = /^(https?:\/\/)?(www\.)?reddit\.com\/r\/[^/]+\/.*$/;
    return urlRegex.test(url);
  };

  const validateInputs = () => {
    const validatedInputs = inputs.map(input => {
      if (!validateUrl(input.value)) {
        return { ...input, error: 'Please provide a valid Reddit URL.' };
      }
      return { ...input, error: '' };
    });
    setInputs(validatedInputs);
    return !validatedInputs.filter(input => input.error).length;
  };

  useEffect(() => {
    checkProjectData();
  }, [checkProjectData]);

  const submitHandler = async () => {
    setRequestInProcess(true);

    try {
      const createdProject = await createProject(
        projectData.workspaceId,
        projectData.name || 'Reddit Scrape',
        projectData.language,
        projectData.description
      );

      if (!createdProject) {
        throw new Error('Unexpected issue during project creation');
      }

      const redditList = inputs.map(({ value }) => {
        const parts = value.slice(value.indexOf('/r/') + 3).split('/');

        const subreddit_name = parts[0];
        const submission_id = parts[2];

        if (submission_id) {
          return { reddit_type: 'redditbyid', submission_id };
        }

        return { reddit_type: 'redditbyname', subreddit_name };
      });

      const taskResponse = await createRedditTaskDashboard(
        createdProject.project_id,
        redditList,
        repeat,
        repeatInterval
      );
      if (
        taskResponse?.status_code === 200 &&
        taskResponse?.status === 'success'
      ) {
        successHandler('Reddit task successfully created!');
        setRequestInProcess(false);
      } else {
        throw new Error('Something went wrong!');
      }
    } catch (e) {
      setRequestInProcess(false);
      errorHandler(e.message);
    }
  };

  const goNextStepHandler = () => {
    if (step === 1) {
      if (!validateInputs()) return;
    }
    setStep(prevState => prevState + 1);
  };

  const goBackHandler = () => {
    if (step === 1) history.push(RoutePatterns.UPLOAD_STREAM_DATA);
    setStep(prevState => prevState - 1);
  };

  useEffect(() => {
    if (step === 1 && location.pathname !== INPUT_URLS_PATHNAME)
      history.push(INPUT_URLS_PATHNAME);
    if (step === 2 && location.pathname !== SELECT_INTERVAL_PATHNAME)
      history.push(SELECT_INTERVAL_PATHNAME);
  }, [history, step]);

  useEffect(() => {
    if (location.pathname === INPUT_URLS_PATHNAME) setStep(1);

    if (location.pathname === SELECT_INTERVAL_PATHNAME && validateInputs())
      setStep(2);
  }, [location.pathname]);

  return (
    <>
      <StreamSourceLayout onGoBack={goBackHandler}>
        <Switch>
          <Route
            exact
            path={`${RoutePatterns.UPLOAD_STREAM_DATA}/select/reddit`}
          >
            <Redirect to={INPUT_URLS_PATHNAME} />
          </Route>
          <Route path={SELECT_INTERVAL_PATHNAME}>
            <OptionsPage
              repeat={repeat}
              setRepeat={setRepeat}
              setRepeatInterval={setRepeatInterval}
              repeatInterval={repeatInterval}
              submitHandler={submitHandler}
              isLoading={requestInProcess}
            />
          </Route>
          <Route path={INPUT_URLS_PATHNAME}>
            <>
              <div
                css={css`
                  display: flex;
                  margin: 20px 0;
                  justify-content: space-between;
                  width: 100%;
                `}
              >
                <Button flavor="subtle" onClick={openImportHandler}>
                  Import URLs
                </Button>
                <div
                  css={css`
                    display: flex;
                    gap: 10px;
                  `}
                >
                  <Button
                    onClick={removeAllInputsHandler}
                    css={css`
                      height: 35px;
                    `}
                    palette="red"
                  >
                    Remove All
                  </Button>
                </div>
              </div>
              <RedditUrlImportModal
                isOpen={isImportUrlsOpen}
                setInputs={setInputs}
                onClose={closeImportHandler}
              />
              <RedditInputDataForm
                inputs={inputs}
                onPressNext={goNextStepHandler}
                onAddInput={addInputHandler}
                onChangeInput={inputChangeHandler}
                onRemoveInput={removeInputHandler}
                projectDataErrors={projectDataErrors}
              />
            </>
          </Route>
        </Switch>
      </StreamSourceLayout>
    </>
  );
};
