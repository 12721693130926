import _ from 'lodash';
import d3 from 'd3';

import { selectConcept } from '../actions';
import ClusterUtils from '../utils/ClusterUtils';
import fisheye from './fisheye';
import V from '../utils/v';
import BaseCloud from './BaseCloud';

/*
TODO:
  - Refactor such that it is possible to build the concept cloud
  and the color cloud on top of a shared BaseCloud class
    - Parameterizable start position (already done?)
    - Parameterizable drag behavior (option to turn off)
    - External click handler
    - layoutComplete: Parameterizable text fill

  - Add incremental update ability
*/

export default class ConceptCloud extends BaseCloud {
  constructor(containerSelector, colorScheme, props) {
    super(containerSelector, props);
    this.useColorScheme = this.useColorScheme.bind(this);
    this.recolor = this.recolor.bind(this);
    this.vecProjection = this.vecProjection.bind(this);
    this.findMeanVector = this.findMeanVector.bind(this);
    this.setDefaultAxisVectors = this.setDefaultAxisVectors.bind(this);
    this.layoutCompleted = this.layoutCompleted.bind(this);
    this.colorScheme = colorScheme;
    this.recolor(colorScheme);

    // Fisheye distortion helps separates clusters when a 2-D projection tends
    // to clump things at the center. A distortion factor of 5 was established
    // in QuickLearn 1, and probably still works with QuickLearn 2's reduced
    // dimensionality.
    this.goFish = fisheye.circular().radius(1).distortion(props.distortion);
  }

  updateProps({
    projectId,
    filter,
    selection,
    resetAxes,
    hideAxisLabels,
    distortion,
    setHovered,
    xAxisConcept,
    yAxisConcept,
    fontSizeDomain
  }) {
    this.props = {
      projectId,
      filter,
      selection,
      resetAxes,
      hideAxisLabels,
      distortion,
      setHovered,
      xAxisConcept,
      yAxisConcept,
      fontSizeDomain
    };
  }

  useColorScheme(colorScheme) {
    this.colorScheme = colorScheme;
    this.recolor(colorScheme);
  }

  recolor(colorScheme) {
    // This way 'this' is the instance, rather than the class.
    this.recolor = _.debounce(colorScheme => {
      let sel;
      if (this.layoutInTransition) {
        // Reuse the existing position transition,
        // as we need to be careful to do any time
        // we transition our text elements.
        sel = this.layoutTransition;
      } else {
        sel = this.vis.selectAll('text').interrupt().transition();
      }

      sel.attr('fill', w => colorScheme(w.datum));
    }, 10);

    this.recolor(colorScheme);
  }

  initEventHandling() {
    super.initEventHandling();
    this.initDragEvents();

    this.svg.on('click', () => {
      if (!this.props.selection) {
        return;
      }
      selectConcept(this.props.projectId, null, this.props.filter);
    });
  }

  // Use normalization and Gram-Schmidt orthogonalization to ensure that
  // this projection represents a valid rotation of the component space,
  // not a dilation or skew.
  //
  // Actually, now it only does that when power=1. When power is less, the
  // space will not enforce strict rotations, it will only make the space
  // wobble back toward orthogonality.
  orthogonalizeVectors(xVec, yVec, power) {
    const len = yVec.length;
    const yVecNew = new Float32Array(len); // TODO: This is inefficient.

    // TODO: Make sure axes are already normalized. I think they already are...
    V.normalize(xVec);
    V.normalize(yVec);

    const commonMag = V.dot(xVec, yVec);
    for (let i = 0; i < len; i++) {
      yVecNew[i] = yVec[i] - xVec[i] * commonMag;
    }

    V.normalize(yVecNew);

    for (let i = 0; i < len; i++) {
      yVec[i] = yVecNew[i] * power + yVec[i] * (1 - power);
    }
  }

  // Because we can zoom, allow concept positions to go 20% beyond the given
  // layout size. This should especially help in laptop-sized windows.
  vecProjection(
    vec,
    xAxis,
    yAxis,
    width = this.layoutWidth * 1.2,
    height = this.layoutHeight * 1.2
  ) {
    // Calculate new position
    const centered = new Float32Array(vec.length);
    V.subtract(vec, this.meanVector, centered);

    const pos = this.goFish({
      x: V.vectorRelatednessScore(xAxis, centered),
      y: V.vectorRelatednessScore(yAxis, centered)
    });

    return [0.5 * width * pos.x, 0.5 * height * pos.y];
  }

  initDragEvents() {
    // Axes used for positioning during a drag operation
    let heightToUse = null,
      widthToUse = null,
      relativeScreenX = null,
      relativeScreenY = null,
      screenMouseX = null,
      screenMouseY = null,
      screenToVisMatrix = null,
      visToScreenMatrix = null,
      startScreenMouseX = null,
      startScreenMouseY = null,
      target = null,
      targetData = null,
      update,
      visMouseX = null,
      visMouseY = null,
      xAxisInterp = null,
      yAxisInterp = null;

    // Input scales are used to map from SVG coordinates to interpolation values
    const inputScale = () => d3.scale.linear().range([-1, 0, 1]).clamp(true);
    const xInputScale = inputScale();
    const yInputScale = inputScale();

    // Smooth position changes. The lower, the slower.
    const convergenceFactor = 0.2;

    let dragDisabled = true;

    const cloud = this;
    const svg = this.svg.node();
    const vis = this.vis.node();

    const screenToVis = function (x, y) {
      let point = svg.createSVGPoint();
      point.x = x;
      point.y = y;
      point = point.matrixTransform(screenToVisMatrix);
      return [point.x, point.y];
    };

    const screenToRelativeScreen = function (x, y) {
      // Screen coordinates relative to the svg element
      const offset = svg.getBoundingClientRect();
      return [x - offset.left, y - offset.top];
    };

    const renewTransformationMatrices = function () {
      visToScreenMatrix = vis.getScreenCTM();
      screenToVisMatrix = visToScreenMatrix.inverse();
    };
    renewTransformationMatrices();

    const cancelEventIfRightButton = function (e = d3.event) {
      if (e.button === 2) {
        e.stopPropagation();
        e.preventDefault();
      }
    };

    // Record screen coordinates
    // TODO: Remove these event listeners when the cloud
    // is unmounted to prevent memory leaks
    window.addEventListener('mousedown', function (e) {
      cancelEventIfRightButton(e);
      [startScreenMouseX, startScreenMouseY] = [screenMouseX, screenMouseY] = [
        e.pageX,
        e.pageY
      ];
      [visMouseX, visMouseY] = screenToVis(screenMouseX, screenMouseY);
    });
    window.addEventListener('mousemove', function (e) {
      cancelEventIfRightButton(e);
      [screenMouseX, screenMouseY] = [e.pageX, e.pageY];
      [visMouseX, visMouseY] = screenToVis(screenMouseX, screenMouseY);
    });
    window.addEventListener('mouseup', function (e) {
      cancelEventIfRightButton(e);
      visMouseX = visMouseY = null;
      screenMouseX = screenMouseY = startScreenMouseX = startScreenMouseY = null;
    });

    this.svg.on(
      'mousedown',
      function () {
        cancelEventIfRightButton();
        [startScreenMouseX, startScreenMouseY] = [
          screenMouseX,
          screenMouseY
        ] = d3.mouse(cloud.vis.node());
        [visMouseX, visMouseY] = screenToVis(screenMouseX, screenMouseY);
      },
      true
    );

    const self = this;
    this.dragBehavior = d3.behavior
      .drag()
      .origin(function () {
        // Return the [x, y] coordinates of the dragged element.
        // If the cloud is animating, neither the origin (w.x, y) or
        // destination (w._x, _y) positions are accurate regarding
        // the current position of the element in the DOM. So we grab
        // the position directly from the element's transform attribute.
        const transformation = d3.transform(d3.select(this).attr('transform'));
        const [x, y] = transformation.translate;
        return { x, y };
      })
      .on('dragstart', function (w) {
        self.currentlyDragging = true;
        // prevent other UI elements from being selected during drag
        d3.event.sourceEvent.preventDefault();
        d3.event.sourceEvent.stopPropagation(); // Prevent the zoom behavior.

        [relativeScreenX, relativeScreenY] = screenToRelativeScreen(
          screenMouseX,
          screenMouseY
        );

        // Set the input scale origins
        xInputScale.domain([0, relativeScreenX, cloud.width]);
        yInputScale.domain([0, relativeScreenY, cloud.height]);

        // Initialize drag axes
        xAxisInterp = new Float32Array(cloud.xAxisVector);
        yAxisInterp = new Float32Array(cloud.yAxisVector);

        // Add 'dragging' class to the concept and the container
        d3.select(this).classed('dragging', true);
        d3.select(cloud.containerSelector).classed('dragging', true);

        // Stop any layout computations that are in progress
        cloud.stopLayout();

        // Stop layout transitions
        cloud.stopTransition(cloud.layoutTransition);
        cloud.layoutInTransition = false;
        cloud.layoutTransition = null;

        // Mark this element as the mouse target
        target = this;
        targetData = w;
        targetData.isTarget = true;

        // Bring it to the front
        target.parentNode.appendChild(target);

        // The drag will be enabled once the concept has been moved sufficiently
        // far from its original position
        dragDisabled = true;

        // Store our original position
        w._dragStartX = w.x;
        w._dragStartY = w.y;
      })
      .on('drag', function (w) {
        renewTransformationMatrices();
        self.currentlyDragging = true;

        // Relative
        const [relativeMouseX, relativeMouseY] = d3.mouse(cloud.vis.node());
        // TODO: Fix the mouse position offset

        // If we've moved sufficiently far, start responding to drag movement.
        if (
          dragDisabled &&
          Math.abs(screenMouseX - startScreenMouseX) +
            Math.abs(screenMouseY - startScreenMouseY) >
            30
        ) {
          dragDisabled = false;
          setTimeout(update, 0);

          // Clear out the concepts that have been selected as the axes
          cloud.props.resetAxes();

          try {
            cloud.vis.selectAll('text').style('fill-opacity', function (w) {
              const r = targetData.datum.getAssociation(w.datum);
              return (r + 1) / 2;
            });
          } catch (e) {
            console.log('Caught:', e);
          }

          widthToUse = cloud.mostRecentBoundingBoxWidth + 50;
          heightToUse = cloud.mostRecentBoundingBoxHeight + 50;
        }

        if (dragDisabled) {
          // If we're not yet dragging, still update the position of the dragged element
          [w.x, w.y] = [visMouseX, visMouseY];
          w.x += relativeMouseX - visMouseX;
          w.y += relativeMouseY - visMouseY;
          d3.select(this).attr('transform', `translate(${w.x}, ${w.y})`);
        }
      })
      .on('dragend', function (w) {
        d3.select(this).classed('dragging', false);
        d3.select(cloud.containerSelector).classed('dragging', false);

        if (dragDisabled) {
          const { projectId, filter } = self.props;
          selectConcept(projectId, w.datum, filter);
          w.x = w._dragStartX;
          w.y = w._dragStartY;
          d3.select(this).attr('transform', `translate(${w.x}, ${w.y})`);
        } else {
          dragDisabled = true;

          // Update axis vectors
          cloud.xAxisVector = new Float32Array(xAxisInterp);
          cloud.yAxisVector = new Float32Array(yAxisInterp);

          const text = cloud.vis.selectAll('text').each(function (w) {
            [w.startx, w.starty] = cloud.vecProjection(
              w.datum.galaxyVectors[0],
              xAxisInterp,
              yAxisInterp
            );
          });

          try {
            text.style('fill-opacity', 1);
          } catch (e) {
            console.log('Caught:', e);
          }

          cloud.startLayout();
        }

        self.currentlyDragging = false;

        delete w.isTarget;
        delete w._dragStartX;
        delete w._dragStartY;

        target = targetData = null;
      });

    update = function () {
      if (dragDisabled) {
        return;
      }

      const vec = targetData.datum.galaxyVectors[0];

      const targetX = visMouseX;
      const targetY = visMouseY;

      // Find out where the point originally should have projected to.
      const [origX, origY] = cloud.vecProjection(
        vec,
        xAxisInterp,
        yAxisInterp,
        widthToUse,
        heightToUse
      );

      let vecMag = 0;
      const k = vec.length;
      for (let i = 0; i < k; i++) {
        vecMag += vec[i] * vec[i] * 1500;
      }

      const deltaX = (targetX - origX) / vecMag;
      const deltaY = (targetY - origY) / vecMag;

      for (let i = 0; i < k; i++) {
        xAxisInterp[i] += deltaX * vec[i];
        yAxisInterp[i] += deltaY * vec[i];
      }

      const magnitude = deltaX * deltaX + deltaY * deltaY;
      const power = Math.min(0.5, Math.atan(magnitude) / 5);
      cloud.orthogonalizeVectors(xAxisInterp, yAxisInterp, power);

      // Figure out the visible layout bounds, given that we've zoomed and scaled
      cloud.vis
        .selectAll('text')
        .each(function (w) {
          const concept = w.datum;

          const [x, y] = cloud.vecProjection(
            concept.galaxyVectors[0],
            xAxisInterp,
            yAxisInterp,
            widthToUse,
            heightToUse
          );

          // Transition smoothly
          w.x = w.x + (x - w.x) * convergenceFactor;
          w.y = w.y + (y - w.y) * convergenceFactor;
        })
        .attr('transform', w => `translate(${w.x}, ${w.y})`);

      setTimeout(update, 1000 / 65);
    };
  }

  initLayout() {
    super.initLayout();
    this.layout.text(d => d.name);
  }

  setData(concepts) {
    super.setData(concepts);

    if (this.data.length) {
      // Build an adjacency cluster and leaders
      this.leaders = ClusterUtils.adjacencyCluster(
        ClusterUtils.graphFromTerms(concepts.slice(0, 50))
      ).leaders;
      this.meanVector = this.findMeanVector();
      this.setDefaultAxisVectors();
    }
  }

  loaded(concepts) {
    // Assume we're activating with at least one concept.
    this.setData(concepts);

    // Re-set axes when we get new data, so that
    // the new concepts have the correct desired positions
    this.setXAxisByConcept(this.props.xAxisConcept);
    this.setYAxisByConcept(this.props.yAxisConcept);

    this.setSizeScheme(this.getRelevanceSizeScheme());

    this.startLayout();
  }

  getRelevanceSizeScheme() {
    // note: Assumes non-empty data

    // avoid 0 or negative values in the domain
    const domain = this.props.fontSizeDomain.map(value => Math.max(value, 1));

    let range;
    const numberOfConcepts = this.data.length;
    if (numberOfConcepts <= 100) {
      range = [4, 18];
    } else if (numberOfConcepts <= 200) {
      range = [3, 17];
    } else if (numberOfConcepts <= 300) {
      range = [2, 16];
    } else {
      range = [1, 15];
    }
    const fontSize = d3.scale.log().clamp(true).domain(domain).range(range);
    return d => fontSize(d.relevance);
  }

  // TODO: Review this type of function for consistency --
  // the hope is to be consistent with things like setColorScheme and colorByX.
  setSizeScheme(scheme) {
    this.sizeScheme = scheme;
    this.layout.fontSize(scheme);
  }

  // It's important to QuickLearn 2 that we recenter the data, so we can
  // spread out the concepts appropriately without changing their relatedness.
  // When we have the data, this function is the one that finds the mean vector
  // of all relevant concepts, which will be subtracted out from all projections.
  findMeanVector() {
    let meanVector = new Float32Array(this.data[0].galaxyVectors[0].length);
    for (let i = 0; i < this.data.length; i++) {
      let vec = this.data[i].galaxyVectors[0];
      for (let j = 0; j < vec.length; j++) {
        meanVector[j] += vec[j];
      }
    }
    for (let j = 0; j < meanVector.length; j++) {
      meanVector[j] /= this.data.length;
    }
    return meanVector;
  }

  setDefaultAxisVectors() {
    if (!this.data) {
      throw new Error('Cannot set default axes without any data.');
    }

    this.xAxisVector = ClusterUtils.deciderFromDominantCluster(this.leaders[1]);
    this.yAxisVector = ClusterUtils.deciderFromDominantCluster(this.leaders[0]);

    this.layout.startx(concept => {
      return this.vecProjection(
        concept.galaxyVectors[0],
        this.xAxisVector,
        this.yAxisVector
      )[0];
    });

    this.layout.starty(concept => {
      return this.vecProjection(
        concept.galaxyVectors[0],
        this.xAxisVector,
        this.yAxisVector
      )[1];
    });
  }

  setXAxisByConcept(concept) {
    if (concept) {
      this.xAxisVector = new Float32Array(concept.galaxyVectors[0]);

      this.layout.startx(concept => {
        return this.vecProjection(
          concept.galaxyVectors[0],
          this.xAxisVector,
          this.yAxisVector
        )[0];
      });
    }
  }

  setYAxisByConcept(concept) {
    if (concept) {
      this.yAxisVector = new Float32Array(concept.galaxyVectors[0]);
      for (let i = 0; i < this.yAxisVector.length; i++) {
        const y = this.yAxisVector[i];
        this.yAxisVector[i] = -y;
      }

      this.layout.starty(concept => {
        return this.vecProjection(
          concept.galaxyVectors[0],
          this.xAxisVector,
          this.yAxisVector
        )[1];
      });
    }
  }

  layoutCompleted(words, bounds) {
    this.bounds = bounds;
    const boundsCopy = _.cloneDeep(this.bounds);
    super.layoutCompleted(words, boundsCopy);

    const { colorScheme } = this;

    const sel = this.vis
      .selectAll('text')
      .on('mouseover', w => {
        if (d3.event.defaultPrevented) {
          return;
        }
        if (!this.layoutInTransition && !this.currentlyDragging) {
          this.props.setHovered(w.datum);
        }
      })
      .on('mouseout', () => {
        if (d3.event.defaultPrevented) {
          return;
        }
        if (!this.layoutInTransition) {
          this.props.setHovered();
        }
      })
      // Remove the remnants of drag data
      .each(function (w) {
        delete w._x;
        delete w._y;
      })
      // NOTE: This should possible be refactored so that it's clearer that
      // recolor() isn't the only thing that controls color.
      .attr({
        class: 'concept',
        fill(w) {
          return colorScheme(w.datum);
        },
        'data-tracking-item': 'galaxy_concept'
      });

    if (this.dragBehavior) {
      sel.call(this.dragBehavior);
    } else {
      console.log('There is no drag behavior. Noes...', sel);
    }
  }
}
