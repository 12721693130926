import _ from 'lodash';
import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import * as gtm from '../../utils/gtm';
import { selectConcept } from '../../actions';
import ConceptComponent from '../Concept';
import List from './List';
import VirtualList from '../VirtualList';
import { Colors } from '../../styles';
import PlaceholderText from './PlaceholderText';
import { useFilter } from '../../search_params';
import { percentify, thousandify } from '../../utils/NumFmtUtils';
import { useHover } from '../../HoverContext';
import { StoreContext } from '../../StoreContext';
import { usePrevious } from '../../utils/hooks';
import { Concept } from '../../classes/Concepts';
import { Icon, IconTypes } from '../icons';

const TableContext = React.createContext({
  sortedColumn: undefined,
  sortDirection: undefined,
  onSortChange: () => {}
});

export function Table({ children, onSortChange, sortDirection, sortedColumn }) {
  return (
    <TableContext.Provider
      value={{ onSortChange, sortDirection, sortedColumn }}
    >
      {children}
    </TableContext.Provider>
  );
}

Table.propTypes = {
  children: PropTypes.node.isRequired,
  onSortChange: PropTypes.func,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  sortedColumn: PropTypes.string
};

export function Header({ children, borderColor = 'transparent' }) {
  return (
    <div
      data-test-id="header"
      css={css`
        font-weight: 700;
        border-bottom: 1px solid ${borderColor};
        background: white;
      `}
    >
      {children}
    </div>
  );
}

Header.propTypes = {
  children: PropTypes.node.isRequired
};

export const Body = React.forwardRef(function Body(
  { children, hoverable = true },
  ref
) {
  return (
    <div data-test-id="body" ref={ref}>
      {typeof children === 'function' ? (
        <VirtualList>{children}</VirtualList>
      ) : (
        <List bordered={false} scrollable={false} hoverable={hoverable}>
          {children}
        </List>
      )}
    </div>
  );
});

Body.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  hoverable: PropTypes.bool
};

export const Row = React.forwardRef(function Row(
  {
    children,
    onClick,
    active,
    renderExpanded,
    bordered,
    height,
    className,
    ...props
  },
  ref
) {
  const clickable = !active && onClick;
  const borderThickness = '0.125rem';
  return (
    <div
      data-test-id="row"
      css={css`
        position: relative;

        ${bordered &&
        css`
          &::after {
            content: '';
            position: absolute;
            height: calc(100% - 2 * ${borderThickness});
            width: calc(100% - 2 * ${borderThickness});
            top: 0;
            left: 0;
            pointer-events: none;
            border: ${borderThickness} solid ${Colors.blue4};
          }
        `}
      `}
      {...props}
      ref={ref}
    >
      <div
        css={css`
          padding-left: 0.5rem;
          display: grid;
          grid-template-columns: 30% 20% auto;
          height: ${height};
          ${clickable &&
          css`
            cursor: pointer;
          `}
        `}
        className={className}
        {...(clickable && {
          tabIndex: 0,
          role: 'button',
          onClick,
          onKeyPress: event => {
            if (event.key === 'Enter' || event.key === ' ') {
              onClick();
            }
          }
        })}
      >
        {children}
      </div>
      {renderExpanded?.()}
    </div>
  );
});

Row.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  renderExpanded: PropTypes.func,
  bordered: PropTypes.bool,
  height: PropTypes.string,
  className: PropTypes.any
};

Row.defaultProps = {
  height: '2.5rem'
};

export const Cell = React.forwardRef(function Cell(
  { onClick, loading, children, className },
  ref
) {
  return (
    <div
      className={className}
      ref={ref}
      data-test-id="cell"
      css={css`
        height: 100%;
        display: flex;
        align-items: center;
        ${loading &&
        css`
          color: ${Colors.gray5};
        `}

        ${onClick &&
        css`
          cursor: pointer;
          user-select: none;
        `}
      `}
      onClick={onClick}
    >
      {children}
    </div>
  );
});

Cell.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string
};

function flipDirection(direction) {
  return direction === 'asc' ? 'desc' : 'asc';
}

export function TableSortControl({
  sortingKey,
  invertedSort = false,
  children
}) {
  const { sortDirection, sortedColumn, onSortChange } = useContext(
    TableContext
  );

  return (
    <button
      type="button"
      onClick={() => {
        onSortChange(
          sortingKey,
          sortingKey === sortedColumn
            ? flipDirection(sortDirection)
            : invertedSort
            ? 'asc'
            : 'desc'
        );
      }}
      css={css`
        display: inline-flex;
        flex-direction: row;
        white-space: pre-wrap;
        gap: 0.5rem;
      `}
      data-tracking-item="concept-list-table_sortable-header"
    >
      <SortOrderIndicator
        sortingKey={sortingKey}
        css={css`
          flex-shrink: 0;
          align-self: center;
        `}
      />
      <span
        css={css`
          flex: 1;
          text-align: start;
        `}
      >
        {children}
      </span>
    </button>
  );
}

TableSortControl.propTypes = {
  children: PropTypes.node.isRequired,
  sortingKey: PropTypes.string.isRequired,
  invertedSort: PropTypes.bool
};

function SortOrderIndicator({ sortingKey, className }) {
  const { sortDirection, sortedColumn } = useContext(TableContext);
  const arrowDirection = sortDirection === 'asc' ? 'up' : 'down';

  return (
    <Icon
      type={IconTypes.SORT}
      direction={sortingKey === sortedColumn ? arrowDirection : undefined}
      className={className}
    />
  );
}

export function LoadingTableBody({ displayPlaceholderText }) {
  return (
    <Body hoverable={false}>
      {[...Array(50)].map((element, index) => {
        return (
          <LoadingRow
            key={index}
            displayPlaceholderText={displayPlaceholderText}
          />
        );
      })}
    </Body>
  );
}

export function LoadingRow({ displayPlaceholderText, active }) {
  return (
    <Row active={active}>
      {displayPlaceholderText ? <LoadingRowContent /> : null}
    </Row>
  );
}

LoadingRow.propTypes = {
  displayPlaceholderText: PropTypes.bool,
  active: PropTypes.bool
};

function LoadingRowContent() {
  const firstCellWidth = useRef(`${_.random(5, 10)}rem`);
  const secondCellWidth = useRef(`${_.random(2.5, 3, true)}rem`);

  return (
    <>
      <Cell>
        <PlaceholderText style={{ width: firstCellWidth.current }} />
      </Cell>
      <Cell>
        <PlaceholderText style={{ width: secondCellWidth.current }} />
      </Cell>
    </>
  );
}

export const ScrollableTable = React.forwardRef(function ScrollableTable(
  { header, body, ...props },
  ref
) {
  const scrollContainerRef = useRef();
  const tableBodyRef = useRef();

  useImperativeHandle(ref, () => ({
    scrollToRow: rowRef => {
      const row = rowRef.current;

      if (row) {
        const { top, bottom } = row.getBoundingClientRect();
        const table = scrollContainerRef.current.getBoundingClientRect();

        const partiallyOutsideTable = top < table.top || bottom > table.bottom;
        const partiallyBelowTable = top < table.bottom && bottom > table.bottom;

        if (partiallyOutsideTable) {
          // If row is partially visible and at the bottom of the table,
          // don't scroll all the way to the top
          row.scrollIntoView(!partiallyBelowTable);
        }
      }
    }
  }));

  return (
    <Table {...props}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          height: 100%;
        `}
      >
        {header}
        <div
          ref={scrollContainerRef}
          css={css`
            flex: 1;
            min-height: 0;
            overflow: auto;
            &::-webkit-scrollbar {
              display: none;
            }
          `}
        >
          <div ref={tableBodyRef}>{body}</div>
        </div>
      </div>
    </Table>
  );
});

ScrollableTable.propTypes = {
  header: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  selectedRowRef: PropTypes.object
};

export function ConceptHeader({
  visualizationHeader,
  loading = false,
  matchType
}) {
  return (
    <Header borderColor={Colors.gray2}>
      <Row>
        <HeaderCell loading={loading} sortingKey="name" invertedSort>
          Concepts
        </HeaderCell>

        <HeaderCell loading={loading} sortingKey="matches">
          {matchType === 'exact' ? 'Exact matches' : 'Total matches'}
        </HeaderCell>

        {visualizationHeader && <Cell>{visualizationHeader}</Cell>}
      </Row>
    </Header>
  );
}

ConceptHeader.propTypes = {
  visualizationHeader: PropTypes.node,
  loading: PropTypes.bool,
  matchType: PropTypes.oneOf(['exact', 'total']).isRequired
};

export function HeaderCell({ children, loading, sortingKey, invertedSort }) {
  return (
    <Cell loading={loading}>
      {!loading ? (
        <TableSortControl sortingKey={sortingKey} invertedSort={invertedSort}>
          {children}
        </TableSortControl>
      ) : (
        children
      )}
    </Cell>
  );
}

HeaderCell.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  sortingKey: PropTypes.string.isRequired,
  invertedSort: PropTypes.bool
};

export const ConceptRow = React.forwardRef(function ConceptRow(
  {
    projectId,
    concept,
    renderVisualization,
    renderExpanded,
    selected,
    matchCount,
    totalCount
  },
  ref
) {
  const filter = useFilter();
  const [, setHovered] = useHover();

  return (
    <Row
      onClick={
        !selected
          ? () => {
              selectConcept(projectId, concept, filter);
              gtm.trigger('concept-list-table_selectable-concept-row');
            }
          : undefined
      }
      renderExpanded={renderExpanded}
      active={selected}
      bordered={selected}
      ref={ref}
      onMouseEnter={() => setHovered(concept)}
      onMouseLeave={() => setHovered()}
    >
      <Cell>
        <ConceptComponent
          concept={concept}
          stopPropagation
          trackingPrefix="concept-list-table"
        />
      </Cell>
      <Cell>
        <span>
          {thousandify(matchCount)}{' '}
          <span
            css={css`
              color: ${Colors.gray5};
            `}
          >
            ({percentify(matchCount, totalCount)})
          </span>
        </span>
      </Cell>
      <Cell>{renderVisualization()}</Cell>
    </Row>
  );
});

ConceptRow.propTypes = {
  projectId: PropTypes.string.isRequired,
  concept: PropTypes.shape({
    sharedConceptId: PropTypes.string,
    exactTermIds: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  renderVisualization: PropTypes.func.isRequired,
  renderExpanded: PropTypes.func,
  selected: PropTypes.bool,
  matchCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired
};

export function useScrollToSelectedConceptRow(tableRef, selectedRowRef) {
  const { selection } = useContext(StoreContext);
  const prevSelection = usePrevious(selection);

  // Scroll to the selected row if the selected concept has changed. i.e.:
  // * There wasn't a selection, but now there is
  // * The selected concept's texts changed (unless it's an active concept that
  //   was edited)
  useEffect(() => {
    if (!selection || selection === prevSelection) {
      return;
    }

    const textsChanged = !Concept.areTextsEqual(selection, prevSelection);
    const sameId =
      selection.isActive &&
      selection.sharedConceptId === prevSelection?.sharedConceptId;

    if (!prevSelection || (textsChanged && !sameId)) {
      tableRef.current.scrollToRow(selectedRowRef);
    }
  }, [selection, prevSelection]);
}
