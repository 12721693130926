function isScrollable(element) {
  const { overflow } = getComputedStyle(element);
  return ['auto', 'scroll'].includes(overflow);
}

function getScrollContainer(element) {
  if (!element) {
    return null;
  }
  let scrollContainer = element.parentElement;
  while (scrollContainer && !isScrollable(scrollContainer)) {
    scrollContainer = scrollContainer.parentElement;
  }
  return scrollContainer;
}

export function scrollToElement(element) {
  const container = getScrollContainer(element);
  if (!container) {
    return;
  }
  const elementRect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  container.scrollBy({
    top: elementRect.top - containerRect.top,
    left: elementRect.left - containerRect.left,
    behavior: 'smooth'
  });
}

/**
 * Scroll just enough to make element completely visible. Only scrolls in the y
 * direction.
 * @param {HTMLElement} element - the element to bring into view
 */
export function scrollElementIntoView(element) {
  const container = getScrollContainer(element);
  if (!container) {
    return;
  }
  const { top, bottom } = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  // Don't scroll if element is fully visible
  if (top >= containerRect.top && bottom <= containerRect.bottom) {
    return;
  }

  // If the element is not fully visible then it must be (at least partially)
  // either above or below the container
  const partiallyBelowContainer = bottom > containerRect.bottom;

  // If the element is below the container then move the container down, thereby
  // moving the element up and vice versa.
  container.scrollBy({
    top: partiallyBelowContainer
      ? bottom - containerRect.bottom
      : top - containerRect.top,
    behavior: 'smooth'
  });
}
