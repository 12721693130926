import React, { useContext } from 'react';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';

import {
  OverlayAlert,
  useOverlayAlertController
} from '../../components/core/Alert';
import Dropdown from '../../components/core/Dropdown';
import { openConceptList, restoreActiveConceptList } from '../../actions';
import { useActiveConceptListHasUnsharedChanges } from '../views/UnsharedChangesAlert';
import { StoreContext } from '../../StoreContext';
import { useDispatchUndoBanner } from '../../UndoBanner';

export function ImportSharedConceptList({
  projectId,
  conceptLists,
  onOpenList,
  refetchConceptLists
}) {
  const alertController = useOverlayAlertController();
  const numberOfLists = conceptLists?.length ?? 0;
  const hasUnsharedChanges = useActiveConceptListHasUnsharedChanges();
  const { activeConceptListName, activeConcepts } = useContext(StoreContext);
  const dispatchUndoBanner = useDispatchUndoBanner();

  return (
    <div
      css={css`
        padding: 0.5rem;
      `}
    >
      <OverlayAlert
        controller={alertController}
        css={css`
          width: 100%;
        `}
        cancellable
        duration={0.5}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
        >
          <Dropdown
            value=""
            disabled={numberOfLists === 0}
            onChange={event => {
              const selectedListId = event.target.value;
              openConceptList(projectId, selectedListId)
                .then(() => {
                  alertController.showSuccess('Success!');

                  if (hasUnsharedChanges) {
                    const { name } = conceptLists.find(
                      cl => cl.concept_list_id === selectedListId
                    );

                    dispatchUndoBanner({
                      message: `Replaced unsaved Active Concepts with "${name}"`,
                      onUndo: () =>
                        restoreActiveConceptList(
                          projectId,
                          activeConceptListName,
                          activeConcepts
                        ),
                      trackingItem: 'undo-load-concept-list'
                    });
                  }
                })
                .catch(() => {
                  alertController.showWarning(
                    'Unable to open concept list. This list may have been deleted.'
                  );
                  refetchConceptLists();
                });
              onOpenList();
            }}
            data-tracking-item="active-concepts_open-list"
            aria-label="Select a concept list"
            containerCss={css`
              flex: 1;
            `}
          >
            <option disabled value="">
              {numberOfLists === 0
                ? 'Select a concept list'
                : `Select a concept list (${numberOfLists})`}
            </option>
            {conceptLists?.map(conceptList => (
              <option
                key={conceptList.name}
                value={conceptList.concept_list_id}
              >
                {conceptList.name} ({conceptList.concepts.length})
              </option>
            ))}
          </Dropdown>
        </div>
      </OverlayAlert>
    </div>
  );
}

ImportSharedConceptList.propTypes = {
  projectId: PropTypes.string.isRequired,
  conceptLists: PropTypes.array,
  onOpenList: PropTypes.func.isRequired,
  refetchConceptLists: PropTypes.func.isRequired
};
