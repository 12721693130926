import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../components/core/Button';
import DialogFooter from '../../components/DialogFooter';

export default function ConceptConceptAssociationsForm({ onDownload }) {
  function onClickDownload(event) {
    event.preventDefault();
    return onDownload();
  }
  return (
    <form
      onSubmit={onClickDownload}
      data-tracking-item="analysis-exports_concept-concept_export-submit"
    >
      <p>
        This file will include the association scores of each active concept
        with each other active concept.
      </p>
      <DialogFooter>
        <Button type="submit">Export</Button>
      </DialogFooter>
    </form>
  );
}

ConceptConceptAssociationsForm.propTypes = {
  onDownload: PropTypes.func.isRequired
};
