/*
Component for handling fuzzy searching of a list of objects.

The box's contents are used as search text. When an onChange event fires, the
collection is searched through using a fuzzy search library, which produces a
reordered subset of the original collection. The search results are then passed
to the onSearch function which was passed in as a prop.
*/
import _ from 'lodash';
import cx from 'classnames';
import Sifter from 'sifter';
import React from 'react';
import PropTypes from 'prop-types';
import css from '@emotion/css';

import { Icon, IconTypes } from '../components/icons';
import { Button } from '../components/core/Button';

export default class FuzzySearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      sifter: new Sifter(this.props.collection),
      prevCollection: props.collection
    };
    this.clearSearch = this.clearSearch.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      prevCollection: props.collection,
      sifter:
        state.prevCollection === props.collection
          ? state.sifter
          : new Sifter(props.collection)
    };
  }

  componentDidUpdate(prevProps) {
    // if the collection that is being searched over has changed, the search should be
    // performed again.
    if (
      prevProps.collection !== this.props.collection &&
      !_.isEmpty(this.state.searchText)
    ) {
      this.search(this.state.searchText);
    }
  }

  search(searchText) {
    const { collection, onSearch, searchKeys } = this.props;

    this.setState({ searchText });

    const trimmedSearchText = searchText.trim();

    const result =
      trimmedSearchText.length > 0
        ? this.state.sifter
            .search(trimmedSearchText, {
              fields: searchKeys,
              sort: searchKeys.map(key => ({ field: key, direction: 'desc' }))
            })
            .items.map(item => collection[item.id])
        : null;

    onSearch(result);
  }

  clearSearch() {
    this.setState({ searchText: '' });
    this.props.onSearch(null);
  }

  render() {
    const { searchText } = this.state;
    const { className } = this.props;

    return (
      <div className={cx('fuzzy-search-box', className)}>
        <input
          {..._.omit(this.props, Object.keys(FuzzySearchBox.propTypes))}
          type="text"
          onChange={evt => this.search(evt.target.value)}
          value={searchText}
        />
        {searchText.trim().length !== 0 ? (
          <Button
            flavor="subtle"
            onClick={this.clearSearch}
            hiddenLabel="Clear Search"
            css={css`
              position: absolute;
              top: -0.2rem;
              right: 0.2rem;
            `}
          >
            <Icon type={IconTypes.CIRCULAR_CLOSE} />
          </Button>
        ) : (
          <Icon type={IconTypes.SEARCH} className="disabled" />
        )}
      </div>
    );
  }
}

FuzzySearchBox.propTypes = {
  className: PropTypes.string,
  collection: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSearch: PropTypes.func.isRequired,
  searchKeys: PropTypes.arrayOf(PropTypes.string)
};

FuzzySearchBox.defaultProps = {
  className: '',
  searchKeys: ['name']
};
