import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { StoreContext } from '../../StoreContext';
import { NoResultsMessage } from '../../side_panel/NoResultsMessage';
import { Colors, Mixins } from '../../styles';
import { DocMetadata } from '../DocMetadata';

export const Metadata = ({ docMetadata, fieldsToDisplay }) => {
  return (
    <Column>
      <h6>Metadata</h6>
      <MetadataList
        docMetadata={docMetadata}
        fieldsToDisplay={fieldsToDisplay}
      />
    </Column>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  word-break: break-word;
  min-width: 9rem;
`;

const MetadataList = ({ docMetadata, fieldsToDisplay }) => {
  const { metadata } = useContext(StoreContext);
  if (metadata.length === 0) {
    return <NoResultsMessage>None</NoResultsMessage>;
  } else if (fieldsToDisplay.length === 0) {
    return <NoResultsMessage>None selected</NoResultsMessage>;
  }
  return (
    <List>
      <DocMetadata
        docMetadata={docMetadata}
        fieldsToDisplay={fieldsToDisplay}
      />
    </List>
  );
};

const List = styled.ul`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  max-width: 60ch;
  flex: 1;
  border: 1px solid ${Colors.gray2};
  padding: 1rem 0.5rem;
  list-style-type: none;
  ${Mixins.roundedCorners};
`;
