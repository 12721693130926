import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import getTextWidth from '../utils/getTextWidth';

export function Label({
  name,
  x,
  y,
  maxWidth = Infinity,
  fontWeight,
  ...textProps
}) {
  const truncatedName = useMemo(
    () => getTruncatedName(name, maxWidth, fontWeight),
    [name, maxWidth, fontWeight]
  );
  return (
    <text
      x={x}
      y={y}
      dominantBaseline="middle"
      fontSize="0.875rem"
      fontWeight={fontWeight}
      {...textProps}
    >
      <title>{name}</title>
      {truncatedName}
    </text>
  );
}

Label.propTypes = {
  name: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  fontWeight: PropTypes.string,
  maxWidth: PropTypes.number
};

function getTruncatedName(name, maxWidth, fontWeight) {
  const measure = text => getTextWidth(text, '0.875rem', fontWeight);
  const ellipsify = text => (text === '' ? '' : `${text}…`);
  const truncate = text => text.slice(0, text.length - 1);

  if (measure(name) <= maxWidth) {
    return name;
  }

  let truncated = name;
  while (truncated !== '' && measure(ellipsify(truncated)) >= maxWidth) {
    truncated = truncate(truncated);
  }

  return ellipsify(truncated);
}
