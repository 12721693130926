import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '../../components/core/Button';
import { Icon, IconTypes } from '../../components/icons';
import { useDispatchUndoBanner } from '../../UndoBanner';
import { StoreContext } from '../../StoreContext';
import { makeEmptyConceptList, undoCreatingConceptList } from '../../actions';
import { useActiveListName } from './activeListName';
import { getNewListName } from './activeConceptsUtils';

export const NewListButton = () => {
  const { projectId } = useParams();
  const dispatchUndoBanner = useDispatchUndoBanner();
  const {
    activeConcepts,
    activeConceptListName,
    sharedConceptLists
  } = useContext(StoreContext);
  const hasUnsavedChanges = useHasUnsavedChanges();

  const createNewList = () => {
    const name = getNewListName(sharedConceptLists);

    makeEmptyConceptList(projectId, name).then(createdListId => {
      if (hasUnsavedChanges) {
        const oldName = activeConceptListName ?? 'unsaved list';
        dispatchUndoBanner({
          message: `${name} created\nChanges to ${oldName} were lost`,
          onUndo: () =>
            undoCreatingConceptList(
              projectId,
              {
                name: activeConceptListName,
                concepts: activeConcepts
              },
              createdListId
            )
        });
      }
    });
  };

  return (
    <Button onClick={createNewList} palette="green">
      <Icon type={IconTypes.ADD} />
      New list
    </Button>
  );
};

const useHasUnsavedChanges = () => {
  // `hasUnsavedChanges` is true if there's no shared list that matches the
  // current active concept list. Here though, if the active list had no name
  // and no concepts, we want to act like there are no unsaved changes to avoid
  // triggering the undo banner.
  const { hasUnsavedChanges } = useActiveListName();
  const { activeConceptListName, activeConcepts } = useContext(StoreContext);

  if (activeConcepts.length === 0 && activeConceptListName === null) {
    return false;
  }
  return hasUnsavedChanges;
};
