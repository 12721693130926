import React from 'react';

import SupportEmailLink from './SupportEmailLink';

const UnknownErrorMsg = () => {
  return (
    <span>
      We're sorry. Something has gone wrong. Please try refreshing the page and{' '}
      <SupportEmailLink>contact us</SupportEmailLink> if the problem persists.
    </span>
  );
};

export default UnknownErrorMsg;
