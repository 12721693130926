import { css } from '@emotion/core';
import { scaleBand, scaleLinear } from 'd3-scale';
import { min, max, range } from 'd3-array';
import React from 'react';
import PropTypes from 'prop-types';

import { Colors } from '../styles';
import { Label } from './Label';
import { centerTextY, getLongestTextLength } from './utils';
import getTextWidth from '../utils/getTextWidth';
import { GridLineXAxis } from '../components/GridLineXAxis';
import { Concept } from '../classes/Concepts';

export function IssuesAffectingScoreVisualization({ width, height, drivers }) {
  const columnGap = 16;

  const longestDriverWidth = getLongestTextLength(
    drivers.map(({ name }) => name),
    '0.875rem',
    'bold'
  );

  const longestAverageScoreWidth = getLongestTextLength(
    drivers.map(({ averageScore }) => averageScore.toFixed(2)),
    '0.875rem'
  );

  const driverWidth = Math.min(160, longestDriverWidth);
  const averageScoreWidth = Math.min(73, longestAverageScoreWidth);
  const barWidth = width - driverWidth - averageScoreWidth - columnGap * 2;

  // Get the count for the longer of the two lists of drivers
  const domainDrivers = drivers;

  // Total vertical space available for the stacked bar
  const yMax = height;
  const yScale = scaleBand()
    .domain(range(-1, domainDrivers.length))
    .rangeRound([0, yMax])
    .padding(0.25);

  // Should we have a better way of handling the case of width or height
  // being 0?
  if (width <= 0 || height <= 0) {
    return null;
  }

  return (
    <svg
      display="block"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="none"
    >
      <DriversColumn drivers={drivers} yScale={yScale} maxWidth={driverWidth} />
      <AverageScoreColumn
        drivers={drivers}
        yScale={yScale}
        columnStart={driverWidth + columnGap}
        columnWidth={averageScoreWidth}
      />
      <AverageScoreBarColumn
        drivers={drivers}
        columnStart={driverWidth + averageScoreWidth + columnGap * 2}
        columnWidth={barWidth}
        yScale={yScale}
      />
    </svg>
  );
}

IssuesAffectingScoreVisualization.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  drivers: PropTypes.array.isRequired
};

function AverageScoreColumn({ drivers, columnStart, columnWidth, yScale }) {
  return (
    <g>
      {drivers.map((driver, index) => {
        return (
          <Label
            key={driver.hash()}
            name={driver.averageScore.toFixed(2)}
            x={columnStart}
            y={centerTextY(index, yScale)}
            fill={driver.impact >= 0 ? Colors.blue6 : Colors.red5}
            maxWidth={columnWidth}
          />
        );
      })}
    </g>
  );
}

AverageScoreColumn.propTypes = {
  drivers: PropTypes.arrayOf(
    PropTypes.shape({
      averageScore: PropTypes.number.isRequired,
      impact: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  columnStart: PropTypes.number.isRequired,
  columnWidth: PropTypes.number.isRequired,
  yScale: PropTypes.func.isRequired
};

function AverageScoreBarColumn({ drivers, columnStart, columnWidth, yScale }) {
  const height = yScale.bandwidth();
  const baseline = drivers.length > 0 ? drivers[0].baseline : undefined;

  const domainWithoutExtraSpace = [
    min([baseline, min(drivers, driver => driver.averageScore)]),
    max([baseline, max(drivers, driver => driver.averageScore)])
  ];

  const domainWidth = domainWithoutExtraSpace[1] - domainWithoutExtraSpace[0];

  const xScale = scaleLinear()
    .domain([
      domainWithoutExtraSpace[0] - 0.1 * domainWidth,
      domainWithoutExtraSpace[1]
    ])
    .range([0, columnWidth]);

  const baselineX = columnStart + xScale(baseline);

  const overallAverageScoreLabel = `Overall average score: ${baseline.toFixed(
    2
  )}`;
  const baselineLabelWidth = getTextWidth(
    overallAverageScoreLabel,
    '0.875rem',
    'bold'
  );

  const labelEnd = baselineX + baselineLabelWidth / 2;
  let labelTranslation = '';
  if (labelEnd > columnStart + columnWidth) {
    labelTranslation = `translate(-${Math.min(
      labelEnd - columnStart - columnWidth,
      baselineLabelWidth / 2
    )}, 0)`;
  }

  return (
    <g>
      {drivers.map((driver, index) => {
        return (
          <rect
            key={driver.hash()}
            x={columnStart}
            y={yScale(index)}
            width={xScale(driver.averageScore)}
            height={height}
            fill={driver.impact >= 0 ? Colors.blue2 : Colors.red2}
          />
        );
      })}
      <GridLineXAxis
        x1={columnStart}
        y1={centerTextY(-1, yScale)}
        x2={columnStart + columnWidth}
        y2={yScale.range()[1] - yScale.bandwidth() / 2}
        domain={domainWithoutExtraSpace}
        yScale={yScale}
      />
      <line
        x1={baselineX}
        x2={baselineX}
        y1={yScale.bandwidth() + 5 - yScale.step() * yScale.padding()}
        y2={yScale.range()[1] - yScale.bandwidth() / 2}
        css={css`
          stroke: ${Colors.gray9};
          stroke-opacity: 0.6;
          stroke-width: 2px;
          stroke-dasharray: 4 6;
          stroke-linecap: round;
        `}
      />
      <Label
        name={overallAverageScoreLabel}
        x={baselineX}
        y={0}
        dominantBaseline="hanging"
        textAnchor="middle"
        fontWeight="bold"
        transform={`${labelTranslation}`}
      />
    </g>
  );
}

AverageScoreBarColumn.propTypes = {
  drivers: PropTypes.arrayOf(PropTypes.instanceOf(Concept).isRequired)
    .isRequired,
  columnStart: PropTypes.number.isRequired,
  columnWidth: PropTypes.number.isRequired,
  yScale: PropTypes.func.isRequired
};

function DriversColumn({ drivers, maxWidth, yScale }) {
  return (
    <g>
      {drivers.map((concept, index) => {
        return (
          <Label
            key={concept.hash()}
            name={concept.name}
            x={0}
            y={centerTextY(index, yScale)}
            fontWeight="bold"
            maxWidth={maxWidth}
          />
        );
      })}
    </g>
  );
}

DriversColumn.propTypes = {
  drivers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  maxWidth: PropTypes.number.isRequired,
  yScale: PropTypes.func.isRequired
};
