import _ from 'lodash';
import { css } from '@emotion/core';
import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Colors } from '../../styles';
import { View } from './view';
import { TabLink } from '../TabTitles';
import { StoreContext } from '../../StoreContext';
import { useActiveListName } from '../active_concepts/activeListName';
import { useActiveConceptListHasUnsharedChanges } from './UnsharedChangesAlert';
import { naturalSortByName } from '../../utils/NaturalSort';
import { decodeFilter } from '../../search_params';
import { Icon, IconTypes } from '../../components/icons';

const DiffContext = createContext({
  view: null,
  comparisonView: null,
  showOperationIcon: null
});

export function ViewDetails({
  view,
  comparisonView = view,
  showOperationIcon = true,
  showUnsavedStyling = false
}) {
  const feature = view.view.feature;

  return (
    <DiffContext.Provider value={{ view, comparisonView, showOperationIcon }}>
      {view.dependsOnConceptList && (
        <ConceptListDiff showUnsavedStyling={showUnsavedStyling} />
      )}
      <SearchDiff />
      <FieldDiff field="concepts" />
      {feature === 'drivers' && <FieldDiff field="field" />}
      <FilterDiff />
      <DiffSeparator />
      <FeatureDiff />
    </DiffContext.Provider>
  );
}

ViewDetails.propTypes = {
  view: PropTypes.instanceOf(View).isRequired,
  comparisonView: PropTypes.instanceOf(View)
};

function useViewName(view) {
  const { sharedConceptLists } = useContext(StoreContext);
  const conceptList = _.find(sharedConceptLists, {
    concept_list_id: view?.conceptListId
  });
  return conceptList?.name;
}

function ConceptListDiff({ showUnsavedStyling }) {
  const { comparisonView, view } = useContext(DiffContext);
  const { nameAsHTML } = useActiveListName();
  const hasUnsharedChanges = useActiveConceptListHasUnsharedChanges();
  const oldName = useViewName(comparisonView);
  const newName = useViewName(view);

  // If oldName and newName are both undefined, that's a sign that the
  // comparison view is null AND the view doesn't have a concept list ID,
  // meaning there are unshared changes.
  const renderHTML =
    (hasUnsharedChanges && showUnsavedStyling) || !(oldName || newName);

  return (
    <DiffLine
      field="concept_list_id"
      // When there are unsaved changes, we don't actually want the concept
      // list name to be rendered as a diff, just styled to indicate the
      // unsaved changes.
      newValue={renderHTML ? nameAsHTML : newName}
      oldValue={renderHTML ? nameAsHTML : oldName}
      feature={view.feature}
    />
  );
}

function FieldDiff({ field }) {
  const { comparisonView, view } = useContext(DiffContext);
  const oldValue = comparisonView.getNameForField(field);
  const newValue = view.getNameForField(field);

  return (
    <DiffLine
      field={field}
      newValue={newValue}
      oldValue={oldValue}
      feature={view.feature}
    />
  );
}

export function DiffLine({ field, newValue, oldValue, feature = null }) {
  const { showOperationIcon } = useContext(DiffContext);
  if (!oldValue && !newValue) {
    return null;
  }
  const operation = getOperation(oldValue, newValue);
  return (
    <ViewDetailRow>
      {showOperationIcon && <OperationIcon operation={operation} />}
      <FieldIcon field={field} feature={feature} />
      <span css={getOperationStyles(operation)}>{newValue ?? oldValue}</span>
    </ViewDetailRow>
  );
}
export const ViewDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.25rem 0;
  &:last-child {
    margin-bottom: 0;
  }

  /* Aligns icons with the center of the first line of text */
  align-items: flex-start;
  > * {
    line-height: 1.2;
  }

  /* Give icons a fixed width and center them */
  > *:not(:last-child) {
    flex: 0 0 1rem;
    display: flex;
    justify-content: center;
    margin-right: 0.5rem;
  }

  /* Make the text take up the rest of the space */
  > *:last-child {
    flex-grow: 1;
  }
`;

function getOperation(oldValue, newValue) {
  if (!oldValue) {
    return 'add';
  } else if (!newValue) {
    return 'remove';
  } else if (oldValue === newValue) {
    return 'no_change';
  } else {
    return 'modify';
  }
}

function FeatureDiff() {
  const { view } = useContext(DiffContext);
  const feature = view.view.feature;

  return (
    <>
      <FieldDiff field="feature" />
      {feature !== 'galaxy' && <FieldDiff field="sortby" />}
      {feature === 'volume' && <VolumeDiff />}
      {feature === 'galaxy' && <GalaxyDiff />}
      {feature === 'drivers' && <DriversDiff />}
    </>
  );
}

function VolumeDiff() {
  const { view } = useContext(DiffContext);
  return (
    <>
      <FieldDiff field="match_type" />
      <FieldDiff field="breakdown" />
      {view.view.breakdown && <FieldDiff field="normalized" />}
    </>
  );
}

function DriversDiff() {
  const { view } = useContext(DiffContext);

  return (
    <>
      <FieldDiff field="tab" />
      <FieldDiff field="comparison_field" />
      {view.view.comparison_field && <FieldDiff field="display_same" />}
      {view.conceptType === 'drivers' && <FieldDiff field="sync_fields" />}
    </>
  );
}

function GalaxyDiff() {
  const { view } = useContext(DiffContext);
  return (
    <>
      <FieldDiff field="x_axis" />
      <FieldDiff field="y_axis" />
      {(view.view.x_axis || view.view.y_axis) && (
        <FieldDiff field="show_axes" />
      )}
    </>
  );
}

function SearchDiff() {
  const { view } = useContext(DiffContext);

  return (
    <>
      <FieldDiff field="search" />
      {view.view.search && <FieldDiff field="doc_match_type" />}
    </>
  );
}

function FilterDiff() {
  const { metadata } = useContext(StoreContext);
  const { comparisonView, view } = useContext(DiffContext);
  const oldConstraints = _.keyBy(
    decodeFilter(comparisonView.view.filter),
    'name'
  );
  const newConstraints = _.keyBy(decodeFilter(view.view.filter), 'name');

  return [...metadata].sort(naturalSortByName).map(field => {
    const oldValue = oldConstraints[field.name]?.toString();
    const newValue = newConstraints[field.name]?.toString();

    return (
      <DiffLine
        key={field.name}
        field="filter"
        oldValue={oldValue}
        newValue={newValue}
      />
    );
  });
}

function FieldIcon({ field, feature }) {
  switch (field) {
    case 'concept_list_id':
      return <TabLink tab="activeConcepts" withLabel={false} />;
    case 'feature':
      return <FeatureIcon feature={feature} />;
    case 'filter':
      return <TabLink tab="filter" withLabel={false} />;
    case 'search':
      return <TabLink tab="search" withLabel={false} />;
    case 'doc_match_type':
      return <TabLink tab="documents" withLabel={false} />;
    case 'sortby':
      return <SortByIcon />;
    default:
      return <TabLink tab="configure" withLabel={false} />;
  }
}

function SortByIcon() {
  const { comparisonView, view } = useContext(DiffContext);
  const sortOrder = view.sortDirection ?? comparisonView.sortDirection;
  const direction = sortOrder === 'asc' ? 'up' : 'down';
  return <Icon type={IconTypes.SORT} direction={direction} thickness="light" />;
}

function FeatureIcon({ feature }) {
  const iconType = IconTypes[feature.toUpperCase()];
  return <Icon type={iconType} size="1rem" />;
}

function OperationIcon({ operation }) {
  switch (operation) {
    case 'add':
      return <Icon type={IconTypes.ADD} theme="secondary" />;
    case 'remove':
      return <Icon type={IconTypes.MINUS} theme="tertiary" />;
    case 'modify':
      return <Icon type={IconTypes.EXCHANGE} theme="tertiary" />;
    case 'no_change':
      // This is a blank icon for grid alignment purposes
      return (
        <svg
          css={css`
            width: 1rem;
            height: 1rem;
          `}
        />
      );
  }
}

function getOperationStyles(operation) {
  switch (operation) {
    case 'remove':
      return css`
        color: ${Colors.red5};
        text-decoration: line-through;
        font-style: italic;
      `;
    case 'add':
      return css`
        color: ${Colors.green7};
        font-weight: bold;
        font-style: italic;
      `;
    case 'modify':
      return css`
        color: ${Colors.red5};
        font-style: italic;
      `;
    default:
      return null;
  }
}

function DiffSeparator() {
  return (
    <div
      css={css`
        height: 0.5rem;
      `}
    />
  );
}
