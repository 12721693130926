import { css } from '@emotion/core';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';

import { Colors } from '../../styles';
import { NameEditor } from './NameEditor';
import { ColorEditor } from './ColorEditor';
import { AddConceptInput } from './AddConceptInput';
import { ConstituentList } from './ConstituentList';
import { Concept } from '../../classes/Concepts';
import { StoreContext } from '../../StoreContext';
import {
  SearchDetailsSection,
  TotalMatchesSection
} from '../../side_panel/search_details/ACSDetails';
import { ConceptualMatchesTable } from './ConceptualMatchesTable';
import SimpleModal from '../../components/core/SimpleModal';
import { useFilter } from '../../search_params';

export function TagEditor({ closeModal }) {
  const { projectId } = useParams();
  const filter = useFilter();
  const {
    selection,
    activeConcepts,
    filterCount,
    filterIsLoading,
    searchInProgress,
    conceptSearchResults
  } = useContext(StoreContext);
  const isLoading = filterIsLoading || searchInProgress;
  const activeConcept = activeConcepts.find(ac =>
    Concept.areTextsEqual(ac, selection)
  );

  return (
    <SimpleModal
      isOpen
      onHide={closeModal}
      header={<Header>Tag editor</Header>}
      containerCss={css`
        max-width: 90vw;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 1rem;
        `}
      >
        <div
          css={css`
            padding-top: 0.5rem;
            width: 20rem;
            max-height: 90vh;
            display: grid;
            grid-auto-rows: fit-content(100%);
          `}
        >
          <BoxedTotalMatchesSection
            activeSearch={Boolean(selection)}
            totalCount={
              conceptSearchResults?.exact.total_count +
              conceptSearchResults?.conceptual.total_count
            }
            documentCount={filterCount}
            isLoading={isLoading}
          />
          <BorderlessSearchDetailsSection
            details={conceptSearchResults?.exact}
            documentCount={filterCount}
            header="Exact matches"
            termCountWhileLoading={selection.included.length}
            noLink={true}
          />
          <ConceptualMatchesTable />
          {selection.excluded.length > 0 && (
            <BorderlessSearchDetailsSection
              details={conceptSearchResults?.excluded}
              documentCount={filterCount}
              header="Excluded matches"
              termCountWhileLoading={selection.excluded.length}
              noLink={true}
            />
          )}
        </div>
        <div
          css={css`
            padding: 0.5rem;
            display: block;
            width: 15rem;
          `}
        >
          <NameEditor
            projectId={projectId}
            concept={activeConcept}
            filter={filter}
          />
          <AddConceptInput
            projectId={projectId}
            concept={activeConcept}
            filter={filter}
          />
          <ConstituentList
            projectId={projectId}
            concept={activeConcept}
            filter={filter}
          />
          <ColorEditor concept={activeConcept} />
        </div>
      </div>
    </SimpleModal>
  );
}

TagEditor.propTypes = {
  closeModal: PropTypes.func.isRequired
};

const BorderlessSearchDetailsSection = styled(SearchDetailsSection)`
  border: 0 !important;
  padding: 0;
  margin: 0.5rem 0;
  max-height: 20vh;
`;

const BoxedTotalMatchesSection = styled(TotalMatchesSection)`
  border: 1px solid ${Colors.gray2};
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 0.875rem;
`;
