import React from 'react';
import PropTypes from 'prop-types';

import { Colors } from '../styles';

// We scale positions by this number so that there's room for text
// at the ends of bars.
const BAR_SCALE_DOWN_FACTOR = 1.4;

const SharesChart = ({ type, sentimentShare }) => {
  return (
    <svg className="share-chart" style={{ height: '2rem', width: '100%' }}>
      <Bar type={type} sentimentShare={sentimentShare} />
      <Percentage type={type} sentimentShare={sentimentShare} />
    </svg>
  );
};

SharesChart.propTypes = {
  type: PropTypes.oneOf(['negative', 'positive']).isRequired,
  sentimentShare: PropTypes.number.isRequired
};

export default SharesChart;

const Bar = ({ type, sentimentShare }) => {
  const width = (sentimentShare * 100) / BAR_SCALE_DOWN_FACTOR;

  let x, fill;
  if (type === 'negative') {
    x = `${100 - width}%`;
    fill = Colors.red2;
  } else {
    x = `${0}%`;
    fill = Colors.blue2;
  }

  return <rect width={`${width}%`} height="70%" y="20%" x={x} fill={fill} />;
};

Bar.propTypes = {
  type: PropTypes.oneOf(['negative', 'positive']).isRequired,
  sentimentShare: PropTypes.number.isRequired
};

const Percentage = ({ type, sentimentShare }) => {
  const position = (sentimentShare * 100) / BAR_SCALE_DOWN_FACTOR;
  const padding = 4;

  let x, textAnchor, fill;
  if (type === 'negative') {
    x = `${100 - position - padding}%`;
    textAnchor = 'end';
    fill = Colors.red5;
  } else {
    x = `${position + padding}%`;
    textAnchor = 'start';
    fill = Colors.blue6;
  }

  return (
    <text
      y="70%"
      style={{
        fontFamily: 'Lato',
        fontSize: '0.875rem'
      }}
      x={x}
      fill={fill}
      textAnchor={textAnchor}
    >
      {Math.round(sentimentShare * 100)}%
    </text>
  );
};

Percentage.propTypes = {
  type: PropTypes.oneOf(['negative', 'positive']).isRequired,
  sentimentShare: PropTypes.number.isRequired
};
