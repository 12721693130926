import React from 'react';
import styled from '@emotion/styled';

import { trademark } from '../utils/trademark';

export const Trademark = () => <FooterText>{trademark}</FooterText>;

const FooterText = styled.div`
  margin-top: auto;
  align-self: center;
  /* Leave at least 2.5 rem of space between the trademark and the footer */
  padding-top: 2.5rem;
`;
