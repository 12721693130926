import React, { useContext, useState } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { Colors, Mixins } from '../styles';
import Progress from '../components/core/Progress';
import { StoreContext } from '../StoreContext';
import { percentify } from '../utils/NumFmtUtils';
import { buildDashboardProject } from '../utils/ApiUtilsV5';
import { useSystemStatus } from '../systemStatus';
import { Icon, IconTypes } from '../components/icons';
import { Button } from '../components/core/Button';
import { DASHBOARD_STATUS } from './dashboardStatus';

export function DashboardStatusMessage({ status, ...props }) {
  if (status === DASHBOARD_STATUS.BUILDING) {
    return <PendingBuildVisualizationDashboard {...props} />;
  }
  if (status === DASHBOARD_STATUS.READY_TO_BUILD) {
    return <BuiltWithoutDashboardData {...props} />;
  }
}

DashboardStatusMessage.propTypes = {
  status: PropTypes.oneOf(Object.values(DASHBOARD_STATUS)).isRequired
};

function PendingBuildVisualizationDashboard(props) {
  const progress = 0;
  const barCss = css`
    width: 100%;
    max-width: 30rem;
    margin-bottom: 1rem;
  `;

  return (
    <MessageWrapper {...props}>
      <Progress
        css={barCss}
        value={progress}
        max={1}
        title={`${percentify(progress, 1)} complete`}
      >
        {percentify(progress, 1, 0)}
      </Progress>
      <div
        css={css`
          text-align: center;
          font-weight: bold;
        `}
      >
        <p>
          Our science is hard at work examining the dashboard in your project.
        </p>
        <p>Please check back later!</p>
      </div>
    </MessageWrapper>
  );
}

function MessageWrapper(props) {
  return (
    <div
      css={css`
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
      {...props}
    />
  );
}

function BuiltWithoutDashboardData(props) {
  const systemStatus = useSystemStatus();
  if (systemStatus == null) {
    return null;
  }
  return (
    <MessageWrapper {...props}>
      <div
        css={css`
          text-align: center;
          font-weight: bold;
        `}
      >
        <DashboardUpgradeAvailable />
      </div>
    </MessageWrapper>
  );
}

const DashboardUpgradeAvailable = () => {
  const { project } = useContext(StoreContext);
  const [isBuilding, setIsBuilding] = useState(false);

  const handleBuildDashboard = async projectId => {
    try {
      await buildDashboardProject(projectId);
      setIsBuilding(true);
    } catch (error) {
      throw new Error('Failed to build sentiment');
    }
  };

  return (
    <UndoParentCss>
      {!isBuilding ? (
        <Column>
          <div
            css={css`
              display: flex;
              border: 1px solid ${Colors.gray0};
              padding: 0.75rem 0.5rem 1rem;
            `}
          >
            <Icon
              type={IconTypes.CIRCULAR_EXCLAMATION}
              theme="primary"
              size="24"
            />
            <div>
              Data needs to be prepared before the <i>dashboard</i> can be used.
              Click the <i>Build Dashboard</i> button below to prepare the data
            </div>
          </div>
          <Button
            palette="green"
            onClick={() => handleBuildDashboard(project.project_id)}
            css={css`
              font-size: 1rem;
            `}
          >
            <Icon type={IconTypes.CLOCK} size="18" />
            Build Dashboard
          </Button>
        </Column>
      ) : (
        <PendingBuildVisualizationDashboard />
      )}
    </UndoParentCss>
  );
};

// The above component is in a different style from the other sentiment status
// messages so undoing the css of the parent div is necessary. The wrapper can
// be removed for the most part when the rest of the messages are standardized.
const UndoParentCss = styled.div`
  text-align: left;
  font-weight: normal;
  font-size: 1rem;
  margin-top: -35%; // hack to undo flex centering

  // Center the column
  display: flex;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
  padding: 0.5rem;
  ${Mixins.shadowOutset}
  max-width: 60ch;
`;
