import keymirror from 'keymirror';

export const DASHBOARD_STATUS = keymirror({
  READY: null,
  BUILDING: null,
  READY_TO_BUILD: null
});

export function getDashboardStatus(project) {
  if (project?.tab_data_get_status === 'in_progress') {
    return DASHBOARD_STATUS.BUILDING;
  }
  if (project?.tab_data_get_status === 'success') {
    return DASHBOARD_STATUS.READY;
  }
  if (project?.tab_data_get_status === undefined) {
    return DASHBOARD_STATUS.READY_TO_BUILD;
  }
}
