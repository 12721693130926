import React from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3-scale';
import css from '@emotion/css';

import { Colors } from '../styles';
import { thousandify } from '../utils/NumFmtUtils';
import getTextWidth from '../utils/getTextWidth';

export function GridLineXAxis({
  domain,
  x1,
  y1,
  x2,
  y2,
  yScale,
  lineMinY = y1 + yScale.bandwidth() - yScale.step() * yScale.padding()
}) {
  const numberOfTicks = 3;

  const scale = scaleLinear().domain(domain).range([x1, x2]);
  const ticks = scale.ticks(numberOfTicks);

  let lastTickEndpoint = x1;
  return (
    <g>
      {ticks.map(tick => {
        const label = thousandify(tick);
        const lineX = scale(tick);
        const labelX = lineX + 2; // Space between label and axis line
        const availableSpace = x2 - labelX;

        const textWidth = getTextWidth(label, '0.875rem');
        const displayTick =
          labelX >= lastTickEndpoint && availableSpace >= textWidth;

        if (displayTick) {
          lastTickEndpoint = labelX + textWidth;
        }

        return (
          displayTick && (
            <g key={tick}>
              <text
                x={labelX}
                y={y1}
                fill={Colors.gray5}
                dominantBaseline="hanging"
                textAnchor="middle"
                fontSize="0.875rem"
              >
                {label}
              </text>

              <line
                x1={lineX}
                x2={lineX}
                y1={lineMinY}
                y2={y2}
                shapeRendering="crispEdges"
                css={css`
                  stroke: ${Colors.gray9};
                  stroke-opacity: 0.15;
                  stroke-width: 1px;
                  opacity: 0.7;
                `}
              />
            </g>
          )
        );
      })}
    </g>
  );
}

GridLineXAxis.propTypes = {
  domain: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  x1: PropTypes.number.isRequired,
  y1: PropTypes.number.isRequired,
  x2: PropTypes.number.isRequired,
  y2: PropTypes.number.isRequired
};
