import React, { memo, useContext, useMemo, useState } from 'react';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';

import { SidePanel } from '../SidePanel';
import { HoverProvider } from '../HoverContext';
import { SearchBar } from '../search_bar/SearchBar';
import { StoreContext } from '../StoreContext';
import { Concept } from '../classes/Concepts';
import { usePrevious } from '../utils/hooks';
import { trademark } from '../utils/trademark';

const Workspace = memo(({ settings, downloadButton, workArea }) => {
  const { selection } = useContext(StoreContext);
  const [currentSearchText, setCurrentSearchText] = useState(
    Concept.toString(selection)
  );
  const prevSelection = usePrevious(selection);
  if (
    selection?.isActive &&
    prevSelection?.isActive &&
    selection.hash() === prevSelection.hash() &&
    currentSearchText === Concept.toString(prevSelection) &&
    currentSearchText !== Concept.toString(selection)
  ) {
    setCurrentSearchText(Concept.toString(selection));
  }
  const searchMatchesSelection = useMemo(() => {
    try {
      return Concept.areTextsEqual(
        Concept.fromString(currentSearchText),
        selection
      );
    } catch (e) {
      return false;
    }
  }, [currentSearchText, selection]);

  return (
    <HoverProvider>
      <div className="workspace">
        <div className="workspace__sidebar">
          <SidePanel
            settings={settings}
            downloadButton={downloadButton}
            showOverlay={!searchMatchesSelection}
          />
        </div>
        <div className="workspace__work-area">
          <SearchBar
            onInputTextChange={setCurrentSearchText}
            searchMatchesSelection={searchMatchesSelection}
          />
          <div
            css={css`
              position: relative;
              min-height: 0;
              flex: 1;
              display: flex;
              flex-direction: column;
            `}
          >
            {workArea}
          </div>
          <div
            css={css`
              /* Fixed height is to accomodate the Userflow button. */
              height: 64px;
              justify-content: center;
              text-align: center;
              display: flex;
              flex-direction: column;
            `}
          >
            {trademark}
          </div>
        </div>
      </div>
    </HoverProvider>
  );
});

Workspace.propTypes = {
  settings: PropTypes.node,
  downloadButton: PropTypes.node,
  workArea: PropTypes.node.isRequired
};

export default Workspace;
