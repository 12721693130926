import React from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import _ from 'lodash';

import List from './core/List';

export function getVisibleRowIndices(
  container,
  firstChild,
  childCount,
  overscanRowCount
) {
  const elementsAboveTable = Math.floor(
    (container.top - firstChild.top) / firstChild.height
  );
  const elementsInTable = Math.ceil(container.height / firstChild.height);

  const minRow = Math.max(elementsAboveTable - overscanRowCount, 0);
  const maxRow = Math.min(
    elementsAboveTable + elementsInTable + overscanRowCount,
    childCount
  );

  return { minRow, maxRow };
}

export default class VirtualList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { minRow: 0, maxRow: 0 };
    this.containerRef = React.createRef();
    this.recalculateVisibleRows = _.debounce(
      this.recalculateVisibleRows.bind(this),
      props.debounceWaitTime
    );
    this.isRowInView = this.isRowInView.bind(this);
  }

  componentDidMount() {
    this.recalculateVisibleRows();
  }

  componentWillUnmount() {
    this.recalculateVisibleRows.cancel();
  }

  recalculateVisibleRows() {
    const container = findDOMNode(this.containerRef.current);
    const table = container.getBoundingClientRect();
    const { overscanRowCount } = this.props;
    const children = container.children;

    if (children.length > 0) {
      const firstChild = children[0].getBoundingClientRect(),
        childCount = children.length;

      this.setState(
        getVisibleRowIndices(table, firstChild, childCount, overscanRowCount)
      );
    }
  }

  isRowInView(index) {
    return this.state.minRow <= index && index <= this.state.maxRow;
  }

  render() {
    return (
      <List
        className={this.props.className}
        ref={this.containerRef}
        scrollable={false}
        bordered={false}
        onScroll={this.recalculateVisibleRows}
      >
        {this.props.children(this.isRowInView)}
      </List>
    );
  }
}

VirtualList.propTypes = {
  className: PropTypes.string,
  overscanRowCount: PropTypes.number,
  debounceWaitTime: PropTypes.number,
  children: PropTypes.func.isRequired
};

VirtualList.defaultProps = {
  overscanRowCount: 10,
  debounceWaitTime: 20
};
