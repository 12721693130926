import _ from 'lodash';
import { rgb, lab } from 'd3-color';
import { scaleLinear } from 'd3-scale';

function gradient(stops) {
  const [domain, range] = _.unzip(stops);

  return scaleLinear().domain(domain).range(range).clamp(true);
}

function defaultColorScale(isQl2) {
  const ql1Colors = [
    [0.0, '#aaa'],
    [0.2, '#aaa'],
    [0.2, '#4499bb'],
    [0.7, '#4499bb'],
    [0.7, '#004375'],
    [0.99, '#004375'],
    [0.99, '#ef532d'],
    [1.0, '#ef532d']
  ];

  // Robyn's "depth map", a helix of decreasing lightness where the colors all
  // end up shades of gray or blue or indigo.
  //
  // This is a replacement for the increasing-lightness "heat map", and is
  // similar to the "viridis" color map that's becoming standard in science
  // visualization.
  const ql2Colors = [
    [0.0, '#bbbbbb'],
    [0.2, '#bbbbbb'],
    [0.25, '#bbbbbb'],
    [0.25, '#7db4a7'],
    [0.3, '#68aba3'],
    [0.4, '#24949c'],
    [0.5, '#004a8f'],
    [0.75, '#002e80'],
    [0.99, '#100056'],
    [0.99, '#ef532d'],
    [1.0, '#ef532d']
  ];

  // TODO: HCL
  return gradient(isQl2 ? ql2Colors : ql1Colors);
}

function colorMix(weightedColors) {
  const totalWeight = _.sumBy(weightedColors, ([weight]) => weight);
  let l = 0;
  let a = 0;
  let b = 0;
  for (const [weight, color] of weightedColors) {
    l += (color.l * weight) / totalWeight;
    a += (color.a * weight) / totalWeight;
    b += (color.b * weight) / totalWeight;
  }

  return lab(l, a, b);
}

function makeColorSchemesForConcepts(colorConcepts) {
  // The goals for this function:
  //
  // - Never blend more than two hues. We can't make sense of the result and it
  //   tends to turn everything brown.
  //
  // - There should be no sharp discontinuities in the mapping from similarity
  //   values to colors.
  //
  // - Concepts that aren't that related to any active concept should be
  //   grayish.
  //
  // - The function should give reasonable results no matter whether there are
  //   0, 1, 2, or 28 active concepts.
  //
  // - Blend colors perceptually, in CIELAB space, so that green active concepts
  //   aren't overrepresented.
  const bgColor = lab(rgb('#999999'));

  return function (concept) {
    const colorConceptColors = [];
    for (const colorConcept of colorConcepts) {
      const weight = concept.getClusteryAssociation(colorConcept);
      colorConceptColors.push([weight, lab(colorConcept.color)]);
    }

    colorConceptColors.sort();
    colorConceptColors.reverse();

    // When multiple active concepts are the same color, keep only the strongest
    // one of each color -- this matches the way that we use active concepts of
    // the same color as 'clusters' where matching any of them puts you in the
    // cluster.
    const weightedColors = [];
    const seenColors = new Set();
    for (const [weight, color] of colorConceptColors) {
      if (!seenColors.has(rgb(color).toString())) {
        weightedColors.push([weight, color]);
        seenColors.add(rgb(color).toString());
      }
    }

    // Add gray colors so that we always have three colors to compare.
    weightedColors.push([0.5, bgColor]);
    weightedColors.push([0.25, bgColor]);
    weightedColors.push([0.1, bgColor]);
    weightedColors.sort();
    weightedColors.reverse();

    const weights = weightedColors.map(wc => wc[0]);

    // weightMax is easy to define -- it's the score of the best matching active
    // concept, weights[0].
    //
    // secondaryWeight is how strongly the secondary active concept color should
    // be blended in, and this is where the continuity comes from:
    //
    // - It's equal to weightMax when weights[0] == weights[1]
    // - It's equal to 0 when weights[1] == weights[2]
    // - It interpolates between those when weights[0] > weights[1] > weights[2]
    // - In the degenerate case where weights[0] == weights[1] == weights[2],
    //   it equals weightMax, not 0
    const weightMax = weights[0];
    let secondaryWeight;
    if (weights[0] === weights[2]) {
      secondaryWeight = weightMax;
    } else {
      secondaryWeight =
        ((weights[1] - weights[2]) / (weights[0] - weights[2])) * weightMax;
    }

    const mainColor = weightedColors[0][1];
    const secondaryColor = weightedColors[1][1];

    // Mix together the main color, the secondary color that probably has lower weight,
    // and an amount of gray that has a higher weight when the best match is poor.
    const color = colorMix([
      [weightMax, mainColor],
      [secondaryWeight, secondaryColor],
      [1 - weightMax, bgColor]
    ]);
    return rgb(color).toString();
  };
}

export function naturalColorScheme(colorConcepts) {
  // Color by the object's natural color in the space, rather than by
  // comparison. This is useful when there is no selection.

  // For concepts not in the set of colorConcepts, their natural coloring in the
  // space is a combination of colors of concepts in colorConcepts if
  // colorConcepts is non-empty, or a constant color otherwise.
  const scheme =
    colorConcepts.length > 0
      ? makeColorSchemesForConcepts(colorConcepts)
      : () => '#666';

  // For concepts in colorConcepts, use the concept's color.
  const colorConceptMap = _.fromPairs(
    colorConcepts.map(c => [c.toString(), c])
  );
  return concept =>
    colorConceptMap[concept.toString()]?.color ?? scheme(concept);
}

export function comparisonColorScheme(selection) {
  const isQl2 = selection.vectors[0].length > 150;
  const colorScale = defaultColorScale(isQl2);
  return concept => colorScale(selection.getAssociation(concept));
}
