import React from 'react';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';

import { Colors } from '../styles';
import Dropdown from '../components/core/Dropdown';

export function PlaintextWhenDisabledDropdown({
  editable,
  onChange,
  options,
  promptOption,
  ...props
}) {
  const optionValues = options.map(o => o.value);
  const hasSelection = optionValues.includes(props.value);
  return (
    <Dropdown
      {...props}
      required
      onChange={evt => onChange(evt.target.value)}
      disabled={!editable}
      borderless={!editable}
      css={css([
        !editable &&
          css`
            color: ${Colors.gray9};
            opacity: 1;
            background: unset;
          `
      ])}
      containerCss={css([
        !editable &&
          css`
            i {
              display: none;
            }
          `
      ])}
    >
      {!hasSelection && (
        <option disabled value="">
          {promptOption}
        </option>
      )}
      {options.map(o => (
        <option key={o.value} value={o.value}>
          {o.name}
        </option>
      ))}
    </Dropdown>
  );
}

PlaintextWhenDisabledDropdown.propTypes = {
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  promptOption: PropTypes.string.isRequired
};
