import React from 'react';
import { useParams } from 'react-router-dom';

import { RequestStatuses } from '../constants';
import { Concept } from '../classes/Concepts';
import { StoreContext } from '../StoreContext';
import { getDrivers } from '../utils/ApiUtilsV5';
import { useFetch, useRefetchWhenActiveConceptsChange } from '../utils/hooks';
import { useCurrentView } from '../side_panel/views/view';
import { useSearchParams } from '../search_params';

export const DriverContext = React.createContext({
  drivers: undefined,
  status: RequestStatuses.PENDING,
  selectedDriver: undefined,
  selectionIsNotDriver: false
});

export function useDriverContext(filter, displaySame = false) {
  const { drivers: specifiedDrivers } = React.useContext(DriverContext);
  const { drivers, status } = useDrivers(filter, displaySame, specifiedDrivers);

  const { selectedDriver, selectionIsNotDriver } = useSelectedDriver(
    filter,
    drivers
  );

  return {
    drivers,
    status,
    selectedDriver,
    selectionIsNotDriver
  };
}

export function useDrivers(
  filter,
  displaySame = false,
  specifiedDrivers = undefined
) {
  const { projectId } = useParams();
  const { searchParams } = useSearchParams();
  let { conceptType, conceptSelector } = useCurrentView();
  const shouldFetch =
    !(displaySame && !specifiedDrivers) &&
    filter &&
    searchParams.field &&
    !filter.find(constraint => constraint.name === searchParams.field);

  if (conceptType !== 'active' && displaySame) {
    conceptSelector = {
      type: 'specified',
      concepts: specifiedDrivers?.map(driver => driver.toJSON())
    };
  } else if (conceptType === 'drivers') {
    // If the axis field is the same as the field we want the drivers for, then
    // we omit the concept selector since this is the default behavior of the
    // drivers endpoint (and empirically runs faster).
    // TODO: Remove this block if this change happens on the backend instead
    if (searchParams.field === searchParams.drivers_of) {
      conceptSelector = undefined;
    }
  }
  const matchType = searchParams.match_type;

  const fetchHookResult = useFetch(
    shouldFetch && getDrivers,
    projectId,
    filter,
    searchParams.field,
    conceptSelector,
    matchType
  );

  const status = !searchParams.field
    ? RequestStatuses.FULFILLED
    : fetchHookResult.status;
  const refetch = fetchHookResult.refetch;
  const drivers = fetchHookResult.response;
  useRefetchWhenActiveConceptsChange(
    searchParams.concepts === 'active' && refetch
  );

  return {
    drivers:
      searchParams.concepts === 'drivers'
        ? drivers?.slice(0, parseInt(searchParams.drivers_count))
        : drivers,
    status
  };
}

function fetchSelectedDriver(projectId, fieldName, text, filter, matchType) {
  const formatted = Concept.fromString(text).toJSON();

  return getDrivers(
    projectId,
    filter,
    fieldName,
    {
      type: 'specified',
      concepts: [formatted]
    },
    matchType
  ).then(([driver]) => driver);
}

export function useSelectedDriver(filter, drivers) {
  const { projectId } = useParams();
  const { searchParams } = useSearchParams();
  const { selection } = React.useContext(StoreContext);

  const selectedDriverInData =
    drivers && drivers.find(driver => Concept.areTextsEqual(selection, driver));

  const { response, status } = useFetch(
    searchParams.field &&
      filter &&
      selection &&
      !selectedDriverInData &&
      fetchSelectedDriver,
    projectId,
    searchParams.field,
    // Pass texts as string to prevent overfetching when selecting new concepts
    Concept.toString(selection),
    filter
  );

  return {
    selectedDriver: selection ? selectedDriverInData || response : null,
    selectionIsNotDriver:
      filter && selection && drivers
        ? status !== RequestStatuses.PENDING &&
          !(selectedDriverInData || response)
        : false
  };
}
