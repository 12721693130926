import React, { Fragment, useContext, useState } from 'react';
import css from '@emotion/css';
import PropTypes from 'prop-types';
import { TabList, TabPanels } from '@reach/tabs';

import { ActiveConceptsPanel } from './side_panel/ActiveConceptsPanel';
import { Tab, Tabs } from './side_panel/Tabs';
import { SearchDetailsPanel } from './side_panel/SearchDetailsPanel';
import { ExportPanel } from './side_panel/ExportPanel';
import { DocumentsPanel } from './side_panel/DocumentsPanel';
import { FilterPanel } from './side_panel/FilterPanel';
import { ConfigureVisualizationPanel } from './side_panel/ConfigureVisualization';
import { labelForTab, SidePanelTabIcon } from './side_panel/TabTitles';
import { StoreContext } from './StoreContext';
import { selectSidePanelTab } from './actions';
import CurrentViewBox from './side_panel/views/CurrentViewBox';

export function SidePanel({ settings, downloadButton, showOverlay }) {
  const { selectedSidePanelTab } = useContext(StoreContext);

  // Update "filterKey" whenever a view is loaded in order to reset the filter
  // panel's expanded/collapsed state
  const [filterKey, setFilterKey] = useState(0);
  const onLoadView = () => {
    setFilterKey(filterKey => filterKey + 1);
  };

  const tabs = [
    {
      key: 'configure',
      panel: <ConfigureVisualizationPanel body={settings} />
    },
    {
      key: 'activeConcepts',
      panel: <ActiveConceptsPanel />
    },
    {
      key: 'filter',
      panel: <FilterPanel key={filterKey} />
    },
    {
      key: 'search',
      panel: <SearchDetailsPanel showOverlay={showOverlay} />
    },
    {
      key: 'documents',
      panel: <DocumentsPanel showOverlay={showOverlay} />
    },
    {
      key: 'export',
      panel: <ExportPanel featureSpecificDownloadButton={downloadButton} />
    }
  ];
  const tabKeys = tabs.map(tab => tab.key);
  const selectedTabIndex = tabKeys.indexOf(selectedSidePanelTab);

  function selectTabByIndex(index) {
    selectSidePanelTab(tabKeys[index]);
  }

  return (
    <>
      <CurrentViewBox onLoadView={onLoadView} />
      <Tabs
        index={selectedTabIndex}
        onChange={selectTabByIndex}
        orientation="horizontal"
      >
        <TabList
          css={css`
            background: none;
            margin-left: 0.5rem;
          `}
          data-test-id="sidebar-tab-list"
        >
          {tabs.map(tab => (
            <Tab label={labelForTab(tab.key)} key={tab.key}>
              <SidePanelTabIcon tab={tab.key} />
            </Tab>
          ))}
        </TabList>
        <TabPanels
          css={css`
            flex: 1;
            min-height: 0;
          `}
        >
          {tabs.map(tab => (
            <Fragment key={tab.key}>{tab.panel}</Fragment>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
}

SidePanel.propTypes = {
  settings: PropTypes.node.isRequired,
  downloadButton: PropTypes.node,
  showingSuggestions: PropTypes.bool
};
