import React from 'react';
import PropTypes from 'prop-types';

import { ConfirmableButton } from '../components/core/Button';
import { showError, showSuccess } from './notifications';
import { Organization } from './model';
import { ConfirmationMessageContent } from './managementPageStyles';
import { Icon, IconTypes } from '../components/icons';

export default function DeleteUsersButton({ organization, selections }) {
  if (!selections.any) {
    return null;
  }

  function deleteUsers() {
    return organization
      .deleteUsers(selections.selections)
      .then(
        showSuccess(
          `Successfully deleted ${selections.singular ? 'user' : 'users'}.`
        )
      )
      .catch(() => {
        showError(
          'An unexpected error occurred. If the problem persists, please contact support@luminoso.com.'
        );
      })
      .finally(() => {
        selections.clear();
      });
  }

  return (
    <ConfirmableButton
      flavor="subtle"
      onConfirm={deleteUsers}
      confirmationMessage={<ConfirmationMessage single={selections.singular} />}
      confirmText={`Yes, delete ${selections.singular ? 'user' : 'users'}`}
      trackingitem="workspace-users_delete-button"
    >
      <Icon type={IconTypes.TRASH} />
      Delete
    </ConfirmableButton>
  );
}

DeleteUsersButton.propTypes = {
  organization: PropTypes.instanceOf(Organization).isRequired,
  selections: PropTypes.object.isRequired
};

function ConfirmationMessage({ single }) {
  return (
    <ConfirmationMessageContent>
      <p>
        {single
          ? 'Are you sure you want to delete this user?'
          : 'Are you sure you want to delete these users?'}
      </p>
      <p>
        This will completely remove them from Daylight. It will not delete the
        projects they have uploaded. This action cannot be undone.
      </p>
    </ConfirmationMessageContent>
  );
}
