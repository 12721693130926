import React, { useState } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import buildRoutePath from '../utils/buildRoutePath';
import { RoutePatterns } from '../constants';
import SimpleModal from '../components/core/SimpleModal';
import {
  AuthForm,
  CancelButton,
  FIELD_WIDTH,
  SubmitButton,
  TextField
} from './Forms';
import { Organization } from './model';
import { Button } from '../components/core/Button';
import { ButtonBar } from './ButtonBar';
import { Icon, IconTypes } from '../components/icons';

export default function AddWorkspaceButton({ organization, className }) {
  const history = useHistory();

  if (!organization.editable) {
    return null;
  }
  const organizationId = organization.organization_id;
  function openForm() {
    history.push(
      buildRoutePath(RoutePatterns.ORGANIZATION_CREATE_WORKSPACE, {
        organizationId
      })
    );
  }

  function closeForm() {
    history.push(
      buildRoutePath(RoutePatterns.ORGANIZATION_SETTINGS, { organizationId })
    );
  }

  return (
    <>
      <Button onClick={openForm} className={className}>
        <Icon type={IconTypes.ADD} />
        Create a workspace
      </Button>
      <Switch>
        <Route exact path={RoutePatterns.ORGANIZATION_CREATE_WORKSPACE}>
          <AddWorkspaceModal hide={closeForm} organization={organization} />
        </Route>
      </Switch>
    </>
  );
}

AddWorkspaceButton.propTypes = {
  organization: PropTypes.instanceOf(Organization).isRequired
};

function AddWorkspaceModal({ hide, organization }) {
  const [createdWorkspaceId, setCreatedWorkspaceId] = useState(null);
  return (
    <SimpleModal
      isOpen
      onHide={hide}
      css={css`
        width: ${FIELD_WIDTH}rem;
      `}
      header={
        <h3
          css={css`
            padding: 0;
          `}
        >
          {createdWorkspaceId === null ? 'Create a new workspace' : 'Success!'}
        </h3>
      }
    >
      {createdWorkspaceId === null ? (
        <AuthForm
          onSubmit={formData => {
            return organization
              .createWorkspace(formData.name)
              .then(workspaceId => setCreatedWorkspaceId(workspaceId));
          }}
          defaults={{ name: '' }}
        >
          <TextField label="workspace name" field={'name'} />
          <ButtonBar>
            <SubmitButton>
              <Icon type={IconTypes.ADD} />
              Create workspace
            </SubmitButton>
            <CancelButton onClick={hide}>Cancel</CancelButton>
          </ButtonBar>
        </AuthForm>
      ) : (
        <p>
          <Link
            to={buildRoutePath(RoutePatterns.WORKSPACE_SETTINGS, {
              workspaceId: createdWorkspaceId
            })}
          >
            Click here to view your workspace.
          </Link>
        </p>
      )}
    </SimpleModal>
  );
}
