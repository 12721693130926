import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { Concept } from '../classes/Concepts';
import SharesChart from './SharesChart';
import Tooltip from '../components/core/Tooltip';
import SharesChartTooltipContent from './SharesChartTooltipContent';
import sortObjects from '../utils/sortObjects';
import { naturalSortByName } from '../utils/NaturalSort';
import {
  Body,
  Cell,
  ConceptHeader,
  ConceptRow,
  LoadingTableBody,
  ScrollableTable,
  Table,
  TableSortControl,
  useScrollToSelectedConceptRow
} from '../components/core/Table';
import SentimentExamples from './SentimentExamples';

export function PlaceholderTable({ displayPlaceholderText }) {
  return (
    <div className="sentiment-table sentiment-table--list">
      <Table>
        <ConceptHeader
          matchType="exact"
          visualizationHeader={
            <div className="sentiment-table__share-charts">
              <Cell loading>Negative matches</Cell>
              <Cell loading>Positive matches</Cell>
            </div>
          }
          loading
        />
        <LoadingTableBody displayPlaceholderText={displayPlaceholderText} />
      </Table>
    </div>
  );
}

PlaceholderTable.propTypes = {
  displayPlaceholderText: PropTypes.bool
};

export function ConceptsTable({
  onSortChange,
  concepts,
  sortConcepts,
  sortOrder,
  totalCount,
  projectId,
  selection,
  downloadExport
}) {
  const tableRef = useRef();
  const selectedRowRef = useRef();
  useScrollToSelectedConceptRow(tableRef, selectedRowRef);

  const handleSortChange = (sortingKey, direction) => {
    onSortChange(`${sortingKey}-${direction}`);
  };

  const sortedConcepts = getSortedConcepts(concepts, sortConcepts, sortOrder);
  return (
    <div className="sentiment-table sentiment-table--list">
      <ScrollableTable
        ref={tableRef}
        sortedColumn={sortConcepts}
        sortDirection={sortOrder}
        onSortChange={handleSortChange}
        header={
          <ConceptHeader
            matchType="exact"
            visualizationHeader={
              <div className="sentiment-table__share-charts">
                <div>
                  <TableSortControl sortingKey="negative">
                    Negative matches
                  </TableSortControl>
                </div>
                <div>
                  <TableSortControl sortingKey="positive">
                    Positive matches
                  </TableSortControl>
                </div>
              </div>
            }
          />
        }
        body={
          <Body>
            {sortedConcepts.map(concept => {
              const selected = Concept.areTextsEqual(selection, concept);
              return (
                <ConceptRow
                  key={concept.hash()}
                  selected={selected}
                  ref={selected ? selectedRowRef : undefined}
                  concept={concept}
                  projectId={projectId}
                  matchCount={concept.exactMatchCount}
                  totalCount={totalCount}
                  renderVisualization={() => {
                    return (
                      <Tooltip
                        delayed={false}
                        anchor={
                          <div className="sentiment-table__share-charts">
                            <Cell>
                              <SharesChart
                                type="negative"
                                sentimentShare={concept.sentimentShare.negative}
                              />
                            </Cell>
                            <Cell>
                              <SharesChart
                                type="positive"
                                sentimentShare={concept.sentimentShare.positive}
                              />
                            </Cell>
                          </div>
                        }
                      >
                        <SharesChartTooltipContent
                          header={`"${concept.name}"`}
                          sentimentCounts={concept.sentimentCounts}
                          sentimentShare={concept.sentimentShare}
                          matchCount={concept.exactMatchCount}
                        />
                      </Tooltip>
                    );
                  }}
                  renderExpanded={
                    selected && concept.exampleDocuments
                      ? () => (
                          <SentimentExamples
                            concept={concept}
                            downloadExport={downloadExport}
                          />
                        )
                      : undefined
                  }
                />
              );
            })}
          </Body>
        }
      />
    </div>
  );
}

ConceptsTable.propTypes = {
  projectId: PropTypes.string.isRequired,
  sortConcepts: PropTypes.oneOf(['name', 'matches', 'positive', 'negative'])
    .isRequired,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  onSortChange: PropTypes.func.isRequired,
  concepts: PropTypes.arrayOf(PropTypes.instanceOf(Concept).isRequired)
    .isRequired,
  totalCount: PropTypes.number.isRequired,
  selection: PropTypes.object,
  downloadExport: PropTypes.func
};

function getSortedConcepts(concepts, sortConcepts, sortOrder) {
  const directionalModifier = sortOrder === 'asc' ? 1 : -1;
  return sortObjects(
    concepts,
    (conceptA, conceptB) => {
      switch (sortConcepts) {
        case 'name':
          return directionalModifier * naturalSortByName(conceptA, conceptB);
        case 'matches':
          return (
            directionalModifier *
            (conceptA.exactMatchCount - conceptB.exactMatchCount)
          );
        case 'positive':
        case 'negative':
          return (
            directionalModifier *
            (conceptA.sentimentShare[sortConcepts] -
              conceptB.sentimentShare[sortConcepts])
          );
        default:
          return 0;
      }
    },
    naturalSortByName
  );
}
