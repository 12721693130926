import _ from 'lodash';
import { css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';

import { setRtlClass } from '../utils/DocUtils';
import Spinner from '../components/core/Spinner';
import Alert from '../components/core/Alert';
import { Colors, Mixins } from '../styles';
import { AlertTypes, MetadataTypes } from '../constants';
import { Cell } from './Cell';
import { Column } from './Column';
import { ColumnGroup } from './ColumnGroup';
import InlineEditor from '../components/InlineEditor';
import {
  selectedHeaderStyles,
  shouldUseSkippedHeaderStyles,
  skippedHeaderStyles
} from './headerStyles';

export default function UploadPreview({
  language,
  parseInProgress,
  parseError,
  columns,
  rows,
  selectedGroup,
  onSelectColumn,
  firstSelectedColumnRef,
  hoveredGroup,
  onHoverColumn,
  onRenameColumn,
  groups,
  groupStats
}) {
  return (
    <div className="upload-preview">
      {parseInProgress ? (
        <Message>
          <Spinner color={Colors.gray5} /> Loading file...
        </Message>
      ) : !_.isEmpty(parseError) ? (
        <Message>
          <Alert type={AlertTypes.ERROR}>{parseError}</Alert>
        </Message>
      ) : columns == null ? (
        <Message>Choose a file to preview your dataset</Message>
      ) : (
        <div>
          <PreviewTable
            language={language}
            columns={columns}
            rows={rows}
            selectedGroup={selectedGroup}
            onSelectColumn={onSelectColumn}
            firstSelectedColumnRef={firstSelectedColumnRef}
            hoveredGroup={hoveredGroup}
            onHoverColumn={onHoverColumn}
            onRenameColumn={onRenameColumn}
            groups={groups}
            groupStats={groupStats}
          />
        </div>
      )}
    </div>
  );
}

UploadPreview.propTypes = {
  parseInProgress: PropTypes.bool.isRequired,
  parseError: PropTypes.string,
  language: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Column).isRequired),
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.instanceOf(Cell)).isRequired
  ),
  selectedColumnIndex: PropTypes.instanceOf(ColumnGroup),
  onSelectColumn: PropTypes.func.isRequired,
  firstSelectedColumnRef: PropTypes.object,
  hoveredGroup: PropTypes.instanceOf(ColumnGroup),
  onHoverColumn: PropTypes.func.isRequired,
  onRenameColumn: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.instanceOf(ColumnGroup).isRequired),
  groupStats: PropTypes.object.isRequired
};

function Message({ children }) {
  return (
    <div className="upload-preview__message-container">
      <div className="upload-preview__message">{children}</div>
    </div>
  );
}

function getTableColumnIndices(group, columns) {
  return (
    group &&
    columns
      // Filter blank columns so we're left with the columns that get rendered
      .filter(column => !column.isBlank)
      // Store the positional indices of the rendered columns
      .map((column, previewTableIndex) => [column, previewTableIndex])
      // Keep only the columns that belong to the selected group
      .filter(([column]) => group.indices.includes(column.index))
      // Pull out indices (and one-index them for use in CSS nth-child selector)
      .map(([, previewTableIndex]) => previewTableIndex + 1)
  );
}

function getTableRowSelectors(prefix, indices) {
  return indices?.map(i => `${prefix}:nth-child(${i})`).join(',');
}

function PreviewTable({
  language,
  columns,
  rows,
  selectedGroup,
  onSelectColumn,
  firstSelectedColumnRef,
  hoveredGroup,
  onHoverColumn,
  onRenameColumn,
  groups,
  groupStats
}) {
  const firstSelectedColumnIndex = selectedGroup?.indices[0];
  const selectedTableColumnIndices = getTableColumnIndices(
    selectedGroup,
    columns
  );
  const getSelectedRowSelectors = prefix => {
    return getTableRowSelectors(prefix, selectedTableColumnIndices);
  };
  const hoveredTableColumnIndices = getTableColumnIndices(
    hoveredGroup,
    columns
  );
  const getHoveredRowSelectors = prefix => {
    return getTableRowSelectors(prefix, hoveredTableColumnIndices);
  };

  return (
    <table
      css={css`
        min-width: 100%;
        /* avoid bug that causes sticky-positioned <th>'s to lose their borders */
        border-collapse: separate;
        cursor: pointer;

        th,
        td {
          ${Mixins.ellipsify};
          padding: 0.5rem 0.875rem;
          border-bottom: 0.0625rem ${Colors.gray2} solid;
          max-width: 20rem;

          border-left: 2px solid transparent;
          border-right: 2px solid transparent;
        }

        th {
          position: sticky;
          top: 0;
          /* hide content that scrolls under header cells */
          background: white;
          text-align: initial;
          border-top: 2px solid transparent;
        }

        em {
          color: ${Colors.gray5};
        }

        ${selectedGroup &&
        css`
          ${getSelectedRowSelectors('th')}, ${getSelectedRowSelectors('td')} {
            border-left: 2px solid ${Colors.blue4};
            border-right: 2px solid ${Colors.blue4};
          }

          ${getSelectedRowSelectors('th')} {
            ${selectedHeaderStyles};
            border-top: 2px solid ${Colors.blue4};
          }

          tr:last-child {
            ${getSelectedRowSelectors('td')} {
              border-bottom: 2px solid ${Colors.blue4};
            }
          }
        `}

        ${hoveredGroup &&
        css`
          ${getHoveredRowSelectors('th')}, ${getHoveredRowSelectors('td')} {
            background: ${Colors.blue0};
          }
        `}
      `}
    >
      <thead>
        <tr>
          {columns.map((column, key) => {
            const group = _.find(groups, { id: column.group });
            return (
              <Header
                key={key}
                ref={
                  firstSelectedColumnIndex === column.index
                    ? firstSelectedColumnRef
                    : null
                }
                column={column}
                onSelectColumn={onSelectColumn}
                onHoverColumn={onHoverColumn}
                onRenameColumn={onRenameColumn}
                stats={groupStats[group?.key]}
              />
            );
          })}
        </tr>
      </thead>
      <tbody>
        {rows.slice(0, 100).map((row, key) => {
          return (
            <DocumentRow
              key={key}
              row={row}
              language={language}
              columns={columns}
              onSelectColumn={onSelectColumn}
              onHoverColumn={onHoverColumn}
            />
          );
        })}
      </tbody>
    </table>
  );
}

const columnTrackingItem = 'upload-page_column';
const Header = React.memo(
  React.forwardRef(function Header(
    { column, onSelectColumn, onHoverColumn, onRenameColumn, stats },
    ref
  ) {
    if (column.isBlank) {
      return null;
    }
    const { name, type } = column;
    return (
      <th
        ref={ref}
        onClick={() => onSelectColumn(column)}
        onMouseEnter={() => onHoverColumn(column)}
        onMouseLeave={() => onHoverColumn(null)}
        data-tracking-item={columnTrackingItem}
      >
        <InlineEditor
          name="column name"
          action="Rename"
          blankValue="No header"
          value={name}
          placeholder="Type a new name"
          onChange={newName => onRenameColumn(column, newName)}
          css={[
            css`
              margin-left: -0.5rem;
              margin-bottom: 0.5rem;
            `,
            shouldUseSkippedHeaderStyles(column, stats) && skippedHeaderStyles
          ]}
        />
        <em
          css={css`
            font-weight: bold;
          `}
        >
          {_.upperFirst(type)}
        </em>
      </th>
    );
  })
);

const DocumentRow = React.memo(function DocumentRow({
  row,
  language,
  columns,
  onSelectColumn,
  onHoverColumn
}) {
  return (
    <tr>
      {columns.map((column, columnIndex) => {
        if (column.isBlank) {
          return null;
        }
        // get cell, or blank Cell if it's missing
        const cell = row[columnIndex] ?? Cell.blank();
        return (
          <PreviewCell
            key={columnIndex}
            cell={cell}
            type={column.type}
            language={language}
            onClick={() => onSelectColumn(column)}
            onMouseEnter={() => onHoverColumn(column)}
            onMouseLeave={() => onHoverColumn(null)}
          />
        );
      })}
    </tr>
  );
});

function PreviewCell({
  cell,
  type,
  language,
  onClick,
  onMouseEnter,
  onMouseLeave
}) {
  if (type === 'title' || type === 'text') {
    return (
      <TextCell
        language={language}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {cell.stringValues[0]}
      </TextCell>
    );
  } else {
    return (
      <MetadataCell
        type={type}
        cell={cell}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    );
  }
}

function TextCell({ children, language, onClick, onMouseEnter, onMouseLeave }) {
  return (
    <td
      className={setRtlClass('', language)}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-tracking-item={columnTrackingItem}
    >
      {children}
    </td>
  );
}

function MetadataCell({ type, cell, onClick, onMouseEnter, onMouseLeave }) {
  const unparsedValues = cell.unparsableValuesAs(type);
  let fieldValues = cell.valuesAs(type);
  if (type === MetadataTypes.DATE) {
    fieldValues = fieldValues.map(value => value.format('MMM D, YYYY'));
  }

  return (
    <td
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-tracking-item={columnTrackingItem}
    >
      {fieldValues.map((value, index) => (
        <span
          key={index}
          className="upload-preview__metadata-value"
          css={
            type === 'skip'
              ? css`
                  text-decoration: line-through;
                  color: ${Colors.gray5};
                  font-style: italic;
                `
              : undefined
          }
        >
          {value}
        </span>
      ))}
      {unparsedValues.map((value, index) => (
        <span key={index} className="upload-preview__metadata-value">
          <em
            css={css`
              text-decoration: line-through;
            `}
          >
            {value}
          </em>
        </span>
      ))}
    </td>
  );
}
