import css from '@emotion/css';
import React from 'react';
import PropTypes from 'prop-types';

import { TabPanel } from './Tabs';

export function ConfigureVisualizationPanel(props) {
  return (
    <TabPanel
      header="Configure visualization"
      body={
        <div
          css={css`
            padding: 0.5rem;
            display: flex;
            flex-direction: column;
            overflow: auto;
          `}
        >
          {props.body}
        </div>
      }
    />
  );
}

ConfigureVisualizationPanel.propTypes = { body: PropTypes.any };
