import React from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3-scale';
import { max } from 'd3-array';
import { area } from 'd3-shape';
import styled from '@emotion/styled';
import css from '@emotion/css';

import { Colors } from '../../styles';
import Tooltip from '../../components/core/Tooltip';

const percentageScale = (maxValue, maxPercentage = 100) =>
  scaleLinear().domain([0, maxValue]).range([0, maxPercentage]);

export default class AreaChart extends React.Component {
  constructor() {
    super();
    this.state = { isHoveringChart: false, mousePositionPercentage: 0 };
    this.containerRef = React.createRef();
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
  }

  onMouseEnter() {
    this.setState({ isHoveringChart: true });
  }

  onMouseLeave() {
    this.setState({ isHoveringChart: false });
  }

  onMouseMove(event) {
    const container = this.containerRef.current.getBoundingClientRect();

    const horizontallyBound =
      container.x <= event.clientX &&
      event.clientX <= container.x + container.width;

    const verticallyBound =
      container.y <= event.clientY &&
      event.clientY <= container.y + container.height;

    const clientX = event.clientX;
    this.setState(state => ({
      isHoveringChart: verticallyBound && horizontallyBound,
      ...(horizontallyBound &&
        state.isHoveringChart && {
          mousePositionPercentage:
            (100 * (clientX - container.left)) / container.width
        })
    }));
  }

  render() {
    const {
      matchCounts,
      matchType,
      renderTooltipContent,
      tooltipPosition
    } = this.props;
    const { isHoveringChart, mousePositionPercentage } = this.state;

    const xScale = percentageScale(matchCounts.length - 1);
    const maxCount = max(matchCounts.map(bucket => bucket[matchType]));
    const yScale = percentageScale(maxCount, 80);

    const hoveredElementIndex = Math.round(
      xScale.invert(mousePositionPercentage)
    );
    const hoveredBucket = matchCounts[hoveredElementIndex];

    const width = xScale(1);

    return (
      <div
        ref={this.containerRef}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onMouseMove={this.onMouseMove}
        css={css`
          position: relative;
          width: 100%;
        `}
      >
        <Visualization
          matchCounts={matchCounts}
          matchType={matchType}
          xScale={xScale}
          yScale={yScale}
        />

        {isHoveringChart && (
          <Tooltip
            position={tooltipPosition}
            css={css`
              position: absolute;
              height: 2rem;
              top: 0;
              width: ${width}%;
              left: ${xScale(hoveredElementIndex) - width / 2}%;
            `}
            anchor={
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  align-items: center;
                `}
              >
                {matchType === 'total' && (
                  <HoverIndicator height={yScale(hoveredBucket.total)} />
                )}
                <HoverIndicator height={yScale(hoveredBucket.exact)} />
              </div>
            }
            delayed={false}
          >
            {renderTooltipContent(hoveredElementIndex)}
          </Tooltip>
        )}
      </div>
    );
  }
}

AreaChart.propTypes = {
  matchCounts: PropTypes.arrayOf(
    PropTypes.shape({
      exact: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired
    }).isRequired
  ),
  matchType: PropTypes.oneOf(['exact', 'total']).isRequired,
  renderTooltipContent: PropTypes.func.isRequired,
  tooltipPosition: PropTypes.string
};

AreaChart.defaultProps = {
  tooltipPosition: 'above'
};

const Visualization = ({ matchCounts, matchType, xScale, yScale }) => {
  const getPath = matchType => {
    return area()
      .x((bucket, i) => xScale(i))
      .y0(bucket => 100 - yScale(bucket[matchType]))
      .y1(100)(matchCounts);
  };

  return (
    <svg
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      css={css`
        display: block;
        width: 100%;
        height: 2rem;
        path {
          fill: ${Colors.blue2};

          &:last-child {
            fill: ${Colors.blue4};
          }
        }
      `}
      data-test-id="area-chart"
    >
      {matchType === 'total' && <path d={getPath('total')} />}

      <path d={getPath('exact')} />
    </svg>
  );
};

Visualization.propTypes = {
  matchCounts: PropTypes.arrayOf(
    PropTypes.shape({
      exact: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  matchType: PropTypes.oneOf(['exact', 'total']).isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired
};

const HoverIndicator = styled.div`
  top: ${props => 100 - props.height}%;
  position: absolute;
  background-color: ${Colors.blue3};
  border: 0.125rem solid white;
  transform: translateY(-50%);
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
`;

HoverIndicator.propTypes = {
  height: PropTypes.number.isRequired
};
