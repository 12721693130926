import React from 'react';
import PropTypes from 'prop-types';

export default function ArrowheadMarker({ baseLength, facing, id }) {
  // refX and refY specify the marker's attachment point (the midpoint of its base),
  // the coords of which are affected by the marker's rotation and translation, if any
  let refX, refY, transform;

  switch (facing) {
    case 'right': {
      refX = 0;
      refY = baseLength / 2;
      transform = null;
      break;
    }
    case 'left': {
      refX = baseLength;
      refY = baseLength / 2;
      transform = `rotate(180) translate(${-baseLength}, ${-baseLength})`;
      break;
    }
    case 'up': {
      refX = baseLength / 2;
      refY = baseLength;
      transform = `rotate(-90) translate(${-baseLength}, 0)`;
      break;
    }
    case 'down': {
      refX = baseLength / 2;
      refY = 0;
      transform = `rotate(90) translate(0, ${-baseLength})`;
      break;
    }
  }

  return (
    <marker
      id={id}
      markerWidth={baseLength}
      markerHeight={baseLength}
      refX={refX}
      refY={refY}
    >
      <path
        transform={transform}
        d={`M 0 0 L ${baseLength} ${baseLength / 2} L 0 ${baseLength} z`}
      />
    </marker>
  );
}

ArrowheadMarker.propTypes = {
  // The length of the arrowhead's base, i.e. length of a side of the square in which it's
  // inscribed, such that the arrowhead triangle's vertices are the ends of one side of the square
  // and the midpoint of the opposite side of the square.
  baseLength: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired, // For use in other elements' markerStart and/or markerEnd attrs
  facing: PropTypes.oneOf(['right', 'left', 'up', 'down']).isRequired
};
