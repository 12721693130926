import React from 'react';
import PropTypes from 'prop-types';

import { removeActiveConcepts, notifier } from '../actions';
import { Button } from '../components/core/Button';
import { Concept } from '../classes/Concepts';
import { Icon, IconTypes } from '../components/icons';

export function RemoveActiveConcept({
  selection,
  projectId,
  activeConcepts,
  ...passedProps
}) {
  const activeConcept = activeConcepts.find(ac =>
    Concept.areTextsEqual(ac, selection)
  );

  return (
    <>
      <Button
        aria-label="Remove active concept"
        flavor="subtle"
        palette="red"
        padded={false}
        onClick={() => {
          removeActiveConcepts(projectId, activeConcept.sharedConceptId).catch(
            () => {
              notifier.error("Can't remove active concept");
            }
          );
        }}
        {...passedProps}
      >
        <Icon type={IconTypes.TRASH} />
      </Button>
    </>
  );
}

RemoveActiveConcept.propTypes = {
  projectId: PropTypes.string.isRequired,
  selection: PropTypes.instanceOf(Concept).isRequired,
  activeConcepts: PropTypes.arrayOf(PropTypes.instanceOf(Concept).isRequired)
    .isRequired
};
