import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {
  Combobox as ReachCombobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover
} from '@reach/combobox';
import PropTypes from 'prop-types';
import Sifter from 'sifter';

import { ZIndexes } from '../../styles';
import { Button } from '../../components/core/Button';

export const AddIgnoreAutosuggest = ({
  options,
  placeholder,
  submitSelection
}) => {
  const [inputText, setInputText] = useState('');
  const getSuggestions = options =>
    // sifter only filters arrays of objects, not strings
    new Sifter(options.map(option => ({ value: option })))
      .search(inputText, {
        fields: ['value'],
        sort: [{ field: 'value', direction: 'asc' }]
      })
      .items.map(({ id: index }) => options[index])
      .slice(0, 10);
  const suggestions = getSuggestions(options);
  return (
    <Form
      onSubmit={event => {
        event.preventDefault();
        submitSelection(inputText);
        setInputText('');
      }}
    >
      <ReachCombobox onSelect={selection => setInputText(selection)}>
        <ComboboxInput
          aria-label="Concept to ignore"
          autocomplete={false}
          value={inputText}
          placeholder={placeholder}
          onChange={event => {
            setInputText(event.target.value);
          }}
          onKeyDown={event => {
            // Allow escape to close the flyout, but not the modal
            if (event.key === 'Escape') {
              event.stopPropagation();
            }
          }}
        />
        {inputText && suggestions.length > 0 && (
          <ComboboxPopover
            css={css`
              /* The flyout is rendered in a portal so we need to manually set the
                 z-index */
              z-index: ${ZIndexes.modal + ZIndexes.flyout};
            `}
          >
            <ComboboxList>
              {suggestions.map(option => (
                <ComboboxOption value={option} key={option} />
              ))}
            </ComboboxList>
          </ComboboxPopover>
        )}
      </ReachCombobox>
      <Button type="submit">Add</Button>
    </Form>
  );
};

AddIgnoreAutosuggest.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  submitSelection: PropTypes.func.isRequired
};

const Form = styled.form`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 1rem;
  flex-grow: 1;

  /* ReachCombobox styling */
  [data-reach-combobox] {
    width: 100%;
    margin-right: 1rem;
  }

  /* ComboboxInput styling */
  input {
    height: 2rem;
    margin: 0;
    width: 100%;
  }
`;
