import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';

import { Mixins } from '../../styles';

export default function DetailsListRow({ left, right, ...props }) {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        font-size: 0.875rem;
        padding: 0.25rem;
      `}
      {...props}
    >
      <div css={Mixins.ellipsify}>{left}</div>
      <div>{right}</div>
    </div>
  );
}

DetailsListRow.propTypes = {
  left: PropTypes.node.isRequired,
  right: PropTypes.node
};
