import { css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';

import List from './core/List';
import Tooltip from './core/Tooltip';
import { Colors } from '../styles';

export default function SidePanelSection({
  header,
  children,
  className,
  contentRef,
  asList = true,
  tooltip,
  dataTestId
}) {
  const headerSection = header && (
    <div
      css={css`
        display: flex;
        font-weight: bold;
        font-size: 0.875rem;
      `}
    >
      {header}
    </div>
  );

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        min-width: 0;
        min-height: 0;
        padding: 0.5rem;
        &:not(:last-child) {
          border-bottom: 1px solid ${Colors.gray2};
        }
      `}
      className={className}
      data-test-id={dataTestId}
    >
      {tooltip ? (
        <Tooltip anchor={headerSection} position="right">
          {tooltip}
        </Tooltip>
      ) : (
        headerSection
      )}
      <List
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          min-height: 0;
          ${header &&
          css`
            margin-top: 0.5rem;
          `}
        `}
        lined={asList}
        hoverable={asList}
        bordered={asList}
        scrollable={asList}
        ref={contentRef}
      >
        {children}
      </List>
    </div>
  );
}

SidePanelSection.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentRef: PropTypes.shape({ current: PropTypes.any }),
  asList: PropTypes.bool,
  tooltip: PropTypes.node,
  dataTestId: PropTypes.string
};
