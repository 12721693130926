import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { downloadDrivers } from '../utils/ApiUtilsV5';
import { ExportCurrentViewSection } from '../side_panel/ExportPanel';
import { useCurrentView } from '../side_panel/views/view';
import { useSearchParams } from '../search_params';

export function DriversDownloadButton({ projectName }) {
  const { projectId } = useParams();
  const { searchParams } = useSearchParams();
  const { conceptSelector } = useCurrentView();

  function doExport() {
    return downloadDrivers(
      projectId,
      projectName,
      conceptSelector,
      searchParams.field,
      searchParams.filter
    );
  }

  return (
    <ExportCurrentViewSection
      featureName="Drivers"
      exportFunction={doExport}
      disableExport={searchParams.field === undefined}
      disabledMessage="You must select a score or number field to export Drivers data."
    />
  );
}

DriversDownloadButton.propTypes = {
  projectName: PropTypes.string.isRequired
};
