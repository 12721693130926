import React from 'react';
import css from '@emotion/css';

import { Button } from '../../../components/core/Button';
import { ToolCard } from '../../../highlights/Cards';
import { Icon, IconTypes } from '../../../components/icons';
import TileHeader from '../TileHeader';
import IconCompanyBox from '../IconCompanyBox';

const Reddit = ({ openReddit }) => {
  return (
    <ToolCard
      styles={css`
        height: 30rem;
      `}
      header={<TileHeader label="Reddit" icon={IconTypes.REDDIT} />}
      blurb={
        <div>
          Automatically scrape data from Reddit threads into a Daylight Project
          by entering the URLs for the threads you’re interested in
        </div>
      }
      visualization={
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              gap: 2rem;
            `}
          >
            <IconCompanyBox height={70}>
              <Icon
                type={IconTypes.REDDIT}
                size="70"
                css={css`
                  color: #ff4b08;
                `}
              />
            </IconCompanyBox>
          </div>
          <div
            css={css`
              display: flex;
              justify-content: center;
            `}
          >
            <Button onClick={openReddit}>Select</Button>
          </div>
        </div>
      }
    />
  );
};

export default Reddit;
