import { css } from '@emotion/core';
import React, { useState, useRef, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { RoutePatterns } from '../constants';
import { StoreContext } from '../StoreContext';
import { getDrivers } from '../utils/ApiUtilsV5';
import { RequestStatuses } from '../constants';
import {
  MetadataField,
  NumericField,
  ScoreField
} from '../classes/MetadataFields';
import { ToolCard } from './Cards';
import { IssuesAffectingScoreVisualization } from './IssuesAffectingScoreVisualization';
import PlaceholderText from '../components/core/PlaceholderText';
import { Colors } from '../styles';
import Dropdown from '../components/core/Dropdown';
import { useBoundingClientRect, useFetch } from '../utils/hooks';
import { getMoreDetailLink } from './utils';
import { Bold } from '../components/Bold';
import { Concept } from '../classes/Concepts';
import { getDefaultDriversField } from '../drivers/utils';

const isValidField = field =>
  field instanceof ScoreField || field instanceof NumericField;

const hasValidFields = metadata => metadata.filter(isValidField).length > 0;

const emptyFilter = [];

export function IssuesAffectingScoreCard({ workspaceId, projectId }) {
  const { metadata } = useContext(StoreContext);
  const [selectedField, setSelectedField] = useState(
    getDefaultDriversField(metadata)
  );
  const { status, response: drivers } = useFetch(
    // only make request if there is a selected field
    selectedField ? getDrivers : undefined,
    projectId,
    emptyFilter,
    selectedField
  );
  const visualization = useRef(null);
  const { width, height } = useBoundingClientRect(visualization);

  const sortedDrivers = drivers && top15ImpactfulDriver([...drivers]);

  const badDriversField = drivers?.length === 0;

  return (
    <ToolCard
      header={
        <TopSection
          metadata={metadata}
          selectedField={selectedField}
          onChangeField={setSelectedField}
        />
      }
      blurb={
        !badDriversField ? (
          <Blurb selectedField={selectedField} drivers={sortedDrivers} />
        ) : undefined
      }
      visualization={
        selectedField !== undefined ? (
          status === RequestStatuses.PENDING ? (
            <ToolCard.VisualizationPlaceholder />
          ) : (
            <div
              ref={visualization}
              css={css`
                height: 100%;
              `}
            >
              {badDriversField ? (
                <BadDriverMessage fieldName={selectedField} />
              ) : (
                <IssuesAffectingScoreVisualization
                  width={width}
                  height={height}
                  drivers={sortedDrivers}
                />
              )}
            </div>
          )
        ) : (
          <NoValidFieldsVisualization />
        )
      }
      footer={
        <ToolCard.MoreDetailLink
          name="Drivers feature"
          link={getMoreDetailLink(
            workspaceId,
            projectId,
            RoutePatterns.DRIVERS,
            {
              concepts: 'drivers',
              field: selectedField
            }
          )}
          disabled={
            selectedField === undefined || status !== RequestStatuses.FULFILLED
          }
          trackingItem="highlights_more-details-link_affecting-score"
        />
      }
    />
  );
}

IssuesAffectingScoreCard.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

function AverageScore({ driver }) {
  return driver ? (
    <Bold>{driver.baseline.toFixed(2)}</Bold>
  ) : (
    <PlaceholderText style={{ width: '3rem' }} />
  );
}

function Driver({ driver }) {
  return (
    <>
      <b>
        "<DriverName driver={driver} />"
      </b>{' '}
      (<DriverAverageScore driver={driver} />)
    </>
  );
}

function DriverName({ driver }) {
  return driver ? (
    <bdi>{driver.name}</bdi>
  ) : (
    <PlaceholderText style={{ width: '3rem' }} />
  );
}

function DriverAverageScore({ driver }) {
  const averageScoreText = driver ? (
    driver.averageScore.toFixed(2)
  ) : (
    <PlaceholderText style={{ width: '3rem' }} />
  );

  const styles = driver && {
    color: driver.impact >= 0 ? Colors.blue6 : Colors.red5
  };

  return <span css={styles}>{averageScoreText}</span>;
}

function TopSection({ metadata = [], selectedField, onChangeField }) {
  return (
    <>
      What issues are affecting{' '}
      {hasValidFields(metadata) ? (
        <TopSectionDropdown
          metadata={metadata}
          selectedField={selectedField}
          onChangeField={onChangeField}
        />
      ) : (
        'my scores?'
      )}
    </>
  );
}

TopSection.propTypes = {
  metadata: PropTypes.arrayOf(PropTypes.instanceOf(MetadataField).isRequired)
    .isRequired,
  selectedField: PropTypes.string,
  onChangeField: PropTypes.func.isRequired
};

function TopSectionDropdown({ metadata, selectedField, onChangeField }) {
  return (
    <Dropdown
      borderless
      aria-label="Select metadata field"
      value={selectedField}
      onChange={event => {
        onChangeField(event.target.value);
      }}
      css={css`
        padding: 0 0.25rem;
        padding-right: 1.5rem;
      `}
    >
      <option disabled={true}>Select a field</option>
      {metadata
        .filter(
          field => field instanceof ScoreField || field instanceof NumericField
        )
        .map(field => {
          const fieldHasNoRange = field.minimum === field.maximum;
          return (
            <option
              key={field.name}
              value={field.name}
              data-tracking-item={
                field instanceof ScoreField
                  ? 'highlights_my-score-dropdown-item_score'
                  : 'highlights_my-score-dropdown-item_number'
              }
              disabled={fieldHasNoRange}
            >
              {field.name}
              {fieldHasNoRange && ' (Field range too small)'}
            </option>
          );
        })}
    </Dropdown>
  );
}

TopSectionDropdown.propTypes = {
  metadata: PropTypes.arrayOf(PropTypes.instanceOf(MetadataField).isRequired)
    .isRequired,
  selectedField: PropTypes.string,
  onChangeField: PropTypes.func.isRequired
};

function Blurb({ selectedField, drivers }) {
  return selectedField ? (
    drivers ? (
      <BlurbWithDrivers drivers={drivers} />
    ) : (
      <LoadingBlurb />
    )
  ) : (
    <p
      css={css`
        font-style: italic;
        font-size: 1rem;
        color: ${Colors.gray5};
      `}
    >
      No score data
    </p>
  );
}

Blurb.propTypes = {
  selectedField: PropTypes.string,
  drivers: PropTypes.array
};

function BlurbWithDrivers({ drivers }) {
  const descendingImpact = driver => -Math.abs(driver.impact);
  const [driverA, driverB] = _.sortBy(drivers, descendingImpact);

  return <DriversAffectingScoreMessage driverA={driverA} driverB={driverB} />;
}

BlurbWithDrivers.propTypes = {
  drivers: PropTypes.array.isRequired
};

function LoadingBlurb() {
  return <DriversAffectingScoreMessage driverA={null} driverB={null} />;
}

function DriversAffectingScoreMessage({ driverA, driverB }) {
  return (
    <p>
      The average scores for some concepts like <Driver driver={driverA} /> and{' '}
      <Driver driver={driverB} /> are significantly different from your overall
      average score of <AverageScore driver={driverA} />.
    </p>
  );
}

DriversAffectingScoreMessage.propTypes = {
  driverA: PropTypes.instanceOf(Concept),
  driverB: PropTypes.instanceOf(Concept)
};

/**
 * Gets the 15 most impactful drivers
 *
 * @param {array} scoreDrivers
 */
function top15ImpactfulDriver(scoreDrivers) {
  const sortByImpact = (driverA, driverB) =>
    Math.abs(driverB.impact) - Math.abs(driverA.impact);

  return scoreDrivers.sort(sortByImpact).slice(0, 15);
}

function NoValidFieldsVisualization() {
  return (
    <div
      css={css`
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
      `}
      data-tracking-item="highlights_no-score-data-message"
    >
      <p>
        Upload documents with a score field to see what’s driving your score
        automatically.
      </p>
    </div>
  );
}

function BadDriverMessage({ fieldName }) {
  return (
    <div
      css={css`
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        font-weight: bold;
        text-align: center;
      `}
    >
      <p>
        There isn't enough data in <em>{fieldName}</em> to do a meaningful
        Drivers analysis.
      </p>
    </div>
  );
}
