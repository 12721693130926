import React from 'react';
import PropTypes from 'prop-types';

import { thousandify } from '../utils/NumFmtUtils';

export function TooltipContent({
  name,
  averageScore,
  impact,
  matchCount,
  currentType
}) {
  return (
    <div className="drivers__tooltip">
      <Row header="Driver" value={name} />
      <Row header="Average score" value={averageScore} />
      <Row header="Difference from overall score" value={impact} />
      <Row header={currentType} value={thousandify(matchCount)} />
    </div>
  );
}

TooltipContent.propTypes = {
  name: PropTypes.string.isRequired,
  averageScore: PropTypes.string.isRequired,
  impact: PropTypes.string.isRequired,
  matchCount: PropTypes.number.isRequired,
  currentType: PropTypes.string.isRequired
};

function Row({ header, value }) {
  return (
    <div className="drivers-tooltip-content__row">
      <div className="drivers-tooltip-content__header">{header}</div>
      <div className="drivers-tooltip-content__value">{value}</div>
    </div>
  );
}
