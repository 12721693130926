import React from 'react';

import { WhichConceptsToVisualize } from './WhichConceptsToVisualize';
import { ConfigSectionWrapper } from './ConfigWrapper';
import { SettingsLabel } from '../../components/SettingsLabel';
import GalaxyColorBySelection from '../../galaxy/GalaxyColorBySelection';

export function GalaxySettings() {
  return (
    <>
      <WhichConceptsToVisualize />
      <ConfigSectionWrapper>
        <SettingsLabel>Color concepts in galaxy by</SettingsLabel>
        <GalaxyColorBySelection style="sidePanel" />
      </ConfigSectionWrapper>
    </>
  );
}
