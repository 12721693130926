import React from 'react';

import { clearToken } from '../utils/apiToken';
import { logout } from '../utils/ApiUtilsV5';
import { redirectToLogin } from '../utils/common';
import { Button } from './core/Button';

export default function Logout() {
  return (
    <Button
      flavor="subtle"
      padded={false}
      onClick={() => {
        logout()
          .then(() => {
            clearToken();
            redirectToLogin();
          })
          .catch(console.error);
      }}
      data-tracking-item="global-nav-bar_user-menu-flyout-item_log-out"
    >
      Log out
    </Button>
  );
}
