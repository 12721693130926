import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Scrollbars } from 'react-custom-scrollbars';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';

import { Button } from './core/Button';
import { Icon, IconTypes } from './icons';

const EditableTextArea = ({ initialValue, onChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialValue);

  const handleEditButtonClick = () => {
    setIsEditing(!isEditing);
  };

  const handleTextChange = e => {
    setText(e.target.value);
  };

  const handleCancelClick = () => {
    // Reset text when cancelling
    setText(initialValue);
    setIsEditing(false);
  };
  const handleBlur = () => {
    onChange(text); // Save the text on blur
    setIsEditing(false);
  };

  return (
    <TextAreaContainer>
      {isEditing ? (
        <>
          <TextArea
            value={text}
            onChange={handleTextChange}
            onBlur={handleBlur}
          />
          <Button
            palette="red"
            onClick={handleCancelClick}
            aria-label="Cancel"
            css={css`
              margin-left: 10px;
            `}
          >
            <Icon type={IconTypes.CLOSE} size="1rem" />
          </Button>
        </>
      ) : (
        <>
          <Scrollbars style={{ width: '420px', height: '50px' }} autoHide>
            <TextContainer>{text}</TextContainer>
          </Scrollbars>
          <Button onClick={handleEditButtonClick}>
            <Icon type={IconTypes.PEN} size="1rem" />
          </Button>
        </>
      )}
    </TextAreaContainer>
  );
};

EditableTextArea.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func
};

EditableTextArea.defaultProps = {
  initialValue: ' ',
  onChange: () => {}
};

export default EditableTextArea;

const TextAreaContainer = styled.div`
  max-width: 475px;
  padding: 20px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextArea = styled.textarea`
  width: 95%;
  min-height: 100px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  resize: vertical;
`;

const TextContainer = styled.div`
  max-width: 100%; /* Adjust the maximum width as needed */
  word-wrap: break-word;
  padding-right: 10px;
  white-space: pre-line;
`;
