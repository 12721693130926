import React from 'react';
import css from '@emotion/css';

const IconCompanyBox = ({ children, height }) => {
  return (
    <div
      css={css`
        overflow: hidden;
        height: ${height}px;
      `}
    >
      {children}
    </div>
  );
};

export default IconCompanyBox;
