import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const ColoredCircle = memo(function ColoredCircle({ color, ...props }) {
  return (
    <div
      data-test-id="colored-circle"
      {...props}
      css={css`
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        background: ${color};
      `}
    />
  );
});

export default ColoredCircle;

ColoredCircle.propTypes = {
  color: PropTypes.string.isRequired
};
