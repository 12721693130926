import React from 'react';
import { css } from '@emotion/core';

import { useUniqueId } from '../../utils/hooks';
import { PlaintextWhenDisabledDropdown } from '../PlaintextWhenDisabledDropdown';
import { Checkbox } from '../../components/core/Checkbox';
import { Button } from '../../components/core/Button';
import { Colors } from '../../styles';
import { Icon, IconTypes } from '../../components/icons';

const intervalOptions = [
  { value: 'day', name: 'Day' },
  { value: 'week', name: 'Week' }
];

export const OptionsPage = ({
  isLoading,
  submitHandler,
  setRepeatInterval,
  repeatInterval,
  repeat,
  setRepeat
}) => {
  const selectId = useUniqueId();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
      `}
    >
      <div
        css={css`
          display: flex;
          gap: 1rem;
        `}
      >
        <Icon
          css={css`
            color: ${Colors.blue4};
          `}
          size="16"
          type={IconTypes.CIRCULAR_INFO}
        />
        <div>
          <p>
            The data scrape can be set up to repeat automatically to add new
            data to the Daylight project.
          </p>
          <p>
            To scrape the data only once, leave the below checkbox unchecked and
            click “Submit”.
          </p>
          <p>
            To repeat the scrape, check the box below and select the frequency
          </p>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1rem;
        `}
      >
        <label
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
          `}
        >
          <Checkbox
            checked={repeat}
            css={css`
              margin-right: 0.5rem;
            `}
            aria-label={`select repeat`}
            // prevent expanding/collapsing selector header
            onChange={event => {
              event.stopPropagation();
              setRepeat(prev => !prev);
            }}
          />
          <span>Repeat</span>
        </label>

        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 20px;
          `}
        >
          <label htmlFor={selectId}>Interval:</label>

          <PlaintextWhenDisabledDropdown
            id={selectId}
            editable={repeat}
            value={repeatInterval}
            onChange={setRepeatInterval}
            options={intervalOptions}
            promptOption="Select the repeat interval"
          />
        </div>

        <Button
          disabled={isLoading}
          onClick={submitHandler}
          type="button"
          palette="green"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
