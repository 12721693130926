import React from 'react';
import css from '@emotion/css';

import TileHeader from '../TileHeader';
import { Icon, IconTypes } from '../../../components/icons';
import { ToolCard } from '../../../highlights/Cards';
import { Button } from '../../../components/core/Button';
import IconCompanyBox from '../IconCompanyBox';

const Reviews = ({ openReviews }) => {
  return (
    <ToolCard
      styles={css`
        height: 30rem;
      `}
      header={<TileHeader label="Reviews" icon={IconTypes.REVIEWS} />}
      blurb={
        <div>
          Automatically scrape review data from the following sources into a
          Daylight Project by entering the URLs for the product listings you’re
          interested in
        </div>
      }
      visualization={
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              gap: 2rem;
            `}
          >
            <IconCompanyBox height={50}>
              <Icon type={IconTypes.AUDIBLE} size="100" />
            </IconCompanyBox>
            <IconCompanyBox height={30}>
              <Icon type={IconTypes.AMAZON} size="100" />
            </IconCompanyBox>
            <IconCompanyBox height={60}>
              <Icon type={IconTypes.BESTBUY} size="100" />
            </IconCompanyBox>
            <IconCompanyBox height={20}>
              <Icon type={IconTypes.SAMSCLUB} size="100" />
            </IconCompanyBox>
            <IconCompanyBox height={70}>
              <Icon type={IconTypes.TARGETCOM} size="70" />
            </IconCompanyBox>
            <IconCompanyBox height={25}>
              <Icon type={IconTypes.WALMART} size="100" />
            </IconCompanyBox>
          </div>
          <div
            css={css`
              display: flex;
              justify-content: center;
            `}
          >
            <Button onClick={openReviews}>Select</Button>
          </div>
        </div>
      }
    />
  );
};

export default Reviews;
