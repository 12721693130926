import React from 'react';
import PropTypes from 'prop-types';

export default function TextList({ items }) {
  items = [...items];
  if (items.length === 0) {
    return null;
  }
  if (items.length === 1) {
    return items[0];
  }
  if (items.length === 2) {
    return (
      <>
        {items[0]} and {items[1]}
      </>
    );
  }
  const last = items.pop();
  return (
    <>
      {items.map((item, i) => (
        <React.Fragment key={i}>{item}, </React.Fragment>
      ))}
      and {last}
    </>
  );
}

TextList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node).isRequired
};
