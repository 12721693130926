import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { downloadSentiment, getSentiment } from './utils/ApiUtilsV5';
import Alert from './components/core/Alert';
import { AlertTypes, RequestStatuses } from './constants';
import { StoreContext } from './StoreContext';
import { ConceptsTable, PlaceholderTable } from './sentiment/Table';
import { center } from './styles';
import { useFetch, useRefetchWhenActiveConceptsChange } from './utils/hooks';
import { SentimentStatusMessage } from './sentiment/SentimentStatusMessage';
import { getSentimentStatus, SENTIMENT_STATUS } from './utils/sentimentStatus';
import { ExportCurrentViewSection } from './side_panel/ExportPanel';
import { useCurrentView } from './side_panel/views/view';
import { useFilter, useSearchParams } from './search_params';

const Sentiment = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

export function SentimentWorkArea({ projectName }) {
  const { projectId } = useParams();
  const { selection, project } = React.useContext(StoreContext);
  const sentimentStatus = getSentimentStatus(project);
  const sentimentIsReady = sentimentStatus === SENTIMENT_STATUS.READY;
  const { searchParams, updateSearch } = useSearchParams();
  const { filter } = searchParams;
  const currentView = useCurrentView();
  const { status, concepts, totalCount, filterCount } = useSentiment(
    projectId,
    currentView,
    filter,
    sentimentIsReady
  );

  function changeSortOrder(sortBy) {
    updateSearch({ sortby: sortBy });
  }

  const [sortConcepts, sortOrder] = searchParams.sortby.split('-');
  if (!sentimentIsReady) {
    return (
      <SentimentStatusMessage
        status={sentimentStatus}
        css={css`
          font-size: 1.5rem;
        `}
      />
    );
  }

  function downloadExport() {
    return downloadSentiment(
      projectId,
      projectName,
      currentView.conceptSelector,
      filter,
      selection
    );
  }

  return (
    <Sentiment>
      {status === RequestStatuses.PENDING ? (
        <PlaceholderTable displayPlaceholderText />
      ) : status === RequestStatuses.FULFILLED ? (
        filterCount === 0 ? (
          <InsufficientConceptsCount />
        ) : concepts.length === 0 && currentView.conceptType === 'sentiment' ? (
          <NoSuggestionsMessage />
        ) : (
          <ConceptsTable
            totalCount={totalCount}
            concepts={concepts}
            selection={selection}
            projectId={projectId}
            sortConcepts={sortConcepts}
            sortOrder={sortOrder}
            onSortChange={changeSortOrder}
            downloadExport={downloadExport}
          />
        )
      ) : (
        <Alert type={AlertTypes.ERROR}>
          Something went wrong loading sentiment match counts
        </Alert>
      )}
    </Sentiment>
  );
}

SentimentWorkArea.propTypes = {
  projectName: PropTypes.string.isRequired
};

function WarningMessage({ children }) {
  return (
    <>
      <PlaceholderTable />
      <div style={center}>
        <Alert type={AlertTypes.WARNING}>{children}</Alert>
      </div>
    </>
  );
}

function NoSuggestionsMessage() {
  const filter = useFilter();
  return (
    <WarningMessage>
      {filter.length > 0
        ? 'There are no sentiment suggestions available for the current filter.'
        : 'We were unable to find any sentiment suggestions for your project.'}
    </WarningMessage>
  );
}

function InsufficientConceptsCount() {
  return (
    <WarningMessage>
      The documents in the current filter don't have enough concepts to display.
      Try changing or clearing the filter.
    </WarningMessage>
  );
}

export function SentimentDownloadButton({ projectId, projectName }) {
  const { conceptSelector } = useCurrentView();
  const filter = useFilter();
  const { selection, project } = React.useContext(StoreContext);
  const sentimentStatus = getSentimentStatus(project);

  function doExport() {
    return downloadSentiment(
      projectId,
      projectName,
      conceptSelector,
      filter,
      selection
    );
  }

  return (
    <ExportCurrentViewSection
      featureName="Sentiment"
      disableExport={
        sentimentStatus === SENTIMENT_STATUS.BUILT_WITHOUT_SENTIMENT
      }
      disabledMessage="This project does not include sentiment analysis to export."
      exportFunction={doExport}
    />
  );
}

function useSentiment(projectId, currentView, filter, sentimentBuilt) {
  const { status, response, refetch } = useFetch(
    sentimentBuilt && getSentiment,
    projectId,
    currentView.conceptSelector,
    filter
  );

  useRefetchWhenActiveConceptsChange(
    currentView.conceptType === 'active' && refetch
  );

  return {
    status,
    totalCount: response?.totalCount ?? null,
    filterCount: response?.filterCount ?? null,
    concepts: response?.matches ?? []
  };
}
