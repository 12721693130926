import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import { Toggle } from '../components/core/Toggle';
import * as gtm from '../utils/gtm';

export function DisplaySameToggle({ displaySame, onToggleSame }) {
  if (displaySame == null) {
    return null;
  }

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        min-height: 2.5rem;
        padding-left: 0.5rem;
        background: white;
        font-size: 0.875rem;
      `}
    >
      <Toggle
        checked={displaySame}
        onChange={() => {
          gtm.trigger(
            displaySame
              ? 'concept-list_toggle-same-concepts_off'
              : 'concept-list_toggle-same-concepts_on'
          );
          onToggleSame();
        }}
        label="Same concepts as top scatterplot"
      />
    </div>
  );
}

DisplaySameToggle.propTypes = {
  displaySame: PropTypes.bool,
  onToggleSame: PropTypes.func
};
