import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import {
  getSharedViews,
  loadSharedConceptLists,
  updateActiveListName
} from '../../actions';
import {
  getSharedConceptLists,
  renameSharedConceptList,
  restoreSharedConceptList,
  saveSharedView
} from '../../utils/ApiUtilsV5';
import { StoreContext } from '../../StoreContext';
import { useSharedListWithActiveListName } from '../hooks';
import { useDispatchUndoBanner } from '../../UndoBanner';
import InlineEditor from '../../components/InlineEditor';
import { useActiveListName } from './activeListName';
import { useShareConcepts } from './ShareActiveConcepts';

export default function ActiveListNameEditor({
  children,
  fillWidth,
  alertController
}) {
  const { projectId } = useParams();
  const { activeConceptListName } = useContext(StoreContext);
  const matchingList = useSharedListWithActiveListName();
  const dispatchUndoBanner = useDispatchUndoBanner();
  const shareConcepts = useShareConcepts();

  function refetchConceptLists() {
    loadSharedConceptLists(projectId);
  }

  function showRenameUndoBanner(
    newName,
    overwrite,
    clobberedList,
    sharedViewsBelongingToClobberedList
  ) {
    const oldName = activeConceptListName;
    dispatchUndoBanner({
      message: `Renamed "${oldName}" to "${newName}".\n${
        overwrite ? `"${newName}" already existed and was replaced.` : ''
      }`,
      onUndo: () => {
        return renameSharedConceptList(projectId, oldName, false)
          .then(() => {
            if (overwrite) {
              return restoreSharedConceptList(
                projectId,
                newName,
                clobberedList.concepts
              ).then(restoredList => {
                // Update all the shared views that used to point to the
                // clobbered list to now point to the restored list (which has a
                // different concept list ID).
                return Promise.all(
                  sharedViewsBelongingToClobberedList.map(sharedView =>
                    saveSharedView(projectId, {
                      ...sharedView,
                      concept_list_id: restoredList.concept_list_id
                    })
                  )
                );
              });
            }
          })
          .then(() => {
            if (overwrite) {
              getSharedViews(projectId);
            }
            refetchConceptLists();
            updateActiveListName(oldName);
          });
      },
      trackingItem: 'undo-rename-concept-list',
      // Don't undo if the old name has been taken by another user
      shouldRetry: error => error.code !== 'CONCEPT_LIST_EXISTS'
    });
  }

  function attemptRenameList(overwrite, newName) {
    if (newName === activeConceptListName) {
      return;
    } else if (!activeConceptListName) {
      shareConcepts(newName)
        .then(() => {
          alertController.showSuccess('Success!');
        })
        .catch(() => {
          alertController.showWarning('Unable to share concept list');
        });
      return;
    }

    // Save state of concept lists before we rename for possible undo
    let clobberedList;
    let sharedViewsBelongingToClobberedList;

    Promise.all([getSharedViews(projectId), getSharedConceptLists(projectId)])
      .then(([sharedViews, mostRecentConceptLists]) => {
        if (overwrite) {
          clobberedList = _.find(mostRecentConceptLists, { name: newName });
          // Keep track of which shared views are going to be modified if any.
          // Declared in function scope for undo purposes.
          sharedViewsBelongingToClobberedList = _.filter(sharedViews, {
            concept_list_id: clobberedList.concept_list_id
          });
        }
        return renameSharedConceptList(projectId, newName, overwrite);
      })
      .then(() => {
        // If a concept list is being overwritten, then we need to update all
        // the shared views that depend on it to point to list that is being
        // renamed.
        if (overwrite) {
          const renamedListId = matchingList.concept_list_id;

          return Promise.all(
            sharedViewsBelongingToClobberedList.map(sharedView =>
              saveSharedView(projectId, {
                ...sharedView,
                concept_list_id: renamedListId
              })
            )
          );
        }
      })
      .then(() => {
        if (overwrite) {
          getSharedViews(projectId);
        }
        refetchConceptLists();
        updateActiveListName(newName);
        alertController.showSuccess('Success!');
        showRenameUndoBanner(
          newName,
          overwrite,
          clobberedList,
          sharedViewsBelongingToClobberedList
        );
      })
      .catch(result => {
        if (result.code === 'CONCEPT_LIST_EXISTS' && !overwrite) {
          attemptRenameList(true, newName);
        } else {
          alertController.showWarning('Unable to rename concept list');
        }
      });
  }

  return (
    <InlineEditor
      name="list name"
      value={activeConceptListName ?? ''}
      onChange={name => {
        attemptRenameList(false, name);
      }}
      required
      fillWidth={fillWidth}
      NameWrapper={ActiveListName}
    >
      {children}
    </InlineEditor>
  );
}

ActiveListNameEditor.propTypes = {
  children: PropTypes.node,
  fillWidth: PropTypes.bool.isRequired,
  alertController: PropTypes.any.isRequired
};

const ActiveListName = props => {
  const { nameAsHTML } = useActiveListName();
  return <span {...props}>{nameAsHTML}</span>;
};
