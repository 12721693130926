import { css } from '@emotion/core';
import { keyBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { StoreContext } from '../StoreContext';
import { selectConcept } from '../actions';
import { Concept } from '../classes/Concepts';
import { AlertTypes, RequestStatuses } from '../constants';
import Alert from '../components/core/Alert';
import ScatterPlot from './ScatterPlot';
import { TooltipContent } from './TooltipContent';
import { Overlay } from './Overlay';
import getTextWidth from '../utils/getTextWidth';
import { DriverContext } from './DriverContext';
import { Colors } from '../styles';
import { useBoundingClientRect } from '../utils/hooks';
import { useSearchParams } from '../search_params';

export default function DriversScatterPlot({
  hoveredDataPoint,
  setHoveredDataPoint,
  mouseIn,
  setMouseIn,
  fieldName,
  comparing = false
}) {
  const { searchParams } = useSearchParams();
  const { projectId } = useParams();
  const expectedXAxisTitle =
    searchParams.match_type !== 'exact' ? 'Total matches' : 'Exact matches';

  const { drivers, status, selectedDriver, selectionIsNotDriver } = useContext(
    DriverContext
  );
  const { activeConcepts, suggestions } = useContext(StoreContext);
  const box = useRef();
  const { width, height } = useBoundingClientRect(box);
  const data =
    drivers && getDataWithSelectedDriver(selectedDriver, drivers, status);
  const isLoading = status === RequestStatuses.PENDING;

  function selectDriver({ id }) {
    const driver = data.find(driver => driver.hash() === id) || selectedDriver;

    selectConcept(projectId, driver, searchParams.filter);
  }

  const baseline = data && data.length > 0 ? data[0].baseline : 0;
  const baselineLabelWidth =
    getTextWidth('Overall average score: ()', '0.875rem') +
    getTextWidth(baseline.toFixed(3), '0.875rem', 'bold');

  return (
    <div className="drivers__plot">
      <div
        css={css`
          height: 100%;
        `}
        ref={box}
      >
        {selectionIsNotDriver && (
          <div className="drivers__alert">
            <Alert type={AlertTypes.WARNING}>
              The concept you searched for is not a driver.
            </Alert>
          </div>
        )}
        {height > 0 && width > 0 && (
          <ScatterPlot
            width={width}
            height={height}
            xAxisTitle={expectedXAxisTitle}
            yAxisTitle={
              <>
                Average score <tspan fontStyle="italic">({fieldName})</tspan>
              </>
            }
            dataPoints={
              data
                ? dataPoints(
                    data,
                    activeConcepts,
                    suggestions,
                    searchParams.concepts
                  )
                : []
            }
            onDatapointClick={selectDriver}
            selectedDataPointId={selectedDriver?.hash()}
            hoveredDataPoint={hoveredDataPoint}
            setHoveredDataPoint={setHoveredDataPoint}
            mouseIn={mouseIn}
            setMouseIn={setMouseIn}
            baseline={baseline}
            baselineLabel={
              <>
                Overall average score:{' '}
                <tspan fontWeight="bold">{baseline.toFixed(3)}</tspan>
              </>
            }
            baselineLabelWidth={baselineLabelWidth}
          />
        )}
        <Overlay
          isLoading={isLoading}
          fieldName={fieldName}
          hasNoData={!data || data.length === 0}
          comparing={comparing}
        />
      </div>
    </div>
  );
}

DriversScatterPlot.propTypes = {
  hoveredDataPoint: PropTypes.object,
  setHoveredDataPoint: PropTypes.func.isRequired,
  mouseIn: PropTypes.bool,
  setMouseIn: PropTypes.func.isRequired,
  fieldName: PropTypes.string,
  comparing: PropTypes.bool
};

function dataPoints(data, activeConcepts, suggestions, conceptType) {
  const activeConceptsByText = keyBy(activeConcepts, ac => ac.toString());
  const suggestionsByText = keyBy(suggestions, s => s.toString());

  return data.map(driver => {
    const activeConcept = activeConceptsByText[Concept.toString(driver)];
    const name = activeConcept?.name ?? driver.name;

    const { searchParams } = useSearchParams();
    const matchType = searchParams.match_type;
    const currentMatchCount =
      matchType !== 'exact' ? 'matchCount' : 'exactMatchCount';
    const currentType =
      matchType !== 'exact' ? 'Total matches' : 'Exact matches';

    let color, selectedBorderColor;
    if (conceptType === 'active') {
      color = activeConcept?.color ?? Colors.gray9;
      selectedBorderColor = Colors.gray9;
    } else if (conceptType === 'clusters') {
      // If we're viewing "clusters", then the concepts should match up exactly
      // with the suggestions in the store, however, the "conceptType" value
      // will change before the data itself, in which case there won't be a
      // matching suggestion (and why we have to guard against undefined values)
      const suggestion = suggestionsByText[Concept.toString(driver)];
      color = suggestion?.color ?? Colors.gray9;
      selectedBorderColor = Colors.gray9;
    } else if (driver.averageScore > driver.baseline) {
      color = Colors.blue3;
      selectedBorderColor = Colors.blue7;
    } else {
      color = Colors.red3;
      selectedBorderColor = Colors.red7;
    }

    return {
      id: driver.hash(),
      x: driver[currentMatchCount],
      y: driver.averageScore,
      name,
      tip: (
        <TooltipContent
          currentType={currentType}
          name={name}
          averageScore={driver.averageScore.toFixed(3)}
          impact={(driver.impact > 0 ? '+' : '') + driver.impact.toFixed(3)}
          matchCount={driver[currentMatchCount]}
        />
      ),
      color,
      selectedBorderColor
    };
  });
}

function getDataWithSelectedDriver(
  selectedDriver,
  drivers = [],
  requestStatus
) {
  const existsInData =
    selectedDriver &&
    drivers.find(driver => driver.hash() === selectedDriver.hash());
  const hasLoaded = requestStatus === RequestStatuses.FULFILLED;
  return [
    ...drivers,
    ...(selectedDriver && hasLoaded && !existsInData ? [selectedDriver] : [])
  ];
}
