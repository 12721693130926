import _ from 'lodash';
import React, { useContext } from 'react';

import { StoreContext } from '../../StoreContext';
import { useActiveConceptList } from '../hooks';

export function useActiveListName() {
  const { sharedConceptLists } = useContext(StoreContext);
  const conceptLists = sharedConceptLists;
  const activeList = useActiveConceptList();
  // We only care about a difference between the active list and a matching
  // shared list if a matching shared list actually exists
  const matchingList = findMatchingList(conceptLists, activeList);
  const hasUnsavedChanges =
    matchingList == null || !activeList.equals(matchingList);

  const isCopy = activeList.name != null;

  let nameAsText;
  let nameAsHTML;

  if (!isCopy) {
    nameAsText = 'Unsaved changes';
  } else if (hasUnsavedChanges) {
    nameAsText = `${activeList.name} *`;
  } else {
    nameAsText = activeList.name;
  }

  if (hasUnsavedChanges) {
    nameAsHTML = <em>{nameAsText}</em>;
  } else {
    nameAsHTML = <>{nameAsText}</>;
  }

  return { nameAsText, nameAsHTML, isCopy, hasUnsavedChanges };
}

function findMatchingList(conceptLists, activeConceptList) {
  return _.find(conceptLists, { name: activeConceptList.name });
}
