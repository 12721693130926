import _ from 'lodash';
import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { useParams } from 'react-router-dom';

import { TabPanel } from './Tabs';
import { Button } from '../components/core/Button';
import Spinner from '../components/core/Spinner';
import Alert from '../components/core/Alert';
import { AlertTypes } from '../constants';
import SimpleModal from '../components/core/SimpleModal';
import { StoreContext } from '../StoreContext';
import { notifier } from '../actions';
import {
  downloadDocuments,
  downloadConceptFilterAssociations,
  downloadConceptConceptAssocScores
} from '../utils/ApiUtilsV5';
import ConceptFilterAssociationsForm from './export_and_share/ConceptFilterAssociationsForm';
import ConceptConceptAssociationsForm from './export_and_share/ConceptConceptAssociationsForm';
import { useMountedRef } from '../utils/hooks';
import SidePanelSection from '../components/SidePanelSection';
import { Mixins } from '../styles';
import { BubbleRadio } from '../components/core/Radio';
import DocDownloadModal from '../components/DocDownloadModal';
import { useFilter } from '../search_params';
import Tooltip from '../components/core/Tooltip';
import { Icon, IconTypes } from '../components/icons';

export function ExportPanel({ featureSpecificDownloadButton }) {
  return (
    <TabPanel
      header="Export"
      body={
        <div
          css={css`
            display: flex;
            flex-direction: column;

            > * {
              flex: 1;
            }
          `}
        >
          {featureSpecificDownloadButton}
          <RawDataExports />
        </div>
      }
    />
  );
}
ExportPanel.propTypes = {
  featureSpecificDownloadButton: PropTypes.node
};

export function ExportCurrentViewSection({
  featureName,
  exportFunction,
  disableExport,
  disabledMessage
}) {
  const [exportIsPending, setExportIsPending] = useState(false);
  const currentExportRequest = useRef();
  const mounted = useMountedRef();

  function handleDownload() {
    setExportIsPending(true);
    currentExportRequest.current = exportFunction()
      .catch(() => notifier.error('Export failed'))
      .finally(() => {
        if (mounted.current) {
          setExportIsPending(false);
        }
      });
  }

  useEffect(() => {
    return () => {
      if (currentExportRequest.current && currentExportRequest.current.abort) {
        currentExportRequest.current.abort();
      }
    };
  }, []);

  const exportButton = (
    <Button
      css={css`
        ${Mixins.ellipsify}
        &:disabled {
          pointer-events: none;
        }
      `}
      onClick={handleDownload}
      disabled={exportIsPending || disableExport}
      data-tracking-item="download-share-pane_download-xlsx-button"
    >
      {exportIsPending ? <Spinner /> : <Icon type={IconTypes.DOWNLOAD} />}
      Export
    </Button>
  );

  return (
    <PanelSection header="Export current selections as spreadsheet">
      <div>{featureName}</div>
      {disableExport && disabledMessage ? (
        <Tooltip anchor={exportButton}>{disabledMessage}</Tooltip>
      ) : (
        exportButton
      )}
    </PanelSection>
  );
}

ExportCurrentViewSection.propTypes = {
  featureName: PropTypes.string.isRequired,
  exportFunction: PropTypes.func.isRequired,
  disableExport: PropTypes.bool,
  disabledMessage: PropTypes.string
};

function PanelSection({ header, children }) {
  return (
    <SidePanelSection header={header} asList={false}>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          padding-bottom: 0.5rem;
        `}
      >
        {children}
      </div>
    </SidePanelSection>
  );
}

function CopyMessage({ status }) {
  return (
    <Alert
      css={css`
        flex: 1;
      `}
      type={status === 'success' ? AlertTypes.SUCCESS : AlertTypes.WARNING}
    >
      {status === 'success'
        ? 'Link copied to clipboard!'
        : 'Unable to copy to clipboard'}
    </Alert>
  );
}

CopyMessage.propTypes = {
  status: PropTypes.oneOf(['success', 'failure']).isRequired
};

function RawDataExports() {
  const [selectedExport, setSelectedExport] = useState('documents');

  return (
    <SidePanelSection header="Export raw data as spreadsheet" asList={false}>
      <div
        css={css`
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        `}
      >
        <BubbleRadio
          name="Export raw data as spreadsheet"
          label="Current documents"
          checked={selectedExport === 'documents'}
          onChange={() => setSelectedExport('documents')}
        />
        <BubbleRadio
          name="Export raw data as spreadsheet"
          label="Concept-Filter association scores"
          checked={selectedExport === 'concept-filter'}
          onChange={() => setSelectedExport('concept-filter')}
        />
        <BubbleRadio
          name="Export raw data as spreadsheet"
          label="Concept-Concept association scores"
          checked={selectedExport === 'concept-concept'}
          onChange={() => setSelectedExport('concept-concept')}
        />
        <DownloadExportsButton selectedExport={selectedExport} />
      </div>
    </SidePanelSection>
  );
}

function DownloadExportsButton({ selectedExport }) {
  const { projectId } = useParams();
  const { selection, project, activeConcepts } = useContext(StoreContext);
  const filter = useFilter();
  const [showModal, setShowModal] = useState(false);
  const [exportIsPending, setExportIsPending] = useState(false);

  const wrapDownloader = downloadFunction => {
    return (...args) => {
      setExportIsPending(true);
      setShowModal(false);
      downloadFunction(...args)
        .catch(() => notifier.error('Analysis export failed'))
        .finally(() => setExportIsPending(false));
    };
  };
  const downloadCurrentDocuments = wrapDownloader(
    _.partial(downloadDocuments, projectId, project.name, selection, filter)
  );
  const downloadConceptFilter = wrapDownloader(
    _.partial(downloadConceptFilterAssociations, projectId, project.name)
  );
  const downloadConceptConcept = wrapDownloader(
    _.partial(downloadConceptConceptAssocScores, projectId, project.name)
  );

  const exportButton = (
    <Button
      css={css`
        margin-top: 0.5rem;
        margin-right: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        float: right;
      `}
      onClick={() => setShowModal(true)}
      disabled={exportIsPending}
      data-tracking-item={`analysis-exports_${selectedExport}_open-modal`}
    >
      {exportIsPending ? <Spinner /> : <Icon type={IconTypes.DOWNLOAD} />}
      Export
    </Button>
  );

  if (selectedExport === 'documents') {
    return (
      <>
        {exportButton}
        <DocDownloadModal
          onHide={() => setShowModal(false)}
          showing={showModal}
          startDownload={downloadCurrentDocuments}
        />
      </>
    );
  }

  const header =
    selectedExport === 'concept-filter'
      ? 'Export concept-filter association scores'
      : 'Export concept-concept association scores';

  return (
    <>
      {exportButton}
      <SimpleModal
        onHide={() => setShowModal(false)}
        isOpen={showModal}
        trackingPrefix={`analysis-exports_${selectedExport}`}
        header={<h5>{header}</h5>}
      >
        {selectedExport === 'concept-filter' ? (
          <ConceptFilterModalContent
            triggerExport={downloadConceptFilter}
            activeConcepts={activeConcepts}
          />
        ) : (
          selectedExport === 'concept-concept' && (
            <ConceptConceptModalContent
              triggerExport={downloadConceptConcept}
              activeConcepts={activeConcepts}
            />
          )
        )}
      </SimpleModal>
    </>
  );
}

function ConceptFilterModalContent({ triggerExport, activeConcepts }) {
  if (_.isEmpty(activeConcepts)) {
    return (
      <p>
        This project doesn't have enough active concepts! To create some, select
        a concept and use the "Add to active concepts" button.
      </p>
    );
  }

  return <ConceptFilterAssociationsForm onDownload={triggerExport} />;
}

function ConceptConceptModalContent({ triggerExport, activeConcepts }) {
  if (activeConcepts.length < 2) {
    return (
      <p>
        This project doesn't have enough active concepts! To create some, select
        a concept and use the "Add to active concepts" button.
      </p>
    );
  }

  return <ConceptConceptAssociationsForm onDownload={triggerExport} />;
}
