import React, { useState, useRef, useEffect } from 'react';
import { css } from '@emotion/core';

import { SidePanelTabIcon } from './TabTitles';

function ExpandableNote({ content }) {
  const [isNoteExpanded, setNoteExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const noteRef = useRef(null);

  useEffect(() => {
    if (noteRef.current) {
      const isOverflow =
        noteRef.current.scrollHeight > noteRef.current.clientHeight;
      setIsOverflowing(isOverflow);
      setNoteExpanded(false);
    }
  }, [content]);

  const noteStyles = css`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${isNoteExpanded ? 'none' : '2'};
    -webkit-box-orient: vertical;
    max-height: ${isNoteExpanded ? 'none' : '3em'};
    white-space: pre-line;
    word-break: break-word;
    margin: 0;
  `;

  const containerStyles = css`
    display: flex;
    align-items: center;
    gap: 0.5rem;
  `;
  const buttonStyle = css`
    color: #04aade;
  `;

  const iconStyles = css`
    flex-shrink: 0; // Ensure the icon doesn't shrink if the text is long
  `;
  return (
    <div>
      <div css={containerStyles}>
        <SidePanelTabIcon css={iconStyles} tab={'documents'} />
        <p css={noteStyles} ref={noteRef}>
          {content}
        </p>
      </div>
      {isOverflowing && !isNoteExpanded && (
        <button css={buttonStyle} onClick={() => setNoteExpanded(true)}>
          Show More
        </button>
      )}
      {isNoteExpanded && (
        <button css={buttonStyle} onClick={() => setNoteExpanded(false)}>
          Show Less
        </button>
      )}
    </div>
  );
}

export default ExpandableNote;
