import { baseFontFamily } from '../styles';

/**
 * Calculate the width (in pixels) that some text is going to take up
 *
 * @param {string} text - Text that you want to find the width of
 * @param {string} fontSize
 * @param {string} fontWeight - defaults to "normal"
 *
 * @return {number} width (in pixels) that the text is going to take up
 */
export default function getTextWidth(text, fontSize, fontWeight = 'normal') {
  const canvas = document.createElement('canvas').getContext('2d');
  if (canvas) {
    canvas.font = `${fontWeight} ${fontSize} ${baseFontFamily}`;
    return canvas.measureText(text).width;
  }

  return 0;
}
