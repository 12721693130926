import styled from '@emotion/styled';

import { Colors } from '../styles';

export const SettingsLabel = styled.label`
  color: ${Colors.gray9};
  font-weight: bold;
  margin: 0.5rem 0;
  margin-left: 0.5rem;
`;
