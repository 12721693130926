import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import buildRoutePath from '../utils/buildRoutePath';
import { RoutePatterns } from '../constants';
import AddUserButton from './AddUserButton';
import { AuthContext, getUserRowsAndColumns } from './model';
import { NavigationDropdown } from './NavigationDropdown';
import EditableName from './EditableName';
import { NotificationArea } from './notifications';
import MultiSelectTable, {
  useClickableSelections
} from '../components/MultiSelectTable';
import RemoveUsersButton from './RemoveUsersButton';
import EditUsersButton from './EditUsersButton';
import {
  ControlBar,
  ManagementPageContainer,
  Subtitle,
  TableAndControlsContainer
} from './managementPageStyles';
import { AdminList } from './AdminList';
import PlaceholderText from '../components/core/PlaceholderText';
import DeleteWorkspaceButton from './DeleteWorkspaceButton';

export function WorkspaceSettings({ workspaceId }) {
  const { profile } = useContext(AuthContext);
  const workspaces = profile.workspacesWhoseUsersYouCanSee();
  const workspace = workspaces.find(
    workspace => workspace.workspace_id === workspaceId
  );
  const users = workspace?.getUsers();
  const projects = workspace?.getProjects();
  const organization = profile.organizationsById[workspace?.organization_id];
  const { rows, columns } = getUserRowsAndColumns(users, profile.username);
  const primaryKey = 'username';
  const selections = useClickableSelections(rows, primaryKey, workspaceId);

  return (
    <ManagementPageContainer>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        `}
      >
        <NavigationDropdown
          message="Switch your workspace"
          options={workspaces?.map(w => ({ name: w.name, id: w.workspace_id }))}
          buildPath={id =>
            buildRoutePath(RoutePatterns.WORKSPACE_SETTINGS, {
              workspaceId: id
            })
          }
        />
        <div>
          Organization: <strong>{organization.name}</strong>
        </div>
      </div>
      <EditableName key={workspace.workspace_id} namedThing={workspace} />
      <h4>Workspace users</h4>
      <Subtitle>
        {users == null ? (
          <PlaceholderText style={{ width: '8rem' }} />
        ) : users.length === 0 ? (
          'This workspace contains no users. You can add users below.'
        ) : (
          <>
            Below is a list of users in this workspace.{' '}
            <AdminList users={users} level={workspace.identifier} />
          </>
        )}
      </Subtitle>
      <TableAndControlsContainer>
        <ControlBar>
          <RemoveUsersButton workspace={workspace} selections={selections} />
          <EditUsersButton editable={workspace} selections={selections} />
          <NotificationArea />
          {workspace.editable && (
            <AddUserButton workspace={workspace} className="right" />
          )}
          {organization?.editable && (
            <DeleteWorkspaceButton workspace={workspace} projects={projects} />
          )}
        </ControlBar>
        {users?.length !== 0 && (
          <MultiSelectTable
            rows={rows}
            columns={columns}
            primaryKey={primaryKey}
            selections={selections}
            editable={workspace.editable}
          />
        )}
      </TableAndControlsContainer>
    </ManagementPageContainer>
  );
}

WorkspaceSettings.propTypes = {
  workspaceId: PropTypes.string.isRequired
};
