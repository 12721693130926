export function set(updates = {}) {
  if (window.dataLayer) {
    window.dataLayer.push(updates);
  }
}

export function trigger(event, updates = {}) {
  if (window.dataLayer) {
    window.dataLayer.push({ event, ...updates });
  }
}
