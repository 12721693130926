import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { css } from '@emotion/core';

import { StoreContext } from '../../StoreContext';
import { useFilter } from '../../search_params';
import SidePanelSection from '../../components/SidePanelSection';
import PlaceholderText from '../../components/core/PlaceholderText';
import { NoResultsMessage } from '../NoResultsMessage';
import { useRandom } from '../../utils/hooks';
import { PlainTextButton } from '../../components/core/Button';
import { useHover } from '../../HoverContext';
import { Colors } from '../../styles';
import { selectConcept } from '../../actions';
import DetailsListRow from './DetailsListRow';

export function TopConcepts() {
  const {
    docSearchResults,
    filterIsLoading,
    searchInProgress,
    selection,
    topConcepts,
    conceptSearchResults
  } = useContext(StoreContext);
  const { projectId } = useParams();
  const filter = useFilter();
  const badSearch =
    selection &&
    conceptSearchResults &&
    docSearchResults &&
    (_.isEmpty(conceptSearchResults) || _.isEmpty(docSearchResults));
  const hasTopConcepts = !_.isEmpty(topConcepts);
  const isLoading = filterIsLoading || searchInProgress;
  return (
    <SidePanelSection
      header={selection ? 'Top related concepts' : 'Top concepts by prevalence'}
      tooltip={`Top concepts are those that are very prevalent in your data compared to the project's native language. If the concept "broken" appears rarely in English conversations, but is in many documents in your dataset, it is a top concept in your project.`}
    >
      {isLoading ? (
        [...Array(50)].map((n, i) => (
          <div
            key={i}
            css={css`
              display: flex;
              justify-content: space-between;
              padding: 0.25rem;
            `}
          >
            <PlaceholderConcept key={`concept-${i}`} />
            {selection && <PlaceholderText style={{ width: '2rem' }} />}
          </div>
        ))
      ) : badSearch || !hasTopConcepts ? (
        <NoResultsMessage>
          No related concepts within current filter
        </NoResultsMessage>
      ) : (
        (conceptSearchResults ?? topConcepts.slice(0, 50)).map(concept => {
          return (
            <TopConceptRow
              key={concept.hash()}
              projectId={projectId}
              concept={concept}
              filter={filter}
              trackingItem="concept-detail-pane_select-top-concept"
            />
          );
        })
      )}
    </SidePanelSection>
  );
}

function TopConceptRow({ projectId, concept, filter, trackingItem }) {
  const relatedness = concept.matchScore;
  const [, setHovered] = useHover();
  return (
    <PlainTextButton
      css={css`
        &:hover {
          background: ${Colors.blue0};
        }
      `}
      onClick={selectConcept.bind(null, projectId, concept, filter)}
      onMouseEnter={() => setHovered(concept)}
      onMouseLeave={() => setHovered()}
      data-test-id="top-concepts-results-row"
      data-tracking-item={trackingItem}
    >
      <DetailsListRow
        left={concept.name}
        right={
          relatedness && (
            <>{(relatedness > 0.99 ? 1 : relatedness).toFixed(2)}</>
          )
        }
      />
    </PlainTextButton>
  );
}

function PlaceholderConcept() {
  const width = useRandom(4, 7);
  return <PlaceholderText style={{ width: `${width}rem` }} />;
}
