import React from 'react';
import { css } from '@emotion/core';

import Alert from '../../components/core/Alert';
import { TabLink } from '../TabTitles';
import {
  useActiveConceptList,
  useSharedListWithActiveListName
} from '../hooks';

export function UnsharedChangesAlert() {
  return (
    <Alert
      type="error"
      css={css`
        margin: 0.5rem;
      `}
    >
      <div>
        We’re not sure which shared concept list you’d like to use. Share your
        current active concepts or select an existing list under:
      </div>
      <TabLink tab="activeConcepts" />
    </Alert>
  );
}

export function useActiveConceptListHasUnsharedChanges() {
  const activeList = useActiveConceptList();
  const matchingList = useSharedListWithActiveListName();
  return !activeList.equals(matchingList);
}
