import _ from 'lodash';

export class Bubble {
  static fromIssue(issue) {
    return new Bubble(issue.id, [issue]);
  }

  static fromGroup(group, issues) {
    return new Bubble(group.id, issues, group);
  }

  constructor(id, issues, group = null) {
    this.id = id;
    this.issues = issues;
    this.group = group;
  }

  get type() {
    const types = _.uniq(this.issues.filter(i => i.showing).map(i => i.type));
    return types.length === 2 ? 'both' : types[0];
  }

  hasVisibleIssueOfType(type) {
    return ['both', type].includes(this.type);
  }
}

export class Bubbles {
  static fromIssuesAndGroups(issues, groups) {
    const [standaloneIssues, groupIssues] = _.partition(issues, {
      groupKey: undefined
    });
    const issuesByGroupKey = _.groupBy(groupIssues, 'groupKey');
    return new Bubbles([
      ...standaloneIssues.map(Bubble.fromIssue),
      ...groups.map(g => Bubble.fromGroup(g, issuesByGroupKey[g.key] ?? []))
    ]);
  }

  constructor(bubbles) {
    this.bubbles = bubbles;
    this.byId = _.keyBy(bubbles, 'id');
  }

  getBubble(bubbleId) {
    return this.byId[bubbleId];
  }

  map(func) {
    return this.bubbles.map(func);
  }

  getNextBubbleWithIssueOfType(selectedBubbleId, type) {
    const issuesOfType = this.bubbles.filter(
      b => b.id === selectedBubbleId || b.hasVisibleIssueOfType(type)
    );
    const currentIndex = _.findIndex(issuesOfType, { id: selectedBubbleId });
    const nextIndex = (currentIndex + 1) % issuesOfType.length;
    return issuesOfType[nextIndex];
  }

  getIssueCount(type) {
    const counts = this.bubbles.map(
      bubble => _.filter(bubble.issues, { type, showing: true }).length
    );
    return _.sum(counts);
  }

  get errorCount() {
    return this.getIssueCount('error');
  }

  get warningCount() {
    return this.getIssueCount('warning');
  }
}
