import React from 'react';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Colors, Mixins } from '../../../main/scripts/styles';
import { Concept } from '../classes/Concepts';
import ColoredCircle from './ColoredCircle';

const Hovered = styled.div`
  color: ${Colors.gray5};
  align-self: flex-end;
`;

export default function ConceptView({
  concept,
  hovered,
  isDisabled = false,
  isSelected,
  className,
  ...passedProps
}) {
  const compoundTexts = concept.texts.join(', '),
    sameName = concept.name === compoundTexts,
    compoundConcept = concept.texts.length > 1;

  let showTextsNode = true,
    showNameNode = true,
    conceptTitle = concept.name;

  if (!sameName) {
    conceptTitle = concept.name + ' (' + compoundTexts + ')';
  } else if (compoundConcept) {
    showNameNode = false;
    conceptTitle = '(' + concept.name + ')';
  } else {
    showTextsNode = false;
  }

  const conceptTextsNodeStyles = css`
    color: ${Colors.gray3};
    font-style: italic;
    ${Mixins.ellipsify};
    /* Apply a flex-basis of 0 such that all available space is first
     * allocated to the concept name and a flex-grow of 1 so that any
     * remaining space is allocated to the concept's texts. */
    flex: 1;
  `;

  const isDisabledConceptViewStyles = css`
    pointer-events: none;
    color: ${Colors.gray1};
    opacity: 0.5;
  `;
  const isSelectedConceptViewStyles = css`
    background: ${Colors.blue0};
    border: 1px solid ${Colors.blue1};
  `;
  const conceptViewStyles = css`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    font-size: 0.875rem;
    padding: 0.5em;
    align-items: center;
    flex: 1;
    min-width: 0;
    &:hover ${Hovered} {
      display: none;
    }
    ${isSelected && isSelectedConceptViewStyles}
    ${isDisabled && isDisabledConceptViewStyles}
  `;
  const conceptTitleStyles = css`
    margin-left: 0.5rem;
    flex: 1;
    min-width: 0;
    user-select: text;
    display: flex;
    gap: 0.25em; // fake a " " between the name and the texts
  `;

  const conceptTextsNode = (
    <span css={conceptTextsNodeStyles}>({compoundTexts})</span>
  );
  const conceptNameNode = <span css={Mixins.ellipsify}>{concept.name}</span>;

  return (
    <div
      {...passedProps}
      css={conceptViewStyles}
      data-test-id="concept-view"
      data-test-active={isSelected}
      data-test-disabled={isDisabled}
    >
      <div>
        <ColoredCircle color={concept.color} />
      </div>
      <span title={conceptTitle} css={conceptTitleStyles}>
        {showNameNode && conceptNameNode}
        {showTextsNode && conceptTextsNode}
      </span>
      {hovered && (
        <Hovered>
          {hovered.getAssociation(concept)?.toFixed(2) ?? 'N/A'}
        </Hovered>
      )}
    </div>
  );
}

ConceptView.propTypes = {
  concept: PropTypes.instanceOf(Concept).isRequired,
  hovered: PropTypes.instanceOf(Concept),
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool
};
