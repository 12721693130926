import { css } from '@emotion/core';
import React from 'react';

import { useSearchParams } from '../search_params';
import { BubbleInput } from '../components/core/BubbleInput';
import { Checkbox } from '../components/core/Checkbox';

export function SyncScoreFields() {
  const { searchParams, updateSearch } = useSearchParams();
  const includingConceptual = searchParams.match_type !== 'exact';

  return (
    <fieldset
      css={css`
        margin: 0.5rem 0;
      `}
    >
      <BubbleInput
        as={Checkbox}
        label="Sync concepts score field and axis score field"
        checked={searchParams.sync_fields === 'yes'}
        onChange={() => {
          const newSyncFieldsValue =
            searchParams.sync_fields === 'yes' ? 'no' : 'yes';

          const updates = { sync_fields: newSyncFieldsValue };

          // sync the two dropdowns if we have just activated sync
          if (newSyncFieldsValue === 'yes') {
            updates.field = searchParams.drivers_of;
          }

          updateSearch(updates);
        }}
      />
      <BubbleInput
        as={Checkbox}
        label="Include conceptual matches"
        checked={includingConceptual}
        onChange={() => {
          updateSearch({ match_type: includingConceptual ? 'exact' : 'total' });
        }}
      />
    </fieldset>
  );
}
