import _ from 'lodash';

import { CategoricalField } from './MetadataFields';
import { Concept } from './Concepts';
import { naturalSortBy } from '../utils/NaturalSort';
import formatDateInterval from '../utils/formatDateInterval';
import { filterBreakdown } from '../utils/filterBreakdown';

export class Buckets {
  /**
   * @param {Object} breakdown - Object returned from API that has a breakdown
   *                             and bucket fields
   * @param {Array.<MetadataField>} metadata
   * @param {Array.<Constraint>} filter
   */
  constructor(
    { breakdown = {}, buckets = [] } = {},
    metadata = [],
    filter = []
  ) {
    const applyConstraint = getApplyConstraint(breakdown, metadata, filter);
    const sortedBuckets = naturalSortBuckets(buckets);
    const formatBucketLabel = bucket =>
      formatLabel(bucket.label, breakdown.interval);

    this.labels = applyConstraint(_.map(sortedBuckets, formatBucketLabel));
    this.totals = applyConstraint(_.map(sortedBuckets, 'total_count'));
    this.matchCountsByConcept = _.mapValues(
      groupMatchCountsByConcept(sortedBuckets),
      applyConstraint
    );
  }

  /**
   * Get a Buckets object from the breakdowns value returned by the API
   *
   * @param {Array.<Objects>} breakdowns - "breakdown" property of object
   *                                       returned by match counts API
   * @param {Array.<MetadataField>} metadata
   * @param {Array.<Constraint>} filter
   *
   * @returns {Buckets}
   */
  static fromAPIBreakdowns(breakdowns, metadata, filter) {
    const breakdown = !_.isEmpty(breakdowns) ? breakdowns[0] : undefined;
    return new Buckets(breakdown, metadata, filter);
  }

  /**
   * @param {Object} concept
   *
   * @returns {Array.<Object>} - Array of objects with exact and total counts
   */
  getMatchCountFor(concept) {
    return this.matchCountsByConcept[concept.hash()] || [];
  }

  /**
   * Gets the normalized array of match counts for a concept
   *
   * @param {Object} concept
   * @returns {Array.<Object>} - Array of normalized match count objects
   */
  getNormalizedMatchCountsFor(concept) {
    return this.getMatchCountFor(concept).map(({ exact, total }, i) => ({
      exact: exact / (this.totals[i] || 1),
      total: total / (this.totals[i] || 1)
    }));
  }
}

function naturalSortBuckets(buckets) {
  return [...buckets].sort(naturalSortBy('label'));
}

function groupMatchCountsByConcept(buckets) {
  const bucketsByConcept = {};

  for (const bucket of buckets) {
    for (const concept of bucket.match_counts) {
      const hash = concept.hash();
      const matchCount = Concept.getMatchCount(concept);

      if (!bucketsByConcept[hash]) {
        bucketsByConcept[hash] = [];
      }

      bucketsByConcept[hash].push(matchCount);
    }
  }

  return bucketsByConcept;
}

function formatLabel(label, interval) {
  return interval && Number.isNaN(parseFloat(interval))
    ? formatDateInterval(label, interval)
    : `${label}`;
}

function naturalSortCategoricalField({ name, values }) {
  return new CategoricalField(name, values.sort(naturalSortBy('value')));
}

function sortField(field) {
  return field instanceof CategoricalField
    ? naturalSortCategoricalField(field)
    : field;
}

function getApplyConstraint(breakdown, metadata, filter) {
  const field = metadata.find(field => field.name === breakdown.name);
  const sortedField = sortField(field);
  const constraint = filter.find(({ name }) => name === breakdown.name);

  return array =>
    constraint
      ? filterBreakdown(sortedField, constraint, array, breakdown.interval)
      : array;
}
