import React from 'react';
import PropTypes from 'prop-types';
import { scaleBand, scaleLinear } from 'd3-scale';
import { max, min, range } from 'd3-array';

import { Colors } from '../styles';
import { Label } from './Label';
import { VisualizationLegend } from './VisualizationLegend';
import { getLongestTextLength } from './utils';
import { GridLineXAxis } from '../components/GridLineXAxis';

export function MostPrevalentConceptsVisualization({
  width,
  height,
  concepts
}) {
  const columnGap = 16;
  const longestLabelWidth = getLongestTextLength(
    concepts.map(({ name }) => name),
    '0.875rem',
    'bold'
  );

  const labelWidth = min([180, longestLabelWidth]);
  const chartStart = labelWidth + columnGap;
  const chartWidth = width - chartStart;
  const chartLegendHeight = 30;
  const chartHeight = height - chartLegendHeight;

  const getMatchCount = concept => concept.matchCount;
  const getExactMatchCount = concept => concept.exactMatchCount;

  const yScale = scaleBand()
    .domain(range(concepts.length + 1))
    .rangeRound([0, chartHeight])
    .padding(0.25);

  const xScale = scaleLinear()
    .domain([0, max(concepts, getMatchCount)])
    .range([0, chartWidth]);

  // Should we have a better way of handling the case of width or height
  // being 0?
  if (width <= 0 || chartHeight <= 0) {
    return null;
  }

  return (
    <svg
      display="block"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="none"
    >
      {concepts.map((concept, index) => {
        return (
          <g key={concept.hash()}>
            <Label
              name={concept.name}
              x={0}
              y={yScale(index + 1) + yScale.bandwidth() / 2}
              maxWidth={labelWidth}
              fontWeight="bold"
            />
            <Bar
              width={xScale(getMatchCount(concept))}
              height={yScale.bandwidth()}
              x={chartStart}
              y={yScale(index + 1)}
              color={Colors.blue2}
            />
            <Bar
              width={xScale(getExactMatchCount(concept))}
              height={yScale.bandwidth()}
              x={chartStart}
              y={yScale(index + 1)}
              color={Colors.blue4}
            />
          </g>
        );
      })}
      <GridLineXAxis
        domain={[0, max(concepts, getMatchCount)]}
        x1={chartStart}
        y1={yScale(0) + yScale.bandwidth() / 2}
        x2={chartStart + chartWidth}
        y2={chartHeight - yScale.bandwidth() / 2}
        yScale={yScale}
      />
      <VisualizationLegend
        width={width}
        y={chartHeight + chartLegendHeight / 2}
        leftKeyColor={Colors.blue4}
        leftKeyLabel="Exact matches"
        rightKeyColor={Colors.blue2}
        rightKeyLabel="Conceptual matches"
      />
    </svg>
  );
}

MostPrevalentConceptsVisualization.propTypes = {
  width: PropTypes.number.isRequired,
  concepts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      matchCount: PropTypes.number.isRequired,
      exactMatchCount: PropTypes.number.isRequired
    }).isRequired
  ).isRequired
};

function Bar({ width, height, x, y, color }) {
  return <rect width={width} height={height} x={x} y={y} fill={color} />;
}

Bar.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
};
