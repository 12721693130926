import React from 'react';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';

import { Button } from './core/Button';
import { Colors } from '../styles';
import { Icon, IconTypes } from './icons';

export default function FilterToolHeader({
  expandedFields,
  disableClearFilter,
  onClear
}) {
  const handleClick = expandedFields.all
    ? expandedFields.collapseAll
    : expandedFields.expandAll;

  return (
    <div
      css={css`
        display: flex;
        padding: 0.5rem;
        justify-content: space-between;
        border-bottom: 2px solid ${Colors.gray2};
      `}
    >
      <Button
        onClick={handleClick}
        css={css`
          svg {
            margin-right: 0.25rem;
            fill: none;
          }

          &:hover svg,
          &:active svg {
            fill: none;
          }
        `}
      >
        <Icon
          type={IconTypes.CARET_STROKE}
          direction={expandedFields.all ? 'right' : 'down'}
          size="14"
        />
        {expandedFields.all ? 'Collapse all' : 'Expand all'}
      </Button>
      <Button
        disabled={disableClearFilter}
        onClick={onClear}
        data-tracking-item="filter-pane_clear-filter-button"
      >
        <Icon type={IconTypes.ROTATE} size="18" />
        Clear all
      </Button>
    </div>
  );
}

FilterToolHeader.propTypes = {
  expandedFields: PropTypes.shape({
    all: PropTypes.bool.isRequired,
    expandAll: PropTypes.func.isRequired,
    collapseAll: PropTypes.func.isRequired
  }),
  disableClearFilter: PropTypes.bool.isRequired,
  onClear: PropTypes.func.isRequired
};
