import _ from 'lodash';

import { MetadataTypes } from '../constants';

export class Column {
  static columnsFromHeaders(headers) {
    return headers.map(Column.fromHeader);
  }

  static fromHeader(header, index) {
    const trimmedHeader = header.trim();
    let originalType;
    let name;
    let type;
    const searchResults = typeAndNameRegExp.exec(trimmedHeader);
    if (searchResults != null) {
      // If the column is annotated with a type and a name, use them
      name = searchResults.groups.name;
      type = searchResults.groups.type.toLowerCase();
      originalType = type;
    } else if (/^(title|text)$/i.test(trimmedHeader)) {
      // Title or Text columns are based on name, not annotation
      name = trimmedHeader;
      type = trimmedHeader.toLowerCase();
      originalType = type;
    } else {
      // If no annotation or standard name is found, leave the type blank so
      // that the autodetection will suggest something appropriate
      name = trimmedHeader;
      type = null;
      originalType = null;
    }
    return new Column(name, type, index, originalType);
  }

  constructor(name, type, index, originalType) {
    this.name = name;
    this.type = type;
    this.originalType = originalType;
    this.index = index;
    this.empty = true;
  }

  get group() {
    return this.name === ''
      ? `index-${this.index}`
      : `name-${this.name.toLowerCase()}`;
  }

  get isBlank() {
    return this.empty && this.name === '';
  }

  update(updates = {}) {
    const newColumn = new Column(
      this.name,
      this.type,
      this.index,
      this.originalType
    );
    newColumn.empty = this.empty;
    for (const key of Object.keys(updates)) {
      newColumn[key] = updates[key];
    }
    return newColumn;
  }
}

const typeAndNameRegExp = new RegExp(
  `^(?<type>${_.values(MetadataTypes).join('|')})_(?<name>.+)$`,
  'i'
);
