import { css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Colors, Mixins } from '../../styles';
import Tooltip from '../../components/core/Tooltip';
import InlineEditor from '../../components/InlineEditor';
import {
  selectedHeaderStyles,
  shouldUseSkippedHeaderStyles,
  skippedHeaderStyles
} from '../headerStyles';
import { PlaintextWhenDisabledDropdown } from '../PlaintextWhenDisabledDropdown';
import { FilterPreview } from './FilterPreview';
import { Issue } from './Issue';
import { bubblePanelItemStyles } from './bubbleStyles';
import { Bubble } from './Bubbles';

const COLUMN_TYPES = [
  'text',
  'title',
  'number',
  'score',
  'date',
  'string',
  'skip'
];

export const GroupBubble = React.forwardRef(function GroupBubble(
  {
    bubble,
    stats,
    onDismissIssue,
    onChangeColumnType,
    onSelectGroup,
    selected,
    hovered,
    onHoverGroup,
    onRenameGroup
  },
  ref
) {
  const { group, issues } = bubble;

  return (
    // Wrap a div around the bubble because scrolling doesn't account for
    // box-shadow/border and would end up not scrolling to the right place
    <div ref={ref} data-issue-type={bubble.type} data-bubble-id={bubble.id}>
      <div
        css={css`
          ${bubblePanelItemStyles};
          cursor: pointer;
          padding: 0.5rem;
          position: relative;

          em {
            color: ${Colors.gray5};
          }

          > :not(:first-child) {
            margin-top: 0.5rem;
          }

          ${hovered &&
          css`
            background: ${Colors.blue0};
          `}
        `}
        onMouseEnter={() => onHoverGroup(group)}
        onMouseLeave={() => onHoverGroup(null)}
        onClick={() => onSelectGroup(group)}
        tabIndex="0"
        onKeyDown={event => {
          if (event.key === 'Enter' || event.key === '') {
            onSelectGroup(group);
          }
        }}
        data-tracking-item="upload-page_bubble"
        data-is-selected={selected || undefined}
      >
        <div
          css={css`
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            word-break: break-word;
          `}
        >
          <InlineEditor
            onChange={name => onRenameGroup(group, name)}
            value={group.name}
            blankValue="No header"
            action="Rename"
            name={`all columns named "${group.name}"`}
            placeholder="Type a new name"
            ellipsify={false}
            css={[
              css`
                word-break: break-word;
                font-weight: bold;
                margin-right: 0.5rem;
                /* Make input take up the full width */
                flex: 1;
                input {
                  width: 100%;
                }
              `,
              shouldUseSkippedHeaderStyles(group, stats) && skippedHeaderStyles,
              selected && selectedHeaderStyles
            ]}
          />
          <TypeTooltip
            typeCanBeChanged={group.typeCanBeChanged}
            alternateMessage={group.frozenTypeReason}
          >
            <PlaintextWhenDisabledDropdown
              css={css`
                /* prevent the select from shrinking when names are long */
                width: unset;
              `}
              editable={
                group.name === 'Question' ? false : group.typeCanBeChanged
              }
              aria-label={`Type of "${group.name}"`}
              value={group.type}
              onChange={newType => {
                onChangeColumnType(group.id, newType);
              }}
              options={COLUMN_TYPES.map(t => ({
                value: t,
                name: _.upperFirst(t)
              }))}
              promptOption="Select a type"
              data-tracking-item="upload-page_bubble-type-select"
            />
          </TypeTooltip>
        </div>
        <GroupIssues issues={issues} onDismiss={onDismissIssue} />
        <FilterPreview group={group} stats={stats} />
      </div>
    </div>
  );
});

GroupBubble.propTypes = {
  bubble: PropTypes.instanceOf(Bubble).isRequired,
  stats: PropTypes.object,
  onDismissIssue: PropTypes.func.isRequired,
  onChangeColumnType: PropTypes.func.isRequired,
  onSelectGroup: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  hovered: PropTypes.bool.isRequired,
  onHoverGroup: PropTypes.func.isRequired,
  onRenameGroup: PropTypes.func.isRequired
};

function TypeTooltip({ children, typeCanBeChanged, alternateMessage }) {
  if (!typeCanBeChanged) {
    return (
      <Tooltip
        // This is a workaround for a bug in Chrome which prevents certain mouse
        // events from firing on disabled elements. The workaround works by
        // using a pseudo element to cover the content inside the anchor and
        // receive mouse events instead. For more information on the bug, see
        // https://bugs.chromium.org/p/chromium/issues/detail?id=1121675 and
        // https://github.com/facebook/react/issues/4251.
        css={css`
          position: relative;
          display: inline-block;
          &::after {
            content: '';
            ${Mixins.fillViewport};
          }
        `}
        anchor={children}
        position="right"
        tooltipWidth="20rem"
      >
        {alternateMessage}
      </Tooltip>
    );
  }
  return (
    <Tooltip
      anchor={children}
      position="right"
      tooltipWidth="20rem"
      delayLength={2500}
    >
      <ul
        css={css`
          padding-left: 1rem;
        `}
      >
        <li>
          <TypeSummary type="text">
            Words Daylight will analyze and use to find concepts.
          </TypeSummary>
        </li>
        <li>
          <TypeSummary type="title">Name of document.</TypeSummary>
        </li>
        <li>
          <TypeSummary type="number">
            Numbers only, no words allowed.
          </TypeSummary>
        </li>
        <li>
          <TypeSummary type="score">
            Numbers you want to be higher, such as net promoter scores or client
            counts.
          </TypeSummary>
        </li>
        <li>
          <TypeSummary type="date">In format 2020-12-30.</TypeSummary>
        </li>
        <li>
          <TypeSummary type="string">
            Catch-all type for any other data, such as short answers, lists, or
            text that shouldn’t be analyzed for concepts.
          </TypeSummary>
        </li>
        <li>
          <TypeSummary type="skip">
            Skipped data will not be uploaded to your project.
          </TypeSummary>
        </li>
      </ul>
    </Tooltip>
  );
}

function TypeSummary({ type, children }) {
  return (
    <>
      <strong>{_.upperFirst(type)}</strong>: {children}
    </>
  );
}

function GroupIssues({ issues = [], onDismiss }) {
  return (
    <div
      css={css`
        > :not(:first-child) {
          margin-top: 0.5rem;
        }
      `}
    >
      {issues.map((issue, index) => {
        return <Issue issue={issue} key={index} onDismiss={onDismiss} />;
      })}
    </div>
  );
}
