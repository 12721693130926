import React, { useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ErrorTypes, RoutePatterns } from '../constants';
import buildRoutePath from '../utils/buildRoutePath';
import SimpleModal from './core/SimpleModal';

const ProjectErrorModal = ({ error }) => {
  const { workspaceId, projectId } = useParams();

  const backLinkRef = useRef();
  let headerMessage, bodyMessage;
  switch (error.code) {
    case ErrorTypes.PROJECT_EMPTY:
      headerMessage = 'This project is empty';
      bodyMessage = "Upload the data you'd like to visualize first.";
      break;
    default:
      headerMessage = 'Error';
      bodyMessage = error.message;
      break;
  }

  return (
    <SimpleModal
      className="project-error-modal"
      isOpen={true}
      onAfterOpen={() => backLinkRef.current?.focus()}
      header={<h3 className="project-error-modal__header">{headerMessage}</h3>}
    >
      <p className="project-error-modal__body">{bodyMessage}</p>
      <div className="project-error-modal__footer">
        <a ref={backLinkRef} className="project-error-modal__link" href="/">
          « Back
        </a>
        {error.code === ErrorTypes.PROJECT_EMPTY && (
          <Link
            className="project-error-modal__link"
            to={buildRoutePath(RoutePatterns.UPLOAD_DATA, {
              workspaceId,
              projectId
            })}
          >
            Upload
          </Link>
        )}
      </div>
    </SimpleModal>
  );
};

ProjectErrorModal.propTypes = {
  error: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.string
  }).isRequired
};

export default ProjectErrorModal;
