import React from 'react';

export default function LuminosoLogoIcon(props) {
  return (
    <svg width="1" height="1" viewBox="0 0 65.61 64" {...props}>
      <g>
        <polygon
          fill="#0cbef0"
          points="44.55 31 44.55 30.99 40.92 35.51 40.92 35.51 40.92 35.51 40.92 35.54 47.4 64 65.61 41.17 44.55 31"
        />
        <polygon
          fill="#01582d"
          points="39.31 28.47 38.02 22.84 38 22.85 32.81 25.34 32.81 25.34 39.31 28.47 39.31 28.47 39.31 28.47"
        />
        <polygon
          fill="#42854b"
          points="44.52 30.98 44.52 30.98 39.33 28.48 39.31 28.47 39.32 28.5 39.32 28.51 40.92 35.51 44.55 30.99 44.55 30.99 44.52 30.98"
        />
        <polygon
          fill="#6dbd46"
          points="51.04 22.85 51.04 22.86 51.02 22.84 51.02 22.83 51.02 22.84 44.55 31 65.61 41.17 51.04 22.85"
        />
        <polygon
          fill="#517bbc"
          points="40.92 35.54 40.92 35.54 40.92 35.51 40.92 35.51 18.19 64 18.19 64 18.19 64 47.4 64 40.92 35.54"
        />
        <polygon
          fill="#048041"
          points="38 22.85 38.02 22.84 39.31 28.47 39.33 28.48 39.33 28.48 44.52 30.98 44.52 30.98 44.52 30.98 44.55 30.99 44.55 30.99 44.55 31 51.02 22.84 47.43 18.33 47.43 18.33 47.42 18.32 47.42 18.31 47.42 18.31 47.42 18.31 38.02 22.83 38 22.84 38 22.85"
        />
        <polygon
          fill="#ab9b3e"
          points="47.43 18.33 47.43 18.33 51.02 22.84 51.02 22.83 51.02 22.84 59.09 12.67 47.42 18.32 47.42 18.32 47.43 18.33"
        />
        <polygon
          fill="#6dbd46"
          points="32.81 0 32.81 0 38.02 22.83 47.42 18.31 32.83 0.02 32.83 0.02 32.83 0.02 32.83 0.02 32.83 0.02 32.81 0 32.81 0 32.81 0 32.81 0 32.81 0 32.81 0 32.81 0 32.81 0 32.81 0"
        />
        <polygon
          fill="#517bbc"
          points="32.81 25.34 32.79 25.34 27.62 22.84 27.6 22.83 27.6 22.83 26.31 28.47 26.31 28.47 26.31 28.47 26.32 28.47 32.81 25.34"
        />
        <polygon
          fill="#1069a1"
          points="39.32 28.51 39.32 28.51 39.32 28.5 39.31 28.47 32.81 25.34 32.81 25.34 32.79 25.33 32.79 25.34 32.81 25.34 26.32 28.47 26.32 28.47 18.19 64 18.19 64 40.92 35.51 40.92 35.51 39.32 28.51"
        />
        <polygon
          fill="#fab962"
          points="18.19 64 18.19 64 26.32 28.47 26.31 28.47 26.31 28.47 26.31 28.47 26.31 28.47 11.72 35.5 11.72 35.5 11.72 35.5 11.71 35.51 11.72 35.53 11.72 35.53 11.72 35.53 18.19 63.97 18.19 64 18.19 64 18.19 64 18.19 64"
        />
        <polygon
          fill="#fde46a"
          points="27.6 22.83 27.6 22.83 27.6 22.83 18.21 18.31 18.21 18.31 18.2 18.32 10.11 28.47 10.11 28.5 11.71 35.51 11.72 35.5 11.72 35.5 11.72 35.5 26.31 28.47 26.31 28.47 27.6 22.83"
        />
        <polygon
          fill="#026836"
          points="27.62 22.84 32.79 25.34 32.79 25.33 32.81 25.34 38 22.85 38 22.84 38.02 22.83 32.81 0 32.81 0.03 32.8 0.06 27.6 22.83 27.62 22.84 27.62 22.84"
        />
        <polygon
          fill="#bcd557"
          points="32.83 0.02 32.83 0.02 32.83 0.02 32.83 0.02 47.42 18.31 47.42 18.31 47.42 18.31 47.42 18.31 47.42 18.32 59.09 12.67 51.02 22.84 51.04 22.86 51.04 22.85 65.61 41.17 59.09 12.67 59.09 12.67 59.09 12.67 32.81 0 32.83 0.02 32.83 0.02 32.83 0.02"
        />
        <polygon
          fill="#bcd557"
          points="32.78 0.04 18.21 18.31 18.21 18.31 18.21 18.31 18.21 18.31 18.21 18.31 27.6 22.83 32.8 0.06 32.8 0.06 32.8 0.03 32.81 0 32.81 0 32.81 0 32.81 0 32.78 0.04"
        />
        <polygon
          fill="#f8a954"
          points="11.72 35.53 11.72 35.53 11.71 35.51 0 41.15 0 41.15 18.18 63.98 18.18 63.98 18.19 63.97 11.72 35.53 11.72 35.53"
        />
        <polygon
          fill="#fab962"
          points="11.71 35.51 10.11 28.5 10.11 28.5 10.11 28.47 0.03 41.11 0 41.15 0 41.15 0 41.15 11.71 35.51"
        />
        <polygon
          fill="#ee552c"
          points="0.03 41.11 10.11 28.47 18.2 18.32 18.2 18.32 18.21 18.31 18.21 18.31 18.21 18.31 6.5 12.67 6.5 12.67 0 41.15 0.03 41.11 0.03 41.11"
        />
        <polygon fill="#b66d3a" points="6.5 12.67 18.21 18.31 32.78 0.04" />
      </g>
    </svg>
  );
}
