import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { StoreContext } from '../StoreContext';
import { ErrorTypes } from '../constants';
import { loadProject } from '../actions';
import { useSearchParams } from '../search_params';
import { Concept } from '../classes/Concepts';
import { RebuildModal, CancelLink } from '../project_management/RebuildModal';
import ProjectErrorModal from './ProjectErrorModal';
import { ProjectBuildingPage } from './ProjectBuildingPage';
import { useConceptManagement } from '../data_hooks';
import { AuthContext } from '../settings/model';

/**
 * If the project information has not been loaded into the store, then this
 * component is rendered instead of the explorer tool. It renders one of:
 * - the rebuild modal
 * - the project error modal
 * - the project building page
 * depending on what the projectError in the store is
 */
const ProjectInexplorable = () => {
  const { project, projectError } = useContext(StoreContext);
  const { projectId } = useParams();
  const { searchParams } = useSearchParams();
  const { search, filter } = searchParams;
  const { conceptManagement } = useConceptManagement(project);
  const {
    profile: { username },
    serverStatus
  } = useContext(AuthContext);

  switch (projectError.code) {
    case ErrorTypes.PROJECT_NEEDS_UPDATING:
      return (
        <RebuildModal
          forcedRebuild={true}
          project={project}
          userEmail={username}
          serverStatus={serverStatus}
          cancelButton={<CancelLink linkTo="/" />}
          onAfterRebuild={() => {
            loadProject(projectId, Concept.fromString(search), filter);
          }}
          conceptManagement={conceptManagement}
          onHide={() => {}}
        />
      );
    case ErrorTypes.PROJECT_NOT_READY:
      return <ProjectBuildingPage />;
    default:
      return <ProjectErrorModal error={projectError} />;
  }
};

export default ProjectInexplorable;
