import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { AlertTypes } from '../constants';
import {
  CategoricalField,
  NumericField,
  ScoreField
} from '../classes/MetadataFields';
import { DiscreteConstraint } from '../classes/Constraints';
import Alert from './core/Alert';
import { Checkbox } from './core/Checkbox';
import List from './core/List';
import CategoryAutosuggest from './CategoryAutosuggest';
import CategoryView from './CategoryView';
import FilterSelector from './FilterSelector';
import { naturalSortBy } from '../utils/NaturalSort';

const AUTOSUGGEST_THRESHOLD = 100; // if there are more rows than this, use the CategoryAutosuggest

export default class FilterSelectorCategorical extends React.Component {
  constructor(props) {
    super(props);
    this.onClear = this.onClear.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onDeselect = this.onDeselect.bind(this);
    this.onToggleSelectAll = this.onToggleSelectAll.bind(this);
  }

  onClear() {
    if (!this.props.constraint.isEmpty()) {
      this.props.updateConstraint([]);
    }
  }

  onSelect(value) {
    this.props.updateConstraint(this.props.constraint.values.concat(value));
  }

  onDeselect(value) {
    this.props.updateConstraint(_.without(this.props.constraint.values, value));
  }

  onToggleSelectAll() {
    if (
      this.props.constraint.values.length === this.props.field.values.length
    ) {
      this.props.updateConstraint([]);
    } else {
      this.props.updateConstraint(
        this.props.field.values.reduce(
          (values, { value }) => values.concat(value),
          []
        )
      );
    }
  }

  render() {
    // The UI for this component changes at a few different numbers of categories:
    // * <= AUTOSUGGEST_THRESHOLD categories:
    //   show each in its own row with a checkbox & include a "Select all" option
    //
    // * AUTOSUGGEST_THRESHOLD < categories <= Categorical field max # values:
    //   an autosuggest component is shown instead, w/ no "Select all"
    //
    // * > Categorical field max # values:
    //   If there are too many categories, then the API will return none at all.
    //   In this case, show a message indicating that there are too many
    //   categories to interact with in the UI
    const numRows =
      (this.props.field.values && this.props.field.values.length) || 0;

    if (0 < numRows && numRows <= AUTOSUGGEST_THRESHOLD) {
      return (
        <FilterSelector
          constraint={this.props.constraint}
          onClear={this.onClear}
          onToggleSelectAll={
            this.props.enableSelectAllFields
              ? this.onToggleSelectAll
              : undefined
          }
          selectAllChecked={
            this.props.constraint.values.length ===
            this.props.field.values.length
          }
          expanded={this.props.expanded}
          toggleExpanded={this.props.toggleExpanded}
        >
          <List className="filter-selector__container">
            {[...this.props.field.values]
              .sort(naturalSortBy('value'))
              .map(category => {
                const { value } = category;
                const checked = this.props.constraint.values.includes(value);
                return (
                  <label
                    className="filter-selector__label filter-selector__label--categorical"
                    key={value}
                  >
                    <CategoryView category={category} />
                    <Checkbox
                      checked={checked}
                      className="filter-selector__checkbox"
                      onChange={() => {
                        if (this.props.constraint.values.includes(value)) {
                          this.onDeselect(value);
                        } else {
                          this.onSelect(value);
                        }
                      }}
                      data-tracking-item={
                        checked
                          ? 'filter-pane_selector-input_checkbox_uncheck'
                          : 'filter-pane_selector-input_checkbox_check'
                      }
                    />
                  </label>
                );
              })}
          </List>
        </FilterSelector>
      );
    }

    if (numRows > AUTOSUGGEST_THRESHOLD) {
      const [selectedCategories, unselectedCategories] = _.partition(
        this.props.field.values,
        ({ value }) => this.props.constraint.values.includes(value)
      );
      return (
        <FilterSelector
          constraint={this.props.constraint}
          onClear={this.onClear}
          expanded={this.props.expanded}
          toggleExpanded={this.props.toggleExpanded}
        >
          <CategoryAutosuggest
            onDeselect={this.onDeselect}
            onSelect={this.onSelect}
            selectedCategories={selectedCategories}
            unselectedCategories={unselectedCategories}
          />
        </FilterSelector>
      );
    }

    return (
      <FilterSelector
        constraint={this.props.constraint}
        expanded={this.props.expanded}
        toggleExpanded={this.props.toggleExpanded}
      >
        <div className="filter-selector__over-category-limit-msg">
          <Alert type={AlertTypes.INFO}>Sorry, too many to display</Alert>
        </div>
      </FilterSelector>
    );
  }
}

FilterSelectorCategorical.propTypes = {
  constraint: PropTypes.instanceOf(DiscreteConstraint).isRequired,
  field: PropTypes.oneOfType([
    PropTypes.instanceOf(CategoricalField),
    PropTypes.instanceOf(NumericField),
    PropTypes.instanceOf(ScoreField)
  ]).isRequired,
  updateConstraint: PropTypes.func.isRequired,
  enableSelectAllFields: PropTypes.bool,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired
};
