import React from 'react';
import PropTypes from 'prop-types';
import { zoom, zoomIdentity } from 'd3-zoom';
import { select, event as d3Event } from 'd3-selection';

export default class ZoomableChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zoomTransform: null
    };
    this.zoomContainer = React.createRef();
    this.handleZoom = this.handleZoom.bind(this);
    this.resetZoom = this.resetZoom.bind(this);
  }

  componentDidMount() {
    this.attachZoomBehavior();
  }

  componentDidUpdate(prevProps, prevState) {
    this.attachZoomBehavior();
    if (prevState.zoomTransform !== null && this.state.zoomTransform === null) {
      select(this.zoomContainer.current).call(zoom().transform, zoomIdentity);
    }
  }

  resetZoom() {
    this.setState({ zoomTransform: null });
  }

  attachZoomBehavior() {
    const { width, height } = this.props;

    select(this.zoomContainer.current)
      .call(
        zoom()
          // The minimum and maximum factors we can zoom in/out by.
          // 1 prevents us from zooming out more than the default state.
          // 100 prevents us from zooming in indefinitely, but can be adjusted
          .scaleExtent([1, 100])
          // Limit how far you can drag to.
          .translateExtent([
            [-100, -100],
            [width + 100, height + 100]
          ])
          .extent([
            [-100, -100],
            [width + 100, height + 100]
          ])
          .on('zoom', this.handleZoom)
          .wheelDelta(() => {
            // Decrease divisor to speed up zoom/pinch scrolling and vice versa
            return (-d3Event.deltaY * (d3Event.deltaMode ? 120 : 1)) / 100;
          })
          .on('end', () => this.setState({ isZooming: false }))
      )
      .on('dblclick.zoom', null); // Disable double click to zoom
  }

  handleZoom() {
    this.setState({ zoomTransform: d3Event.transform, isZooming: true });
  }

  render() {
    return this.props.children({
      zoomTransform: this.state.zoomTransform,
      zoomRef: this.zoomContainer,
      resetZoom: this.resetZoom,
      isZooming: this.state.isZooming
    });
  }
}

ZoomableChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired
};
