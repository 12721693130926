# ported from https://github.com/d3/d3-plugins/tree/master/fisheye

module.exports = {
  circular: ->
    radius = 200
    distortion = 2
    focus = [0, 0]

    k0 = null
    k1 = null

    fisheye = (d) ->
      dx = d.x - focus[0]
      dy = d.y - focus[1]
      dd = Math.sqrt(dx * dx + dy * dy)

      if not dd or dd >= radius then return {x: d.x, y: d.y, z: if dd >= radius then 1 else 10}

      k = k0 * (1 - Math.exp(-dd * k1)) / dd * 0.75 + 0.25

      return {x: focus[0] + dx * k, y: focus[1] + dy * k, z: Math.min(k, 10)}

    rescale = ->
      k0 = Math.exp(distortion)
      k0 = k0 / (k0 - 1) * radius
      k1 = distortion / radius
      return fisheye

    fisheye.radius = (_) ->
      if not arguments.length then return radius
      radius = +_
      return rescale()

    fisheye.distortion = (_) ->
      if not arguments.length then return distortion
      distortion = +_
      return rescale()

    fisheye.focus = (_) ->
      if not arguments.length then return focus
      focus = _
      return fisheye

    return rescale()
}
