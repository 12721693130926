import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { UploadUsageMeter } from '../settings/UsageMeter';
import { AuthContext } from '../settings/model';
import { Colors } from '../styles';

function UploadPageUsage({ workspaceId, uploadCount, uploadCountNoText }) {
  /* There are a few interesting wrinkles around how and when this component is
   * shown.  First, some background:
   * - Most users only have access to one organization.  The only real
   *   exceptions are users within Luminoso, or one or two other organizations
   *   that resell Daylight.
   * - Most organizations have a usage limit, so the user should be shown
   *   a usage meter on the upload page.
   * - Some organizations don't have a usage limit, either because it hasn't
   *   been set up yet or because they've paid for an unmetered license.
   * - For organizations that do have usage limits, the usage report can
   *   sometimes take a few seconds to load.
   *
   * So, there are a few UI annoyances we want to avoid:
   * - We don't want everything else on the page to jump down a few inches
   *   after the usage finishes loading.  That is annoying and disruptive.
   * - We don't want to show a blank space at the top of the page for users
   *   in organizations that don't have a usage limit set.  That would confuse
   *   them and waste valuable space.
   *
   * To reconcile these forces, we have a few different loading states around
   * the page as a whole, and this component in particular:
   * - Until the usage information the the user's home organization has loaded,
   *   we don't show the page at all.  (This is handled in the
   *   UploadAndValidateDatasetPage component).
   * - If the user has selected a workspace in another organization, and the
   *   usage data for that organization is still loading, then we don't show
   *   the meter, but we do show a placeholder that's the same height as the
   *   meter, so that the page won't jump around unnecessarily.
   * - If the usage has finished loading, and there is no limit for the
   *   organization, then we don't show anything at all for this component,
   *   so that it won't take up unnecessary space.  (This will lead to some
   *   jumping for Luminoso internal users when they navigate between
   *   organizations, but that's an acceptable compromise.)
   */

  const { profile } = useContext(AuthContext);
  // Some users don't have a default workspace. Since we can't calculate
  // anything about usage until one is selected, return null in the
  // meantime.
  const orgId = workspaceId
    ? profile.workspacesById[workspaceId].organization_id
    : profile.organizationId;
  const usageReport = profile.organizationsById[orgId]?.getUsageReport();
  if (usageReport == null) {
    return <UsageSection />;
  }

  const billingPeriod = usageReport?.getBillingPeriods()?.[0];
  if (billingPeriod == null || billingPeriod.limit == null) {
    return null;
  }

  return (
    <UsageSection>
      <UploadUsageMeter
        billingPeriod={billingPeriod}
        uploadCount={uploadCount}
        uploadCountNoText={uploadCountNoText}
      />
    </UsageSection>
  );
}

UploadPageUsage.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  uploadCount: PropTypes.number,
  uploadCountNoText: PropTypes.number
};

export default memo(UploadPageUsage);

const UsageSection = styled.div`
  border-bottom: ${Colors.gray2} solid 1px;
  margin: 0 -2rem;
  padding: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
`;
