import _ from 'lodash';

const workspaceExists = (workspaces, id) => {
  if (id) {
    return _.some(workspaces, { workspace_id: id });
  }

  return false;
};

export default function autoSelectWorkspaceId(
  workspaces,
  userDefaultWorkspaceId,
  preferredWorkspaceId
) {
  // If there's only one workspace, select it...
  if (workspaces.length === 1) {
    return workspaces[0].workspace_id;
  }

  if (workspaceExists(workspaces, preferredWorkspaceId)) {
    return preferredWorkspaceId;
  }

  if (workspaceExists(workspaces, userDefaultWorkspaceId)) {
    return userDefaultWorkspaceId;
  }

  return '';
}
