import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { Colors } from '../../styles';

const extendBorderToParent = css`
  margin: 0 -0.5rem;
  padding: 0.5rem;
`;

const border = `1px solid ${Colors.gray0}`;

export const ConfigRadioWrapper = styled.div`
  ${extendBorderToParent};
  &:not(:first-child):not(:last-child) {
    border-bottom: ${border};
  }
`;

export const ConfigSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${extendBorderToParent};
  border-top: ${border};
`;
