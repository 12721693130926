import _ from 'lodash';
import { useContext } from 'react';

import { StoreContext } from '../StoreContext';
import { ActiveConceptList } from './active_concepts/ActiveConceptList';

export function useActiveConceptList() {
  const { activeConceptListName, activeConcepts } = useContext(StoreContext);
  return new ActiveConceptList(activeConceptListName, activeConcepts);
}

export function useSharedListWithActiveListName() {
  const { activeConceptListName, sharedConceptLists } = useContext(
    StoreContext
  );
  return _.find(sharedConceptLists, { name: activeConceptListName });
}
