import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { CategoricalField, DateField } from '../../classes/MetadataFields';
import { Button } from '../../components/core/Button';
import DialogFooter from '../../components/DialogFooter';
import { Checkbox } from '../../components/core/Checkbox';
import FilterTool from '../../components/FilterTool';
import {
  DateRangeConstraint,
  DiscreteConstraint
} from '../../classes/Constraints';
import { Colors } from '../../styles';
import { StoreContext } from '../../StoreContext';

const ALL_DOCS_FILTER = [];

export default function ConceptFilterAssociationsForm({ onDownload }) {
  const [filter, setFilter] = useState([]);
  const { metadata } = useContext(StoreContext);

  function onSubmit(evt) {
    evt.preventDefault();
    // Convert the filter into a list of separate filters. One filter for each date
    // constraint or each value of categorical constraint.
    const filterList = [ALL_DOCS_FILTER].concat(filterToFilterList(filter));
    onDownload(filterList);
  }

  return (
    <form
      onSubmit={onSubmit}
      data-tracking-item="analysis-exports_concept-filter_export-submit"
    >
      <p>
        This file will include, for each active concept, the average association
        between the active concept and each selected filter.
      </p>
      <div>
        <Subtitle>Select filters to compare</Subtitle>
        <AllDocumentsCheckbox />
        <FilterToolContainer>
          <FilterTool
            enableSelectAllFields={true}
            filter={filter}
            metadata={metadata.filter(
              field =>
                field instanceof CategoricalField || field instanceof DateField
            )}
            onChangeFilter={setFilter}
          />
        </FilterToolContainer>
      </div>
      <DialogFooter>
        <Button type="submit">Export</Button>
      </DialogFooter>
    </form>
  );
}

ConceptFilterAssociationsForm.propTypes = {
  onDownload: PropTypes.func.isRequired
};

const filterToFilterList = filter =>
  filter
    .map(constraint => {
      // we only need to deal with categorical and date metadata here for now
      if (constraint instanceof DiscreteConstraint) {
        return constraint.values.map(
          val => new DiscreteConstraint(constraint.name, [val])
        );
      } else if (constraint instanceof DateRangeConstraint) {
        return new DateRangeConstraint(
          constraint.name,
          constraint.minimum,
          constraint.maximum
        );
      }
    })
    .flat()
    .map(constraint => [constraint]);

const AllDocumentsCheckbox = () => {
  return (
    <label
      css={css`
        position: relative;
        left: 1.25rem;
      `}
    >
      <Checkbox
        checked={true}
        // We're always including "All documents" for now
        disabled={true}
      />
      <span
        css={css`
          font-weight: bold;
          margin-left: 0.25rem;
        `}
      >
        All documents (always included)
      </span>
    </label>
  );
};

const FilterToolContainer = styled.div`
  position: relative;
  height: 200px;
  margin: 0.5rem 0;
`;

const Subtitle = styled.span`
  color: ${Colors.gray5};
  font-size: 0.875rem;
  margin: 0.5rem;
  cursor: default;
  user-select: none;
`;
