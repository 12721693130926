import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Colors } from '../styles';

export const NoResultsMessage = styled.div`
  color: ${Colors.gray5};
  font-style: italic;
  font-size: 0.875rem;
`;

NoResultsMessage.propTypes = {
  children: PropTypes.node
};

export const InlineNoResultsMessage = styled.span`
  color: ${Colors.gray5};
  font-style: italic;
`;

InlineNoResultsMessage.propTypes = {
  children: PropTypes.node
};
