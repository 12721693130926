import React, { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { flyoutButtonStyle } from './ActiveConceptEditor';
import { Button } from '../../components/core/Button';

const ColorPicker = ({ color, onChangeColor }) => {
  const [currentColor, setCurrentColor] = useState(color);
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  useEffect(() => {
    setCurrentColor(color);
  }, [color]);
  const handleChangeColor = e => {
    const newValue = e.target.value;
    const isColorValid = /^#[0-9A-Fa-f]{0,6}$/i.test(newValue);

    if (isColorValid) {
      setCurrentColor(newValue);
    }
  };

  const toggleDisplay = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleChange = () => {
    if (currentColor.length > 3) {
      onChangeColor(currentColor);
      setDisplayColorPicker(false);
    }
  };

  return (
    <PickerWrapper>
      <Label>
        <ColorIcon
          style={{ backgroundColor: currentColor }}
          onClick={toggleDisplay}
        />
        <Input value={currentColor} onChange={handleChangeColor} />
      </Label>
      {displayColorPicker && (
        <HexColorPicker color={currentColor} onChange={setCurrentColor} />
      )}
      <Button
        css={flyoutButtonStyle}
        disabled={
          (currentColor.length !== 4 && currentColor.length !== 7) ||
          color === currentColor
        }
        onClick={handleChange}
      >
        Set color
      </Button>
    </PickerWrapper>
  );
};

const PickerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;

  & .react-colorful {
    width: 100%;
  }
`;

const Label = styled.label`
  width: 100%;
  height: 35px;
  margin: 0;
  display: flex;
`;

const ColorIcon = styled.button`
  width: 40px;
  height: 100%;
  border: none;
  outline: none;
  border-bottom-left-radius: 0.125rem;
  border-top-left-radius: 0.125rem;
`;

const Input = styled.input`
  box-sizing: border-box;
  width: calc(100% - 40px);
  margin: 0;
  height: 100%;
  border: none;
  outline: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`;

ColorPicker.propTypes = {
  color: PropTypes.string,
  onChangeColor: PropTypes.func.isRequired
};

export default ColorPicker;
