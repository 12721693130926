import React, { useContext, useRef } from 'react';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { getSentiment } from '../utils/ApiUtilsV5';
import { Colors } from '../styles';
import { ToolCard } from './Cards';
import PlaceholderText from '../components/core/PlaceholderText';
import { getMoreDetailLink } from './utils';
import { RoutePatterns, RequestStatuses } from '../constants';
import { percentify } from '../utils/NumFmtUtils';
import { useBoundingClientRect, useFetch } from '../utils/hooks';
import { WhatPeopleFeelStronglyAboutVisualization } from './WhatPeopleFeelStronglyAboutVisualization';
import { SentimentStatusMessage } from '../sentiment/SentimentStatusMessage';
import { SENTIMENT_STATUS, getSentimentStatus } from '../utils/sentimentStatus';
import { StoreContext } from '../StoreContext';

const conceptSelector = { type: 'sentiment_suggested', limit: 50 };

export function WhatPeopleFeelStronglyAboutCard({ projectId, workspaceId }) {
  const { project } = useContext(StoreContext);
  const sentimentStatus = getSentimentStatus(project);
  const sentimentIsReady = sentimentStatus === SENTIMENT_STATUS.READY;
  const visualization = useRef();
  const { width, height } = useBoundingClientRect(visualization);
  const { status, response } = useFetch(
    sentimentIsReady && getSentiment,
    projectId,
    conceptSelector
  );

  const concepts = _.orderBy(
    response?.matches,
    'exactMatchCount',
    'desc'
  ).slice(0, 15);
  const [conceptA, conceptB] = _.orderBy(
    concepts,
    c => Math.max(c.sentimentShare.positive, c.sentimentShare.negative),
    'desc'
  );

  return (
    <ToolCard
      header="What do people feel strongly about?"
      blurb={
        sentimentIsReady &&
        (status !== RequestStatuses.FULFILLED || concepts.length > 0) && (
          <Blurb conceptA={conceptA} conceptB={conceptB} />
        )
      }
      visualization={
        !sentimentIsReady ? (
          <SentimentStatusMessage
            status={sentimentStatus}
            css={css`
              font-size: 1.25rem;
            `}
          />
        ) : status === RequestStatuses.PENDING ? (
          <ToolCard.VisualizationPlaceholder />
        ) : (
          RequestStatuses.FULFILLED && (
            <div
              ref={visualization}
              css={css`
                height: 100%;
              `}
            >
              {concepts.length > 0 ? (
                <WhatPeopleFeelStronglyAboutVisualization
                  width={width}
                  height={height}
                  concepts={concepts}
                />
              ) : (
                <NoSuggestionsMessage />
              )}
            </div>
          )
        )
      }
      footer={
        <ToolCard.MoreDetailLink
          name="Sentiment feature"
          link={getMoreDetailLink(
            workspaceId,
            projectId,
            RoutePatterns.SENTIMENT,
            { concepts: 'sentiment', sortby: 'matches-desc' }
          )}
          disabled={!sentimentIsReady || status !== RequestStatuses.FULFILLED}
          trackingItem="highlights_more-details-link_feel-strongly"
        />
      }
    />
  );
}

WhatPeopleFeelStronglyAboutCard.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

function Blurb({ conceptA, conceptB }) {
  return (
    <>
      Some concepts like <BlurbConcept concept={conceptA} />{' '}
      {(!conceptA || conceptB) && (
        <>
          and <BlurbConcept concept={conceptB} />{' '}
        </>
      )}
      reflect strong sentiment.
    </>
  );
}

Blurb.propTypes = {
  conceptA: PropTypes.shape({
    name: PropTypes.string.isRequired,
    sentimentShare: PropTypes.shape({
      negative: PropTypes.number.isRequired
    }).isRequired
  }),
  conceptB: PropTypes.shape({
    name: PropTypes.string.isRequired,
    sentimentShare: PropTypes.shape({
      negative: PropTypes.number.isRequired
    }).isRequired
  })
};

function BlurbConcept({ concept }) {
  const name = concept ? (
    <b>
      <bdi>{concept.name}</bdi>
    </b>
  ) : (
    <PlaceholderText style={{ width: '3rem' }} />
  );

  const percentage = concept ? (
    <SentimentPercentage concept={concept} />
  ) : (
    <PlaceholderText style={{ width: '3rem' }} />
  );

  return (
    <>
      "{name}" ({percentage})
    </>
  );
}

BlurbConcept.propTypes = {
  concept: PropTypes.shape({
    name: PropTypes.string.isRequired,
    sentimentShare: PropTypes.shape({
      negative: PropTypes.number.isRequired
    }).isRequired
  })
};

function SentimentPercentage({ concept }) {
  const { positive, negative } = concept.sentimentShare;
  const displayNegative = negative > positive;

  return (
    <span
      css={css`
        color: ${displayNegative ? Colors.red5 : Colors.blue6};
      `}
    >
      {percentify(displayNegative ? negative : positive, 1, 0)}{' '}
      {displayNegative ? 'negative' : 'positive'}
    </span>
  );
}

SentimentPercentage.propTypes = {
  concept: PropTypes.shape({
    sentimentShare: PropTypes.shape({
      negative: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

function NoSuggestionsMessage() {
  return (
    <div
      css={css`
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
      `}
    >
      <div
        css={css`
          text-align: center;
          font-weight: bold;
        `}
      >
        <p>
          We were unable to find any sentiment suggestions for your project.
        </p>
      </div>
    </div>
  );
}
