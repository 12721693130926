import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Icon, IconTypes } from '../../components/icons';
import { Button } from '../../components/core/Button';
import Tooltip from '../../components/core/Tooltip';
import { getCopiedListName } from './activeConceptsUtils';
import { StoreContext } from '../../StoreContext';
import { shareActiveConceptList, undoCreatingConceptList } from '../../actions';
import { useActiveListName } from './activeListName';
import { useDispatchUndoBanner } from '../../UndoBanner';

export default function DuplicateButton() {
  const { projectId } = useParams();
  const {
    activeConceptListName,
    sharedConceptLists,
    activeConcepts
  } = useContext(StoreContext);
  const { hasUnsavedChanges } = useActiveListName();
  const dispatchUndoBanner = useDispatchUndoBanner();

  function duplicateList() {
    const nameToSaveWith = getCopiedListName(
      sharedConceptLists,
      activeConceptListName || 'active concepts'
    );

    shareActiveConceptList(projectId, nameToSaveWith).then(createdList => {
      if (hasUnsavedChanges) {
        const name = activeConceptListName ?? 'unsaved list';
        const createdListId = createdList.concept_list_id;
        dispatchUndoBanner({
          message: `${nameToSaveWith} created\nChanges to ${name} were lost`,
          onUndo: () =>
            undoCreatingConceptList(
              projectId,
              {
                name: activeConceptListName,
                concepts: activeConcepts
              },
              createdListId
            )
        });
      }
    });
  }
  return (
    <Tooltip
      anchor={
        <Button onClick={duplicateList}>
          <Icon type={IconTypes.COPY} /> Duplicate
        </Button>
      }
    >
      Creates a new list with your current active concepts
    </Tooltip>
  );
}
