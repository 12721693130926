import _ from 'lodash';
import * as d3 from 'd3-dsv';

import { MetadataTypes } from '../constants';
import { readExcelFileAsCsv, readNormalizedTextFromFile } from './FileUtils';
import { Column } from './Column';
import { Cell } from './Cell';

function parseDocRow(columns, row) {
  const doc = { metadata: [] };
  const texts = [];
  const titles = [];

  for (let index = 0; index < row.length; index++) {
    const cell = row[index];
    const column = columns[index];

    if (cell.hasValue) {
      // If the column has a valid metadata type
      const { name, type } = column;

      switch (type) {
        case MetadataTypes.CATEGORY:
          for (let value of cell.stringValues) {
            doc.metadata.push({ name, type, value });
          }
          break;

        case MetadataTypes.NUMBER:
        case MetadataTypes.SCORE:
          for (let value of cell.numberValues) {
            doc.metadata.push({ name, type, value });
          }
          break;

        case MetadataTypes.DATE: {
          for (let value of cell.dateValues) {
            doc.metadata.push({ name, type, value });
          }
          break;
        }
        case 'title': {
          titles.push(cell.rawValue);
          break;
        }
        case 'text': {
          texts.push(cell.rawValue);
          break;
        }
        case 'skip':
          break;
      }
    }
  }

  if (texts.length > 0) {
    doc.text = texts.join(' ¶ ');
  }
  if (titles.length > 0) {
    doc.title = titles.join(' / ');
  }

  // remove duplicate metadata values
  doc.metadata = _.uniqWith(doc.metadata, _.isEqual);

  return doc;
}

export function parseFile(file) {
  const extension = _.last(file.name.split('.'));
  return readExcelOrCsv(file).then(text => {
    // parseText is synchronous and will potentially block for a while, so defer its
    // computation until later so that the UI can update.
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          const { rows, columns } = parseFileContents(text, extension);
          resolve({ rows, columns });
        } catch (error) {
          reject(error);
        }
      });
    });
  });
}

function readExcelOrCsv(file) {
  const extension = _.last(file.name.split('.'));
  if (extension === 'xlsx') {
    return readExcelFileAsCsv(file);
  } else {
    return readNormalizedTextFromFile(file);
  }
}

export function parseFileContents(contents, extension = 'csv') {
  extension = extension.toLowerCase();

  let csvRows;
  switch (false) {
    case extension !== 'csv':
      csvRows = d3.csvParseRows(contents);
      break;
    case extension !== 'tsv':
      csvRows = d3.tsvParseRows(contents);
      break;
    case extension.slice(1, 3) !== 'sv':
      // Use a general parser for other .*sv formats (e.g. snowman-separated values)
      csvRows = d3.dsvFormat(extension[0], 'text/plain').parseRows(contents);
      break;
    default:
      // We were given a file extension that we cannot parse, guess at, or even begin to
      // comprehend. So assume it was a CSV and see how far that gets us.
      csvRows = d3.csvParseRows(contents);
  }

  return processCsvRows(csvRows);
}

export function processCsvRows(csvRows) {
  const headers = csvRows.shift();
  const columns = Column.columnsFromHeaders(headers);

  const rows = [];
  for (const row of csvRows) {
    let isEmpty = true;
    const cells = [];

    for (let i = 0; i < row.length; i++) {
      if (columns[i] == null) {
        // Make sure there is a column definition for each cell in the row
        columns.push(new Column('', 'skip', i, null));
      }

      const cell = Cell.fromString(row[i]);
      cells.push(cell);
      if (cell.hasValue) {
        isEmpty = false;
      }
      if (cell.hasValue && columns[i].empty) {
        columns[i] = columns[i].update({ empty: false });
      }
    }
    if (!isEmpty) {
      rows.push(cells);
    }
  }

  return { rows, columns };
}

export function parseDocs(rows, columns) {
  return rows
    .map(row => parseDocRow(columns, row))
    .filter(
      doc =>
        !_.isEmpty(doc.text) ||
        !_.isEmpty(doc.title) ||
        !_.isEmpty(doc.metadata)
    );
}

export function transformByType(
  rows,
  columns,
  questions = false,
  upload = false
) {
  let newRows = [];
  let newColumns = [...columns];

  const textFields = columns
    .filter(
      col =>
        col.type === 'text' && col.name !== 'Text' && col.name !== 'Question'
    )
    .map(col => ({
      name: col.name,
      source: col.name
    }));

  if (upload) {
    const columnsWithTypeText = columns
      .filter(col => /^text(_[a-zA-Z]*)?$/i.test(col.name))
      .map(col => ({
        name: col.name,
        source: col.name
      }));
    if (columnsWithTypeText.length > 1) {
      textFields.push(...columnsWithTypeText);
    } else {
      return { rows, columns };
    }
  }
  // Check for the 'Question' column and get its index
  const textSourceColumnIndex = columns.findIndex(
    col => col.name === 'Question'
  );

  if (textSourceColumnIndex !== -1) {
    // Get header names from the 'Question' column
    const headerNamesFromTextSource = new Set(
      rows
        .map(row => row[textSourceColumnIndex]?.rawValue)
        .filter(value => value)
    );
    // Add to textFields headers that match the values from 'Question'
    columns.forEach(col => {
      if (
        headerNamesFromTextSource.has(col.name) &&
        !textFields.some(tf => tf.name === col.name)
      ) {
        textFields.push({
          name: col.name,
          source: col.name
        });
      }
    });
  }

  if (!textFields.length) {
    return { rows, columns };
  }

  let textIndex = newColumns.findIndex(col => col.name === 'Text');
  if (textIndex === -1) {
    textIndex = newColumns.length;
    newColumns.push(new Column('Text', 'text', textIndex));
  }

  let stringTextSourceIndex = newColumns.findIndex(
    col => col.name === 'Question'
  );
  if (stringTextSourceIndex === -1) {
    stringTextSourceIndex = newColumns.length;
    newColumns.push(new Column('Question', 'string', stringTextSourceIndex));
  }

  // Save existing Question values
  let existingTextSources = rows.map(row =>
    row[stringTextSourceIndex] ? row[stringTextSourceIndex].value : ''
  );

  rows.forEach((row, rowIndex) => {
    textFields.forEach(textField => {
      const textCellIndex = columns.findIndex(
        col => col.name === textField.name
      );
      if (textCellIndex > -1 && row[textCellIndex].hasValue) {
        let newRow = [...row]; // Clone the existing row

        // Update or add the 'Text' value
        newRow[textIndex] = new Cell(row[textCellIndex].rawValue);

        // Concatenate new text source with existing ones
        let newTextSourceValue = textField.source;
        if (existingTextSources[rowIndex]) {
          newTextSourceValue =
            existingTextSources[rowIndex] + '; ' + newTextSourceValue;
        }
        newRow[stringTextSourceIndex] = new Cell(newTextSourceValue);

        newRows.push(newRow);
      }
    });
  });
  newRows = removeDuplicateRows(newRows);
  // Update the columns to reflect the possible new 'Text' column
  newColumns = newColumns.map((col, index) => {
    const isEmpty = newRows.every(row => !row[index]?.hasValue);
    const type =
      col.name === 'Text' ? 'text' : col.type === 'text' ? 'string' : col.type;
    return col.update({ index, empty: isEmpty, type });
  });

  if (questions && questions.length) {
    const nameUpdatesMap = new Map(
      questions.map(item => [item.oldName, item.newName])
    );

    // Iterate over each row
    newRows = newRows.map(row => {
      // Iterate over each cell in the row
      return row.map(cell => {
        // Check if the cell's rawValue matches any oldName from the questions
        const newName = nameUpdatesMap.get(cell.rawValue);
        if (newName) {
          // If it matches, create a new Cell with the newName
          return new Cell(
            newName,
            cell.hasValue,
            cell.stringValues,
            cell._numberValues
          );
        }
        // Otherwise, return the cell unchanged
        return cell;
      });
    });
  }

  return {
    rows: newRows,
    columns: newColumns
  };
}
function removeDuplicateRows(rows) {
  const uniqueRows = [];
  const seenRows = new Set();

  rows.forEach(row => {
    const rowString = JSON.stringify(row.map(cell => cell?.rawValue));
    if (!seenRows.has(rowString)) {
      seenRows.add(rowString);
      uniqueRows.push(row);
    }
  });

  return uniqueRows;
}
