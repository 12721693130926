import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import { Mixins } from '../styles';
import SelectedConcept from './SelectedConcept';
import { MatchingDocuments } from './MatchingDocuments';

export const SearchBar = memo(function SearchBar({
  onInputTextChange,
  searchMatchesSelection
}) {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1rem;
        ${Mixins.shadowOutset};
        ${Mixins.roundedCorners};
      `}
    >
      <SelectedConcept
        onInputTextChange={onInputTextChange}
        selectedConceptMatchesSearch={searchMatchesSelection}
      />
      <MatchingDocuments />
    </div>
  );
});

SearchBar.propTypes = {
  onInputTextChange: PropTypes.func.isRequired,
  searchMatchesSelection: PropTypes.bool.isRequired
};
