import React from 'react';
import css from '@emotion/css';

import { Icon } from '../../components/icons';
import { Colors } from '../../styles';

const TileHeader = ({ label, icon }) => {
  return (
    <div
      css={css`
        display: flex;
        gap: 10px;
      `}
    >
      {label}
      <Icon
        css={css`
          color: ${Colors.blue4};
        `}
        size="16"
        type={icon}
      />
    </div>
  );
};

export default TileHeader;
