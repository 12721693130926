import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';

import Spinner from '../components/core/Spinner';
import { center } from '../styles';
import { loadProject } from '../actions';
import { Concept } from '../classes/Concepts';
import { useSearchParams } from '../search_params';

export function ProjectBuildingPage() {
  const { projectId } = useParams();
  const { searchParams } = useSearchParams();

  useEffect(() => {
    const reloadProject = () => {
      // No need to pass the science version here since we only render this
      // component if the project is being rebuilt
      loadProject(
        projectId,
        Concept.fromString(searchParams.search),
        searchParams.filter
      );
    };
    const handle = setInterval(reloadProject, 5000);

    return () => {
      clearInterval(handle);
    };
  }, [projectId, searchParams]);

  return <ProjectBuildingMessage />;
}
export function ProjectBuildingMessage() {
  return (
    <Container>
      <Message fontSize="large">
        <Spinner /> We're building your project.
      </Message>
      <Message>
        This may take a few minutes. In the meantime, you can{' '}
        <a href="/">go back</a> to the Projects page and check the status there,
        or wait here and we'll take you to your project when it's ready.
      </Message>
    </Container>
  );
}

const Container = styled.div`
  ${center};
  max-width: 50%;
  text-align: center;
`;
const Message = styled.p`
  margin: 0.5rem 0;
  font-size: ${({ fontSize }) => (fontSize === 'large' ? '1' : '0.875')}rem;
`;
