import d3 from 'd3';

// Adapted to Coffeescript and bounding-box only from d3-cloud
// Jason Davies, http://www.jasondavies.com/word-cloud/
// Algorithm due to Jonathan Feinberg, http://static.mrfeinberg.com/bv_ch03.pdf

const c = document.createElement('canvas').getContext('2d');

const archimedeanSpiral = function (size) {
  const e = size[0] / size[1];
  return function (t) {
    t *= 0.5;
    return [e * t * Math.cos(t), t * Math.sin(t)];
  };
};

const cloudStartx = () => undefined;

const cloudStarty = () => undefined;

const cloudText = d => d.name;

const cloudFont = () => 'serif';

const cloudFontSize = d => Math.sqrt(d.value);

// This function is the return value of the outer function
export default function mathcloud() {
  let size = [256, 256];
  let text = cloudText;
  let font = cloudFont;
  let fontSize = cloudFontSize;
  let words = [];
  const timeInterval = 150;
  const event = d3.dispatch('word', 'end');
  let timer = null;
  const cloud = {};
  let startx = cloudStartx; // Yuriedit
  let starty = cloudStarty; // Yuriedit
  let data = null; // Yuriedit

  // Deal with the ugly bit math.
  const bitMath = function (board, tag) {
    const x0 = tag.x0 + tag.x;
    const x1 = tag.x1 + tag.x;
    const y0 = tag.y0 + tag.y;
    const y1 = tag.y1 + tag.y;
    if (x0 < 0 || y0 < 0 || x1 > size[0] || y1 > size[1]) {
      return false;
    }

    const incr = size[0] >> 5;
    for (let apply of [false, true]) {
      for (let i = x0 >> 5; i <= (x1 - 1) >> 5; i++) {
        let mask = ~0;
        const dx0 = x0 - (i << 5);
        if (dx0 > 0) {
          mask >>>= dx0;
        }
        const dx1 = x1 - (i << 5);
        if (dx1 < 32) {
          mask &= ~(~0 >>> dx1);
        }
        for (let j = y0; j < y1; j++) {
          if (apply) {
            board[j * incr + i] |= mask;
          } else if (board[j * incr + i] & mask) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const place = function (board, tag) {
    let dxdy;
    const startX = tag.x;
    const startY = tag.y;
    const eccentricity = size[0] / size[1];
    const maxDelta = Math.sqrt(size[0] * size[0] + size[1] * size[1]);
    const s = archimedeanSpiral(size);
    const dt = -1;
    let t = -dt;

    while ((dxdy = s((t += dt)))) {
      const dx = ~~dxdy[0];
      const dy = ~~dxdy[1];

      if (Math.min(dx, dy) > maxDelta) {
        break;
      }
      tag.x = Math.floor(
        (startX * (1 + (dx / 100) * eccentricity) + dx + size[0]) >> 1
      );
      tag.y = Math.floor((startY * (1 + dy / 100) + dy + size[1]) >> 1);
      if (bitMath(board, tag)) {
        return true;
      }
    }
    return false;
  };

  cloud.start = function () {
    let i;
    const board = [
      [...Array(Math.ceil((size[0] >> 5) * size[1]))].map(() => 0)
    ];
    let bounds = null;
    const n = words.length;
    i = -1;
    const tags = [];

    const step = function () {
      const start = Date.now();
      while (Date.now() - start < timeInterval && timer && ++i < n) {
        const d = data[i];
        d.x =
          d.startx != null ? d.startx : (size[0] * (Math.random() + 0.5)) >> 1;
        d.y =
          d.starty != null ? d.starty : (size[1] * (Math.random() + 0.5)) >> 1;
        if (place(board, d)) {
          tags.push(d);
          event.word(d);
          if (bounds) {
            const b0 = bounds[0];
            const b1 = bounds[1];
            if (d.x + d.x0 < b0.x) {
              b0.x = d.x + d.x0;
            }
            if (d.y + d.y0 < b0.y) {
              b0.y = d.y + d.y0;
            }
            if (d.x + d.x1 > b1.x) {
              b1.x = d.x + d.x1;
            }
            if (d.y + d.y1 > b1.y) {
              b1.y = d.y + d.y1;
            }
          } else {
            bounds = [
              { x: d.x + d.x0, y: d.y + d.y0 },
              { x: d.x + d.x1, y: d.y + d.y1 }
            ];
          }
          // Temporary hack
          d.x -= size[0] >> 1;
          d.y -= size[1] >> 1;
        }
      }

      if (i >= n) {
        cloud.stop();
        return event.end(tags, bounds);
      }
    };

    if (!data) {
      data = words.map(function (d, i) {
        return {
          text: text.call(this, d, i),
          font: font.call(this, d, i),
          size: ~~fontSize.call(this, d, i),
          startx: ~~startx(d),
          starty: ~~starty(d),
          datum: d
        };
      });
      data.sort((a, b) => b.size - a.size);

      for (let d of data) {
        c.font = d.size + 1 + 'px ' + d.font;
        const w = (c.measureText(d.text).width + 3) >> 1;
        d.x0 = -w;
        d.x1 = w;
        d.y0 = -1 - d.size;
        d.y1 = 1 - ~~(-0.3 * d.size);
      }
    }

    if (timer) {
      clearInterval(timer);
    }
    timer = setInterval(step, 0);
    step();

    return cloud;
  };

  cloud.stop = function () {
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
    return cloud;
  };

  cloud.words = function (x) {
    if (!arguments.length) {
      return words;
    }
    words = x;
    data = null; // Yuriedit
    return cloud;
  };

  cloud.size = function (x) {
    if (!arguments.length) {
      return size;
    }
    size = [+x[0], +x[1]];
    data = null; // Yuriedit
    return cloud;
  };

  cloud.font = function (x) {
    if (!arguments.length) {
      return font;
    }
    font = d3.functor(x);
    return cloud;
  };

  cloud.text = function (x) {
    if (!arguments.length) {
      return text;
    }
    text = d3.functor(x);
    return cloud;
  };

  cloud.startx = function (x) {
    if (!arguments.length) {
      return startx;
    }
    startx = d3.functor(x);
    data = null; // Yuriedit
    return cloud;
  };

  cloud.starty = function (x) {
    if (!arguments.length) {
      return starty;
    }
    starty = d3.functor(x);
    data = null; // Yuriedit
    return cloud;
  };

  cloud.fontSize = function (x) {
    if (!arguments.length) {
      return fontSize;
    }
    fontSize = d3.functor(x);
    return cloud;
  };

  return d3.rebind(cloud, event, 'on');
}
