import React, { useContext } from 'react';
import { css } from '@emotion/core';

import { StoreContext } from '../StoreContext';
import { Bold } from './Bold';
import { useSystemStatus } from '../systemStatus';
import { objHasPerm } from '../utils/common';
import InlineEditor from './InlineEditor';
import { editProject } from '../utils/ApiUtilsV5';
import { editProject as editProjectAction, notifier } from '../actions';
import { Colors } from '../styles';
import ScienceChangeLog from './ScienceChangeLog';
import { formatTimestamp, getProjectLanguageName } from '../utils/projectUtils';

export function ProjectDetail({
  showExtraInfo = false,
  showScienceChangeLog = false,
  baseStyle = null
}) {
  const { workspaces, project } = useContext(StoreContext);
  const systemStatus = useSystemStatus();

  if (systemStatus == null) {
    return null;
  }

  const workspace = workspaces.find(
    workspace => workspace.workspace_id === project.workspace_id
  );

  if (!project || !workspace) {
    return null;
  }

  const languageName = getProjectLanguageName(systemStatus, project);

  let scienceVersion = project.last_build_info.science_version;
  if (systemStatus.gpu && project.last_build_info.sentiment?.skip_sentiment) {
    scienceVersion += ' (built without sentiment)';
  }

  return (
    <div css={baseStyle}>
      <p>
        Created <Bold>{formatTimestamp(project.creation_date)}</Bold> by{' '}
        <Bold>{project.creator}</Bold>
      </p>
      <p>
        Workspace: <Bold>{workspace.name}</Bold>
      </p>
      <p>
        Documents: <Bold>{project.totalDocCount}</Bold>
      </p>
      <p>
        Language: <Bold>{languageName}</Bold>
      </p>
      <p
        css={css`
          display: inline-flex;
          flex-wrap: wrap;
          gap: 0 0.5rem;
        `}
      >
        <span>
          Science version: <Bold>{scienceVersion}</Bold>
        </span>
        {showScienceChangeLog && <ScienceChangeLog />}
      </p>
      {showExtraInfo ? (
        <>
          <p>
            Total Documents:{' '}
            <Bold>{project.document_count.toLocaleString()}</Bold>
          </p>
          <p>
            Last Built:{' '}
            <Bold>{formatTimestamp(project.last_successful_build_time)}</Bold>
          </p>
        </>
      ) : null}
    </div>
  );
}

export function ProjectName({ withoutWriteStyle, withWriteStyle }) {
  const { project } = useContext(StoreContext);

  // specify default styles
  withoutWriteStyle =
    withoutWriteStyle ||
    css`
      font-size: 1.5rem;
      word-break: break-word;
      font-weight: normal;
      display: inline;
      padding: 0.5rem;
    `;

  withWriteStyle =
    withWriteStyle ||
    css`
      font-size: 1.5rem;
      word-break: break-word;
      margin-bottom: 0.5rem;
      /* Add some space between the cancel button and the upload data buttons */
      margin-right: 2rem;
      button {
        font-size: 1rem;
      }
      input {
        width: 100%;
      }
    `;

  if (!objHasPerm(project, 'write')) {
    return <h3 css={withoutWriteStyle}>{project?.name}</h3>;
  }

  return (
    <InlineEditor
      onChange={newName => {
        const { project_id, description } = project;
        editProject(project_id, { name: newName })
          .then(() => editProjectAction(project_id, newName, description))
          .catch(() => notifier.error("Can't update the project"));
      }}
      value={project?.name}
      name="project name"
      ellipsify={false}
      css={withWriteStyle}
      required
    />
  );
}

export function ProjectDescription({ withoutWriteStyle, withWriteStyle }) {
  const { project } = useContext(StoreContext);

  // specify default styles
  withoutWriteStyle =
    withoutWriteStyle ||
    css`
      color: ${Colors.gray8};
      word-break: break-word;
      white-space: pre-wrap;
      padding: 0.5rem;
      margin-top: 0.5rem;
    `;

  withWriteStyle =
    withWriteStyle ||
    css`
      color: ${Colors.gray8};
      word-break: break-word;
      textarea {
        width: 100%;
      }
      /* Line up with name editor */
      margin-right: 2rem;
    `;

  if (!objHasPerm(project, 'write')) {
    if (!project?.description) {
      return null;
    }

    return <div css={withoutWriteStyle}>{project.description}</div>;
  }

  return (
    <InlineEditor
      onChange={newDescription => {
        const { project_id, name } = project;
        editProject(project_id, { description: newDescription })
          .then(() => editProjectAction(project_id, name, newDescription))
          .catch(() => notifier.error("Can't update the project"));
      }}
      value={project?.description}
      name="project description"
      multiline
      blankValue="No description"
      ellipsify={false}
      css={withWriteStyle}
    />
  );
}
