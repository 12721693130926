import { css, keyframes } from '@emotion/core';
import React from 'react';

const shimmer = keyframes`
  0% {
    background-position: 0 0%;
  }
  100% {
    background-position: -75rem 0%;
  }
`;

const PlaceholderText = props => {
  return (
    <span
      className="placeholder-text"
      css={css`
        display: inline-block;
        height: 0.7rem;
        max-width: 50rem;
        background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.1)
        );
        background-size: 150rem;
        animation: ${shimmer} 1s linear infinite;
      `}
      {...props}
    />
  );
};

export default PlaceholderText;
