import React from 'react';
import PropTypes from 'prop-types';
import { scaleBand } from 'd3-scale';
import { range, min } from 'd3-array';

import { Colors } from '../styles';
import { Label } from './Label';
import getTextWidth from '../utils/getTextWidth';
import { percentify } from '../utils/NumFmtUtils';
import { VisualizationLegend } from './VisualizationLegend';
import { centerTextY, getLongestTextLength } from './utils';

export function WhatPeopleFeelStronglyAboutVisualization({
  width,
  height,
  concepts
}) {
  const columnGap = 16;
  const longestLabelWidth = getLongestTextLength(
    concepts.map(({ name }) => name),
    '0.875rem',
    'bold'
  );

  const labelWidth = min([180, longestLabelWidth]);
  const chartStart = labelWidth + columnGap;
  const chartWidth = width - chartStart;
  const chartLegendHeight = 30;
  const chartHeight = height - chartLegendHeight;

  const yScale = scaleBand()
    .domain(range(concepts.length))
    .rangeRound([0, chartHeight])
    .padding(0.25);

  if (width <= 0 || chartHeight <= 0) {
    return null;
  }

  return (
    <svg
      display="block"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="none"
    >
      {concepts.map((concept, index) => (
        <g key={concept.hash()}>
          <Label
            name={concept.name}
            x={0}
            y={centerTextY(index, yScale)}
            fontWeight="bold"
            maxWidth={labelWidth}
          />
          <SentimentBars
            concept={concept}
            x={chartStart}
            y={yScale(index)}
            width={chartWidth}
            height={yScale.bandwidth()}
          />
        </g>
      ))}
      <VisualizationLegend
        width={width}
        y={chartHeight + chartLegendHeight / 2}
        leftKeyColor={Colors.red2}
        leftKeyLabel="Negative matches"
        rightKeyColor={Colors.blue2}
        rightKeyLabel="Positive matches"
      />
    </svg>
  );
}

WhatPeopleFeelStronglyAboutVisualization.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  concepts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      sentimentShare: PropTypes.shape({
        negative: PropTypes.number.isRequired,
        positive: PropTypes.number.isRequired
      }).isRequired
    }).isRequired
  ).isRequired
};

function SentimentBars({ concept, x, y, width, height }) {
  const halfGap = 1;
  const textPadding = 10;
  const textWidth = getTextWidth('100%', '0.875rem', 'bold');
  const availableBarSpace = width / 2 - halfGap - textWidth - textPadding;
  const negativeWidth = concept.sentimentShare.negative * availableBarSpace;
  const positiveWidth = concept.sentimentShare.positive * availableBarSpace;
  const center = x + width / 2;

  const negativePercentage = percentify(concept.sentimentShare.negative, 1, 0);
  const negativeTextWidth = getTextWidth(
    negativePercentage,
    '0.875rem',
    'bold'
  );

  return (
    <g>
      <Label
        x={center - negativeWidth - halfGap - negativeTextWidth - textPadding}
        y={y + 2 + height / 2}
        fill={Colors.red5}
        name={negativePercentage}
      />
      <rect
        x={center - negativeWidth - halfGap}
        y={y}
        width={negativeWidth}
        height={height}
        fill={Colors.red2}
      />
      <rect
        x={center + halfGap}
        y={y}
        width={positiveWidth}
        height={height}
        fill={Colors.blue2}
      />
      <Label
        x={center + halfGap + positiveWidth + textPadding}
        y={y + 2 + height / 2}
        fill={Colors.blue6}
        name={percentify(concept.sentimentShare.positive, 1, 0)}
      />
    </g>
  );
}
