import React, { useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import { Colors } from '../../styles';
import { ColumnGroup } from '../ColumnGroup';
import Spinner from '../../components/core/Spinner';
import { scrollToElement } from '../../utils/scrollToElement';
import { usePrevious } from '../../utils/hooks';
import { Bubbles } from './Bubbles';
import { IssuesSummary } from './IssueSummary';
import { Issue } from './Issue';
import { bubblePanelItemStyles } from './bubbleStyles';
import { GroupBubble } from './GroupBubble';

export const BubblePanel = React.forwardRef(function BubblePanel(
  {
    analysisInProgress,
    groupStats,
    bubbles,
    onChangeColumnType,
    onSelectGroup,
    onDismissIssue,
    selectedGroup,
    hoveredGroup,
    onHoverGroup,
    onRenameGroup
  },
  ref
) {
  const scrollContainerRef = useRef();
  const bubbleRefs = useRef({});
  const [selectedBubbleId, selectBubble] = useSelectedBubble(
    selectedGroup,
    onSelectGroup
  );

  useImperativeHandle(ref, () => ({
    scrollToGroup(groupId) {
      scrollToElement(bubbleRefs.current[groupId]);
    }
  }));

  function goToNextBubble(type) {
    const nextBubble = bubbles.getNextBubbleWithIssueOfType(
      selectedBubbleId,
      type
    );
    selectBubble(nextBubble);
    scrollToElement(bubbleRefs.current[nextBubble.id]);
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-right: 1rem;
        /* Apply an arbitrary width so things don't look bad */
        width: 33vw;
        /* Don't let it get too wide */
        max-width: 30rem;
        /* Add spacing between items */
        > :not(:last-child) {
          margin-bottom: 0.5rem;
        }

        [data-is-selected] {
          outline: 2px auto ${Colors.blue4};
          h6 {
            color: ${Colors.blue4};
          }
        }
      `}
    >
      {analysisInProgress ? (
        <AnalysisInProgressMessage />
      ) : (
        <IssuesSummary
          bubbles={bubbles}
          onClickNextError={() => goToNextBubble('error')}
          onClickNextWarning={() => goToNextBubble('warning')}
        />
      )}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          overflow: scroll;
          word-wrap: break-word;
        `}
        ref={scrollContainerRef}
      >
        {bubbles.map(bubble => {
          const groupProps = bubble.group && {
            onChangeColumnType,
            hoveredGroup,
            onRenameGroup,
            onHoverGroup,
            onSelectGroup,
            stats: groupStats[bubble.group.key]
          };

          return (
            <BubbleElement
              ref={el => (bubbleRefs.current[bubble.id] = el)}
              key={bubble.id}
              selected={selectedBubbleId === bubble.id}
              bubble={bubble}
              onDismissIssue={onDismissIssue}
              {...groupProps}
            />
          );
        })}
      </div>
    </div>
  );
});

BubblePanel.propTypes = {
  analysisInProgress: PropTypes.bool.isRequired,
  bubbles: PropTypes.instanceOf(Bubbles).isRequired,
  groupStats: PropTypes.object.isRequired,
  onChangeColumnType: PropTypes.func.isRequired,
  onSelectGroup: PropTypes.func.isRequired,
  onDismissIssue: PropTypes.func.isRequired,
  selectedGroup: PropTypes.instanceOf(ColumnGroup),
  hoveredGroup: PropTypes.instanceOf(ColumnGroup),
  onHoverGroup: PropTypes.func.isRequired,
  onRenameGroup: PropTypes.func.isRequired
};

function AnalysisInProgressMessage() {
  return (
    <div
      css={css`
        min-height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        > :not(:first-child) {
          margin-left: 0.5rem;
        }
      `}
    >
      <Spinner size="small" />
      <span>Analyzing...</span>
    </div>
  );
}

function useSelectedBubble(selectedGroup, onSelectGroup) {
  const [selectedStandaloneIssueId, setSelectedStandaloneIssueId] = useState(
    null
  );

  const prevSelectedGroup = usePrevious(selectedGroup);
  // if we've selected a new group, clear the selected standalone issue
  if (
    selectedStandaloneIssueId &&
    selectedGroup &&
    prevSelectedGroup?.id !== selectedGroup.id
  ) {
    setSelectedStandaloneIssueId(null);
  }
  const selectedBubbleId = selectedGroup?.id ?? selectedStandaloneIssueId;

  function selectBubble(bubble) {
    if (bubble.group) {
      // Select the next group issue
      onSelectGroup(bubble.group);
      setSelectedStandaloneIssueId(null);
    } else {
      // Select the next standalone issue
      onSelectGroup(null);
      setSelectedStandaloneIssueId(bubble.id);
    }
  }

  return [selectedBubbleId, selectBubble];
}

const BubbleElement = React.forwardRef(function BubbleElement(
  { bubble, onDismissIssue, selected, ...groupProps },
  ref
) {
  if (bubble.group) {
    const { hoveredGroup, ...otherGroupProps } = groupProps;

    return (
      <GroupBubble
        ref={ref}
        bubble={bubble}
        onDismissIssue={onDismissIssue}
        selected={selected}
        hovered={hoveredGroup === bubble.group}
        {...otherGroupProps}
      />
    );
  }

  return (
    <StandaloneIssueBubble
      ref={ref}
      bubble={bubble}
      onDismissIssue={onDismissIssue}
      selected={selected}
    />
  );
});

const StandaloneIssueBubble = React.forwardRef(function StandaloneIssueBubble(
  { bubble, onDismissIssue, selected },
  ref
) {
  const [issue] = bubble.issues;

  return (
    <Issue
      ref={ref}
      data-issue-type={issue.showing ? issue.type : undefined}
      data-bubble-id={issue.showing ? bubble.id : undefined}
      data-is-selected={selected || undefined}
      issue={issue}
      onDismiss={onDismissIssue}
      css={bubblePanelItemStyles}
    />
  );
});
