import React from 'react';
import _ from 'lodash';
import styled from '@emotion/styled';
import moment from 'moment';

import naturalSort from '../utils/NaturalSort';
import { InlineNoResultsMessage } from '../side_panel/NoResultsMessage';
import { thousandify } from '../utils/NumFmtUtils';

export const DocMetadata = ({ docMetadata, fieldsToDisplay }) => {
  return (
    <>
      {fieldsToDisplay
        .map(field => field.name)
        .sort(naturalSort)
        .map(name => (
          <FieldItem key={name}>
            <strong>{name}:</strong>
            {_.some(docMetadata, { name }) ? (
              <span>{getValuesString(docMetadata, name)}</span>
            ) : (
              <InlineNoResultsMessage>None</InlineNoResultsMessage>
            )}
          </FieldItem>
        ))}
    </>
  );
};

const FieldItem = styled.li`
  line-height: 1.4;
  margin-bottom: 1rem;

  /* Move the value below the field name when wrapping */
  display: flex;
  flex-wrap: wrap;
  > :first-child {
    margin-right: 0.5rem;
  }
`;

const getValuesString = (metadata, name) => {
  return _.filter(metadata, { name }).map(formatMetadataValue).join(', ');
};

const formatMetadataValue = ({ type, value }) => {
  switch (type) {
    case 'date':
      return moment.utc(value).format('MMMM D, Y');
    case 'number':
    case 'score':
      return thousandify(value);
    default:
      return value;
  }
};
