import React from 'react';
import _ from 'lodash';
import css from '@emotion/css';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import { getAPIStatus, getMetadata, getProject } from '../utils/ApiUtilsV5';
import { loadProject } from '../actions';
import * as storage from '../utils/storage';
import * as CsvUtils from './CsvUtils';
import FileDropZone from '../components/FileDropZone';
import { Button } from '../components/core/Button';
import UploadPageUsage from './UploadPageUsage';
import { thousandify } from '../utils/NumFmtUtils';
import UploadPreview from './UploadPreview';
import { naturalSortByName } from '../utils/NaturalSort';
import ExternalLink from '../components/core/ExternalLink';
import { AlertTypes, RoutePatterns, URLs } from '../constants';
import { Colors, Mixins, standardRadius } from '../styles';
import { PlaintextWhenDisabledDropdown } from './PlaintextWhenDisabledDropdown';
import { useUniqueId } from '../utils/hooks';
import InlineEditor from '../components/InlineEditor';
import Alert from '../components/core/Alert';
import { AuthContext } from '../settings/model';
import Spinner from '../components/core/Spinner';
import { BubblePanel } from './BubblePanel/BubblePanel';
import {
  makeError,
  makeWarning,
  validateDataset,
  validateGroup
} from './validation';
import { ColumnGroup } from './ColumnGroup';
import GroupStatsWorkerInterface from './group_stats/GroupStatsWorkerInterface';
import JobTracker from './group_stats/JobTracker';
import { scrollToElement } from '../utils/scrollToElement';
import { updateColumnsWithMissingTypes } from './autodetection';
import { Bubbles } from './BubblePanel/Bubbles';
import { Icon, IconTypes } from '../components/icons';
import SendEmailSelector from './SendEmailSelector';
import SentimentSelector from './SentimentSelector';
import RebuildUploadPageModal from './RebuildUploadPageModal';
import { transformByType } from './CsvUtils';
import DisplayStreamData from './DisplayStreamData/DisplayStreamData';
import { getFeatureFlags } from '../featureFlagsSingleton';

const grouplessIssuesKey = Symbol();

export class UploadAndValidateDatasetPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      prevUploadToWorkspaceId: props.uploadToWorkspaceId,
      prevUploadToProjectId: props.uploadToProjectId,

      projectId: '',
      metadata: [],

      defaultSelectedWorkspace: '',
      selectedWorkspace: '',

      languages: [],
      selectedLanguage: '',

      selectedFile: null,
      parseError: '',
      rows: null,
      columns: null,
      groups: [],
      groupStats: {},
      dismissedWarnings: {},
      parseInProgress: false,
      analysisInProgress: false,

      name: '',

      description: '',

      supportEmail: '',
      sendEmail: true,

      numFilesSelected: 0,

      selectedGroupId: null,
      hoveredGroupId: null,
      skip_sentiment: false,
      isModalOpen: false,

      dashboardBuild: false,

      showLanguageError: false,
      showWorkspaceError: false
    };

    this.firstSelectedColumnRef = React.createRef();
    this.bubblePanelRef = React.createRef();
    this.groupStatsJobTracker = new JobTracker();

    this.onSelectFile = this.onSelectFile.bind(this);
    this.onClearFileSelection = this.onClearFileSelection.bind(this);
    this.handleChangeColumnType = this.handleChangeColumnType.bind(this);
    this.onSelectGroup = this.onSelectGroup.bind(this);
    this.onHoverGroup = this.onHoverGroup.bind(this);
    this.onSelectColumn = this.onSelectColumn.bind(this);
    this.onHoverColumn = this.onHoverColumn.bind(this);
    this.handleDismissIssue = this.handleDismissIssue.bind(this);
    this.onRenameGroup = this.onRenameGroup.bind(this);
    this.onRenameColumn = this.onRenameColumn.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const projectHasChanged =
      props.uploadToWorkspaceId !== state.prevUploadToWorkspaceId ||
      props.uploadToProjectId !== state.prevUploadToProjectId;

    return {
      prevUploadToWorkspaceId: props.uploadToWorkspaceId,
      prevUploadToProjectId: props.uploadToProjectId,
      loading: projectHasChanged || state.loading
    };
  }

  componentDidMount() {
    const defaultWorkspaceId = this.getDefaultWorkspaceId();
    this.setState({
      selectedWorkspace: defaultWorkspaceId,
      defaultSelectedWorkspace: defaultWorkspaceId
    });

    const featureFlags = getFeatureFlags();

    this.setState({
      dashboardBuild: featureFlags.less_clicks_btn
    });

    Promise.all([getAPIStatus(), this.getTargetProject(this.props)]).then(
      ([{ languages, support_email }]) => {
        this.setState({
          languages,
          supportEmail: support_email,
          sendEmail: !!support_email,
          loading: false
        });
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.loading && this.state.loading) {
      this.getTargetProject(this.props).finally(() => {
        this.setState({ loading: false });
      });
    }
  }

  componentWillUnmount() {
    this.groupStatsJobTracker.clearJobs();
  }

  getFilteredWorkspaces() {
    return this.context.profile.workspacesForPermission('create');
  }

  getDefaultWorkspaceId() {
    const { profile } = this.context;
    const workspacesWithCreatePerm = this.getFilteredWorkspaces();

    if (!this.props.creating) {
      // If we're uploading to an existing project, use that project's workspace
      return this.props.uploadToWorkspaceId;
    }

    // If there's only one valid workspace, just use that
    if (workspacesWithCreatePerm.length === 1) {
      return workspacesWithCreatePerm[0].workspace_id;
    }

    // If the user has filtered the project list page to a specific workspace,
    // try and use that.
    const projectListWorkspaceId = storage.load('projectListWorkspaceId');
    if (
      _.find(workspacesWithCreatePerm, { workspace_id: projectListWorkspaceId })
    ) {
      return projectListWorkspaceId;
    }

    // Otherwise, if the user has a default workspace, try to use that
    if (
      _.find(workspacesWithCreatePerm, {
        workspace_id: profile.defaultWorkspace
      })
    ) {
      return profile.defaultWorkspace;
    }

    return '';
  }

  getTargetProject({ uploadToProjectId }) {
    if (this.props.creating) {
      this.setState(({ defaultSelectedWorkspace }) => {
        const selectedWorkspace = defaultSelectedWorkspace;
        return {
          projectId: '',
          selectedWorkspace,
          selectedLanguage: '',
          name: '',
          description: '',
          metadata: []
        };
      });
      return Promise.resolve();
    } else {
      if (!this.props.project) {
        loadProject(
          this.props.uploadToProjectId,
          null,
          [],
          this.context.serverStatus.minimum_science_version
        );
      }
      return Promise.all([
        getProject(uploadToProjectId),
        getMetadata(uploadToProjectId).catch(e => {
          console.warn('Failed to load project metadata:\n', e);

          // no need to worry if we couldn't get metadata b/c it doesn't exist yet
          if (e.code === 'PROJECT_NOT_BUILT') {
            return [];
          } else {
            throw e;
          }
        })
      ])
        .then(([project, metadata]) => {
          this.setState({
            projectId: project.project_id,
            selectedWorkspace: project.workspace_id,
            selectedLanguage: project.next_build_language,
            name: project.name,
            description: project.description,
            skip_sentiment: project.last_build_info?.sentiment?.skip_sentiment,
            metadata
          });
        })
        .catch(e => {
          if (e.code === 'INADEQUATE_PERMISSION') {
            this.props.onProjectLoadError(e);
          } else {
            console.warn('Failed to load target project data:\n', e);
            this.props.onFatalError();
          }
        });
    }
  }

  parseFile(file) {
    if (file == null) {
      return;
    }

    this.setState({
      parseError: '',
      rows: null,
      columns: null,
      groups: [],
      groupStats: {},
      parseInProgress: true,
      analysisInProgress: false,
      selectedGroupId: null,
      hoveredGroupId: null
    });
    this.groupStatsJobTracker.clearJobs();

    return CsvUtils.parseFile(file)
      .then(({ rows, columns }) => {
        // These errors entirely prevent us from showing a preview
        const parseError =
          columns.length === 0
            ? 'It looks like your file does not have any title, text, or metadata information.'
            : '';
        columns = updateColumnsWithMissingTypes(columns, rows);
        const reformattedByTypeText = transformByType(
          rows,
          columns,
          false,
          true
        );
        this.setRowsAndColumns(
          reformattedByTypeText.rows,
          reformattedByTypeText.columns
        );
        this.setState({ parseError });
      })
      .catch(error => {
        console.warn('Caught error:', error);
        this.setState({
          parseError: 'We were unable to import that file'
        });
      })
      .finally(() => {
        this.setState({ parseInProgress: false });
      });
  }

  toggleModal = () => {
    this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }));
  };
  reformatUploadData = questions => {
    const { rows, columns } = transformByType(
      this.state.rows,
      this.state.columns,
      questions
    );
    this.setRowsAndColumns(rows, columns);
    this.toggleModal();
  };
  getIssues() {
    if (!this.state.columns) {
      return [];
    }
    const { groups, groupStats, metadata } = this.state;
    const existingMetadata = this.props.creating ? null : metadata;
    let issues = [];
    for (const group of groups) {
      const stats = groupStats[group.key];
      issues = [
        ...issues,
        ...validateGroup(group, stats, groups, existingMetadata)
      ];
    }
    issues.push(
      ...validateDataset({
        rows: this.state.rows,
        columns: this.state.columns,
        metadata: this.state.metadata
      })
    );

    if (!this.state.selectedWorkspace) {
      issues.push(makeError('Select a workspace.', 'no-workspace'));
    }

    if (!this.state.selectedLanguage) {
      issues.push(makeError("Select the dataset's language.", 'no-language'));
    }

    let columnWithTypeText = this.state.columns.filter(
      column => column.type === 'text'
    );
    if (columnWithTypeText.length > 1) {
      columnWithTypeText = columnWithTypeText.filter(
        column => column.name !== 'Text'
      );
      issues.push(
        makeError(
          <>
            <div>
              <p>You have selected more than one column as “Text”</p>
              <div
                css={css`
                  width: 100%;
                  display: flex;
                  justify-content: flex-end;
                `}
              >
                <Button palette="green" onClick={this.toggleModal}>
                  <Icon type={IconTypes.GEAR} size="18" />
                  configure
                </Button>
              </div>
              {this.state.isModalOpen && (
                <RebuildUploadPageModal
                  isOpen={this.state.isModalOpen}
                  onHide={this.toggleModal}
                  reformatData={this.reformatUploadData}
                  columns={columnWithTypeText}
                />
              )}
            </div>
          </>
        )
      );
    }
    if (this.state.numFilesSelected > 1) {
      issues.push(
        makeWarning(
          'Only one data file can be accepted per upload.',
          'multiple files'
        )
      );
    }

    if (this.state.selectedFile?.name?.toLowerCase()?.endsWith('.xlsx')) {
      issues.push(
        makeWarning(
          'Support for Excel formatted spreadsheets (.xlsx) is currently' +
            ' experimental. If your data isn’t showing up correctly, save it in' +
            ' CSV (.csv) format and try again.',
          'xlsx'
        )
      );
    }

    const dismissedWarnings = this.state.dismissedWarnings;
    issues = issues.map(issue => {
      const showing = !dismissedWarnings[
        issue.groupKey ?? grouplessIssuesKey
      ]?.includes(issue.messageId);
      return { ...issue, showing };
    });

    // Make errors show up before warnings
    const [errors, warnings] = _.partition(issues, { type: 'error' });
    return [...errors, ...warnings];
  }

  handleDismissIssue(groupKey = grouplessIssuesKey, messageId) {
    // adds issue to dismissed issues
    const groupWarnings = this.state.dismissedWarnings[groupKey] ?? [];
    const newGroupWarnings = groupWarnings.concat(messageId);
    const dismissedWarnings = {
      ...this.state.dismissedWarnings,
      [groupKey]: newGroupWarnings
    };
    this.setState({ dismissedWarnings });
  }

  onSelectFile(file) {
    const defaultName = this.state.selectedFile?.name;
    const newName =
      this.state.name === '' || this.state.name === defaultName
        ? file.name
        : this.state.name;
    this.setState(
      {
        parseError: '',
        rows: null,
        columns: null,
        groups: [],
        groupStats: {},
        parseInProgress: false,
        analysisInProgress: false,
        dismissedWarnings: {},
        selectedFile: file,
        name: newName,
        selectedGroupId: null,
        hoveredGroupId: null
      },
      () => {
        this.parseFile(this.state.selectedFile);
      }
    );
    this.groupStatsJobTracker.clearJobs();
  }

  onClearFileSelection() {
    this.setState({
      parseError: '',
      rows: null,
      columns: null,
      groups: [],
      groupStats: {},
      dismissedWarnings: {},
      parseInProgress: false,
      analysisInProgress: false,
      selectedFile: null,
      name:
        this.state.name === this.state.selectedFile.name ? '' : this.state.name,
      selectedGroupId: null,
      hoveredGroupId: null
    });
    this.groupStatsJobTracker.clearJobs();
  }

  handleChangeColumnType(columnGroup, newType) {
    const group = this.state.groups.find(g => g.id === columnGroup);
    const updatedColumns = group.changeTypeOfColumns(
      newType,
      this.state.columns
    );
    this.setRowsAndColumns(this.state.rows, updatedColumns);
  }

  setRowsAndColumns(rows, proposedColumns, callback) {
    const { groups, columns } = ColumnGroup.groupColumns(
      proposedColumns,
      this.state.metadata
    );
    const groupKeys = groups.map(group => group.key);
    const oldDismissedWarnings = this.state.dismissedWarnings;
    const newDismissedWarnings = {};
    for (const groupKey in oldDismissedWarnings) {
      if (groupKeys.includes(groupKey)) {
        newDismissedWarnings[groupKey] = oldDismissedWarnings[groupKey];
      }
    }
    newDismissedWarnings[grouplessIssuesKey] =
      oldDismissedWarnings[grouplessIssuesKey];
    this.startGroupStatsCalculations(groups, rows);
    this.setState(
      {
        rows,
        columns,
        groups,
        analysisInProgress: this.groupStatsJobTracker.hasWorkInProgress,
        dismissedWarnings: newDismissedWarnings
      },
      callback
    );
  }

  /**
   * Triggers recalculation of group stats:
   * - Identifies groups that need stats calculated
   * - Creates a worker to process the stats
   * - Registers the tasks with the job tracker
   * - As results come in, updates the state on this component to include the
   *   new stats -- but not so frequently as to cause performance problems
   */
  startGroupStatsCalculations(groups, rows) {
    const groupsToRecalculate = groups.filter(group => {
      return (
        group.type !== 'skip' &&
        this.state.groupStats[group.key] == null &&
        !this.groupStatsJobTracker.runningJobForGroup(group.key)
      );
    });
    const find = groups.find(group => group.name === 'Question');

    if (find) {
      groupsToRecalculate.push(find);
    }

    // This next bit is a little tricky: if the worker returns stats for a lot
    // of groups in rapid succession, all the setState calls could swamp the
    // browser and cause it to freeze.  To prevent this from happening, we
    // collect updates in a holding area, pendingUpdates, and update the state
    // only every half second at most.  Whatever else happens, we ensure that
    // the state will be updated again when the _last_ task finishes.
    let lastUpdate = Date.now();
    let pendingUpdates = {};
    const worker = new GroupStatsWorkerInterface(({ key, stats }) => {
      pendingUpdates[key] = stats;
      this.groupStatsJobTracker.markGroupDone(key);
      const timeNow = Date.now();
      if (
        timeNow - lastUpdate > 500 ||
        !this.groupStatsJobTracker.hasWorkInProgress
      ) {
        this.setState({
          groupStats: { ...this.state.groupStats, ...pendingUpdates },
          analysisInProgress: this.groupStatsJobTracker.hasWorkInProgress
        });
        pendingUpdates = {};
        lastUpdate = timeNow;
      }
    });

    for (let group of groupsToRecalculate) {
      this.groupStatsJobTracker.registerJobForGroup(group.key, worker);
    }
    worker.postMessage({ groups: groupsToRecalculate, rows });
  }

  onSelectGroup(group) {
    if (group == null) {
      this.setState({ selectedGroupId: null });
    } else {
      this.setState({ selectedGroupId: group.id }, () => {
        scrollToElement(this.firstSelectedColumnRef.current);
      });
    }
  }

  onHoverGroup(group) {
    this.setState({ hoveredGroupId: group?.id });
  }

  onRenameGroup(group, newName) {
    this.renameColumns(group.indices, newName);
  }

  onRenameColumn(column, newName) {
    this.renameColumns([column.index], newName);
  }

  renameColumns(columnIndices, newName) {
    // Figure out what update we'll be making to the columns.
    //
    // This depends on whether the columns are in a group, or if they will be
    // merged into one because of the renaming. The specifications are:
    //
    //   - If the columns are being merged into an existing group, we give them
    //     the name (including capitalization) and type of the group they're
    //     being merged with.
    //
    //   - If the columns were already a part of an existing group, and the name
    //     change is just a capitalization change, then we change the
    //     capitalization of the whole group to reflect that change.
    //
    //   - Otherwise, we just give the columns the new name.

    const updates = { name: newName };
    const existingGroup = this.state.groups.find(
      g => g.name.toLowerCase() === newName.toLowerCase()
    );
    let columnIndicesToUpdate = columnIndices;

    if (existingGroup) {
      // check if the columns to be renamed are already a subset of the existing group
      const alreadyInGroup = columnIndices.every(elem =>
        existingGroup.indices.includes(elem)
      );

      if (alreadyInGroup) {
        // rename the whole group
        columnIndicesToUpdate = existingGroup.indices;
      } else {
        // make the columns have the same name as the group
        updates.type = existingGroup.type;
        updates.originalType = existingGroup.type;
        updates.name = existingGroup.name;
      }
    }

    // Now apply the change to the columns and update the state
    const proposedColumns = this.state.columns.map(column => {
      if (columnIndicesToUpdate.includes(column.index)) {
        return column.update(updates);
      }
      return column;
    });
    this.setRowsAndColumns(this.state.rows, proposedColumns, () => {
      for (const index of columnIndicesToUpdate) {
        const column = this.state.columns[index];
        if (!column.isBlank) {
          this.setSelectedGroupId(column.group);
          return;
        }
      }
    });
  }

  setSelectedGroupId(selectedGroupId) {
    this.setState({ selectedGroupId });
    this.bubblePanelRef.current.scrollToGroup(selectedGroupId);
  }

  onSelectColumn(column) {
    this.setSelectedGroupId(column.group);
  }

  onHoverColumn(column) {
    this.setState({ hoveredGroupId: column?.group });
  }

  countRowsWithoutText() {
    const textGroupKey = _.find(this.state.groups, { type: 'text' })?.key;
    return this.state.groupStats[textGroupKey]?.emptyRowsCount;
  }

  render() {
    const { profile } = this.context;
    const orgId = profile.organizationId;
    const usageReport = profile.organizationsById[orgId]?.getUsageReport();

    /* Don't show the page until a couple of key things have loaded:
     * - If the user is uploading to an existing project, the current details
     *   of that project.  Waiting for this lets us avoid drawing a project
     *   details form with missing data.
     * - The usage report for the user's home organization.  This avoids a
     *   jumpy page load experience for users in organizations that don't have
     *   usage limits in place.  The logic here is actually a bit tricky, and
     *   is detailed in a comment on <UploadPageUsage />.
     */
    if (this.state.loading || usageReport == null) {
      return (
        <div
          css={css`
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Spinner size="large" />
        </div>
      );
    }

    const workspaces = this.getFilteredWorkspaces();
    const issues = this.getIssues();
    const hasErrors =
      _.filter(issues, { type: 'error' }).length > 0 ||
      this.state.parseError !== '';
    const shouldCreateProject = this.props.creating;
    const selectedGroup = _.find(this.state.groups, {
      id: this.state.selectedGroupId
    });
    const hoveredGroup = _.find(this.state.groups, {
      id: this.state.hoveredGroupId
    });
    const hasFile = this.state.selectedFile !== null;
    const numRowsWithoutText = this.countRowsWithoutText();
    const displayStreamData = this.props.history.location.pathname.includes(
      '/upload/stream'
    );

    return (
      <FileDropZone
        onDrop={files => {
          this.setState({ numFilesSelected: files.length });
          this.onSelectFile(files.shift());
        }}
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          padding: 0.5rem 2rem;

          > :not(:last-child) {
            margin-bottom: 0.5rem;
          }
        `}
      >
        <UploadPageUsage
          workspaceId={this.state.selectedWorkspace}
          uploadCount={this.state.rows?.length}
          uploadCountNoText={numRowsWithoutText}
        />
        <div
          css={css`
            display: grid;
            grid-template-columns: max-content minmax(20rem, auto) max-content max-content max-content;
            align-items: baseline;
            gap: 0.5rem;

            label {
              color: ${Colors.gray5};
              font-size: 1rem;
              display: inline-block;
            }

            input {
              display: inline-block;
            }
          `}
        >
          <ProjectNameSelector
            value={this.state.name}
            onChange={name => {
              this.setState({
                name
              });
            }}
          />
          <WorkspaceSelector
            editable={shouldCreateProject && workspaces.length > 1}
            value={this.state.selectedWorkspace}
            onChange={selectedWorkspace => {
              this.setState({ selectedWorkspace });
            }}
            workspaces={workspaces}
            showError={
              (hasFile && !this.state.selectedWorkspace) ||
              (displayStreamData && !this.state.selectedWorkspace)
            }
          />
          <DescriptionSelector
            value={this.state.description}
            onChange={description => {
              this.setState({ description });
            }}
          />
          <LanguageSelector
            editable={shouldCreateProject}
            value={this.state.selectedLanguage}
            onChange={selectedLanguage => {
              this.setState({ selectedLanguage });
            }}
            languages={this.state.languages}
            showError={
              (hasFile && !this.state.selectedLanguage) ||
              (displayStreamData && !this.state.selectedLanguage)
            }
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            flex-basis: 0;
            min-height: 0;
          `}
        >
          {this.state.columns !== null && (
            <BubblePanel
              ref={this.bubblePanelRef}
              analysisInProgress={this.state.analysisInProgress}
              bubbles={Bubbles.fromIssuesAndGroups(issues, this.state.groups)}
              groupStats={this.state.groupStats}
              onDismissIssue={this.handleDismissIssue}
              onChangeColumnType={this.handleChangeColumnType}
              metadata={this.state.metadata}
              onSelectGroup={this.onSelectGroup}
              selectedGroup={selectedGroup}
              hoveredGroup={hoveredGroup}
              onHoverGroup={this.onHoverGroup}
              onRenameGroup={this.onRenameGroup}
            />
          )}
          {!displayStreamData ? (
            <div className="upload-page__preview-section">
              {!hasFile ? (
                <PreviewSection>
                  <div
                    css={css`
                      ${Mixins.fillViewport};
                      overflow-y: auto;
                    `}
                  >
                    <div
                      css={css`
                        max-width: 40rem;
                        margin: 2rem auto;
                      `}
                    >
                      <p>
                        <strong>
                          Drag and drop your dataset here or click the button
                          for a file browser.
                        </strong>
                      </p>
                      <div
                        css={css`
                          margin-top: 1rem;
                          margin-bottom: 1rem;
                          display: flex;
                          justify-content: center;
                        `}
                      >
                        <DatasetSelector onSelectFile={this.onSelectFile} />
                      </div>
                      <p>Quick start:</p>
                      <p>
                        Data must be a single file in comma separated value
                        (CSV) or tab separated value (TSV) format.
                      </p>
                      <p>A header row is required.</p>
                      <p>
                        A Text data type column is required. Only one is
                        permitted.
                      </p>
                      <p>Daylight will suggest data types for each column.</p>
                      <p>Choose from:</p>
                      <ul
                        css={css`
                          margin-bottom: 1rem;
                        `}
                      >
                        <li>
                          Text: Words that Daylight will analyze and use to find
                          concepts.
                        </li>
                        <li>Title: Name of document.</li>
                        <li>Number: Numbers only, no words allowed.</li>
                        <li>
                          Score: Numbers that you want to be higher, such as net
                          promoter scores or client counts.
                        </li>
                        <li>Date: In format 2020-12-01.</li>
                        <li>
                          String: Catch-all type for any other data, such as
                          short answers, lists, or text that shouldn’t be
                          analyzed for concepts.
                        </li>
                        <li>
                          Skip: Skipped data will not be uploaded to your
                          project.
                        </li>
                      </ul>
                      <p>For more information and instructions, see:</p>
                      <ExternalLink
                        className="upload-page__link"
                        href={URLs.DATA_FORMATTING_GUIDE}
                      >
                        How should I format my dataset?
                      </ExternalLink>
                    </div>
                  </div>
                </PreviewSection>
              ) : (
                <>
                  <div
                    css={css`
                      margin-bottom: 0.5rem;
                    `}
                  >
                    <span
                      css={css`
                        font-size: 1.25rem;
                      `}
                    >
                      Dataset:{' '}
                      {this.state.selectedFile?.name ?? 'no file chosen'}
                      {this.state.rows &&
                        ` (${thousandify(
                          this.state.rows.length
                        )} documents)`}{' '}
                    </span>
                    <Button
                      palette="red"
                      hiddenLabel="Clear dataset"
                      onClick={this.onClearFileSelection}
                      data-tracking-item="upload-page_clear-dataset"
                    >
                      <Icon type={IconTypes.TRASH} />
                    </Button>
                  </div>
                  <PreviewSection>
                    <UploadPreview
                      parseInProgress={this.state.parseInProgress}
                      parseError={this.state.parseError}
                      language={this.state.selectedLanguage}
                      columns={this.state.columns}
                      rows={this.state.rows}
                      selectedGroup={selectedGroup}
                      onSelectColumn={this.onSelectColumn}
                      firstSelectedColumnRef={this.firstSelectedColumnRef}
                      hoveredGroup={hoveredGroup}
                      onHoverColumn={this.onHoverColumn}
                      onRenameColumn={this.onRenameColumn}
                      groups={this.state.groups}
                      groupStats={this.state.groupStats}
                    />
                  </PreviewSection>
                </>
              )}
            </div>
          ) : (
            <Switch>
              <Route path={RoutePatterns.UPLOAD_STREAM_DATA}>
                <DisplayStreamData
                  projectData={{
                    name: this.state.name,
                    description: this.state.description,
                    language: this.state.selectedLanguage,
                    workspaceId: this.state.selectedWorkspace,
                    projectId: this.state.projectId
                  }}
                />
              </Route>
            </Switch>
          )}
        </div>
        {hasFile && (
          <div>
            {this.state.supportEmail && (
              <SendEmailSelector
                checked={this.state.sendEmail}
                onChange={sendEmail => {
                  this.setState({ sendEmail });
                }}
                email={this.context.profile.username}
                additionalCss={css`
                  margin: 1rem 0;
                `}
              />
            )}
            <SentimentSelector
              additionalCss={css`
                margin: 1rem 0;
              `}
              onChange={skip_sentiment => {
                this.setState({ skip_sentiment });
              }}
              checked={this.state.skip_sentiment}
            />
            <div
              css={css`
                display: flex;
                flex-direction: row;
              `}
            >
              <Button
                disabled={hasErrors || this.state.analysisInProgress}
                onClick={evt => {
                  evt.preventDefault();
                  this.props.onSubmit({
                    projectId: this.state.projectId,
                    name: this.state.name,
                    language: this.state.selectedLanguage,
                    description: this.state.description,
                    workspaceId: this.state.selectedWorkspace,
                    filename: this.state.selectedFile.name,
                    documents: CsvUtils.parseDocs(
                      this.state.rows,
                      this.state.columns
                    ),
                    sendEmail: this.state.sendEmail,
                    sentiment: this.state.skip_sentiment,
                    dashboardBuild: this.state.dashboardBuild
                  });
                }}
                data-tracking-item="upload-page_create-project"
              >
                {shouldCreateProject ? 'Create project' : 'Upload data'}
              </Button>
              {hasErrors && (
                <Alert
                  type={AlertTypes.ERROR}
                  css={css`
                    margin-left: 0.5rem;
                  `}
                >
                  Fix errors to create project
                </Alert>
              )}
            </div>
          </div>
        )}
      </FileDropZone>
    );
  }
}

UploadAndValidateDatasetPage.contextType = AuthContext;

UploadAndValidateDatasetPage.propTypes = {
  creating: PropTypes.bool.isRequired,
  uploadToWorkspaceId: PropTypes.string,
  uploadToProjectId: PropTypes.string,
  project: PropTypes.object,
  history: PropTypes.object,
  onSubmit: PropTypes.func,
  onFatalError: PropTypes.func,
  onProjectLoadError: PropTypes.func
};

const PreviewSection = styled.div`
  ${Mixins.shadowOutset}
  ${Mixins.roundedCorners}
  position: relative;
  flex-grow: 1;
`;

function DatasetSelector({ onSelectFile }) {
  return (
    <>
      <label
        className="upload-page__field upload-page__field--button"
        htmlFor="upload-page_file-picker"
      >
        Choose file
      </label>
      <input
        className="upload-page__field upload-page__field--file"
        id="upload-page_file-picker"
        type="file"
        required
        onChange={evt => onSelectFile(evt.target.files[0])}
        // rendering this file input w/o a value lets us reselect the same file
        // e.g. when a dataset needs to be fixed then reparsed
        value=""
      />
    </>
  );
}

DatasetSelector.propTypes = { onSelectFile: PropTypes.func };

function LanguageSelector({ value, onChange, languages, editable, showError }) {
  const selectId = useUniqueId();

  return (
    <>
      <ErrorIcon showError={showError} />
      <label htmlFor={selectId}>Language:</label>
      <PlaintextWhenDisabledDropdown
        id={selectId}
        editable={editable}
        value={value}
        onChange={onChange}
        options={languages.map(l => ({ value: l.code, name: l.name }))}
        promptOption="Select the dataset's language"
        css={showError && Mixins.errorOutline}
      />
    </>
  );
}

function ProjectNameSelector({ value, onChange }) {
  const biggerText = css`
    font-size: 1.5rem;
    button {
      font-size: 1rem;
    }
  `;
  return (
    <>
      <label htmlFor="upload-page_name">Project name:</label>
      <InlineEditor
        name="project name"
        value={value}
        onChange={onChange}
        blankValue="No project name"
        css={css`
          ${biggerText}
          input {
            width: 100%;
          }
        `}
        required
      />
    </>
  );
}

ProjectNameSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

function DescriptionSelector({ value, onChange }) {
  return (
    <>
      <label htmlFor="upload-page_description">Description:</label>
      <InlineEditor
        name="description"
        value={value}
        onChange={onChange}
        blankValue="No description"
        css={css`
          textarea {
            width: 100%;
          }
        `}
        multiline
      />
    </>
  );
}

DescriptionSelector.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func
};

function WorkspaceSelector({
  editable,
  value,
  onChange,
  workspaces,
  showError
}) {
  const selectId = useUniqueId();

  return (
    <>
      <ErrorIcon showError={showError} />
      <label htmlFor={selectId}>Workspace:</label>
      <PlaintextWhenDisabledDropdown
        id={selectId}
        editable={editable}
        value={value}
        onChange={onChange}
        options={workspaces
          .map(w => ({ value: w.workspace_id, name: w.name }))
          .sort(naturalSortByName)}
        promptOption="Select workspace"
        css={showError && Mixins.errorOutline}
        data-tracking-item="upload-page_workspace-select"
      />
    </>
  );
}

function ErrorIcon({ showError }) {
  if (!showError) {
    // We need to always render something here not to mess with the grid layout
    return <span />;
  }

  return (
    <div
      css={css`
        color: ${Colors.red0};
        background: ${Colors.red3};
        /* Match button dimensions and style */
        ${Mixins.shadowOutset};
        border-radius: ${standardRadius};
        padding: 0.5rem;
        height: 1.1875rem;
        width: 1rem;
        /* Center icon inside this div */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
    >
      <Icon type={IconTypes.CIRCULAR_CLOSE} alt="error" />
    </div>
  );
}
