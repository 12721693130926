import React from 'react';
import css from '@emotion/css';

import ReviewsUrlInput from './ReviewsUrlInput';

const ReviewsInputDataForm = ({ inputs, onChangeInput, onRemoveInput }) => {
  return (
    <form
      css={css`
        overflow-y: auto;
        max-height: 30rem;
        padding: 2px 10px;
      `}
    >
      {inputs.map(input => (
        <ReviewsUrlInput
          key={input.name}
          input={input}
          inputs={inputs}
          onInputChange={onChangeInput}
          onInputRemove={onRemoveInput}
        />
      ))}
    </form>
  );
};

export default ReviewsInputDataForm;
