import React from 'react';

export function BuildTimeoutPage() {
  return (
    <div className="upload-page__result-container upload-page__result-container--timeout">
      <p className="upload-page__result-msg">
        Sorry, this is taking a while. Your project will be accessible from the{' '}
        <a href="/">Projects page</a> when it is ready to be explored.
      </p>
    </div>
  );
}
