import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as DocUtils from '../utils/DocUtils';
import { Doc } from '../classes/Docs';
import { Concept } from '../classes/Concepts';
import { ParagraphList } from './ParagraphList';
import { ReadMoreButton } from './ReadMoreButton';
import { getFormattedDocument } from '../utils/DocUtils';

const ELLIPSIS = '…';

function SentimentDocText({ doc, language, selection }) {
  const [truncated, setTruncated] = useState(true);
  useEffect(() => {
    setTruncated(true);
  }, [doc, selection]);

  const toggle = () => {
    setTruncated(truncated => !truncated);
  };

  const {
    paragraphList,
    canTruncate,
    truncatedStart,
    truncatedEnd
  } = getFormattedDocument(doc, selection, truncated);

  return (
    <>
      {doc.title && (!canTruncate || (canTruncate && !truncated)) && (
        <h6 className={DocUtils.setRtlClass('doc-title', language)}>
          {doc.title}
        </h6>
      )}
      <ParagraphList
        language={language}
        paragraphList={paragraphList}
        beforeFirst={truncatedStart && ELLIPSIS}
        afterLast={
          <>
            {truncatedEnd && ELLIPSIS}
            {canTruncate && (
              <ReadMoreButton onClick={toggle} truncated={truncated} />
            )}
          </>
        }
      />
    </>
  );
}

SentimentDocText.propTypes = {
  doc: PropTypes.instanceOf(Doc).isRequired,
  selection: PropTypes.instanceOf(Concept),
  language: PropTypes.string.isRequired
};

export default memo(SentimentDocText);
