import styled from '@emotion/styled';

export const ManagementPageContainer = styled.div`
  padding: 0.5rem;
  margin-right: 2rem;
  width: 90%;
  height: 100%;

  h4 {
    /* Clear padding above the h4, leaving the same amount of space above 
     * and below the h2. 
     */
    padding-top: 0;
  }

  /* Push text a little to the right to match the start of the text in
   * the navigation dropdown at the top of the page. (The h2 already has
   * extra padding of its own to make it match the input box in the
   * editing state.)
   */
  h4,
  p {
    margin-left: 0.5625rem;
  }
`;

export const TableAndControlsContainer = styled.div`
  min-width: 40rem;
  max-width: 70rem;
`;

export const Subtitle = styled.p`
  /** Clear default p tag styling. Adjacent sibling elements (h2 and
   *  ControlBar) are responsible for controlling vertical spacing */
  margin-bottom: 0;
`;

export const ConfirmationMessageContent = styled.div`
  margin: 1rem;
  & > * {
    margin-bottom: 2rem;
  }
  p:first-child {
    font-size: 1.5rem;
    font-weight: 400;
  }
`;

export const ControlBar = styled.div`
  margin: 0.75rem 0;
  min-height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: start;

  button,
  button svg {
    margin-right: 0.5rem;
  }

  .right {
    margin-left: auto;
  }

  button:last-child {
    margin-right: 0;
  }
`;
