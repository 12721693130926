import moment from 'moment';

export default (timestamp, interval) => {
  const time = moment.utc(timestamp);

  switch (interval) {
    case 'year':
      return time.format('YYYY');
    case 'quarter':
      return time.format('[Q]Q YYYY');
    case 'month':
      return time.format('MMM YYYY');
    case 'week': {
      const endWeek = time.clone().add(6, 'days');
      return `${time.format('ll')} - ${endWeek.format('ll')}`;
    }
    case 'day':
      return time.format('ll');
    case 'hour':
      return time.format('ll k:mm');
  }
};
