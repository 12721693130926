import * as XLSX from 'xlsx';

export function readNormalizedTextFromFile(file) {
  return readFile(file).then(({ text }) => {
    // Normalize our unicode into Normalization Form C. Whatever that means.
    // (http://unicode.org/reports/tr15/)
    return text.normalize('NFC');
  });
}
export function readFile(file) {
  return readFileInEncoding(file, 'binary').then(({ encoding, text }) => {
    const bom = text.slice(0, 2); // byte-order marker
    if (bom === 'þÿ' || bom === 'ÿþ') {
      // Read the file as UTF-16
      return readFileInEncoding(file, 'UTF-16');
    } else if (bom === 'ï»') {
      // Read the file as UTF-8
      return readFileInEncoding(file, 'UTF-8');
    } else {
      try {
        // If the file isn't UTF-16, attempt to decode it into UTF-8 ourselves to catch errors
        text = decodeURIComponent(escape(text));
        encoding = 'UTF-8';
      } catch (error) {
        encoding = 'unknown';
      }
      return { encoding, text };
    }
  });
}

function readFileInEncoding(file, encoding) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = evt => resolve({ encoding, text: evt.target.result });
    reader.onerror = evt => reject(evt.target.error);

    if (encoding === 'binary') {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsText(file, encoding);
    }
  });
}

export function readExcelFileAsCsv(file) {
  return readFileToArray(file).then(data => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          const workbook = XLSX.read(data, {
            type: 'array',
            cellDates: true,
            cellText: false
          });
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          resolve(
            XLSX.utils.sheet_to_csv(sheet, {
              blankrows: false,
              dateNF: 'yyyy-mm-dd'
            })
          );
        } catch (error) {
          reject(error);
        }
      });
    });
  });
}

export function readFileToArray(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = evt => resolve(new Uint8Array(evt.target.result));
    reader.onerror = evt => reject(evt.target.error);
    reader.readAsArrayBuffer(file);
  });
}
