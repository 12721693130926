import React, { useContext } from 'react';
import { css } from '@emotion/core';

import { AuthForm, FIELD_WIDTH, PasswordField, SubmitButton } from './Forms';
import { AuthContext } from './model';
import { changePasswordValidationErrors } from '../utils/password';

export function ChangePassword() {
  const { profile } = useContext(AuthContext);
  return (
    <div
      css={css`
        padding: 0.5rem;

        p {
          width: ${FIELD_WIDTH}rem;
          margin-bottom: 1rem;
        }
      `}
    >
      <h2>{profile.fullName}</h2>
      <p>
        Changing your password will log you out of all your current sessions. If
        you have created a long-lived token for API use, it will{' '}
        <strong>not</strong> be removed. Passwords must be at least eight
        characters long and cannot be the same as your username.
      </p>
      <AuthForm
        defaults={{
          oldPass: '',
          newPass: '',
          confirmPass: ''
        }}
        onSubmit={formData => changePassword(formData, profile)}
      >
        <PasswordField label="old password" field="oldPass" />
        <PasswordField label="enter a new password" field="newPass" />
        <PasswordField label="confirm new password" field="confirmPass" />
        <SubmitButton>Reset my password</SubmitButton>
      </AuthForm>
    </div>
  );
}

function changePassword({ oldPass, newPass, confirmPass }, profile) {
  const errors = changePasswordValidationErrors(
    oldPass,
    newPass,
    confirmPass,
    profile.username
  );

  if (Object.keys(errors).length > 0) {
    return Promise.reject({ fieldErrors: errors });
  }

  return profile.changePassword(oldPass, newPass).catch(error => {
    if (error.code === 'AUTH_CREDS_INVALID') {
      return Promise.reject({
        fieldErrors: {
          oldPass: ["That's not your old password.  Please try again."]
        }
      });
    } else if (error.code === 'INADEQUATE_PERMISSION') {
      return Promise.reject({
        generalError: error.message
      });
    } else {
      return Promise.reject({
        fieldErrors: {}
      });
    }
  });
}
