import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { StoreContext } from '../../StoreContext';
import { Popover } from '../../components/core/Popover';
import { SidePanelHeaderButton } from '../../side_panel/SidePanelHeaderButton';
import { CloseButton } from '../../components/CloseButton';
import { FieldTable } from './FieldTable';
import { selectedFieldsProps } from './useMetadataFilter';

export function MetadataFilter({ selectedFields }) {
  const buttonRef = useRef();
  const popoverRef = useRef();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { metadata } = useContext(StoreContext);

  useEffect(() => {
    popoverRef.current?.focus();
  }, [popoverOpen]);

  if (metadata.length === 0) {
    return null;
  }

  const onBlur = event => {
    const element = event.relatedTarget;
    const clickingOnButton = buttonRef.current === element;
    const focusingInsidePopover = popoverRef.current?.contains(element);

    // Close the popover when you click outside of it. We do this by closing it
    // when the popover receives an onBlur event, unless:
    // - the popover is losing focus to an element inside of it
    // - the user is clicking on the button that toggles the popover open/closed
    if (!clickingOnButton && !focusingInsidePopover) {
      setPopoverOpen(false);
    }
  };

  return (
    <>
      <SidePanelHeaderButton
        ref={buttonRef}
        trackingItem="documents-pane-select-fields-button"
        onClick={() => setPopoverOpen(isOpen => !isOpen)}
      >
        Select fields
      </SidePanelHeaderButton>
      {popoverOpen && (
        <Popover
          ref={popoverRef}
          buttonRef={buttonRef}
          onBlur={onBlur}
          data-test-id="metadata-popover"
        >
          <Container>
            <CloseButton onClick={() => setPopoverOpen(false)} />
            <FieldTable metadata={metadata} selectedFields={selectedFields} />
          </Container>
        </Popover>
      )}
    </>
  );
}

MetadataFilter.propTypes = {
  selectedFields: selectedFieldsProps.isRequired
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
