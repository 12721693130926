import { css } from '@emotion/core';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';

import { ZIndexes } from '../../styles';
import { Button } from '../../components/core/Button';
import { NameEditor } from './NameEditor';
import { ColorEditor } from './ColorEditor';
import { AddConceptInput } from './AddConceptInput';
import { ConstituentList } from './ConstituentList';
import { Concept } from '../../classes/Concepts';
import { TooltipBox, TooltipPointer } from '../../components/core/Tooltip';
import { Icon, IconTypes } from '../../components/icons';
import { StoreContext } from '../../StoreContext';
import { useFilter } from '../../search_params';

const FLYOUT_PADDING = '0.5rem';

export function Flyout({ toggleFlyout, clientRect }) {
  const flyoutRef = React.useRef();
  const { projectId } = useParams();
  const { selection, activeConcepts } = useContext(StoreContext);
  const filter = useFilter();
  const activeConcept = activeConcepts.find(ac =>
    Concept.areTextsEqual(ac, selection)
  );

  return createPortal(
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: calc(${clientRect.bottom}px);
        left: ${clientRect.left + clientRect.width / 2}px;
        transform: translateX(-50%);
        box-sizing: border-box;
        z-index: ${ZIndexes.flyout};
      `}
      tabIndex={0}
      ref={flyoutRef}
      onBlur={event => {
        if (!flyoutRef.current.contains(event.relatedTarget)) {
          toggleFlyout();
        }
      }}
      onKeyDown={event => {
        if (event.key === 'Escape') {
          toggleFlyout();
        }
      }}
    >
      <TooltipPointer position="below" />
      <TooltipBox
        css={css`
          width: 20rem;
        `}
      >
        <FlyoutHeader toggleFlyout={toggleFlyout}>
          Edit active concept
        </FlyoutHeader>
        <div
          css={css`
            padding-top: 0.5rem;
          `}
        >
          <NameEditor
            projectId={projectId}
            concept={activeConcept}
            filter={filter}
          />
          <AddConceptInput
            projectId={projectId}
            concept={activeConcept}
            filter={filter}
          />
          <ConstituentList
            projectId={projectId}
            concept={activeConcept}
            filter={filter}
          />
          <ColorEditor concept={activeConcept} />
        </div>
      </TooltipBox>
    </div>,
    document.body
  );
}

Flyout.propTypes = {
  toggleFlyout: PropTypes.func.isRequired,
  clientRect: PropTypes.shape({
    width: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired
  }).isRequired
};

function FlyoutHeader({ toggleFlyout, children }) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #dde0e2;
        /* Apply a negative margin to let the bottom border extend into the
        parent's padding, then add padding so the non-border content won't sit
        against the parent's edge.*/
        margin: 0 -${FLYOUT_PADDING};
        padding: 0 ${FLYOUT_PADDING};
        /* Match ConceptDetail's header font-size */
        font-size: 0.875rem;
      `}
    >
      {children}
      <Button
        flavor="subtle"
        palette="gray"
        aria-label="Close"
        onClick={toggleFlyout}
      >
        <Icon type={IconTypes.CLOSE} size="18" />
      </Button>
    </div>
  );
}

FlyoutHeader.propTypes = {
  toggleFlyout: PropTypes.func.isRequired
};
