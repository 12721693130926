import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { loadSharedConceptLists } from '../actions';
import { StoreContext } from '../StoreContext';
import { ActiveConcepts } from './active_concepts/ActiveConcepts';
import { TabPanel } from './Tabs';
import { NewListButton } from './active_concepts/NewListButton';
import { objHasPerm } from '../utils/common';

export function ActiveConceptsPanel() {
  const { projectId } = useParams();
  const {
    activeConceptListName,
    project,
    activeConcepts,
    isOpeningConceptList,
    sharedConceptLists
  } = useContext(StoreContext);

  function refetchConceptLists() {
    loadSharedConceptLists(projectId);
  }

  const hasWritePermissions = objHasPerm(project, 'write');

  return (
    <TabPanel
      header={
        <>
          Active concepts
          {hasWritePermissions && <NewListButton />}
        </>
      }
      body={
        <ActiveConcepts
          projectId={projectId}
          permissions={project.permissions}
          activeConcepts={activeConcepts}
          isOpeningConceptList={isOpeningConceptList}
          activeConceptListName={activeConceptListName}
          conceptLists={sharedConceptLists}
          refetchConceptLists={refetchConceptLists}
        />
      }
    />
  );
}
