import { useEffect, useState } from 'react';

import { baseFontFamily } from '../../styles';
import getTextWidth from '../getTextWidth';

/**
 * A hook version of the getTextWidth function that recalculates the width after
 * the fonts have fully loaded
 *
 * @param {string} text - Text that you want to find the width of
 * @param {string} fontSize
 * @param {string} fontWeight - defaults to "normal"
 *
 * @return {number} width (in pixels) that the text is going to take up
 */
export function useTextWidth(text, fontSize, fontWeight = 'normal') {
  const [width, setWidth] = useState(() =>
    getTextWidth(text, fontSize, fontWeight)
  );

  useEffect(() => {
    const canvas = document.createElement('canvas').getContext('2d');
    if (canvas) {
      document.fonts.ready.then(() => {
        canvas.font = `${fontWeight} ${fontSize} ${baseFontFamily}`;
        setWidth(canvas.measureText(text).width);
      });
    }
  }, [text, fontSize, fontWeight]);

  return width;
}
