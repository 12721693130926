import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';

/**
 * When rendered, this component redirects from any hash-routing style
 * routes (ones beginning with "/#/") to the same route with the
 * leading "#/" removed.
 */
export default function HashRedirect({ children }) {
  const location = useLocation();
  if (location.pathname === '/' && /^#\/(.*)$/.test(location.hash)) {
    return <Redirect to={`${location.pathname}${location.hash.slice(2)}`} />;
  }
  return children;
}

HashRedirect.propTypes = {
  children: PropTypes.node.isRequired
};
