import React from 'react';
import css from '@emotion/css';

import RedditUrlInput from './RedditUrlInput';
import { Button } from '../../../components/core/Button';
import { AlertTypes } from '../../../constants';
import Alert from '../../../components/core/Alert';

const RedditInputDataForm = ({
  onPressNext,
  inputs,
  onChangeInput,
  onRemoveInput,
  projectDataErrors,
  onAddInput
}) => {
  return (
    <form onSubmit={e => e.preventDefault()}>
      {inputs.map(input => (
        <RedditUrlInput
          key={input.name}
          input={input}
          inputs={inputs}
          onInputChange={onChangeInput}
          onInputRemove={onRemoveInput}
        />
      ))}
      <Button
        onClick={onAddInput}
        css={css`
          height: 35px;
        `}
      >
        Add more
      </Button>
      {projectDataErrors?.length > 0 && (
        <div
          css={css`
            margin-top: 20px;
          `}
        >
          {projectDataErrors?.map(error => (
            <Alert key={error} type={AlertTypes.ERROR}>
              {error}
            </Alert>
          ))}
        </div>
      )}

      <div
        css={css`
          display: flex;
          margin-top: 20px;
          justify-content: flex-end;
        `}
      >
        <Button
          disabled={projectDataErrors?.length}
          onClick={onPressNext}
          type="button"
          palette="yellow"
        >
          Next
        </Button>
      </div>
    </form>
  );
};

export default RedditInputDataForm;
