import React from 'react';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';

import { Colors, Mixins, standardRadius } from '../styles';

export default function Card({ header, footer, children, ...additionalProps }) {
  return (
    <div
      css={css`
        ${Mixins.shadowOutset};
        ${Mixins.roundedCorners};
        min-height: 0;
        display: flex;
        flex-direction: column;
      `}
      {...additionalProps}
    >
      <div
        css={css`
          border-radius: ${standardRadius} ${standardRadius} 0 0;
          background: ${Colors.blue4};
          color: white;
          ${Mixins.ellipsify};
          flex-shrink: 0;
          padding: 0.5rem;
        `}
      >
        {header}
      </div>
      <div
        css={css`
          min-height: 0;
          overflow: auto;
          padding: 0.5rem;
          padding-bottom: 0;
        `}
      >
        {children}
      </div>
      <div
        css={css`
          flex-shrink: 0;
          padding: 0.5rem;
          padding-top: 0;
        `}
      >
        {footer}
      </div>
    </div>
  );
}

Card.propTypes = {
  header: PropTypes.node.isRequired,
  footer: PropTypes.node,
  children: PropTypes.node.isRequired
};
