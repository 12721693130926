import _ from 'lodash';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import List from '../components/core/List';
import ConceptView from '../components/ConceptView';
import FuzzySearchBox from './FuzzySearchBox';
import { Concept } from '../classes/Concepts';
import { Icon, IconTypes } from '../components/icons';

export default function ActiveConceptSelector({
  concepts,
  axis,
  placeholder,
  selectedConceptId,
  onBlur,
  onConceptSelect
}) {
  const [searchResults, setSearchResults] = useState(null);
  const containerRef = useRef();

  function clearSelection() {
    if (selectedConceptId) {
      onConceptSelect(selectedConceptId);
    }
  }

  return (
    <div
      ref={ref => {
        containerRef.current = ref;
      }}
      className="active-concept-selector"
      onBlur={evt => {
        // Don't fire onBlur unless the actual active concept selector
        // is losing focus
        if (!containerRef.current?.contains(evt.relatedTarget)) {
          onBlur(evt);
        }
      }}
      tabIndex={0}
    >
      <div className="active-concept-selector-header">
        {selectedConceptId ? (
          <div
            className="fuzzy-search-box active-concept-selector-selection"
            onClick={clearSelection}
          >
            <input
              autoFocus={true}
              className="active-concept-selector-selection-name"
              readOnly={true}
              value={
                _.find(concepts, {
                  sharedConceptId: selectedConceptId
                })?.name ?? ''
              }
            />
            <Icon type={IconTypes.CIRCULAR_CLOSE} />
          </div>
        ) : (
          <FuzzySearchBox
            autoFocus={true}
            collection={concepts}
            onSearch={results => setSearchResults(results)}
            placeholder={placeholder || 'Find concepts'}
          />
        )}
      </div>
      <List
        className="active-concept-selector-list"
        hoverable={false}
        lined={false}
        bordered={false}
      >
        {(searchResults
          ? _.intersectionBy(concepts, searchResults, 'sharedConceptId')
          : concepts
        ).map(concept => {
          const conceptId = concept.sharedConceptId;
          return (
            <ConceptView
              key={conceptId}
              concept={concept}
              isSelected={conceptId === selectedConceptId}
              onClick={() => {
                onConceptSelect(conceptId);
                setSearchResults(null);
              }}
              data-tracking-item={`galaxy_${axis}-axis-concept`}
            />
          );
        })}
      </List>
    </div>
  );
}

ActiveConceptSelector.propTypes = {
  selectedConceptId: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onConceptSelect: PropTypes.func.isRequired,
  concepts: PropTypes.arrayOf(PropTypes.instanceOf(Concept).isRequired)
    .isRequired,
  axis: PropTypes.oneOf(['x', 'y']).isRequired
};
