import { css } from '@emotion/core';
import React from 'react';

import { Button } from '../components/core/Button';

export function ReadMoreButton({ onClick, truncated }) {
  return (
    <Button
      className="doc-expand-toggle"
      flavor="subtle"
      padded={false}
      css={css`
        margin-left: 0.125rem;
        white-space: nowrap;
        &:hover {
          text-decoration: underline;
        }
      `}
      onClick={onClick}
    >
      {truncated ? 'Read more »' : '« Show less'}
    </Button>
  );
}
