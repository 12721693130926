import { css } from '@emotion/core';

import { Mixins } from '../../styles';

export const bubblePanelItemStyles = css`
  margin-bottom: 0.5rem;
  ${Mixins.roundedCorners};
  ${Mixins.shadowOutset};
  /* Apply box-shadow-size margin to avoid overflow */
  margin-top: 1px;
  margin-right: 3px;
  margin-left: 3px;
`;
