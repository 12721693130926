import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { css } from '@emotion/core';
import _ from 'lodash';
import * as clipboard from 'clipboard-polyfill';

import { Mixins } from '../styles';
import buildRoutePath from '../utils/buildRoutePath';
import { RoutePatterns } from '../constants';
import SimpleModal from '../components/core/SimpleModal';
import {
  AuthForm,
  CancelButton,
  FIELD_WIDTH,
  SubmitButton,
  PasswordField,
  TextArea
} from './Forms';
import { Button } from '../components/core/Button';
import { ButtonBar } from './ButtonBar';
import { AuthContext } from './model';
import { NotificationArea, showSuccess, showError } from './notifications';
import { Icon, IconTypes } from '../components/icons';

export default function NewTokenButton(props) {
  const history = useHistory();

  function goToAddTokenPage() {
    history.push(buildRoutePath(RoutePatterns.NEW_TOKEN));
  }

  function goToListTokensPage() {
    history.push(buildRoutePath(RoutePatterns.TOKENS));
  }

  return (
    <>
      <Button {...props} onClick={goToAddTokenPage}>
        <Icon type={IconTypes.ADD} />
        Create new token
      </Button>
      <Switch>
        <Route exact path={RoutePatterns.NEW_TOKEN}>
          <NewTokenModal hide={goToListTokensPage} />
        </Route>
      </Switch>
    </>
  );
}

function NewTokenModal({ hide }) {
  const [createdToken, setCreatedToken] = React.useState(null);
  const { profile, serverStatus } = React.useContext(AuthContext);
  const requirePasswordReentry = serverStatus?.saml_authentication === false;
  const tokens = profile.getTokens();
  const notYetCreated = createdToken === null;

  function createToken(formData) {
    const fieldErrors = {};
    if (formData.description.trim() === '') {
      fieldErrors['description'] = ['This field is required.'];
    } else if (
      tokens.some(token => token.description === formData.description)
    ) {
      fieldErrors['description'] = [
        'A token already exists with this description.'
      ];
    }
    if (requirePasswordReentry) {
      if (formData.password === '') {
        fieldErrors['password'] = ['This field is required.'];
      }
    }
    if (!_.isEmpty(fieldErrors)) {
      return Promise.reject({ fieldErrors });
    }
    return profile.createToken(formData).then(setCreatedToken, error => {
      if (error.code === 'AUTH_CREDS_INVALID') {
        return Promise.reject({
          fieldErrors: {
            password: ["That's not your password. Please try again."]
          }
        });
      } else {
        return Promise.reject({
          fieldErrors: {}
        });
      }
    });
  }

  return (
    <SimpleModal
      isOpen
      onHide={notYetCreated ? hide : undefined}
      css={css`
        width: ${FIELD_WIDTH}rem;
      `}
      header={notYetCreated && 'Create a new API token'}
    >
      {notYetCreated ? (
        <AuthForm
          onSubmit={createToken}
          defaults={{
            description: '',
            ...(requirePasswordReentry && { password: '' })
          }}
        >
          <TextArea
            label="Token description"
            field="description"
            placeholder="What's this token for?"
          />
          {requirePasswordReentry && (
            <PasswordField
              label="Please re-enter your password"
              field="password"
            />
          )}
          <ButtonBar>
            <SubmitButton disabled={tokens === null}>
              <Icon type={IconTypes.ADD} />
              Create token
            </SubmitButton>
            <CancelButton onClick={hide}>Cancel</CancelButton>
          </ButtonBar>
        </AuthForm>
      ) : (
        <>
          <p>
            Success! You've created an API token. Please copy this and save it
            somewhere now. We won't show it to you again.
          </p>
          <div
            css={css`
              ${Mixins.inputWithInsetIcon}
              margin: 1rem 0;
            `}
          >
            <input
              value={createdToken.token}
              aria-label="Token"
              readOnly
              css={css`
                font-family: monospace;
                width: 100%;
              `}
            />
            <Button
              onClick={() =>
                clipboard.writeText(createdToken.token).then(
                  () => showSuccess('Token copied to clipboard!'),
                  () => showError('Unable to copy to clipboard')
                )
              }
              flavor="subtle"
              aria-label="Copy token to clipboard"
            >
              <Icon type={IconTypes.CLIPBOARD} />
            </Button>
          </div>
          <ButtonBar>
            <NotificationArea
              css={css`
                margin-right: auto;
              `}
            />
            <Button onClick={hide}>Done</Button>
          </ButtonBar>
        </>
      )}
    </SimpleModal>
  );
}
