import _ from 'lodash';
import { css } from '@emotion/core';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ConfirmableButton } from '../components/core/Button';
import { ConfirmationMessageContent } from './managementPageStyles';
import { AuthContext, Workspace } from './model';
import { showError, showSuccess } from './notifications';
import { RoutePatterns } from '../constants';
import buildRoutePath from '../utils/buildRoutePath';
import { Colors } from '../styles';
import { Icon, IconTypes } from '../components/icons';

export default function DeleteWorkspaceButton({ workspace, projects }) {
  const { profile } = useContext(AuthContext);
  const history = useHistory();
  return (
    <ConfirmableButton
      onConfirm={() => {
        const nextWorkspaceId = profile.pickDefault(
          profile
            .workspacesWhoseUsersYouCanSee()
            .filter(ws => ws.workspace_id !== workspace.workspace_id)
        );
        workspace
          .deactivate()
          .then(() => {
            if (nextWorkspaceId) {
              history.push(
                buildRoutePath(RoutePatterns.WORKSPACE_SETTINGS, {
                  workspaceId: nextWorkspaceId
                })
              );
            } else {
              history.push(
                buildRoutePath(RoutePatterns.ORGANIZATION_SETTINGS, {
                  organizationId: workspace.organization_id
                })
              );
            }
            showSuccess('Successfully deleted workspace!');
          })
          .catch(() =>
            showError(
              'An unexpected error occurred. If the problem persists, please contact support@luminoso.com.'
            )
          );
      }}
      confirmationMessage={<ConfirmationMessage projects={projects} />}
      confirmText="Yes, delete workspace"
      trackingitem="workspace-delete-button"
      disabled={!projects}
      palette="red"
    >
      <Icon type={IconTypes.TRASH} />
      Delete this workspace
    </ConfirmableButton>
  );
}

DeleteWorkspaceButton.propTypes = {
  workspace: PropTypes.instanceOf(Workspace).isRequired,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      project_id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  )
};

function ConfirmationMessage({ projects }) {
  return (
    <ConfirmationMessageContent>
      <p>Are you sure you want to delete this workspace?</p>
      {_.isEmpty(projects) ? (
        <p>
          <strong>This action cannot be undone.</strong>
        </p>
      ) : (
        <>
          <p>
            Deleting this workspace will delete all of its data.{' '}
            <strong>This action cannot be undone.</strong>
          </p>
          <p>
            The following projects will be deleted if this workspace is deleted:
          </p>
          <ul
            css={css`
              max-height: 30vh;
              overflow: auto;
              border: 1px solid ${Colors.gray2};
            `}
          >
            {projects.map(project => (
              <li key={project.project_id}>{project.name}</li>
            ))}
          </ul>
        </>
      )}
    </ConfirmationMessageContent>
  );
}
