import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import css from '@emotion/css';

import Calendar from './Calendar';
import { Button } from './Button';
import { Icon, IconTypes } from '../icons';
import { usePrevious } from '../../utils/hooks';

const didDateChange = (oldDate, newDate) =>
  !(
    !(oldDate != null || newDate != null) ||
    (oldDate != null && newDate != null && oldDate.isSame(newDate))
  );

export default function Datepicker({
  numberOfMonths = 2,
  enabledDatesStart,
  enabledDatesEnd,
  startDate,
  endDate,
  onSelectStart,
  onSelectEnd
}) {
  const [currentMonth, setCurrentMonth] = useState(
    startDate?.clone().startOf('month') ??
      endDate?.clone().startOf('month') ??
      moment.utc().startOf('month')
  );

  const prevStartDate = usePrevious(startDate);
  const prevEndDate = usePrevious(endDate);

  // If the user types in a new start or end date, adjust the two-month range
  // the datepicker is showing to include the newly typed in date
  useEffect(() => {
    const bringDateIntoView = date => {
      if (!(date != null ? date.isValid() : undefined)) {
        return;
      }

      const firstMonth = currentMonth;
      const lastMonth = currentMonth
        .clone()
        .add({ months: numberOfMonths })
        .startOf('month');

      if (firstMonth.isBefore(date, 'month')) {
        setCurrentMonth(
          date
            .clone()
            .subtract({ months: numberOfMonths - 1 })
            .startOf('month')
        );
      } else if (lastMonth.isAfter(date, 'month')) {
        setCurrentMonth(date.clone().startOf('month'));
      }
    };

    if (didDateChange(prevEndDate, endDate)) {
      bringDateIntoView(endDate);
    }

    if (didDateChange(prevStartDate, startDate)) {
      bringDateIntoView(startDate);
    }
  });

  return (
    <div
      css={css`
        position: relative;
        display: inline-block;
        user-select: none;
      `}
    >
      <div
        css={css`
          width: 100%;
          position: absolute;
          display: flex;
          justify-content: space-between;
        `}
      >
        <Button
          flavor="subtle"
          aria-label="Previous Month Arrow"
          disabled={
            enabledDatesStart != null &&
            currentMonth
              .clone()
              .subtract({ months: 1 })
              .endOf('month')
              .isBefore(enabledDatesStart)
          }
          onClick={() => {
            setCurrentMonth(currentMonth.clone().subtract({ months: 1 }));
          }}
        >
          <Icon type={IconTypes.CARET_STROKE} direction="left" />
        </Button>
        <Button
          flavor="subtle"
          aria-label="Next Month Arrow"
          disabled={
            enabledDatesEnd != null &&
            currentMonth
              .clone()
              .add({ months: numberOfMonths })
              .startOf('month')
              .isAfter(enabledDatesEnd)
          }
          onClick={() => {
            setCurrentMonth(currentMonth.clone().add({ months: 1 }));
          }}
        >
          <Icon type={IconTypes.CARET_STROKE} direction="right" />
        </Button>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 1rem;
        `}
      >
        {[...Array(numberOfMonths)].map((el, num) => {
          const dateInMonth = currentMonth.clone().add({ months: num });

          return (
            <Calendar
              key={dateInMonth.toISOString()}
              dateInMonth={dateInMonth}
              enabledDatesStart={enabledDatesStart}
              enabledDatesEnd={enabledDatesEnd}
              startDate={startDate}
              endDate={endDate}
              onSelectStart={onSelectStart}
              onSelectEnd={onSelectEnd}
            />
          );
        })}
      </div>
    </div>
  );
}
Datepicker.propTypes = {
  numberOfMonths: PropTypes.number,
  enabledDatesStart: PropTypes.instanceOf(moment),
  enabledDatesEnd: PropTypes.instanceOf(moment),
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
  onSelectStart: PropTypes.func.isRequired,
  onSelectEnd: PropTypes.func.isRequired
};
