import { useContext } from 'react';

import { StoreContext } from '../StoreContext';
import { useSearchParams } from '../search_params';
import { getById } from '../utils/conceptUtils';

export function useAxes() {
  const { activeConcepts } = useContext(StoreContext);
  const { searchParams, updateSearch } = useSearchParams();
  const { x_axis: xAxisId, y_axis: yAxisId } = searchParams;
  const axisLabelsAreVisible = searchParams.show_axes === 'true';
  const xAxisConcept = getById(activeConcepts, xAxisId);
  const yAxisConcept = getById(activeConcepts, yAxisId);
  const hasSelectedAxis = !!(xAxisConcept || yAxisConcept);

  const resetAxes = () => {
    updateSearch({ x_axis: undefined, y_axis: undefined, show_axes: 'false' });
  };

  const toggleLabels = () => {
    updateSearch({ show_axes: (!axisLabelsAreVisible).toString() });
  };

  const setXAxis = conceptId => {
    updateSearch({ x_axis: conceptId !== xAxisId ? conceptId : null });
  };

  const setYAxis = conceptId => {
    updateSearch({ y_axis: conceptId !== yAxisId ? conceptId : null });
  };

  const hideLabels = () => {
    updateSearch({ show_axes: 'false' });
  };

  return {
    xAxisConcept,
    yAxisConcept,
    hasSelectedAxis,
    axisLabelsAreVisible,
    resetAxes,
    toggleLabels,
    setXAxis,
    setYAxis,
    hideLabels
  };
}
