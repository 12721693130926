import React from 'react';
import PropTypes from 'prop-types';

import { showError, showSuccess } from './notifications';
import { ConfirmableButton } from '../components/core/Button';
import { Workspace } from './model';
import { ConfirmationMessageContent } from './managementPageStyles';
import { Icon, IconTypes } from '../components/icons';

export default function RemoveUsersButton({ workspace, selections }) {
  if (!selections.any) {
    return null;
  }

  function removeUsers() {
    workspace
      .removeUsers(selections.selections)
      .then(
        showSuccess(
          `Successfully removed ${selections.singular ? 'user' : 'users'}.`
        )
      )
      .catch(() => {
        showError(
          'An unexpected error occurred. If the problem persists, please contact support@luminoso.com.'
        );
      })
      .finally(() => {
        selections.clear();
      });
  }

  return (
    <ConfirmableButton
      flavor="subtle"
      onConfirm={removeUsers}
      confirmationMessage={
        <ConfirmationMessage
          single={selections.singular}
          workspace={workspace}
        />
      }
      confirmText={`Yes, remove ${selections.singular ? 'user' : 'users'}`}
      trackingitem="workspace-users_remove-from-workspace-button"
    >
      <Icon type={IconTypes.CLOSE} size="18" />
      Remove
    </ConfirmableButton>
  );
}

RemoveUsersButton.propTypes = {
  workspace: PropTypes.instanceOf(Workspace).isRequired,
  selections: PropTypes.object
};

function ConfirmationMessage({ single, workspace }) {
  return (
    <ConfirmationMessageContent>
      <p>
        {single
          ? 'Are you sure you want to remove this user'
          : 'Are you sure you want to remove these users'}{' '}
        from <strong>{workspace.name}</strong>?
      </p>
      <p>
        This will completely remove them from the workspace. You can invite them
        back later if you change your mind.
      </p>
    </ConfirmationMessageContent>
  );
}
