import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import { Button } from './core/Button';
import { StoreContext } from '../StoreContext';
import { AuthContext } from '../settings/model';
import { useConceptManagement } from '../data_hooks';
import { RebuildModalWithContext } from '../project_management/RebuildModal';
import { getBuildChanges } from '../project_management/utils';
import { RoutePatterns } from '../constants';
import { Icon, IconTypes } from './icons';
import { Colors } from '../styles';

export default function Banners() {
  return (
    <div>
      <LongPathWarning />
      <RebuildPrompt />
    </div>
  );
}

export function Banner({ children, onDismiss }) {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        padding: 0.5rem 1.5rem;
        align-items: center;
        background: ${Colors.orange0};

        &:not(:last-of-type) {
          border-bottom: 1px solid ${Colors.gray3};
        }
      `}
    >
      <div
        css={css`
          flex: 1;
          color: ${Colors.gray9};
          text-align: center;
        `}
      >
        {children}
      </div>
      {onDismiss && (
        <Button
          aria-label="dismiss"
          flavor="subtle"
          palette="gray"
          onClick={onDismiss}
          css={css`
            top: -2px;
            position: relative;
          `}
        >
          <Icon type={IconTypes.CLOSE} />
        </Button>
      )}
    </div>
  );
}

Banner.propTypes = {
  children: PropTypes.node.isRequired,
  onDismiss: PropTypes.func
};

function Dismissable({ children, dismissalKey }) {
  const [dismissed, setDismissed] = React.useState(
    localStorage.getItem(dismissalKey) === 'true'
  );

  const dismiss = () => {
    setDismissed(true);
    localStorage.setItem(dismissalKey, 'true');
  };

  return !dismissed && children({ dismiss });
}

Dismissable.propTypes = {
  children: PropTypes.func.isRequired,
  dismissalKey: PropTypes.string.isRequired
};

// Like Dismissable, but store the dismissed state in sessionStorage instead of
// localStorage.  This is useful to create banners which should remember their
// dismissed state only for a single session.
function SessionDismissable({ children, dismissalKey }) {
  const [dismissed, setDismissed] = React.useState(
    sessionStorage.getItem(dismissalKey) === 'true'
  );

  const dismiss = () => {
    setDismissed(true);
    sessionStorage.setItem(dismissalKey, 'true');
  };

  return !dismissed && children({ dismiss });
}

SessionDismissable.propTypes = {
  children: PropTypes.func.isRequired,
  dismissalKey: PropTypes.string.isRequired
};

// The real maximum is around 2000 characters. We subtract a few
// hundred characters to provide time to act on the warning before
// anything potentially breaks completely.
const MAX_SAFE_PATH_LENGTH = 1500;
export function LongPathWarning() {
  const location = useLocation();
  const path = location.pathname + location.search + location.hash;
  return (
    path.length > MAX_SAFE_PATH_LENGTH && (
      <Banner>
        <p style={{ margin: 0 }}>
          Your filter contains too many selections. This may cause the
          application to crash in older browsers.
        </p>
        <p style={{ margin: 0 }}>
          Please discard some selections or clear your filter.
        </p>
      </Banner>
    )
  );
}

export function RebuildPrompt() {
  const [showModal, setShowModal] = useState(false);
  const isViewingUpload = !!useRouteMatch(RoutePatterns.UPLOAD_DATA);

  if (isViewingUpload) {
    return null;
  }

  return (
    <>
      <RebuildModalWithContext
        isOpen={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        onRebuildStart={() => {
          setShowModal(false);
        }}
      />
    </>
  );
}

export function InnerRebuildPrompt({ rebuildButtonOnClick }) {
  const { project, projectHasLoaded, workspaces } = useContext(StoreContext);
  const { serverStatus } = useContext(AuthContext);
  const latestScienceVersion = serverStatus.science_version;
  const { conceptManagement } = useConceptManagement(project);
  const [buildChanges, setBuildChanges] = useState(null);

  useEffect(() => {
    if (!project || !serverStatus || !conceptManagement) {
      return;
    }

    const changes = getBuildChanges(project, serverStatus, conceptManagement);

    if (!buildChanges) {
      // Set `buildChanges` on initial load
      setBuildChanges(changes);
    } else if (buildChanges.hasBuildChanges && !changes.hasBuildChanges) {
      // When the user addresses the issues that caused the banner to show up
      // originally, then update build changes (so that the banner is hidden).
      setBuildChanges(changes);
    } else if (
      !buildChanges.canRebuild &&
      buildChanges.hasBuildChanges &&
      changes.canRebuild
    ) {
      // If the project initially had build changes but its sentiment build was
      // still running, then the user makes further build changes, when the
      // sentiment build finishes, the banner should reflect the additional
      // changes made by the user when it *first* shows up.
      setBuildChanges(changes);
    }
  });

  if (!projectHasLoaded || !buildChanges || !buildChanges.canRebuild) {
    return null;
  }

  const {
    hasUnbuiltAssertions,
    addingSentiment,
    changingLanguage,
    significantScienceChange
  } = buildChanges;

  // Users should only be prompted to rebuild if there are changes the user has
  // edit permissions for
  const workspace = workspaces.find(
    w => w.workspace_id === project.workspace_id
  );
  const canEditAssertions = workspace.permissions.includes('account_manage');
  const hasWriteLevelChanges =
    significantScienceChange || addingSentiment || changingLanguage;
  const shouldPromptRebuild =
    (hasWriteLevelChanges && project.permissions.includes('write')) ||
    (hasUnbuiltAssertions && canEditAssertions);

  if (shouldPromptRebuild) {
    const dismissalKey =
      `rebuild_banner_dismissed_for_project_${project.project_id}` +
      `_built_${project.last_successful_build_time}`;

    return (
      <SessionDismissable key={dismissalKey} dismissalKey={dismissalKey}>
        {({ dismiss }) => (
          <Banner onDismiss={dismiss}>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 2rem;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
                `}
              >
                {significantScienceChange && (
                  <span>
                    Daylight science version <b>{latestScienceVersion}</b> is
                    now available.
                  </span>
                )}
                {addingSentiment && (
                  <span>
                    Your company's system has been upgraded to support{' '}
                    <b>sentiment analysis</b>.
                  </span>
                )}
                {hasUnbuiltAssertions && changingLanguage ? (
                  <span>
                    This project's <b>science assertions</b> and <b>language</b>{' '}
                    have been updated.
                  </span>
                ) : hasUnbuiltAssertions ? (
                  <span>
                    This project's <b>science assertions</b> have been updated.
                  </span>
                ) : changingLanguage ? (
                  <span>
                    This project's <b>language</b> has been updated.
                  </span>
                ) : null}
                Rebuild the project to incorporate these changes.
              </div>
              <Button
                palette="gray"
                onClick={rebuildButtonOnClick}
                css={css`
                  height: fit-content;
                `}
              >
                Rebuild
              </Button>
            </div>
          </Banner>
        )}
      </SessionDismissable>
    );
  } else {
    return null;
  }
}
