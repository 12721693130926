import _ from 'lodash';
import { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { StoreContext } from '../../StoreContext';
import { MetadataField } from '../../classes/MetadataFields';
import { useLocalStorage, useStableMemo } from '../../utils/hooks';

export const useMetadataSelections = () => {
  const sidePanel = useSelections('side-panel');
  const viewer = useSelections('viewer');
  return { sidePanel, viewer };
};

const useSelections = selectionType => {
  const { metadata } = useContext(StoreContext);
  const [fields, setFields] = useFields(selectionType);

  const toggleField = field => {
    setFields(fields => {
      if (fields.includes(field)) {
        return fields.filter(f => f !== field);
      } else {
        return [...fields, field];
      }
    });
  };

  const toggleAllFields = () => {
    setFields(fields => {
      if (fields.length === metadata.length) {
        return [];
      } else {
        return metadata;
      }
    });
  };

  return {
    fields,
    toggleField,
    toggleAllFields,
    all: fields.length === metadata.length,
    any: fields.length > 0
  };
};

const selectionProps = PropTypes.shape({
  fields: PropTypes.arrayOf(PropTypes.instanceOf(MetadataField).isRequired)
    .isRequired,
  toggleField: PropTypes.func.isRequired,
  toggleAllFields: PropTypes.func.isRequired,
  all: PropTypes.bool.isRequired,
  any: PropTypes.bool.isRequired
});

export const selectedFieldsProps = PropTypes.shape({
  sidePanel: selectionProps.isRequired,
  viewer: selectionProps.isRequired
});

const useFields = selectionType => {
  const { projectId } = useParams();
  const { metadata } = useContext(StoreContext);
  const key = `${projectId}-${selectionType}-metadata`;

  // By default, all metadata fields should be enabled in the document viewer
  // and only the first metadata field in the side panel.
  let defaultSelectedMetadata = [];
  if (selectionType === 'viewer') {
    defaultSelectedMetadata = metadata;
  } else if (selectionType === 'side-panel' && metadata.length > 0) {
    defaultSelectedMetadata = [metadata[0]];
  }

  // Because localStorage only deals with strings, we store the selected fields
  // as a stringified array of the selected metadata field names.
  const initialState = JSON.stringify(_.map(defaultSelectedMetadata, 'name'));
  const [selectedNames, setSelectedNames] = useLocalStorage(key, initialState);

  const fields = useStableMemo(() => {
    const names = JSON.parse(selectedNames);
    return metadata.filter(field => names.includes(field.name));
  }, [metadata, selectedNames]);

  const setFields = useCallback(
    updater => {
      setSelectedNames(currentValue => {
        const names = JSON.parse(currentValue);
        const fields = metadata.filter(field => names.includes(field.name));
        const updatedFields = updater(fields);
        return JSON.stringify(_.map(updatedFields, 'name'));
      });
    },
    [metadata, setSelectedNames]
  );

  return [fields, setFields];
};
