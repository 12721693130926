import _ from 'lodash';
import React, { useCallback, useContext, useReducer } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import Alert from './components/core/Alert';
import { Button } from './components/core/Button';
import { ZIndexes } from './styles';
import { Icon, IconTypes } from './components/icons';

const UndoBannerContext = React.createContext(_.noop);

const UNDO_TIMEOUT = 10000;
const RETRY_TIMEOUT = 10000;
const SUCCESS_TIMEOUT = 2000;
const RETRY_ERROR_TIMEOUT = 5000;

/**
 * @typedef {Object} bannerConfig
 * @property {string} message - Message to display in undo banner. Displays
 *                              new line characters.
 * @property {function(): Promise} onUndo - Function to call when a user clicks
 *                                          the undo button.
 * @property {string} [trackingItem] - data-tracking-item for undo button.
 * @property {function(*): boolean} [shouldRetry] -
 *           Function to check if a failed undo should be retried. Gets passed
 *           the error from the failed undo.
 */

/**
 * Hook that returns a function used to dispatch an undo banner. The returned
 * function expects an object containing any banner configuration options.
 *
 * It requires a `message` property (the message to display) and an `onUndo`
 * property (the function to call when trying to undo).
 *
 * It optionally accepts:
 * - A `trackingItem` property that sets a
 *   `data-tracking-item` onto the undo button itself.
 *
 * - A `shouldRetry` function that checks if we should give the user
 *   the option to retry a failed undo.
 *
 * @returns {function(bannerConfig): void} - dispatchUndoBanner
 */
export function useDispatchUndoBanner() {
  const dispatchUndoBanner = useContext(UndoBannerContext);
  if (dispatchUndoBanner === _.noop) {
    throw 'Cannot use "useUndoDispatcher" outside of a <UndoBannerArea />';
  }
  return dispatchUndoBanner;
}

export function UndoBannerArea({ children }) {
  const [undoBanner, dispatchUndoBanner] = useUndoBanner();

  return (
    <>
      {undoBanner && (
        <Container>
          <UndoBanner banner={undoBanner} />
        </Container>
      )}
      <UndoBannerContext.Provider value={dispatchUndoBanner}>
        {children}
      </UndoBannerContext.Provider>
    </>
  );
}

export const Container = styled.div`
  position: absolute;
  z-index: ${ZIndexes.undoBanner};
  display: flex;
  flex-direction: column;

  /* Horizontally center banners */
  width: 100%;
  align-items: center;

  /* Mouse events should pass through this div, but not the banners */
  pointer-events: none;
  > * {
    pointer-events: auto;
  }
`;

export function UndoBanner({
  banner: { status, undo, message, retry, trackingItem }
}) {
  return (
    <Alert
      type={status === 'failure' || status === 'error' ? 'error' : 'success'}
      cancellable={true}
      css={css`
        width: max-content;
        max-width: 80vw;
        word-break: break-word;
        margin-bottom: 1rem;

        button[aria-label='Dismiss'] {
          margin-left: 1.5rem;
          /* If there's an Undo/Retry button, vertically align with it */
          ${(!status || status === 'failure') && 'margin-top: 0.5rem'};
        }
      `}
    >
      {status === 'success' ? (
        <span>Undo successful</span>
      ) : status === 'failure' ? (
        <MessageWithButton
          message="Sorry, we were unable to undo that action"
          button={<Button onClick={retry}>Retry</Button>}
        />
      ) : status === 'error' ? (
        <span>Sorry, we were unable to undo that action</span>
      ) : (
        <MessageWithButton
          message={message}
          button={<UndoButton onClick={undo} trackingItem={trackingItem} />}
        />
      )}
    </Alert>
  );
}

function MessageWithButton({ message, button }) {
  return (
    <div
      css={css`
        display: flex;
        align-items: flex-start;
      `}
    >
      <div
        css={css`
          align-self: center;
          display: flex;
          flex-direction: column;
          margin-right: 0.5rem;
        `}
      >
        {message.split('\n').map((m, index) => (
          <span key={index}>{m}</span>
        ))}
      </div>
      {button}
    </div>
  );
}

function UndoButton(props) {
  return (
    <Button {...props}>
      <Icon type={IconTypes.ROTATE} />
      <span>Undo</span>
    </Button>
  );
}

function useUndoBanner() {
  const [bannerState, dispatch] = useReducer(bannerReducer, null);
  const banner = bannerState?.hidden ? null : bannerState;

  const dispatchUndoBanner = useCallback(
    ({ onUndo, shouldRetry, ...banner }) => {
      const id = _.uniqueId(banner.message);
      const removeBanner = () => dispatch({ type: 'remove-banner', id });
      const handler = setTimeout(removeBanner, UNDO_TIMEOUT);

      const undo = () => {
        clearTimeout(handler);
        // Hide the banner while undoing things to prevent the undo button from
        // being click on multiple times
        dispatch({ type: 'hide-banner', id });

        const handleUndoSuccess = () => {
          dispatch({ type: 'set-status', id, status: 'success' });
          setTimeout(removeBanner, SUCCESS_TIMEOUT);
        };

        const handleUndoFailure = error => {
          // Default to retry if there is no shouldRetry function
          if (shouldRetry?.(error) ?? true) {
            const failureTimerHandler = setTimeout(removeBanner, RETRY_TIMEOUT);
            const retry = () => {
              clearTimeout(failureTimerHandler);
              dispatch({ type: 'hide-banner', id });
              onUndo().then(handleUndoSuccess).catch(handleUndoFailure);
            };
            dispatch({ type: 'set-status', id, status: 'failure', retry });
          } else {
            dispatch({ type: 'set-status', id, status: 'error' });
            setTimeout(removeBanner, RETRY_ERROR_TIMEOUT);
          }
        };

        onUndo().then(handleUndoSuccess).catch(handleUndoFailure);
      };

      dispatch({ type: 'add-banner', banner: { ...banner, id, undo } });
    },
    []
  );

  return [banner, dispatchUndoBanner];
}

function bannerReducer(state, event) {
  const updateIfNotStale = value => (event.id === state?.id ? value : state);

  switch (event.type) {
    case 'add-banner': {
      return event.banner;
    }
    case 'remove-banner': {
      return updateIfNotStale(null);
    }
    case 'hide-banner': {
      return updateIfNotStale({ ...state, hidden: true });
    }
    case 'set-status': {
      const { status, retry } = event;
      return updateIfNotStale({ ...state, hidden: false, status, retry });
    }
  }
}
