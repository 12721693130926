import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { Colors } from '../../styles';
import { useUniqueId } from '../../utils/hooks';

export function BubbleInput({
  as,
  label,
  checked,
  disabled = false,
  id: passedId,
  ...passedProps
}) {
  const generatedId = useUniqueId();
  const id = passedId ?? generatedId;

  return (
    <BubbleTarget checked={checked}>
      {React.createElement(as, {
        id,
        checked,
        disabled,
        ...passedProps
      })}
      <label
        htmlFor={id}
        css={css`
          margin-left: 0.5rem;
          color: ${disabled ? Colors.gray5 : Colors.gray9};
        `}
      >
        {label}
      </label>
    </BubbleTarget>
  );
}

BubbleInput.propTypes = {
  // TODO use PropTypes.elementType when we're on a newer version of prop-types
  as: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired
};

const BubbleTarget = styled.div`
  display: flex;
  width: fit-content;
  border-radius: 1rem;
  background: ${({ checked }) => (checked ? Colors.blue0 : undefined)};
  padding: 0.5rem;
  padding-right: 0.65rem; // crop bubble less closely on the right
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;
