import React from 'react';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';

import { MostPrevalentConceptsCard } from './highlights/MostPrevalentConcepts';
import { WhatPeopleFeelStronglyAboutCard } from './highlights/WhatPeopleFeelStronglyAbout';
import { ConversationClustersCard } from './highlights/ConversationClusters';
import { IssuesAffectingScoreCard } from './highlights/IssuesAffectingScore';
import { ProjectDetail } from './components/ProjectDetail';
import { FlexibleGrid } from './components/core/Layout';
import { Colors } from './styles';
import { ProjectTitle } from './highlights/ProjectTitle';
import { trademark } from './utils/trademark';

export function Highlights({ projectId, workspaceId }) {
  return (
    <div
      css={css`
        box-sizing: border-box;
        height: 100%;
        padding: 1rem;
        background: ${Colors.gray0};
        overflow-y: auto;

        > * {
          margin-top: 1rem;
        }
      `}
    >
      <ProjectTitle />
      <FlexibleGrid minColumnWidth="25rem" gap="medium">
        <MostPrevalentConceptsCard
          projectId={projectId}
          workspaceId={workspaceId}
        />
        <ConversationClustersCard
          projectId={projectId}
          workspaceId={workspaceId}
        />
        <IssuesAffectingScoreCard
          projectId={projectId}
          workspaceId={workspaceId}
        />
        <WhatPeopleFeelStronglyAboutCard
          projectId={projectId}
          workspaceId={workspaceId}
        />
      </FlexibleGrid>
      <footer
        css={css`
          text-align: center;
        `}
      >
        <ProjectDetail />
        {trademark}
      </footer>
    </div>
  );
}

Highlights.propTypes = {
  projectId: PropTypes.string.isRequired,
  workspaceId: PropTypes.string.isRequired
};
