import React, { useEffect, useState } from 'react';
import css from '@emotion/css';
import { Route, Switch, useHistory } from 'react-router-dom';
import { uniqueId } from 'lodash';

import Reddit from './Reddit/Reddit';
import { FlexibleGrid } from '../../components/core/Layout';
import Reviews from './Reviews/Reviews';
import Surveys from './Surveys/Surveys';
import { AlertTypes, RoutePatterns } from '../../constants';
import Alert from '../../components/core/Alert';
import { ReviewsPage } from './Reviews/ReviewsPage';
import { RedditPage } from './Reddit/RedditPage';

const initialRedditInputs = [
  { name: `url${uniqueId()}`, value: '', error: '' }
];

const DisplayStreamData = ({ projectData }) => {
  const history = useHistory();

  const initialAlertState = {
    type: 'success',
    message: ''
  };

  const [alertState, setAlertState] = useState(initialAlertState);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const [redditInputs, setRedditInputs] = useState(initialRedditInputs);
  const [redditRepeat, setRedditRepeat] = useState(false);
  const [redditRepeatInterval, setRedditRepeatInterval] = useState('day');

  const [reviewsSourcesInputs, setReviewsSourcesInputs] = useState([]);

  const initialReviewInputs = [
    { name: `url${uniqueId()}`, value: '', error: '' }
  ];
  const [amazonInputs, setAmazonInputs] = useState(initialReviewInputs);
  const [audibleInputs, setAudibleInputs] = useState(initialReviewInputs);
  const [bestBuyInputs, setBestBuyInputs] = useState(initialReviewInputs);
  const [costcoInputs, setCostcoInputs] = useState(initialReviewInputs);
  const [samsclubInputs, setSamsclubInputs] = useState(initialReviewInputs);
  const [walmartInputs, setWalmartInputs] = useState(initialReviewInputs);
  const [targetInputs, setTargetInputs] = useState(initialReviewInputs);

  const [reviewsRepeat, setReviewsRepeat] = useState(false);
  const [reviewsRepeatInterval, setReviewsRepeatInterval] = useState('day');

  const reviewInputs = {
    amazonInputs,
    audibleInputs,
    bestBuyInputs,
    costcoInputs,
    samsclubInputs,
    walmartInputs,
    targetInputs
  };

  const setReviewInputs = {
    setAmazonInputs,
    setAudibleInputs,
    setBestBuyInputs,
    setCostcoInputs,
    setSamsclubInputs,
    setWalmartInputs,
    setTargetInputs
  };

  const alertHandler = alert => {
    setAlertState(alert);
  };

  const closeAlertHandler = () => {
    setIsAlertOpen(false);
    setAlertState(initialAlertState);
  };

  useEffect(() => {
    if (alertState.message) {
      setIsAlertOpen(true);
    }
    const closeAlertTimeout = setTimeout(() => {
      closeAlertHandler();
    }, 3000);
    return () => {
      clearTimeout(closeAlertTimeout);
    };
  }, [alertState.message]);

  return (
    <>
      <Alert
        css={css`
          position: absolute;
          top: 10%;
          left: 50%;
          transform: translateX(-50%);
        `}
        showing={isAlertOpen}
        cancellable={true}
        disappearing={true}
        onHide={closeAlertHandler}
        type={
          alertState.type === 'success' ? AlertTypes.SUCCESS : AlertTypes.ERROR
        }
      >
        {alertState.message}
      </Alert>

      <Switch>
        <Route exact path={RoutePatterns.UPLOAD_STREAM_DATA}>
          <div
            css={css`
              box-sizing: border-box;
              width: 100%;
              margin-top: 1rem;

              * {
                box-sizing: border-box;
              }
            `}
          >
            <FlexibleGrid minColumnWidth="25rem" gap="medium">
              <Reddit
                openReddit={() =>
                  history.push(
                    `${RoutePatterns.UPLOAD_STREAM_DATA}/select/reddit/input-urls`
                  )
                }
              />

              <Reviews
                openReviews={() =>
                  history.push(
                    `${RoutePatterns.UPLOAD_STREAM_DATA}/select/reviews/select-source`
                  )
                }
              />

              <Surveys />
            </FlexibleGrid>
          </div>
        </Route>
        <Route path={`${RoutePatterns.UPLOAD_STREAM_DATA}/select/reddit`}>
          <RedditPage
            repeatInterval={redditRepeatInterval}
            setRepeat={setRedditRepeat}
            setRepeatInterval={setRedditRepeatInterval}
            repeat={redditRepeat}
            inputs={redditInputs}
            setInputs={setRedditInputs}
            projectData={projectData}
            onAlert={alertHandler}
            initialInputs={initialRedditInputs}
          />
        </Route>
        <Route path={`${RoutePatterns.UPLOAD_STREAM_DATA}/select/reviews`}>
          <ReviewsPage
            sourcesInputs={reviewsSourcesInputs}
            setSourcesInputs={setReviewsSourcesInputs}
            projectData={projectData}
            onAlert={alertHandler}
            inputs={reviewInputs}
            setInputs={setReviewInputs}
            setRepeat={setReviewsRepeat}
            repeat={reviewsRepeat}
            repeatInterval={reviewsRepeatInterval}
            setRepeatInterval={setReviewsRepeatInterval}
          />
        </Route>
      </Switch>
    </>
  );
};

export default DisplayStreamData;
