import React from 'react';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';

import List from '../../components/core/List';
import CategoryView from '../../components/CategoryView';
import { Category } from '../../classes/MetadataFields';
import { ColumnGroup } from '../ColumnGroup';
import { thousandify } from '../../utils/NumFmtUtils';
import Spinner from '../../components/core/Spinner';

const LIST_PREVIEW_THRESHOLD = 100;
const MAX_UNIQUE_STRINGS_THRESHOLD = 10000;

export function FilterPreview({ group, stats }) {
  let preview;
  if (group.type === 'skip') {
    preview = <em>Skipped data are not uploaded.</em>;
  } else if (stats == null) {
    preview = <PreviewSpinner />;
  } else if (group.type === 'text') {
    preview = 'Text fields are analyzed for concepts.';
  } else if (group.type === 'title') {
    preview = 'Title fields are used when viewing documents.';
  } else if (group.type === 'date') {
    preview = <DateFilterPreview group={group} stats={stats} />;
  } else if (group.type === 'number' || group.type === 'score') {
    preview = <NumericFilterPreview group={group} stats={stats} />;
  } else if (group.type === 'string') {
    preview = <StringFilterPreview group={group} stats={stats} />;
  }
  return <div>{preview}</div>;
}

FilterPreview.propTypes = {
  group: PropTypes.instanceOf(ColumnGroup).isRequired,
  stats: PropTypes.shape({
    uniqueValues: PropTypes.array,
    minimum: PropTypes.any,
    maximum: PropTypes.any
  })
};

function StringFilterPreview({ group, stats }) {
  const numberOfUniqueValues = stats.uniqueValues.length;

  if (numberOfUniqueValues <= LIST_PREVIEW_THRESHOLD) {
    return <CategoricalFilterPreview group={group} stats={stats} />;
  } else if (numberOfUniqueValues <= MAX_UNIQUE_STRINGS_THRESHOLD) {
    return (
      <>
        You will be able to search and select values to filter. There are{' '}
        <Count count={numberOfUniqueValues} /> unique string values.
      </>
    );
  } else {
    return (
      <>
        There are <Count count={numberOfUniqueValues} /> unique string values.
        This is too many to filter on in Daylight. You will not be able to
        interact with this field.
      </>
    );
  }
}

function NumericFilterPreview({ group, stats }) {
  const numberOfUniqueValues = stats.uniqueValues.length;

  if (numberOfUniqueValues <= LIST_PREVIEW_THRESHOLD) {
    return <CategoricalFilterPreview group={group} stats={stats} />;
  }

  return (
    <>
      You will be able to select a range to filter. There are{' '}
      <Count count={numberOfUniqueValues} /> unique {group.type} values from{' '}
      <Count count={stats.minimum} /> to <Count count={stats.maximum} />.
    </>
  );
}

function DateFilterPreview({ stats }) {
  const valueCount = stats.uniqueValues.length;
  if (valueCount === 0) {
    return null;
  }
  if (valueCount === 1) {
    return (
      <>
        You will be able to select a range to filter. The only unique date value
        is <strong>{stats.minimum}</strong>.
      </>
    );
  }
  return (
    <>
      You will be able to select a range to filter from{' '}
      <strong>{stats.minimum}</strong> to <strong>{stats.maximum}</strong>.
    </>
  );
}

function CategoricalFilterPreview({ group, stats }) {
  const valueCount = stats.uniqueValues.length;
  if (valueCount === 0) {
    return null;
  }

  return (
    <>
      <List
        hoverable={false}
        css={css`
          margin: 0.5rem 0rem;
        `}
      >
        {stats.uniqueValues.map(({ value, count }) => (
          <div
            css={css`
              font-size: 0.875rem;
              padding: 0.25rem 0.5rem;
            `}
            key={value}
          >
            <CategoryView category={new Category(value, count)} />
          </div>
        ))}
      </List>
      You will be able to select values to filter. There
      {valueCount === 1 ? ' is ' : ' are '}
      <Count count={valueCount} /> unique {group.type}
      {valueCount === 1 ? ' value.' : ' values.'}
    </>
  );
}

function Count({ count }) {
  return <strong>{thousandify(count)}</strong>;
}

function PreviewSpinner() {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <Spinner size="medium" />
    </div>
  );
}
