import React from 'react';
import { css } from '@emotion/core';

import { Icon, IconTypes } from '../../components/icons';
import { Colors } from '../../styles';
import { Card } from '../../components/Card';
import { useConceptCountContext } from './ConceptCountProvider';
import { Button } from '../../components/core/Button';

export const PlainConcept = ({ concept }) => (
  <ConceptListEntry concept={concept} />
);

export const DeletedConcept = ({ concept }) => (
  <ConceptListEntry
    concept={concept}
    color={Colors.red5}
    iconType={IconTypes.MINUS}
    conceptDisplayFn={concept => <s>{concept.concept}</s>}
  />
);

export const AddedConcept = ({ concept }) => (
  <ConceptListEntry
    concept={concept}
    color={Colors.green7}
    iconType={IconTypes.ADD}
  />
);

export const TrashableConcept = ({ concept, onClick }) => (
  <ConceptListEntry
    concept={concept}
    iconColor={Colors.red5}
    iconType={IconTypes.TRASH}
    iconAfterLabel={true}
    iconOnClick={onClick}
    iconAriaLabel="Remove assertion"
  />
);

export const EditableConceptsList = ({ concepts, onRemoveConcept }) => (
  <div
    css={css`
      overflow-y: auto;
    `}
  >
    {concepts.map(concept => (
      <TrashableConcept
        key={concept.concept}
        concept={concept}
        onClick={() => {
          onRemoveConcept(concept);
        }}
      />
    ))}
  </div>
);

export const ConceptListEntry = ({
  concept,
  color,
  iconColor,
  iconType,
  iconOnClick,
  iconAriaLabel,
  conceptDisplayFn = c => c.concept,
  iconAfterLabel = false
}) => {
  const { conceptCounts } = useConceptCountContext();

  const icon = (
    <div
      css={css`
        width: 1rem;
        display: flex;
        align-items: center;
        margin-left: ${iconAfterLabel && 'auto'};
        color: ${iconColor};
      `}
    >
      {iconType &&
        (iconOnClick ? (
          <Button
            aria-label={iconAriaLabel}
            onClick={iconOnClick}
            flavor="subtle"
            palette="red"
            padded={false}
          >
            <Icon type={iconType} />
          </Button>
        ) : (
          <Icon type={iconType} />
        ))}
    </div>
  );

  const label = (
    <div>
      <span>{conceptDisplayFn(concept)}</span>
      <span>
        {conceptCounts && conceptCounts[concept.concept]
          ? ' (' + conceptCounts[concept.concept] + ')'
          : null}
      </span>
    </div>
  );

  return (
    <Card.Section
      data-test-id="concept-list-entry"
      css={css`
        display: flex;
        flex-direction: row;
        color: ${color};
        gap: 0.5rem;
        &:not(:last-child) {
          border-bottom: 1px solid ${Colors.gray1};
        }
      `}
    >
      {iconAfterLabel ? (
        <>
          {label} {icon}
        </>
      ) : (
        <>
          {icon} {label}
        </>
      )}
    </Card.Section>
  );
};
