import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Alert from '../components/core/Alert';
import { AlertTypes, RoutePatterns } from '../constants';
import buildRoutePath from '../utils/buildRoutePath';

export function UploadErrorPage({
  uploadStatus,
  buildErrorReason,
  selectedWorkspace,
  projectId,
  onRestartUploadProcess
}) {
  return (
    <div className="upload-page__result-container">
      {uploadStatus === 'error: no docs' ? (
        <Alert type={AlertTypes.ERROR}>
          <p>
            We were unable to build your project because there was not enough
            text to analyze.
          </p>
          <p>
            Please fix your dataset and{' '}
            <Link
              className="upload-page__upload-data-link"
              to={buildRoutePath(RoutePatterns.UPLOAD_DATA, {
                workspaceId: selectedWorkspace,
                projectId
              })}
              onClick={onRestartUploadProcess}
            >
              try again
            </Link>
            .
          </p>
        </Alert>
      ) : buildErrorReason ? (
        <Alert type={AlertTypes.ERROR}>
          <p>Something went wrong while trying to build your project.</p>
          <p className="upload-page__build-error-reason">{buildErrorReason}</p>
        </Alert>
      ) : (
        <Alert type={AlertTypes.ERROR}>Sorry, something went wrong.</Alert>
      )}
    </div>
  );
}

UploadErrorPage.propTypes = {
  uploadStatus: PropTypes.string.isRequired,
  buildErrorReason: PropTypes.string,
  selectedWorkspace: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  onRestartUploadProcess: PropTypes.func.isRequired
};
