import React, { useContext } from 'react';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch
} from 'react-router-dom';
import { css } from '@emotion/core';

import ExternalLink from '../components/core/ExternalLink';
import { Colors } from '../styles';
import { UserProfile } from './UserProfile';
import { ChangePassword } from './ChangePassword';
import { RoutePatterns } from '../constants';
import { WorkspaceSettings } from './WorkspaceSettings';
import buildRoutePath from '../utils/buildRoutePath';
import { AuthContext, AuthContextProvider } from './model';
import { OrganizationSettings } from './OrganizationSettings';
import OrganizationUsage from './OrganizationUsage';
import { Tokens } from './Tokens';
import { useDocumentTitle } from '../utils/hooks';

export default function Settings() {
  useDocumentTitle('Settings | Luminoso Daylight');
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        margin: 1rem;
        margin-top: 2rem;

        /* The topmost element of every section of the page should be flush
         * with the top of the page itself.
         */
        > div > :first-child {
          padding-top: 0;
        }
      `}
    >
      <AuthContextProvider>
        <Navigation />
        <Routes />
      </AuthContextProvider>
    </div>
  );
}

function Navigation() {
  const { profile } = useContext(AuthContext);
  const workspaceMatch = useRouteMatch({
    path: RoutePatterns.WORKSPACE_SETTINGS
  });
  const workspaceId =
    workspaceMatch?.params.workspaceId ??
    profile.pickDefault(profile.workspacesWhoseUsersYouCanSee());
  const organizationMatch = useRouteMatch({
    path: RoutePatterns.ORGANIZATION_SETTINGS
  });
  const organizationId =
    organizationMatch?.params.organizationId ?? profile.organizationId;
  const canManageWorkspaces =
    profile.workspacesForPermission('account_manage').length > 0;
  const canManageOrganizations =
    profile.organizationsForPermission('organization_manage').length > 0 ||
    profile.globalPermissions.includes('organization_manage');

  return (
    <div
      css={css`
        padding: 0.5rem;
        padding-right: 1rem;
        min-width: 14rem;
      `}
    >
      <h4>Personal settings</h4>
      <NavList>
        <SettingsLink to={RoutePatterns.PROFILE}>User profile</SettingsLink>
        <SettingsLink to={RoutePatterns.PASSWORD}>Change password</SettingsLink>
        <SettingsLink to={RoutePatterns.TOKENS}>Tokens</SettingsLink>
      </NavList>
      <h4>Team settings</h4>
      <NavList>
        {canManageOrganizations && (
          <SettingsLink
            to={buildRoutePath(RoutePatterns.ORGANIZATION_SETTINGS, {
              organizationId
            })}
            exact
          >
            Organization management
          </SettingsLink>
        )}
        {workspaceId && (
          <SettingsLink
            to={buildRoutePath(RoutePatterns.WORKSPACE_SETTINGS, {
              workspaceId
            })}
          >
            Workspace management
          </SettingsLink>
        )}
        {canManageWorkspaces && (
          <SettingsLink
            to={buildRoutePath(RoutePatterns.ORGANIZATION_USAGE, {
              organizationId
            })}
          >
            Usage
          </SettingsLink>
        )}
      </NavList>
      <h4>Support</h4>
      <NavList>
        <SettingsLink href="https://www.luminoso.com/getting-started-with-daylight/">
          Getting started
        </SettingsLink>
        <SettingsLink href="https://www.luminoso.com/settings-guide">
          Settings page guide
        </SettingsLink>
        <SettingsLink href="https://www.luminoso.com/getting-started-with-daylight/roles-and-actions-in-daylight">
          Roles and actions
        </SettingsLink>
        <SettingsLink href="/api/v5/">API documentation</SettingsLink>
      </NavList>
    </div>
  );
}

function NavList({ children }) {
  return (
    <nav
      css={css`
        width: 100%;
      `}
    >
      <ul
        css={css`
          list-style: none;
          padding-inline-start: 1rem;
        `}
      >
        {children}
      </ul>
    </nav>
  );
}

function SettingsLink({ to, href, children, exact }) {
  const linkStyles = css`
    color: ${Colors.gray9};
    border-left-width: 3px;
    border-left-color: transparent;
    border-left-style: solid;
    padding-left: 1rem;
  `;
  return (
    <li
      css={css`
        margin-top: 1rem;
        margin-bottom: 1rem;
      `}
    >
      {to === undefined ? (
        <ExternalLink href={href} css={linkStyles}>
          {children}
        </ExternalLink>
      ) : (
        <NavLink
          to={to}
          activeStyle={{
            color: Colors.blue4,
            borderLeftColor: Colors.blue4
          }}
          exact={exact}
          css={linkStyles}
        >
          {children}
        </NavLink>
      )}
    </li>
  );
}

function Routes() {
  const { profile } = useContext(AuthContext);

  return (
    <Switch>
      <Route exact path={RoutePatterns.PROFILE}>
        <UserProfile />
      </Route>
      <Route exact path={RoutePatterns.PASSWORD}>
        <ChangePassword />
      </Route>
      <Route path={RoutePatterns.ORGANIZATION_USAGE}>
        <OrganizationUsage />
      </Route>
      <Route
        path={RoutePatterns.ORGANIZATION_SETTINGS}
        render={({ match }) => {
          const organizationId = match.params.organizationId;
          return <OrganizationSettings organizationId={organizationId} />;
        }}
      />
      <Route
        path={RoutePatterns.WORKSPACE_SETTINGS}
        render={({ match }) => {
          const workspaceId = match.params.workspaceId;
          return <WorkspaceSettings workspaceId={workspaceId} />;
        }}
      />
      <Route path={RoutePatterns.TOKENS}>
        <Tokens />
      </Route>
      <Redirect
        exact
        from={RoutePatterns.SETTINGS}
        to={RoutePatterns.PROFILE}
      />
      <Redirect
        exact
        from={RoutePatterns.WORKSPACE_USAGE}
        to={buildRoutePath(RoutePatterns.ORGANIZATION_USAGE, {
          organizationId: profile.organizationId
        })}
      />
    </Switch>
  );
}
