import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';

import { getDashboardProjects } from './utils/ApiUtilsV5';
import { DashboardStatusMessage } from './dashboard/DashboardStatusMessage';
import {
  DASHBOARD_STATUS,
  getDashboardStatus
} from './dashboard/dashboardStatus';
import { Icon, IconTypes } from './components/icons';
import { Button } from './components/core/Button';

const Dashboard = ({ projectId }) => {
  const [currentProject, setCurrentProject] = useState(null);
  const [status, setStatus] = useState();
  const lumiToken = localStorage.getItem('auth_token').replace(/['"]+/g, '');

  useEffect(() => {
    if (projectId) {
      const fetchData = async () => {
        try {
          const projects = await getDashboardProjects();
          const foundProject = projects.find(
            project => project.project_id === projectId
          );

          if (foundProject) {
            setCurrentProject(foundProject);
            setStatus(getDashboardStatus(foundProject));
          } else {
            console.error('Project not found:', projectId);
          }
        } catch (error) {
          console.error('Error fetching projects:', error);
        }
      };

      fetchData();
    }
  }, [projectId]);

  const openDashboard = () => {
    const dashboardUrl = `https://dashboards.luminoso.com/app/projects/${projectId}/dashboard?lumitoken=${lumiToken}`;
    window.open(dashboardUrl, '_blank');
  };

  if (typeof status === 'undefined') {
    return null;
  }
  const dashboardIsReady = status === DASHBOARD_STATUS.READY;
  if (!dashboardIsReady) {
    return (
      <DashboardStatusMessage
        status={status}
        css={css`
          font-size: 1.5rem;
        `}
      />
    );
  }
  return (
    <div>
      {currentProject && dashboardIsReady && (
        <div>
          <Button
            palette="green"
            onClick={() => openDashboard()}
            css={css`
              font-size: 1rem;
              display: flex;
              align-items: center;
              position: absolute;
              top: 50%;
              right: 50%;
            `}
          >
            <Icon type={IconTypes.DASHBOARD} size="18" />
            Open Dashboard
          </Button>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
