import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { percentify, thousandify } from '../utils/NumFmtUtils';
import { Colors } from '../styles';

const TooltipHeader = styled.h6`
  padding-top: 0;
  font-size: 1rem;
`;

export default function ComparisonChartTooltipContent({
  header,
  matchCounts,
  matchType,
  total
}) {
  return (
    <>
      <TooltipHeader>{header}</TooltipHeader>
      <div>
        <Match count={matchCounts.exact} total={total}>
          Exact matches
        </Match>
        {matchType === 'total' && (
          <>
            <br />
            <Match count={matchCounts.total - matchCounts.exact} total={total}>
              Conceptual matches
            </Match>
          </>
        )}
      </div>
    </>
  );
}

ComparisonChartTooltipContent.propTypes = {
  header: PropTypes.node.isRequired,
  matchCounts: PropTypes.shape({
    exact: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired,
  matchType: PropTypes.oneOf(['exact', 'total']).isRequired,
  total: PropTypes.number.isRequired
};

const TooltipKey = styled.div`
  border-left: 0.5rem solid ${Colors.blue2};
  padding-left: 0.25rem;

  &:first-child {
    border-left-color: ${Colors.blue4};
  }
`;

const TooltipLabel = styled.div`
  color: ${Colors.gray5};
`;

const TooltipPercentage = styled.span`
  color: ${Colors.gray5};
`;

const Match = props => {
  return (
    <TooltipKey>
      <TooltipLabel>{props.children}</TooltipLabel>
      <div>
        {thousandify(props.count)}{' '}
        <TooltipPercentage>
          ({percentify(props.count, props.total)})
        </TooltipPercentage>
      </div>
    </TooltipKey>
  );
};

Match.propTypes = {
  children: PropTypes.node.isRequired,
  count: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};
