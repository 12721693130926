import React from 'react';
import styled from '@emotion/styled';

const NotFoundContext = React.createContext({
  onNotFound: () => {}
});

const PageNotFoundDiv = styled.div`
  font-weight: bold;
`;

/*
CapturePageNotFound should sit at the point in the component tree where you'd
want to display the message about an unmatched route. If PageNotFound is
rendered at any point in the tree under CapturePageNotFound, CapturePageNotFound
will render its message instead of whichever children it was passed.
*/
export class CapturePageNotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pageNotFound: false };
    this.handlePageNotFound = this.handlePageNotFound.bind(this);
  }

  handlePageNotFound() {
    this.setState({ pageNotFound: true });
  }

  render() {
    return (
      <NotFoundContext.Provider value={{ onNotFound: this.handlePageNotFound }}>
        {this.state.pageNotFound ? (
          <PageNotFoundDiv>
            This is not the page you were looking for. 404 Error
          </PageNotFoundDiv>
        ) : (
          this.props.children
        )}
      </NotFoundContext.Provider>
    );
  }
}

export default class PageNotFound extends React.Component {
  componentDidMount() {
    this.context.onNotFound();
  }

  render() {
    return null;
  }
}

PageNotFound.contextType = NotFoundContext;
