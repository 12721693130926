import React, { useContext } from 'react';

import { AuthContext, formatTokenDate } from './model';
import {
  ControlBar,
  Subtitle,
  TableAndControlsContainer,
  ManagementPageContainer
} from './managementPageStyles';
import MultiSelectTable, {
  useClickableSelections
} from '../components/MultiSelectTable';
import PlaceholderText from '../components/core/PlaceholderText';
import NewTokenButton from './NewTokenButton';
import DeleteTokensButton from './DeleteTokensButton';
import { NotificationArea } from './notifications';

export function Tokens() {
  const { profile } = useContext(AuthContext);
  const tokens = profile.getTokens();
  const rows = tokens?.map(token => {
    return {
      ...token,
      clickable: true,
      label: token.description
    };
  });
  const columns = [
    {
      key: 'description',
      label: 'Token description',
      width: '1fr'
    },
    {
      key: 'last_used',
      label: 'Last used time',
      width: '0.3fr',
      render: token => formatTokenDate(token.last_used),
      defaultSortColumn: true,
      defaultSortDirection: 'desc',
      sort: sortLastUsedTime
    }
  ];
  const primaryKey = 'token_id';
  const selections = useClickableSelections(rows, primaryKey);

  return (
    <ManagementPageContainer>
      <h2>{profile.fullName}</h2>
      <h4>API Tokens</h4>
      <Subtitle>
        {tokens == null ? (
          <PlaceholderText style={{ width: '8rem' }} />
        ) : tokens.length === 0 ? (
          'You have no tokens. You can create a token below.'
        ) : (
          'Tokens are a way of authenticating your API requests. Tokens that you have generated can be used to access the Luminoso API.'
        )}
      </Subtitle>
      <TableAndControlsContainer>
        <ControlBar>
          <DeleteTokensButton selections={selections} />
          <NotificationArea />
          <NewTokenButton className="right" />
        </ControlBar>
        {tokens?.length !== 0 && (
          <MultiSelectTable
            rows={rows}
            columns={columns}
            primaryKey={primaryKey}
            selections={selections}
            editable={true}
          />
        )}
      </TableAndControlsContainer>
    </ManagementPageContainer>
  );
}

const sortLastUsedTime = (a, b) => {
  if (a === b) {
    return 0;
  }

  if (a === null || b === undefined) {
    return 1;
  }

  if (a === undefined || b === null) {
    return -1;
  }

  return a - b;
};
