import React from 'react';
import styled from '@emotion/styled';

import { Button } from '../../components/core/Button';
import { Icon, IconTypes } from '../../components/icons';

export const NavigationArrows = ({
  onPrev,
  onNext,
  prevDisabled,
  nextDisabled
}) => {
  return (
    <ArrowColumn>
      <ArrowButton type="previous" onClick={onPrev} disabled={prevDisabled} />
      <ArrowButton type="next" onClick={onNext} disabled={nextDisabled} />
    </ArrowColumn>
  );
};

const ArrowColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ArrowButton = ({ type, onClick, disabled }) => {
  const iconType = type === 'next' ? 'down' : 'up';
  const label = type === 'next' ? 'Next document' : 'Previous document';

  return (
    <Button
      trackingItem="documents-pane-arrow-button"
      flavor="subtle"
      hiddenLabel={label}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon type={IconTypes.ARROW} direction={iconType} />
    </Button>
  );
};
