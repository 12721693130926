import React, { useContext, useEffect, useState } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Scrollbars } from 'react-custom-scrollbars';

import { Card } from '../components/Card';
import { Title } from './components';
import { copyConceptList, getProjects } from '../utils/ApiUtilsV5';
import MultiSelectDropdown from '../components/core/MultiSelectDropdown';
import { Button } from '../components/core/Button';
import { StoreContext } from '../StoreContext';
import { loadSharedConceptLists } from '../actions';
import {
  OverlayAlert,
  useOverlayAlertController
} from '../components/core/Alert';
import { Bold } from '../components/Bold';
import { formatTimestamp } from '../utils/projectUtils';

const CopyConceptListSection = () => {
  const { project } = useContext(StoreContext);
  const [projectsToImport, setProjectsToImport] = useState([]);
  const [projectsToExport, setProjectsToExport] = useState([]);
  const [selectedToMultipleProjects, setSelectedToMultipleProjects] = useState(
    []
  ); // State to handle multiple selections
  const [
    selectedFromMultipleProjects,
    setSelectedFromMultipleProjects
  ] = useState([]); // State to handle multiple selections
  const [processing, setProcessing] = useState(false);
  const alertController = useOverlayAlertController();
  const [selectedValuesToProjects, setSelectedValuesToProjects] = useState([]);
  const [selectedValuesFromProjects, setSelectedValuesFromProjects] = useState(
    []
  );
  const [selectAll, setSelectAll] = useState(false); // State to track select/deselect all

  //list of projects from which to import concept list
  const importFromProject = projectsToImport.filter(project =>
    selectedValuesFromProjects.includes(project.project_id)
  );
  //list of projects to import to concept list
  const exportToProjects = projectsToImport.filter(project =>
    selectedValuesToProjects.includes(project.project_id)
  );

  function refetchConceptLists() {
    loadSharedConceptLists(project.project_id);
  }

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const fetchedProjects = await getProjects();
        setProjectsToImport(
          fetchedProjects.filter(
            projectList => projectList.project_id !== project.project_id
          )
        );
        setProjectsToExport(
          fetchedProjects.filter(
            projectList =>
              projectList.project_id !== project.project_id &&
              projectList.permissions.includes('write')
          )
        );
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };
    fetchProjects();
  }, []);

  const multiSelectOptions = projectsToImport.map(project => ({
    value: project.project_id,
    label: project.name,
    creation_date: project.creation_date
  }));

  const multiSelectOptionsToExport = projectsToExport.map(project => ({
    value: project.project_id,
    label: project.name,
    creation_date: project.creation_date
  }));

  const handlerCopyConceptList = async () => {
    setProcessing(true);
    try {
      if (multiSelectOptions.length) {
        if (selectedValuesToProjects.length) {
          await copyConceptListToProjects();
        }
        if (selectedValuesFromProjects.length) {
          await copyConceptListFromProjects();
        }
      }
      // After all copies are done, refetchConceptLists
      refetchConceptLists();
      alertController.showSuccess('Success!');
    } catch (error) {
      console.error('Error in handlerCopyConceptList:', error);
      alertController.showWarning('Error!');
    } finally {
      resetSelections();
    }
  };
  const copyConceptListToProjects = async () => {
    await Promise.all(
      selectedToMultipleProjects.map(value =>
        copyConceptList(value, project.project_id)
      )
    );
  };

  const copyConceptListFromProjects = async () => {
    setProcessing(true);
    await Promise.all(
      selectedFromMultipleProjects.map(value =>
        copyConceptList(project.project_id, value)
      )
    );
  };

  const resetSelections = () => {
    setProcessing(false);
    setSelectedValuesToProjects([]);
    setSelectedValuesFromProjects([]);
    setSelectedToMultipleProjects([]);
    setSelectAll(false);
  };

  return (
    <Card data-test-id="Copy-concept-section">
      <Card.Section>
        <HeaderContainer>
          <Title>Copy Concept Lists</Title>
          <OverlayAlert
            controller={alertController}
            css={css`
              width: 20%;
            `}
            duration={0.5}
          >
            <div></div>
          </OverlayAlert>
        </HeaderContainer>
      </Card.Section>
      <Card.Separator />
      <RowDiv>
        <Card.Section>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              row-gap: 1rem;
            `}
          >
            <MultiSelectDropdown
              containerCss={css`
                min-width: 15rem;
              `}
              placeholder={'Import from project'}
              options={multiSelectOptions}
              onChange={setSelectedFromMultipleProjects}
              disabled={processing || !!exportToProjects.length}
              setSelectedValues={setSelectedValuesFromProjects}
              selectedValues={selectedValuesFromProjects}
              disabledValues={selectedValuesToProjects}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
            />
            <MultiSelectDropdown
              containerCss={css`
                min-width: 15rem;
              `}
              placeholder={'Export to projects'}
              options={multiSelectOptionsToExport}
              onChange={setSelectedToMultipleProjects}
              disabled={processing || !!importFromProject.length}
              setSelectedValues={setSelectedValuesToProjects}
              selectedValues={selectedValuesToProjects}
              disabledValues={selectedValuesFromProjects}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
            />
            <ContainerButtons>
              <Button
                onClick={handlerCopyConceptList}
                disabled={
                  !selectedValuesFromProjects.length &&
                  !selectedValuesToProjects.length
                }
              >
                Copy Concept Lists
              </Button>
            </ContainerButtons>
          </div>
        </Card.Section>
        <div>
          {selectedValuesFromProjects.length > 0 && (
            <TextAreaContainer>
              <Title>
                Importing concept lists from the following projects to{' '}
                {project.name} :
              </Title>
              <Scrollbars style={{ height: '100px' }} autoHide>
                <ul>
                  {importFromProject?.map(project => (
                    <li key={project.project_id}>
                      {project.name}
                      <p>
                        Documents: <Bold>{project.document_count}</Bold>
                      </p>
                      <p>
                        Created{' '}
                        <Bold>{formatTimestamp(project.creation_date)}</Bold>
                      </p>
                    </li>
                  ))}
                </ul>
              </Scrollbars>
            </TextAreaContainer>
          )}
        </div>
        <div>
          {selectedValuesToProjects.length > 0 && (
            <TextAreaContainer>
              <Title>
                Exporting concept lists from {project.name} to the following
                projects :
              </Title>
              <Scrollbars style={{ height: '100px' }} autoHide>
                <ul>
                  {exportToProjects?.map(project => (
                    <li key={project.project_id}>
                      {project.name}
                      <p>
                        Documents: <Bold>{project.document_count}</Bold>
                      </p>
                      <p>
                        Created{' '}
                        <Bold>{formatTimestamp(project.creation_date)}</Bold>
                      </p>
                    </li>
                  ))}
                </ul>
              </Scrollbars>
            </TextAreaContainer>
          )}
        </div>
      </RowDiv>
    </Card>
  );
};

const ContainerButtons = styled.div`
  display: flex;
  column-gap: 1rem;
`;
const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;

const HeaderContainer = styled.div`
  height: 1.5rem;
  align-items: center;
  display: -webkit-box;
  column-gap: 10rem;
`;
const TextAreaContainer = styled.div`
  padding: 10px;
  width: 100%;
  height: 15vh;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 14px;
`;
export default CopyConceptListSection;
