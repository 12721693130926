import d3 from 'd3';

import mathcloud from './mathcloud';

/**
 * Notes:
 * To use, you must specify a function for @layout.fontSize()
 * before calling startLayout. You're also responsible for coloring
 * the terms yourself, by overriding `layoutComplete` and adding a
 * `vis.selectAll('text').style('fill', '#fff')`
 */
export default class BaseCloud {
  constructor(containerSelector, props) {
    this.props = props;
    this.onResize = this.onResize.bind(this);
    this.onZoom = this.onZoom.bind(this);
    this.layoutCompleted = this.layoutCompleted.bind(this);
    this.createElements(containerSelector);
    this.initLayout();
    this.initEventHandling();
  }

  createElements(containerSelector) {
    const parent = d3.select(containerSelector);
    this.parentElement = parent.node();

    // Remove previous svgs
    document
      .querySelector(containerSelector)
      .querySelectorAll('svg.cloud')
      .forEach(svg => svg.parentElement.removeChild(svg));

    // Main svg element
    this.svg = parent.append('svg').classed('cloud', true);

    // Element for fading out words in the exit selection
    this.background = this.svg.append('g').attr('class', 'background');

    // Element for holding text entries
    this.vis = this.svg.append('g').attr('class', 'vis');

    // TODO: Evaluate any potential issues with doing this
    this.vis.style('opacity', 1e-6);
  }

  initEventHandling() {
    this.initZoomEvents();
    this.onResize();
  }

  initZoomEvents() {
    this.setDimensions();

    this.zoomScaleLow = 0.75;
    this.zoomScaleHigh = 20;

    this.zoomBehavior = d3.behavior
      .zoom()
      .scaleExtent([this.zoomScaleLow, this.zoomScaleHigh])
      .translate([this.width / 2, this.height / 2])
      .on('zoom', this.onZoom);

    // Enable zoom events
    this.svg.call(this.zoomBehavior);
  }

  initLayout() {
    this.layout = mathcloud()
      .font('Lato, Helvetica, Arial')
      .fontSize(14)
      .on('end', this.layoutCompleted);
  }

  setDimensions() {
    const rect = this.parentElement.getBoundingClientRect();
    // Firefox needs the SVG element to have a non-zero width before getScreenCTM will work.
    // The first real resize event will replace this with the actual correct width.
    this.width = (rect && rect.width) || 1;
    this.height = (rect && rect.height) || 1;
  }

  onResize() {
    this.setDimensions();

    this.svg.attr('width', this.width).attr('height', this.height);

    this.centerView();

    // TODO: Adjust dimensions based on the size of the data
    // TODO: Figure out why this starts breaking zooming at values other than 1000
    this.layoutScaleFactor = 0.8; // 1.5 --- .6 for 350 terms in terms.coffee
    this.layoutWidth = this.width * this.layoutScaleFactor;
    this.layoutHeight = this.height * this.layoutScaleFactor;
    this.layout.size([this.layoutWidth, this.layoutHeight]);
  }

  onZoom() {
    this.props.hideAxisLabels();
    // TODO: Nicer behavior when zooming during a zoom transition.
    // Might involve something like the current way we keep track
    // of layout things in the ConceptCloud.

    this.vis.attr(
      'transform',
      //1 @zoomBehavior.scale()
      `translate(${this.zoomBehavior.translate()}) scale(${this.zoomBehavior.scale()})`
    );

    // Flicker the content to re-kern.
    // Fixes an issue that appears on some browsers where the text
    // layout doesn't update as you zoom in.
    this.svg
      .style('opacity', 0.99)
      .transition()
      .delay(1000)
      .duration(0)
      .style('opacity', 1);
  }

  setData(data) {
    this.data = data;
    this.layout.words(this.data);
  }

  startLayout() {
    this.stopLayout();
    this.layout.start();
  }

  stopLayout() {
    this.layout.stop();
  }

  layoutCompleted(words, bounds) {
    // Words is an array of cloud-generated objects, each representing a word.

    // Position every text element based on the cloud layout
    const position = sel =>
      sel.attr('transform', w => `translate(${w.x}, ${w.y})`);

    const text = this.vis.selectAll('text').data(words, w => w.text);

    const enter = text
      .enter()
      .append('text')
      .text(w => w.text)
      .attr({
        'text-anchor': 'middle',
        'font-family'(d) {
          return d.font;
        }
      });

    try {
      enter.style('fill-opacity', 1e-6);
    } catch (e) {
      console.log('Caught:', e);
    }

    enter.call(position).on('click', () => d3.event.stopPropagation());

    text.style({
      'font-size'(d) {
        return `${d.size + 1}px`;
      }
    });

    if (this.layoutInTransition) {
      text.call(this.stopTransition);
    }

    this.layoutTransition = text
      .transition()
      .duration(1000)
      .each('end', (w, i) => {
        // Listen only for the last item.
        if (i === 0) {
          this.layoutInTransition = false;
          this.layoutTransition = null;
        }
      })
      .style('fill-opacity', 1)
      .call(position);

    this.layoutInTransition = true;

    // Animate away the un-needed words in the exit selection
    const exitGroup = this.background
      .append('g')
      .attr('transform', this.vis.attr('transform'));

    const exitGroupNode = exitGroup.node();
    text.exit().each(function () {
      exitGroupNode.appendChild(this);
    });

    exitGroup.transition().duration(1000).style('opacity', 1e-6).remove();

    // Reorder the DOM elements to put smaller terms on top.
    // This ensures that you can click and hover on small terms.
    text.sort((a, b) => b.size - a.size).order();

    // Calculate bounding box. First, center the bounds on zero
    for (let bound of bounds) {
      bound.x -= this.layoutWidth / 2;
      bound.y -= this.layoutHeight / 2;
    }

    const [lo, hi] = bounds;

    // Pad the top & bottom
    const padding = 10;
    hi.y += padding;
    lo.y -= padding;

    // Calculate dimensions
    const width = hi.x - lo.x;
    const height = hi.y - lo.y;

    // Identify the center
    const centerX = lo.x + width / 2;
    const centerY = lo.y + height / 2;

    // And zoom to it
    this.mostRecentBoundingBoxWidth = width;
    this.mostRecentBoundingBoxHeight = height;
    this.mostRecentBoundingBoxCenterX = centerX;
    this.mostRecentBoundingBoxCenterY = centerY;

    this.zoomToBoundingBox(width, height, centerX, centerY, { duration: 1000 });

    // Save the dimensions for later re-centering
    this.layoutViewWidth = width;
    this.layoutViewHeight = height;
    this.layoutViewCenterX = centerX;
    this.layoutViewCenterY = centerY;
  }

  zoomToBoundingBox(width, height, centerX, centerY, options = {}) {
    // Options:
    //   - duration: animation duration. Default: No animation
    //   - scale: additional scale factor. Default: 1 (fill the screen)
    // By the way, the center of the screen is considered to be at (0, 0).

    let sel;
    const scale =
      options.scale != null
        ? options.scale
        : Math.min(this.width / width, this.height / height);

    // Calculate target position, accounting for the fact that
    // it will happen before scaling
    const x = this.width / 2 - scale * centerX;
    const y = this.height / 2 - scale * centerY;
    const translate = [x, y];

    this.zoomBehavior.translate(translate).scale(scale);

    if (options.duration != null) {
      sel = this.vis
        .transition()
        .duration(options.duration)
        .style('opacity', 1);
    } else {
      sel = this.vis;
    }

    sel.attr('transform', `translate(${translate}) scale(${scale})`);
  }

  stopTransition(sel) {
    if (sel) {
      sel.transition().delay(0);
    }
  }

  centerView(options = {}) {
    if (this.layoutViewWidth != null && this.layoutViewHeight != null) {
      // If there is a computed layout, center on it.
      this.zoomToBoundingBox(
        this.layoutViewWidth,
        this.layoutViewHeight,
        this.layoutViewCenterX,
        this.layoutViewCenterY,
        options
      );
    } else {
      // Otherwise, center as best we can, even if we don't zoom.
      this.zoomToBoundingBox(this.width, this.height, 0, 0, options);
    }
  }
}
