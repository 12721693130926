import React from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  Route,
  Switch,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import styled from '@emotion/styled';

import branding_data from '../../../brandings.json';
import { RoutePatterns, URLs } from '../constants';
import buildRoutePath from '../utils/buildRoutePath';
import PlaceholderText from './core/PlaceholderText';
import { StoreContext } from '../StoreContext';
import LumiLogo from './icons/LuminosoLogoIcon';
import CustomLogoIcon from './icons/CustomLogoIcon';
import { Colors, Mixins } from '../styles';
import { load } from '../../../lui/coffee/utils/localStorage';

export default function BreadcrumbNav() {
  return (
    <Nav>
      <BreadCrumbList>
        <DaylightBreadcrumb />
        <Switch>
          <Route path={RoutePatterns.PROFILE}>
            <BasicBreadcrumb label="User profile" />
          </Route>
          <Route path={RoutePatterns.PASSWORD}>
            <BasicBreadcrumb label="Change password" />
          </Route>
          <Route path={RoutePatterns.WORKSPACE_SETTINGS}>
            <BasicBreadcrumb label="Workspace" />
          </Route>
          <Route path={RoutePatterns.ORGANIZATION_USAGE}>
            <BasicBreadcrumb label="Usage" />
          </Route>
          <Route path={RoutePatterns.ORGANIZATION_SETTINGS}>
            <BasicBreadcrumb label="Organization" />
          </Route>
          <Route path={RoutePatterns.TOKENS}>
            <BasicBreadcrumb label="Tokens" />
          </Route>
          <Route exact path={RoutePatterns.UPLOAD_DATA}>
            <ProjectExplorerBreadcrumb alwaysLink />
          </Route>
          <Route path={RoutePatterns.UPLOAD_STREAM_DATA}>
            <BasicBreadcrumb label="Create new data stream" />
          </Route>
          <Route path={RoutePatterns.PROJECT_EXPLORER}>
            <ProjectExplorerBreadcrumb />
          </Route>
        </Switch>
        <Switch>
          <Route exact path={RoutePatterns.CREATE_PROJECT}>
            <BasicBreadcrumb label="Create project" />
          </Route>
          <Route
            path={`${RoutePatterns.UPLOAD_STREAM_DATA}/select/reddit/input-urls`}
          >
            <BasicBreadcrumb label="Reddit" />
          </Route>
          <Route
            path={`${RoutePatterns.UPLOAD_STREAM_DATA}/select/reddit/select-interval`}
          >
            <BasicBreadcrumb label="Reddit" />
          </Route>
          <Route
            path={`${RoutePatterns.UPLOAD_STREAM_DATA}/select/reviews/select-source`}
          >
            <BasicBreadcrumb label="Reviews" />
          </Route>
          <Route
            path={`${RoutePatterns.UPLOAD_STREAM_DATA}/select/reviews/urls-input`}
          >
            <BasicBreadcrumb label="Reviews" />
          </Route>
          <Route
            path={`${RoutePatterns.UPLOAD_STREAM_DATA}/select/reviews/select-interval`}
          >
            <BasicBreadcrumb label="Reviews" />
          </Route>
          <Route path={RoutePatterns.UPLOAD_DATA}>
            <BasicBreadcrumb label="Upload data" />
          </Route>
          <Route exact path={RoutePatterns.HIGHLIGHTS}>
            <BasicBreadcrumb label="Highlights" />
          </Route>
          <Route exact path={RoutePatterns.GALAXY}>
            <BasicBreadcrumb label="Galaxy" />
          </Route>
          <Route path={RoutePatterns.VOLUME}>
            <BasicBreadcrumb label="Volume" />
          </Route>
          <Route path={RoutePatterns.DRIVERS}>
            <BasicBreadcrumb label="Drivers" />
          </Route>
          <Route path={RoutePatterns.SENTIMENT}>
            <BasicBreadcrumb label="Sentiment" />
          </Route>
          <Route path={RoutePatterns.PROJECT_MANAGEMENT}>
            <BasicBreadcrumb label="Project management" />
          </Route>
        </Switch>
      </BreadCrumbList>
    </Nav>
  );
}

const Nav = styled.nav`
  position: relative;
  flex-grow: 1;
  min-width: 0;
`;

const BreadCrumbList = styled.ol`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding-left: 0;
`;

export function DaylightBreadcrumb() {
  const branding = load('branding');

  if (branding === undefined || branding === null) {
    return (
      <BreadcrumbItem>
        <LogoLink
          href={URLs.ANALYTICS_BASE}
          data-tracking-item="global-nav-bar_logo"
          title="Projects"
        >
          <Logo />
          <span>Luminoso Daylight®</span>
        </LogoLink>
      </BreadcrumbItem>
    );
  } else {
    const header_data = branding_data[`${branding}`];
    const logo = header_data.logo;

    if (logo) {
      return (
        <BreadcrumbItem>
          <LogoLink
            href={URLs.ANALYTICS_BASE}
            data-tracking-item="global-nav-bar_logo"
            title="Projects"
          >
            <CustomLogo logo={logo} />
            <span>{header_data.header_name}</span>
          </LogoLink>
        </BreadcrumbItem>
      );
    } else {
      return (
        <BreadcrumbItem>
          <LogoLink
            href={URLs.ANALYTICS_BASE}
            data-tracking-item="global-nav-bar_logo"
            title="Projects"
          >
            <span>{header_data.header_name}</span>
          </LogoLink>
        </BreadcrumbItem>
      );
    }
  }
}

const LogoLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${Mixins.ellipsify};
  &:hover {
    text-decoration: underline;
  }
`;

const Logo = styled(LumiLogo)`
  display: inline-block;
  height: 2rem;
  width: auto;
  margin-right: 1.125rem;
`;
const CustomLogo = styled(CustomLogoIcon)`
  display: inline-block;
  height: 2rem;
  width: auto;
  margin-right: 1.125rem;
`;

export const BasicBreadcrumb = ({ label }) => {
  return (
    <BreadcrumbItem title={label}>
      <BreadcrumbLink>{label}</BreadcrumbLink>
    </BreadcrumbItem>
  );
};

BasicBreadcrumb.propTypes = {
  label: PropTypes.string
};

export function ProjectExplorerBreadcrumb({ alwaysLink }) {
  const { workspaceId, projectId } = useParams();
  const { project } = React.useContext(StoreContext);
  const name = project?.name || 'Unknown project';

  return (
    <BreadcrumbItem title={name}>
      {!project ? (
        <PlaceholderText style={{ width: '8rem' }} />
      ) : (
        <BreadcrumbLink
          alwaysLink={alwaysLink}
          to={buildRoutePath(RoutePatterns.PROJECT_EXPLORER, {
            workspaceId,
            projectId
          })}
          title={name}
          data-tracking-item="global-nav-bar_crumb_project-name"
        >
          {name}
        </BreadcrumbLink>
      )}
    </BreadcrumbItem>
  );
}

ProjectExplorerBreadcrumb.propTypes = {
  alwaysLink: PropTypes.bool
};

const BreadcrumbItem = styled.li`
  display: flex;
  flex-direction: row;
  // make room for focus outlines around links
  padding: 0.25rem;
  padding-left: 0;
  align-items: center;
  min-width: 2rem;

  &:not(:first-child):before {
    content: '';
    height: 1rem;
    border-left: solid 1px ${Colors.gray5};
    margin: 0 0.5rem;
    transform: skewX(-25deg);
  }
`;

function BreadcrumbLink({ alwaysLink = false, children, ...passedProps }) {
  const match = useRouteMatch();

  if (match.isExact && !alwaysLink) {
    return <ActiveLink>{children}</ActiveLink>;
  }

  return (
    <InactiveLink
      to={match.url || ''} // overridden by any "to" prop passed in
      {...passedProps}
    >
      {children}
    </InactiveLink>
  );
}

BreadcrumbLink.propTypes = {
  alwaysLink: PropTypes.bool,
  children: PropTypes.node
};

const ActiveLink = styled.span`
  ${Mixins.ellipsify};
  &:hover {
    text-decoration: none;
  }
`;

const InactiveLink = styled(Link)`
  ${Mixins.ellipsify};
  &:hover {
    text-decoration: underline;
  }
`;
