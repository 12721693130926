import React, { useContext } from 'react';
import { css } from '@emotion/core';

import { StoreContext } from '../StoreContext';
import PlaceholderText from '../components/core/PlaceholderText';
import { percentify, thousandify } from '../utils/NumFmtUtils';
import { Colors } from '../styles';

export function MatchingDocuments() {
  const {
    filterCount,
    filterIsLoading,
    searchInProgress,
    selection
  } = useContext(StoreContext);

  if (!selection) {
    return null;
  }

  if (filterIsLoading || searchInProgress) {
    return (
      <span
        css={css`
          margin-left: 0.5rem;
        `}
      >
        <PlaceholderText style={{ width: '5rem' }} /> matches
      </span>
    );
  }

  return (
    <span
      css={css`
        margin-left: 0.5rem;
      `}
    >
      {thousandify(selection.matchCount)}{' '}
      <span
        css={css`
          color: ${Colors.gray5};
        `}
      >
        ({percentify(selection.matchCount, filterCount)})
      </span>{' '}
      matches
    </span>
  );
}
