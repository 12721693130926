import React, { useContext, useEffect, useState } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Colors, Mixins } from '../styles';
import SupportEmailLink from '../components/SupportEmailLink';
import { getSentimentStatus, SENTIMENT_STATUS } from '../utils/sentimentStatus';
import Progress from '../components/core/Progress';
import { StoreContext } from '../StoreContext';
import { percentify } from '../utils/NumFmtUtils';
import { buildSentiment, getProject } from '../utils/ApiUtilsV5';
import { loadProject } from '../actions';
import { useFilter } from '../search_params';
import { useSystemStatus } from '../systemStatus';
import { Icon, IconTypes } from '../components/icons';
import { Button } from '../components/core/Button';

export function SentimentStatusMessage({ status, ...props }) {
  if (status === SENTIMENT_STATUS.BUILDING) {
    return <PendingBuildVisualization {...props} />;
  }
  if (status === SENTIMENT_STATUS.BUILD_FAILED) {
    return <BuildFailedVisualization {...props} />;
  }
  if (status === SENTIMENT_STATUS.BUILT_WITHOUT_SENTIMENT) {
    return <BuiltWithoutSentiment {...props} />;
  }
}

SentimentStatusMessage.propTypes = {
  status: PropTypes.oneOf(Object.values(SENTIMENT_STATUS)).isRequired
};

function BuildFailedVisualization(props) {
  return (
    <MessageWrapper {...props}>
      <Icon type={IconTypes.CIRCULAR_CLOSE} theme="tertiary" size="6rem" />
      <div
        css={css`
          text-align: center;
          font-weight: bold;
        `}
      >
        <p>
          Something went wrong while examining the sentiment in your project.
        </p>
        <p>
          Contact us at <SupportEmailLink />.
        </p>
      </div>
    </MessageWrapper>
  );
}

function PendingBuildVisualization(props) {
  const { projectId } = useParams();
  const { project: originalProject, selection } = useContext(StoreContext);
  const filter = useFilter();
  const [project, setProject] = useState(originalProject);

  useEffect(() => {
    let timeoutHandle;
    let request;
    function updateProject() {
      request = getProject(projectId).then(project => {
        setProject(project);
        const sentimentStatus = getSentimentStatus(project);
        // If the project finished its sentiment build, update the project in
        // the store and trigger the UI to fetching and displaying sentiment.
        if (sentimentStatus === SENTIMENT_STATUS.READY) {
          loadProject(projectId, selection, filter);
        } else {
          timeoutHandle = setTimeout(updateProject, 30000);
        }
      });
    }
    updateProject();
    return () => {
      clearTimeout(timeoutHandle);
      request?.abort();
    };
  }, [projectId, selection, filter]);

  const sentiment = project.last_build_info.sentiment;
  const progress = sentiment?.progress ?? 0;
  const barCss = css`
    width: 100%;
    max-width: 30rem;
    margin-bottom: 1rem;
  `;

  return (
    <MessageWrapper {...props}>
      <Progress
        css={barCss}
        value={progress}
        max={1}
        title={`${percentify(progress, 1)} complete`}
      >
        {percentify(progress, 1, 0)}
      </Progress>
      <div
        css={css`
          text-align: center;
          font-weight: bold;
        `}
      >
        <p>
          Our science is hard at work examining the sentiment in your project.
        </p>
        <p>Please check back later!</p>
      </div>
    </MessageWrapper>
  );
}

function MessageWrapper(props) {
  return (
    <div
      css={css`
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
      {...props}
    />
  );
}

function BuiltWithoutSentiment(props) {
  const systemStatus = useSystemStatus();
  if (systemStatus == null) {
    return null;
  }
  return (
    <MessageWrapper {...props}>
      <div
        css={css`
          text-align: center;
          font-weight: bold;
        `}
      >
        {systemStatus.gpu ? (
          <SentimentUpgradeAvailable />
        ) : (
          <>
            <p>
              Sentiment analysis is not available in your system because it is
              not configured with a GPU.
            </p>
            <p>Install one to use Sentiment in Daylight.</p>
          </>
        )}
      </div>
    </MessageWrapper>
  );
}

const SentimentUpgradeAvailable = () => {
  const { project } = useContext(StoreContext);
  const [isBuilding, setIsBuilding] = useState(false);

  const handleBuildSentiment = async projectId => {
    try {
      await buildSentiment(projectId);
      setIsBuilding(true);
    } catch (error) {
      throw new Error('Failed to build sentiment');
    }
  };

  return (
    <UndoParentCss>
      {!isBuilding ? (
        <Column>
          <div
            css={css`
              display: flex;
              border: 1px solid ${Colors.gray0};
              padding: 0.75rem 0.5rem 1rem;
            `}
          >
            <Icon
              type={IconTypes.CIRCULAR_EXCLAMATION}
              theme="primary"
              size="24"
            />
            <div>
              This project does not have <i>sentiment</i> data. Click the{' '}
              <i>Build Sentiment</i> button below to perform sentiment analysis
              for this project.
            </div>
          </div>
          <Button
            palette="green"
            onClick={() => handleBuildSentiment(project.project_id)}
            css={css`
              font-size: 1rem;
            `}
          >
            <Icon type={IconTypes.CLOCK} size="18" />
            Build Sentiment
          </Button>
        </Column>
      ) : (
        <PendingBuildVisualization />
      )}
    </UndoParentCss>
  );
};

// The above component is in a different style from the other sentiment status
// messages so undoing the css of the parent div is necessary. The wrapper can
// be removed for the most part when the rest of the messages are standardized.
const UndoParentCss = styled.div`
  text-align: left;
  font-weight: normal;
  font-size: 1rem;
  margin-top: -35%; // hack to undo flex centering

  // Center the column
  display: flex;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
  padding: 0.5rem;
  ${Mixins.shadowOutset}
  max-width: 60ch;
`;
