import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useReducer, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import {
  removeActiveConcepts,
  reorderActiveConcepts,
  restoreActiveConceptList,
  selectConcept
} from '../../actions';
import { Concept } from '../../classes/Concepts';
import ConceptView from '../../components/ConceptView';
import { Button } from '../../components/core/Button';
import { Checkbox } from '../../components/core/Checkbox';
import List from '../../components/core/List';
import PlaceholderText from '../../components/core/PlaceholderText';
import DraggableConceptWrapper from './DraggableConceptWrapper';
import { useHover } from '../../HoverContext';
import { StoreContext } from '../../StoreContext';
import { Colors } from '../../styles';
import { useFilter } from '../../search_params';
import { useRandom, useSelections, useUniqueId } from '../../utils/hooks';
import { ImportSharedConceptList } from './ImportSharedConceptList';
import { useDispatchUndoBanner } from '../../UndoBanner';
import { BulkColorEditor } from './BulkColorEditor';
import { AddFromVisualizationButton } from './AddFromVisualizationButton';
import { ActiveConceptListManagement } from './ActiveConceptListManagement';

export const ActiveConcepts = memo(function ActiveConcepts({
  projectId,
  permissions,
  activeConcepts,
  isOpeningConceptList,
  activeConceptListName,
  conceptLists,
  refetchConceptLists
}) {
  const selections = useSelections(activeConcepts, 'sharedConceptId');

  return (
    <SidePanelContainer data-test-id="active-concepts">
      <HeaderContainer>
        <ConceptListManagement
          projectId={projectId}
          permissions={permissions}
          clearSelections={() => selections.clear()}
          conceptLists={conceptLists}
          refetchConceptLists={refetchConceptLists}
        />
      </HeaderContainer>
      <ActiveConceptList
        activeConcepts={activeConcepts}
        selections={selections}
        conceptLists={conceptLists}
        isOpeningConceptList={isOpeningConceptList}
      />
      <Footer
        projectId={projectId}
        activeConceptListName={activeConceptListName}
        activeConcepts={activeConcepts}
        selections={selections}
      />
    </SidePanelContainer>
  );
});

ActiveConcepts.propTypes = {
  projectId: PropTypes.string.isRequired,
  activeConcepts: PropTypes.arrayOf(PropTypes.instanceOf(Concept).isRequired)
    .isRequired,
  isOpeningConceptList: PropTypes.bool.isRequired,
  activeConceptListName: PropTypes.string,
  conceptLists: PropTypes.arrayOf(
    PropTypes.shape({
      concept_list_id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      concepts: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
    }).isRequired
  ),
  refetchConceptLists: PropTypes.func.isRequired,
  permissions: PropTypes.array.isRequired
};

const SidePanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
`;

const HeaderContainer = styled.div`
  > * {
    border-bottom: 1px solid ${Colors.gray2};
    &:last-child {
      border-bottom: 2px solid ${Colors.gray2};
    }
  }
`;

function ConceptListManagement({
  projectId,
  permissions,
  clearSelections,
  conceptLists,
  refetchConceptLists
}) {
  return (
    <>
      <ActiveConceptListManagement permissions={permissions} />
      <ImportSharedConceptList
        projectId={projectId}
        conceptLists={conceptLists}
        onOpenList={clearSelections}
        refetchConceptLists={refetchConceptLists}
      />
    </>
  );
}

function ActiveConceptList({
  activeConcepts,
  selections,
  isOpeningConceptList
}) {
  const conceptListRef = useRef();
  const selectionRef = useRef();
  useScrollToSelectedConcept(conceptListRef, selectionRef);

  const header = (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid ${Colors.gray1};
        border-bottom: 1px solid ${Colors.gray1};
        padding: 0 0.5rem;
      `}
    >
      <SelectAll selections={selections} disabled={isOpeningConceptList} />
      <AddFromVisualizationButton disabled={isOpeningConceptList} />
    </div>
  );

  if (isOpeningConceptList) {
    return (
      <>
        {header}
        <ActiveListContainer ref={conceptListRef}>
          <List lined={false} bordered={false}>
            {[...Array(20)].map((n, i) => (
              <PlaceholderActiveConcept key={i} />
            ))}
          </List>
        </ActiveListContainer>
      </>
    );
  }

  if (_.isEmpty(activeConcepts)) {
    /**
     * When a user clicks the "Add from visualizations" button in this message
     * they save concepts to their active concept list, so we no longer render
     * this message about them not having active concepts. Instead, we render
     * the `header` above and the list of active concepts.
     *
     * A different "Add from visualizations" button is rendered in the header
     * above, but React, trying to be clever, thinks that they're the same (in
     * order to avoid extra rendering). Since the user was hovering this
     * button, when the other button gets rendered it starts out with its
     * tooltip open.
     *
     * To get around this issue, we just apply an arbitrary key on one of
     * those buttons so React treats them as separate buttons. The actual
     * value of the key doesn't matter.
     */
    return (
      <div
        css={css`
          color: ${Colors.gray5};
          margin: auto;
          padding: 1em;
        `}
      >
        You don't have any active concepts. Add all displayed concepts by
        clicking <AddFromVisualizationButton key="🔑" /> or add them
        individually from the search bar.
      </div>
    );
  }

  return (
    <>
      {header}
      <ActiveListContainer ref={conceptListRef}>
        <List lined={false} bordered={false}>
          <DraggableConcepts
            selectionRef={selectionRef}
            selections={selections}
          />
        </List>
      </ActiveListContainer>
    </>
  );
}

const ActiveListContainer = styled.div`
  flex: 1;
  overflow: auto;
  // We apply position: relative so that we can check listItem.offsetTop in our scripts.
  // offsetTop's value is relative to the closest relatively positioned parent element, so without
  // adding position: relative, the offsetTop won't be the correct value for our purposes.
  position: relative;
  min-height: 2rem;
`;

function PlaceholderActiveConcept() {
  const width = useRandom(30, 80);
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        font-size: 0.875rem;
        padding: 0.5em;
      `}
    >
      <PlaceholderText style={{ width: `${width}%` }} />
    </div>
  );
}

function SelectAll({ selections, disabled }) {
  const checkboxId = useUniqueId();
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <Checkbox
        id={checkboxId}
        className="suggestion__checkbox"
        onChange={selections.selectAll}
        checked={selections.all}
        indeterminate={selections.any && !selections.all}
        disabled={disabled}
      />
      <label
        htmlFor={checkboxId}
        css={css`
          padding: 0.5rem;
          cursor: pointer;
          flex: 1;
        `}
      >
        Select all
      </label>
    </div>
  );
}

function dragReducer(state, action) {
  switch (action.type) {
    case 'move-list-item': {
      const { draggedItemIndex, hoverIndex } = action;
      return { ...state, draggedItemIndex, hoverIndex };
    }
    case 'start-drag': {
      return { ...state, dragInProgress: true };
    }
    case 'end-drag': {
      return {
        draggedItemIndex: null,
        dragInProgress: false,
        hoverIndex: null
      };
    }
    default:
      throw new Error('Unknown action type');
  }
}

function DraggableConcepts({ selectionRef, selections }) {
  const { projectId } = useParams();
  const { activeConcepts } = useContext(StoreContext);
  const firstSelectedConcept = useFirstSelectedConcept(activeConcepts);
  const [
    { dragInProgress, hoverIndex, draggedItemIndex },
    dispatch
  ] = useReducer(dragReducer, {
    draggedItemIndex: null,
    dragInProgress: false,
    hoverIndex: null
  });

  let orderedConcepts = [...activeConcepts];
  if (dragInProgress) {
    orderedConcepts.splice(
      hoverIndex,
      0,
      orderedConcepts.splice(draggedItemIndex, 1)[0]
    );
  }

  return orderedConcepts.map((concept, index) => {
    return (
      <div
        key={concept.hash()}
        ref={concept === firstSelectedConcept ? selectionRef : undefined}
      >
        <DraggableConceptWrapper
          key={concept.hash()}
          index={index}
          moveListItem={(draggedItemIndex, hoverIndex) =>
            dispatch({ type: 'move-list-item', draggedItemIndex, hoverIndex })
          }
          startDrag={() => dispatch({ type: 'start-drag' })}
          endDrag={(draggedItemIndex, hoverIndex) => {
            dispatch({ type: 'end-drag' });

            const orderedConcepts = [...activeConcepts];
            orderedConcepts.splice(
              hoverIndex,
              0,
              orderedConcepts.splice(draggedItemIndex, 1)[0]
            );
            const orderedIds = _.map(orderedConcepts, 'sharedConceptId');

            // don't fire an action if there is no need to reorder the concepts
            if (
              !_.isEqual(orderedIds, _.map(activeConcepts, 'sharedConceptId'))
            ) {
              reorderActiveConcepts(projectId, orderedIds);
            }
          }}
        >
          <ConceptViewWrapper
            concept={concept}
            isChecked={selections.includes(concept.sharedConceptId)}
            onChange={event => {
              const shiftClick = event.nativeEvent.shiftKey;
              selections.toggleRow(concept.sharedConceptId, shiftClick);
            }}
          />
        </DraggableConceptWrapper>
      </div>
    );
  });
}

function ConceptViewWrapper({ concept, isChecked, onChange }) {
  const { projectId } = useParams();
  const filter = useFilter();
  const { selection } = useContext(StoreContext);
  const [hovered, setHovered] = useHover();
  const isSelected = Concept.areTextsEqual(selection, concept);
  const isSelectedStyles = css`
    background: ${Colors.blue0};
    border: 1px solid ${Colors.blue1};
  `;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0.5rem;
        ${isSelected && isSelectedStyles}
      `}
    >
      <Checkbox
        aria-label={`Select "${concept.name}" in active concepts`}
        className="suggestion__checkbox"
        checked={isChecked}
        onChange={onChange}
      />
      <ConceptView
        concept={concept}
        hovered={hovered}
        onMouseEnter={() => setHovered(concept)}
        onMouseLeave={() => setHovered()}
        onClick={() => {
          if (!Concept.areTextsEqual(selection, concept)) {
            selectConcept(projectId, concept, filter);
          }
        }}
      />
    </div>
  );
}

function useScrollToSelectedConcept(conceptListRef, selectionRef) {
  const { selection } = useContext(StoreContext);

  useEffect(() => {
    const conceptNode = selectionRef.current;
    if (conceptListRef.current && selection?.isActive && conceptNode) {
      const listTop = conceptListRef.current.scrollTop;
      const listHeight = conceptListRef.current.getBoundingClientRect().height;
      const conceptTop = conceptNode.offsetTop;
      const conceptHeight = conceptNode.getBoundingClientRect().height;

      const aboveListTop = listTop > conceptTop;
      const belowListBottom = listTop + listHeight < conceptTop + conceptHeight;

      // only scroll if the concept will be out of view
      if (aboveListTop || belowListBottom) {
        conceptNode.scrollIntoView(aboveListTop);
      }
    }
  }, [conceptListRef, selectionRef, selection]);
}

function useFirstSelectedConcept(concepts) {
  const { selection } = useContext(StoreContext);
  // Active concepts aren't guaranteed to be unique, so there may be more than
  // one selected concept. We only look for the first concept that matches the
  // selection.
  return _.find(concepts, Concept.areTextsEqual.bind(null, selection));
}

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem;
  justify-content: space-between;
`;

const Footer = ({
  projectId,
  activeConceptListName,
  activeConcepts,
  selections
}) => {
  const dispatchUndoBanner = useDispatchUndoBanner();

  if (_.isEmpty(activeConcepts)) {
    return null;
  }

  return (
    <FooterWrapper>
      <BulkColorEditor selections={selections} />
      <div>
        <Button
          palette="red"
          className="suggested-concepts__close-btn"
          onClick={() =>
            removeActiveConcepts(projectId, ...selections.selections).then(
              () => {
                dispatchUndoBanner({
                  message: 'Removed concepts',
                  onUndo: () =>
                    restoreActiveConceptList(
                      projectId,
                      activeConceptListName,
                      activeConcepts
                    ),
                  trackingItem: 'undo-remove-active-concepts'
                });
              }
            )
          }
          disabled={!selections.any}
        >
          Remove ({selections.count})
        </Button>
      </div>
    </FooterWrapper>
  );
};
