import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Colors, Mixins } from '../styles';

export const Card = styled.div`
  ${Mixins.shadowOutset};
  ${Mixins.roundedCorners};
  display: flex;
  flex-direction: column;
  background: white;
  height: fit-content;
`;

Card.propTypes = {
  children: PropTypes.node.isRequired
};

const CardSection = styled.div`
  height: ${props => props.height};
  padding: ${props => props.padding ?? '0.5rem'};
`;

CardSection.propTypes = {
  children: PropTypes.node,
  height: PropTypes.string,
  padding: PropTypes.string
};

Card.Section = CardSection;

const CardSeparator = styled.hr`
  margin: 0;
  border: none;
  border-bottom: 1px solid ${Colors.gray1};
`;

Card.Separator = CardSeparator;
