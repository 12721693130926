import React from 'react';
import { css } from '@emotion/core';

import { Title } from './components';
import { Card } from '../components/Card';
import { IgnoreAssertions } from './scienceAssertionsSection/IgnoreAssertions';
import { ConceptCountProvider } from './scienceAssertionsSection/ConceptCountProvider';

export const ScienceAssertionsSection = () => (
  <ConceptCountProvider>
    <Card data-test-id="science-assertions-section">
      <ScienceAssertionsTitle />
      <Card.Separator />
      <IgnoreAssertions />
    </Card>
  </ConceptCountProvider>
);

const ScienceAssertionsTitle = () => (
  <>
    <Card.Section
      css={css`
        display: flex;
        flex-direction: row;
      `}
    >
      <Title>Science assertions</Title>

      {/* The import assertions button, can be enabled when relevant. */}

      {/* <Button */}
      {/*   css={css` */}
      {/*     margin-left: auto; */}
      {/*   `} */}
      {/* > */}
      {/*   <Icon type={IconTypes.DOWNLOAD} /> */}
      {/*   Import */}
      {/* </Button> */}
    </Card.Section>
  </>
);
