import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ConfirmableButton } from '../components/core/Button';
import { showError, showSuccess } from './notifications';
import { AuthContext } from './model';
import { ConfirmationMessageContent } from './managementPageStyles';
import { Icon, IconTypes } from '../components/icons';

export default function DeleteTokensButton({ selections }) {
  const { profile } = useContext(AuthContext);

  if (!selections.any) {
    return null;
  }

  function deleteTokens() {
    return profile
      .deleteTokens(selections.selections)
      .then(
        showSuccess(
          `Successfully deleted ${selections.singular ? 'token' : 'tokens'}.`
        )
      )
      .catch(() => {
        showError(
          'An unexpected error occurred. If the problem persists, please contact support@luminoso.com.'
        );
      })
      .finally(() => {
        selections.clear();
      });
  }

  return (
    <ConfirmableButton
      flavor="subtle"
      onConfirm={deleteTokens}
      confirmationMessage={<ConfirmationMessage single={selections.singular} />}
      confirmText={`Yes, delete ${selections.singular ? 'token' : 'tokens'}`}
      trackingitem="tokens_delete-button"
    >
      <Icon type={IconTypes.TRASH} />
      Delete
    </ConfirmableButton>
  );
}

DeleteTokensButton.propTypes = {
  selections: PropTypes.object.isRequired
};

function ConfirmationMessage({ single }) {
  return (
    <ConfirmationMessageContent>
      <p>
        {single
          ? 'Are you sure you want to delete this token?'
          : 'Are you sure you want to delete these tokens?'}
      </p>
      <p>This action cannot be undone.</p>
    </ConfirmationMessageContent>
  );
}
