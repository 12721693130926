import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { thousandify } from '../utils/NumFmtUtils';
import { Colors } from '../styles';

const TooltipHeader = styled.h6`
  padding-top: 0;
  font-size: 1rem;
  max-width: 18.75rem;
`;

const TooltipSubheader = styled.div`
  padding-bottom: 0.75rem;
  font-size: 1rem;
`;

export default function SharesChartTooltipContent({
  header,
  sentimentCounts,
  sentimentShare
}) {
  const totalMentions =
    sentimentCounts['positive'] +
    sentimentCounts['negative'] +
    sentimentCounts['neutral'];
  return (
    <>
      <TooltipHeader>{header}</TooltipHeader>
      <TooltipSubheader>
        {thousandify(totalMentions)} Total mentions
      </TooltipSubheader>
      <div>
        {['negative', 'neutral', 'positive'].map(type => (
          <Share
            key={type}
            shares={sentimentShare}
            counts={sentimentCounts}
            type={type}
          />
        ))}
      </div>
    </>
  );
}

SharesChartTooltipContent.propTypes = {
  header: PropTypes.node.isRequired,
  sentimentCounts: PropTypes.shape({
    negative: PropTypes.number.isRequired,
    neutral: PropTypes.number.isRequired,
    positive: PropTypes.number.isRequired
  }).isRequired,
  sentimentShare: PropTypes.shape({
    negative: PropTypes.number.isRequired,
    neutral: PropTypes.number.isRequired,
    positive: PropTypes.number.isRequired
  }).isRequired,
  matchCount: PropTypes.number.isRequired
};

const TooltipKey = styled.div`
  border-left: 0.5rem solid;
  border-left-color: ${props => props.borderColor};
  padding-left: 0.25rem;
  ${props => (props.neutral ? 'margin: 0.5rem 0;' : '')}
`;

const TooltipLabel = styled.div`
  color: ${Colors.gray5};
`;

const TooltipPercentage = styled.span`
  color: ${Colors.gray5};
`;

function Share({ shares, counts, type }) {
  let borderColor;
  if (type === 'positive') {
    borderColor = Colors.blue2;
  } else if (type === 'neutral') {
    borderColor = Colors.gray2;
  } else if (type === 'negative') {
    borderColor = Colors.red2;
  }

  return (
    <TooltipKey borderColor={borderColor} neutral={type === 'neutral'}>
      <TooltipLabel>{_.capitalize(type)} mentions</TooltipLabel>
      <div>
        {thousandify(counts[type])}{' '}
        <TooltipPercentage>
          ({Math.round(shares[type] * 100)}%)
        </TooltipPercentage>
      </div>
    </TooltipKey>
  );
}

Share.propTypes = {
  shares: PropTypes.object.isRequired,
  counts: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};
