import React from 'react';
import PropTypes from 'prop-types';

import { Constraint } from '../classes/Constraints';
import cloudInterface from './cloudInterface';
import CloudAxes from './CloudAxes';
import { Concept } from '../classes/Concepts';

const CLASS_NAME = 'concept-cloud';

export default class CloudWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      width: 0
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    cloudInterface.create(`.${CLASS_NAME}`, {
      projectId: this.props.projectId,
      filter: this.props.filter,
      conceptsToVisualize: this.props.conceptsToVisualize,
      colorConcepts: this.props.colorConcepts,
      selection: this.props.selection,
      hovered: this.props.hovered,
      resetAxes: this.props.resetAxes,
      xAxisConcept: this.props.xAxisConcept,
      yAxisConcept: this.props.yAxisConcept,
      hideAxisLabels: this.props.hideAxisLabels,
      setHovered: this.props.setHovered,
      fontSizeDomain: this.props.fontSizeDomain
    });

    window.addEventListener('resize', this.handleResize);
    this.updateDims();
  }

  componentDidUpdate() {
    this.updateDims();

    cloudInterface.update({
      projectId: this.props.projectId,
      filter: this.props.filter,
      conceptsToVisualize: this.props.conceptsToVisualize,
      colorConcepts: this.props.colorConcepts,
      selection: this.props.selection,
      hovered: this.props.hovered,
      resetAxes: this.props.resetAxes,
      xAxisConcept: this.props.xAxisConcept,
      yAxisConcept: this.props.yAxisConcept,
      hideAxisLabels: this.props.hideAxisLabels,
      setHovered: this.props.setHovered,
      fontSizeDomain: this.props.fontSizeDomain
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    cloudInterface.destroy();
  }

  handleResize() {
    this.updateDims();
    cloudInterface.resize();
  }

  render() {
    const { xAxisConcept, yAxisConcept, axisLabelsAreVisible } = this.props;
    const { height, width } = this.state;

    return (
      <div
        ref={ref => {
          this.wrapper = ref;
        }}
        className="concept-cloud-wrapper"
      >
        <div className={CLASS_NAME} />
        {axisLabelsAreVisible && (
          <CloudAxes
            height={height}
            width={width}
            xAxisLabel={xAxisConcept && xAxisConcept.name}
            yAxisLabel={yAxisConcept && yAxisConcept.name}
          />
        )}
      </div>
    );
  }

  updateDims() {
    const { height, width } = this.wrapper.getBoundingClientRect();
    if (width !== this.state.width || height !== this.state.height) {
      this.setState({ height, width });
    }
  }
}

CloudWrapper.propTypes = {
  projectId: PropTypes.string.isRequired,
  filter: PropTypes.arrayOf(PropTypes.instanceOf(Constraint).isRequired)
    .isRequired,
  conceptsToVisualize: PropTypes.arrayOf(
    PropTypes.instanceOf(Concept).isRequired
  ).isRequired,
  colorConcepts: PropTypes.arrayOf(PropTypes.instanceOf(Concept).isRequired)
    .isRequired,
  selection: PropTypes.object,
  hovered: PropTypes.object,
  resetAxes: PropTypes.func.isRequired,
  xAxisConcept: PropTypes.object,
  yAxisConcept: PropTypes.object,
  axisLabelsAreVisible: PropTypes.bool.isRequired,
  hideAxisLabels: PropTypes.func.isRequired,
  setHovered: PropTypes.func.isRequired,
  fontSizeDomain: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired
};
