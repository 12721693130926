import React from 'react';
import styled from '@emotion/styled';

import { Colors, Mixins } from '../../styles';
import { FullDocText } from '../FullDocText';

export const DocumentText = ({ doc, selection, language }) => {
  return (
    <Column>
      <h6>Document text</h6>
      <ScrollableContainer>
        <FullDocText doc={doc} language={language} selection={selection} />
      </ScrollableContainer>
    </Column>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
`;

const ScrollableContainer = styled.div`
  overflow: scroll;
  min-width: 8rem;
  max-width: 60ch;
  flex: 1;
  border: 1px solid ${Colors.gray2};
  ${Mixins.roundedCorners};
  padding: 1rem;
  overflow-wrap: break-word;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;
