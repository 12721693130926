import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { objHasPerm } from '../../utils/common';
import DeleteSharedListButton from './DeleteSharedListButton';
import {
  OverlayAlert,
  useOverlayAlertController
} from '../../components/core/Alert';
import ActiveListNameEditor from './ActiveListNameEditor';
import SaveActiveListButton from './SaveActiveListButton';
import DuplicateButton from './DuplicateButton';
import { useActiveListName } from './activeListName';

export const ActiveConceptListManagement = ({ permissions }) => {
  const alertController = useOverlayAlertController();
  const userHasWritePermissions = objHasPerm({ permissions }, 'write');
  const { nameAsHTML } = useActiveListName();
  return (
    <SpacedColumn>
      <OverlayAlert
        controller={alertController}
        css={css`
          width: calc(100% - 1rem);
          margin: 0.5rem;
          padding: 0;
        `}
      >
        {userHasWritePermissions ? (
          <ActiveListNameEditor
            fillWidth={false}
            alertController={alertController}
          />
        ) : (
          <span
            css={css`
              padding: 0.5rem 1rem;
            `}
          >
            {nameAsHTML}
          </span>
        )}
      </OverlayAlert>
      {userHasWritePermissions && (
        <ButtonRow>
          <SaveActiveListButton />
          <DuplicateButton />
          <DeleteSharedListButton alertController={alertController} />
        </ButtonRow>
      )}
    </SpacedColumn>
  );
};

const ButtonRow = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  *:nth-child(2) {
    margin-right: auto;
  }
`;

const SpacedColumn = styled.div`
  > * {
    padding: 0.5rem;
  }
`;
