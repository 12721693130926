import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { useCurrentView } from '../side_panel/views/view';
import { useFilter } from '../search_params';
import { getConcepts } from '../utils/ApiUtilsV5';
import { StoreContext } from '../StoreContext';

export const useConceptsWithRelevance = () => {
  const { projectId } = useParams();
  const { activeConcepts } = useContext(StoreContext);
  const { conceptSelector, conceptType } = useCurrentView();
  const filter = useFilter();
  const showingActiveConcepts = conceptType === 'active';
  const key = ['concepts', projectId, conceptSelector, filter];
  const { data } = useQuery(
    key,
    () => getConcepts(projectId, conceptSelector, filter),
    { keepPreviousData: true, enabled: !showingActiveConcepts }
  );
  return showingActiveConcepts ? activeConcepts : data?.result;
};
