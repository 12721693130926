import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { thousandify } from '../utils/NumFmtUtils';

const Scale = styled.div`
  position: relative;
  height: 1rem;
  width: 100%;
`;

const Label = styled.div`
  left: ${props => props.left};
  position: absolute;
`;

const ChartLabels = ({ scale, numTicks }) => {
  const ticks = scale.ticks(numTicks);

  return (
    <Scale>
      {ticks.map(tick => (
        <Label key={tick} left={`${scale(tick)}%`}>
          {thousandify(tick)}
        </Label>
      ))}
    </Scale>
  );
};

ChartLabels.propTypes = {
  scale: PropTypes.func.isRequired,
  numTicks: PropTypes.number.isRequired
};

export default ChartLabels;
