import { css } from '@emotion/core';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Constraint } from '../../classes/Constraints';
import { updateActiveConcept } from '../../actions';
import { Button } from '../../components/core/Button';
import {
  inputHeaderText,
  flyoutInputStyle,
  flyoutButtonStyle
} from './ActiveConceptEditor';
import { useUniqueId } from '../../utils/hooks';
import { Concept } from '../../classes/Concepts';
import { FeatureFlagsContext } from '../../FeatureFlagsContext';
import { Colors, Mixins } from '../../styles';

export function AddConceptInput({ projectId, concept, filter }) {
  const [enteredText, setEnteredText] = useState('');
  const [error, setError] = useState(null);
  const featureFlags = useContext(FeatureFlagsContext);
  const inputId = useUniqueId();

  function onConceptsStringChange(evt) {
    setError(null);
    setEnteredText(evt.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    try {
      const updated = concept.addTexts(enteredText);
      updateActiveConcept(projectId, updated, filter, true);
      setEnteredText('');
    } catch (e) {
      setError(e.message);
    }
  }

  return (
    <>
      <label css={inputHeaderText} htmlFor={inputId}>
        Concepts
      </label>
      <form
        css={css`
          display: flex;
          justify-content: space-between;
        `}
        data-tracking-item="edit-saved-concept_add-text"
        onSubmit={handleSubmit}
      >
        <input
          css={[
            flyoutInputStyle,
            error &&
              css`
                /* replace the normal focus outline with an error outline */
                outline: none;
                ${Mixins.errorOutline};
              `
          ]}
          id={inputId}
          onChange={onConceptsStringChange}
          placeholder={
            featureFlags.boolean_search
              ? 'Example: coffee, -tea'
              : 'Add concepts'
          }
          aria-label="Add concepts"
          value={enteredText}
          autoComplete="off"
        />
        <Button
          type="submit"
          disabled={enteredText.trim().length === 0}
          css={flyoutButtonStyle}
        >
          Add
        </Button>
      </form>
      {error && (
        <em
          css={css`
            display: block;
            color: ${Colors.red5};
            font-size: 0.875rem;
            margin-bottom: 0.5rem;
          `}
        >
          * {error}
        </em>
      )}
    </>
  );
}

AddConceptInput.propTypes = {
  projectId: PropTypes.string.isRequired,
  filter: PropTypes.arrayOf(PropTypes.instanceOf(Constraint).isRequired)
    .isRequired,
  concept: PropTypes.instanceOf(Concept).isRequired
};
