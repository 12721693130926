import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { StoreContext } from '../StoreContext';
import { Colors, Mixins } from '../styles';
import { AlertTypes } from '../constants';
import Alert from '../components/core/Alert';
import Spinner from '../components/core/Spinner';
import { NumericField, ScoreField } from '../classes/MetadataFields';
import { TabLink } from '../side_panel/TabTitles';
import { fieldHasNoRange, instanceOf } from './utils';
import { useFilter } from '../search_params';

export function Overlay({
  isLoading,
  fieldName,
  hasNoData,
  comparing = false
}) {
  const filter = useFilter();
  const { metadata, filterCount } = useContext(StoreContext);

  const scoreAndNumericFields = metadata.filter(
    field => instanceOf(ScoreField, field) || instanceOf(NumericField, field)
  );
  const noValidFields = scoreAndNumericFields.length === 0;
  const noValidRanges = scoreAndNumericFields.every(fieldHasNoRange);

  const overlay = noValidFields ? (
    <NoDataMessage />
  ) : noValidRanges ? (
    <NoRangeMessage />
  ) : filter.find(c => c.name === fieldName) ? (
    <FilterOnScoreFieldMessage />
  ) : filterCount === 0 ? (
    <NoDocumentsInFilterMessage />
  ) : isLoading ? (
    <LoadingMessage />
  ) : fieldName === undefined ? (
    <NoFieldSelectedMessage />
  ) : (
    hasNoData && <NoDriversFoundMessage comparing={comparing} />
  );

  if (!overlay) {
    return null;
  }

  return (
    <div
      css={css`
        ${Mixins.fillViewport};
        display: flex;
        align-items: center;
        justify-content: center;

        /* Allow mouseEnter events to fire on the scatter plot while loading */
        pointer-events: none;
        > * {
          pointer-events: auto;
        }
      `}
    >
      {overlay}
    </div>
  );
}

Overlay.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fieldName: PropTypes.string,
  hasNoData: PropTypes.bool.isRequired,
  comparing: PropTypes.bool
};

function InvalidFieldsMessage({ header, children }) {
  return (
    <Container>
      <Message>
        <HeaderContainer>{header}</HeaderContainer>
        {children}
      </Message>
    </Container>
  );
}

const Container = styled.div`
  ${Mixins.shadowOutset}
  padding: 1.8rem;
  width: 80%;
  max-width: 28rem;
  background: white;
  text-align: center;
`;

const HeaderContainer = styled.div`
  font-size: 1.5rem;
  font-weight: lighter;
  margin: 0.5rem 0;
  text-align: center;
`;

const Message = styled.div`
  font-size: 0.9rem;
  color: ${Colors.gray8};
  text-align: left;
`;

export function NoRangeMessage() {
  return (
    <InvalidFieldsMessage header="Your fields are too small!">
      Your project's score fields and number fields are too small. If you would
      like to see what concepts are driving your score, please upload score
      and/or number type data with larger field ranges.
    </InvalidFieldsMessage>
  );
}

export function NoDataMessage() {
  return (
    <InvalidFieldsMessage header="No score or number data!">
      <span data-tracking-item="drivers_no-score-data-message">
        Your project does not contain any score fields or number fields. If you
        would like to see which concepts are driving your score, please upload
        score and/or number data.
      </span>
    </InvalidFieldsMessage>
  );
}

function NoFieldSelectedMessage() {
  return (
    <FilterWarningMessage>
      Choose a number field from the <TabLink tab="configure" /> tab
    </FilterWarningMessage>
  );
}

function FilterWarningMessage({ children }) {
  return (
    <div className="drivers__filter-warning">
      <Alert type={AlertTypes.WARNING}>{children}</Alert>
    </div>
  );
}

export function NoDocumentsInFilterMessage() {
  return (
    <FilterWarningMessage>
      The documents in the current filter don't have enough concepts to display.
      Try changing or clearing the filter.
    </FilterWarningMessage>
  );
}

export function FilterOnScoreFieldMessage() {
  return (
    <FilterWarningMessage>
      Sorry, you can't filter on the same field as your selected axis score
      field. Please make a different selection.
    </FilterWarningMessage>
  );
}

export function NoDriversFoundMessage({ comparing }) {
  return comparing ? (
    <InvalidFieldsMessage>
      <div
        data-tracking-item="drivers_no-documents-message_second-scatterplot"
        css={css`
          text-align: center;
        `}
      >
        <p>The comparison period doesn’t contain any documents.</p>
        <p>Please adjust the date range.</p>
      </div>
    </InvalidFieldsMessage>
  ) : (
    <FilterWarningMessage>
      Sorry, there are no drivers returned for your current selections. Please
      adjust your selections.
    </FilterWarningMessage>
  );
}

export function LoadingMessage() {
  return (
    <div className="drivers__loading">
      <Spinner color={Colors.gray5} size="large" />
      <p>Calculating</p>
    </div>
  );
}
