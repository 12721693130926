import React from 'react';
import PropTypes from 'prop-types';

import { inputHeaderText } from './ActiveConceptEditor';
import { Concept } from '../../classes/Concepts';
import {
  ColorDropdown,
  useRecolorConcepts
} from '../../components/ColorDropdown';
import ColorPicker from './ColorPicker';
import { COLORS } from '../../constants';

export function ColorEditor({ concept }) {
  const recolorConcepts = useRecolorConcepts();
  const [isCustomColor, setIsCustomColor] = React.useState(false);

  function onColorClick(color) {
    setIsCustomColor(false);
    if (COLORS.includes(color) && color !== concept.color) {
      recolorConcepts([concept], color);
    }
  }

  function handleColor(color) {
    if (color !== concept.color) {
      recolorConcepts([concept], color);
      setIsCustomColor(true);
    }
  }

  return (
    <div>
      <div css={inputHeaderText}>Color</div>
      <ColorDropdown
        IsCustomColor={isCustomColor}
        color={!COLORS.includes(concept.color) ? null : concept.color}
        onChangeColor={onColorClick}
      />
      <ColorPicker color={concept.color} onChangeColor={handleColor} />
    </div>
  );
}

ColorEditor.propTypes = {
  concept: PropTypes.instanceOf(Concept).isRequired
};
