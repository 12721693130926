import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from '../components/core/Tooltip';
import { Button } from '../components/core/Button';
import { Icon, IconTypes } from '../components/icons';

export function ResetZoomButton({ resetZoom, className }) {
  return (
    <Tooltip
      className={className}
      anchor={
        <Button
          onClick={resetZoom}
          data-tracking-item="drivers_scatterplot-reset-button"
        >
          <Icon type={IconTypes.ROTATE} />
        </Button>
      }
    >
      Reset zoom
    </Tooltip>
  );
}

ResetZoomButton.propTypes = {
  resetZoom: PropTypes.func.isRequired,
  className: PropTypes.string
};
