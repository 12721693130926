import _ from 'lodash';
import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import * as gtm from '../utils/gtm';
import { NumericField, ScoreField } from '../classes/MetadataFields';
import { NumericRangeConstraint } from '../classes/Constraints';
import { thousandify } from '../utils/NumFmtUtils';
import { isNumeric } from '../utils/common';
import FilterSelector from './FilterSelector';
import { Button } from './core/Button';
import { Icon, IconTypes } from './icons';

const isValidRangeInput = input =>
  input != null && `${input}`.trim().length ? isNumeric(input) : true;

const numberToString = number => (isNumeric(number) ? `${number}` : '');

const parseInputAsNumber = string =>
  isNumeric(string) ? parseFloat(string) : undefined;

export default class FilterSelectorNumeric extends React.Component {
  constructor(props) {
    super(props);

    const enteredMinimum = numberToString(props.constraint.minimum);
    const enteredMaximum = numberToString(props.constraint.maximum);
    this.state = {
      enteredMinimum,
      enteredMaximum,
      submittedMinimum: enteredMinimum,
      submittedMaximum: enteredMaximum,
      prevConstraint: props.constraint
    };

    this.onClear = this.onClear.bind(this);
    this.submitMinimum = this.submitMinimum.bind(this);
    this.submitMaximum = this.submitMaximum.bind(this);

    this.minimumInputId = _.uniqueId('filter-selector_minimum-');
    this.maximumInputId = _.uniqueId('filter-selector_maximum-');
  }

  static getDerivedStateFromProps(props, state) {
    const minChanged =
      props.constraint.minimum !== state.prevConstraint.minimum;
    const maxChanged =
      props.constraint.maximum !== state.prevConstraint.maximum;
    const nextMin = numberToString(props.constraint.minimum);
    const nextMax = numberToString(props.constraint.maximum);

    return {
      prevConstraint: props.constraint,
      ...(minChanged && {
        enteredMinimum: nextMin,
        submittedMinimum: nextMin
      }),
      ...(maxChanged && {
        enteredMaximum: nextMax,
        submittedMaximum: nextMax
      })
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.submittedMinimum !== this.state.submittedMinimum ||
        prevState.submittedMaximum !== this.state.submittedMaximum) &&
      isValidRangeInput(this.state.submittedMinimum) &&
      isValidRangeInput(this.state.submittedMaximum) &&
      this.isRangeValid()
    ) {
      const minimum = parseInputAsNumber(this.state.submittedMinimum);
      const maximum = parseInputAsNumber(this.state.submittedMaximum);
      if (
        minimum !== this.props.constraint.minimum ||
        maximum !== this.props.constraint.maximum
      ) {
        this.props.updateConstraint(minimum, maximum);
        [this.minimum, this.maximum].forEach(ref => ref && ref.blur());
      }
    }
  }

  onClear() {
    if (!this.props.constraint.isEmpty()) {
      this.props.updateConstraint();
    }
  }

  isRangeValid() {
    const parsedMinimum = parseInputAsNumber(this.state.submittedMinimum);
    const parsedMaximum = parseInputAsNumber(this.state.submittedMaximum);

    if (parsedMinimum != null && parsedMaximum != null) {
      return parsedMinimum <= parsedMaximum;
    }
    return true;
  }

  submitMinimum() {
    gtm.trigger('filter-pane_selector-input_number-start-submit');
    this.setState(({ enteredMinimum }) => ({
      submittedMinimum: enteredMinimum
    }));
  }

  submitMaximum() {
    gtm.trigger('filter-pane_selector-input_number-end-submit');
    this.setState(({ enteredMaximum }) => ({
      submittedMaximum: enteredMaximum
    }));
  }

  render() {
    const hasEnteredMinimum = !_.isEmpty(this.state.enteredMinimum);
    const hasEnteredMaximum = !_.isEmpty(this.state.enteredMaximum);
    const minimumIsUnchanged =
      this.state.enteredMinimum === this.state.submittedMinimum;
    const maximumIsUnchanged =
      this.state.enteredMaximum === this.state.submittedMaximum;
    const minimumIsInvalid = !isValidRangeInput(this.state.submittedMinimum);
    const maximumIsInvalid = !isValidRangeInput(this.state.submittedMaximum);
    const rangeIsInvalid = !this.isRangeValid();

    return (
      <FilterSelector
        constraint={this.props.constraint}
        onClear={this.onClear}
        expanded={this.props.expanded}
        toggleExpanded={this.props.toggleExpanded}
      >
        <div className="filter-selector__container">
          <label
            className="filter-selector__label filter-selector__label--numeric"
            htmlFor={this.minimumInputId}
          >
            <span className="filter-selector__field-name">From </span>
            <span className="filter-selector__field-description">
              (Min. {thousandify(this.props.field.minimum)})
            </span>
          </label>
          <div
            className={cx('filter-selector__input-container', {
              'filter-selector__input-container--invalid':
                hasEnteredMinimum &&
                minimumIsUnchanged &&
                (minimumIsInvalid || rangeIsInvalid)
            })}
          >
            <input
              className="filter-selector__range-input"
              id={this.minimumInputId}
              ref={ref => (this.minimum = ref)}
              autoComplete="off"
              value={this.state.enteredMinimum}
              onBlur={this.submitMinimum}
              onChange={evt =>
                this.setState({ enteredMinimum: evt.target.value })
              }
              onKeyDown={evt => {
                switch (evt.key) {
                  case 'Enter':
                    this.submitMinimum();
                    break;
                  case 'Escape':
                    this.setState(
                      ({ submittedMinimum }) => ({
                        enteredMinimum: submittedMinimum
                      }),
                      () => this.minimum && this.minimum.blur()
                    );
                }
              }}
            />
            {hasEnteredMinimum && (
              <Button
                flavor="subtle"
                onClick={() => {
                  this.setState({ enteredMinimum: '' }, this.submitMinimum);
                }}
                data-tracking-item="filter-pane_selector-input_number-start-clear-button"
              >
                <Icon type={IconTypes.CIRCULAR_CLOSE} alt="clear" />
              </Button>
            )}
          </div>
          <div className="err-text">
            {hasEnteredMinimum &&
              minimumIsUnchanged &&
              (minimumIsInvalid
                ? 'Value must be a valid number.'
                : rangeIsInvalid
                ? 'Lower limit must be less than or equal to upper limit.'
                : '')}
          </div>
          <label
            className="filter-selector__label filter-selector__label--numeric"
            htmlFor={this.maximumInputId}
          >
            <span className="filter-selector__field-name">To </span>
            <span className="filter-selector__field-description">
              (Max. {thousandify(this.props.field.maximum)})
            </span>
          </label>
          <div
            className={cx('filter-selector__input-container', {
              'filter-selector__input-container--invalid':
                hasEnteredMaximum &&
                maximumIsUnchanged &&
                (maximumIsInvalid || rangeIsInvalid)
            })}
          >
            <input
              className="filter-selector__range-input"
              id={this.maximumInputId}
              ref={ref => (this.maximum = ref)}
              autoComplete="off"
              value={this.state.enteredMaximum}
              onBlur={this.submitMaximum}
              onChange={evt =>
                this.setState({ enteredMaximum: evt.target.value })
              }
              onKeyDown={evt => {
                switch (evt.key) {
                  case 'Enter':
                    this.submitMaximum();
                    break;
                  case 'Escape':
                    this.setState(
                      ({ submittedMaximum }) => ({
                        enteredMaximum: submittedMaximum
                      }),
                      () => this.maximum && this.maximum.blur()
                    );
                }
              }}
            />
            {hasEnteredMaximum && (
              <Button
                flavor="subtle"
                onClick={() => {
                  this.setState({ enteredMaximum: '' }, this.submitMaximum);
                }}
                data-tracking-item="filter-pane_selector-input_number-end-clear-button"
              >
                <Icon type={IconTypes.CIRCULAR_CLOSE} alt="clear" />
              </Button>
            )}
          </div>
          <div className="err-text">
            {hasEnteredMaximum &&
              maximumIsUnchanged &&
              (maximumIsInvalid
                ? 'Value must be a valid number.'
                : rangeIsInvalid
                ? 'Upper limit must be greater than or equal to lower limit.'
                : '')}
          </div>
        </div>
      </FilterSelector>
    );
  }
}

FilterSelectorNumeric.propTypes = {
  field: PropTypes.oneOfType([
    PropTypes.instanceOf(NumericField),
    PropTypes.instanceOf(ScoreField)
  ]).isRequired,
  constraint: PropTypes.instanceOf(NumericRangeConstraint).isRequired,
  updateConstraint: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired
};
