import React, { useContext } from 'react';

import { StoreContext } from '../StoreContext';
import { ConfirmableButton } from '../components/core/Button';
import { objHasPerm } from '../utils/common';
import { deleteProject } from '../utils/ApiUtilsV5';
import { notifier } from '../actions';
import { Bold } from '../components/Bold';
import { Icon, IconTypes } from '../components/icons';

export function DeleteProjectButton() {
  const { project } = useContext(StoreContext);
  return (
    <ConfirmableButton
      palette="red"
      onConfirm={() =>
        deleteProject(project.project_id)
          .then(() => {
            window.location = '/';
          })
          .catch(() => {
            notifier.error("Can't delete the project");
          })
      }
      confirmationMessage={
        <>
          Are you sure you want to delete the project{' '}
          <Bold>{project.name}</Bold>?
        </>
      }
      disabled={!objHasPerm(project, 'write')}
      confirmText={'Delete'}
      trackingItemConfirm="highlights_delete-project_delete-button"
      trackingItemCancel="highlights_delete-project_cancel-button"
    >
      <Icon type={IconTypes.TRASH} />
      Delete project
    </ConfirmableButton>
  );
}
