import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Colors, Mixins } from '../styles';
import { useTextWidth } from '../utils/hooks/useTextWidth';
import ArrowheadMarker from './ArrowheadMarker';

const ARROW_BASE_LENGTH = 5;
const LINE_STROKE_WIDTH = 2;
// The marker size scales up with the line's stroke width. For more detail,
// search for the `markerUnits` property of `marker` elements.
const ARROW_SIZE = LINE_STROKE_WIDTH * ARROW_BASE_LENGTH;
// Keep a 2px gap between the container edge and arrow
const PADDING = ARROW_SIZE + 2;
// Keep a 16px gap from the arrow to the pill
const ARROW_PILL_GAP = 16;
const PILL_STROKE_WIDTH = 1;
const FONT_SIZE = 14;

export default function CloudAxes({ height, width, xAxisLabel, yAxisLabel }) {
  return (
    <SVGContainer height={height} width={width}>
      <ArrowHeadDefinitions />
      <Axis axis="x" label={xAxisLabel} height={height} width={width} />
      <Axis axis="y" label={yAxisLabel} height={height} width={width} />
    </SVGContainer>
  );
}

CloudAxes.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string
};

const SVGContainer = styled.svg`
  ${Mixins.fillViewport};
  /* force axis overlay to let all mouse events pass through it */
  pointer-events: none;
`;

const ArrowHeadDefinitions = () => (
  <defs>
    {['left', 'right', 'up', 'down'].map(dir => (
      <ArrowheadMarker
        key={dir}
        baseLength={ARROW_BASE_LENGTH}
        id={`arrowhead-${dir}`}
        facing={dir}
      />
    ))}
  </defs>
);

const Axis = ({ axis, label, height, width }) => (
  <AxisContainer
    className={`cloud-axis-${axis}`}
    display={!label ? 'none' : undefined}
    transform={
      axis === 'x' ? `translate(0 ${height / 2})` : `translate(${width / 2} 0)`
    }
  >
    <Line axis={axis} width={width} height={height} />
    <Label axis={axis} width={width} height={height} label={label} sign="neg" />
    <Label axis={axis} width={width} height={height} label={label} sign="pos" />
  </AxisContainer>
);

const AxisContainer = styled.g`
  transition: opacity linear 0.1s;
`;

const Line = ({ axis, width, height }) => (
  <line
    stroke="black"
    strokeDasharray="2, 8"
    strokeLinecap="round"
    strokeWidth={LINE_STROKE_WIDTH}
    x1={axis === 'x' ? PADDING : 0}
    x2={axis === 'x' ? width - PADDING : 0}
    y1={axis === 'x' ? 0 : PADDING}
    y2={axis === 'x' ? 0 : height - PADDING}
    markerStart={`url(#arrowhead-${axis === 'x' ? 'left' : 'up'})`}
    markerEnd={`url(#arrowhead-${axis === 'x' ? 'right' : 'down'})`}
  />
);

const Label = ({ sign, axis, height, width, label }) => {
  const symbol = sign === 'pos' ? '+' : '-';
  const text = `${symbol} ${label}`;
  const textWidth = useTextWidth(text, `${FONT_SIZE}px`);
  const pillHeight = FONT_SIZE + 2 * PILL_STROKE_WIDTH + 1; // approximate
  const pillRadius = pillHeight / 2;

  // The origin for each of the translations below is the top-left corner of the
  // respective axis label's pill's inner rectangle, which is in the same place
  // as the top-left corner of the label's text box.
  let x, y;
  if (axis === 'x' && sign === 'neg') {
    x = ARROW_PILL_GAP + PADDING + pillRadius;
    y = -pillRadius;
  } else if (axis === 'x' && sign === 'pos') {
    x = width - (textWidth + pillRadius + ARROW_PILL_GAP + PADDING);
    y = -pillRadius;
  } else if (axis === 'y' && sign === 'neg') {
    x = -textWidth / 2;
    y = height - (ARROW_PILL_GAP + PADDING + pillHeight);
  } else if (axis === 'y' && sign === 'pos') {
    x = -textWidth / 2;
    y = ARROW_PILL_GAP + PADDING;
  }

  return (
    <LabelContainer transform={`translate(${x} ${y})`}>
      <Pill sign={sign} width={textWidth} height={pillHeight} />
      <Text sign={sign} text={text} />
    </LabelContainer>
  );
};

const LabelContainer = styled.g`
  /* prevent interacting with terms that are hidden behind axis labels */
  pointer-events: all;
`;

/** pill-shaped path w/ inner rectangle dimensions equal to label's text box's
 *  dimensions*/
const Pill = ({ sign, width, height }) => {
  const radius = height / 2;
  return (
    <path
      d={`M 0 0 h ${width} a ${radius} ${radius} 0 0 1 0 ${height} h ${-width} a ${radius} ${radius} 0 0 1 0 ${-height}`}
      fill={sign === 'pos' ? 'black' : Colors.gray1}
      stroke="black"
      strokeWidth={PILL_STROKE_WIDTH}
    />
  );
};

const Text = ({ sign, text }) => (
  <text
    fill={sign === 'pos' ? Colors.gray1 : 'black'}
    fontSize={FONT_SIZE}
    dominantBaseline="text-before-edge"
  >
    {text}
  </text>
);
