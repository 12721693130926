import _ from 'lodash';

export class ActiveConceptList {
  constructor(name = null, concepts = []) {
    this.name = name;
    this.concepts = concepts;
  }

  equals(sharedList) {
    if (!sharedList) {
      return this.name === null && this.concepts.length === 0;
    }

    if (this.name !== sharedList.name) {
      return false;
    }

    return _.isEqual(
      // Remove ids from active list before comparing them
      this.concepts.map(fieldsCheckedForEquality),
      sharedList.concepts.map(fieldsCheckedForEquality)
    );
  }

  get empty() {
    return this.name == null && this.concepts.length === 0;
  }
}

function fieldsCheckedForEquality(concept) {
  return _.pick(concept, ['name', 'texts', 'color']);
}
