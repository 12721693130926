import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';

import { ReviewsSourceSelect } from './ReviewsSourceSelect';
import { ReviewsInputData } from './ReviewsInputData';
import { RoutePatterns } from '../../../constants';
import { OptionsPage } from '../OptionsPage';
import {
  createProject,
  createReviewsTaskDashboard
} from '../../../utils/ApiUtilsV5';
import StreamSourceLayout from '../StreamSourceLayout';

const SELECT_SOURCE_PATHNAME = `${RoutePatterns.UPLOAD_STREAM_DATA}/select/reviews/select-source`;
const URLS_INPUT_PATHNAME = `${RoutePatterns.UPLOAD_STREAM_DATA}/select/reviews/urls-input`;
const SELECT_INTERVAL_PATHNAME = `${RoutePatterns.UPLOAD_STREAM_DATA}/select/reviews/select-interval`;

class SourceInput {
  constructor(id, inputs, setInputs, title, validation) {
    this.id = id;
    this.inputs = inputs;
    this.setInputs = setInputs;
    this.title = title;
    this.validation = validation;
  }
}

const reviewsSources = [
  {
    id: 1,
    title: 'Amazon',
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?amazon\.com\/?.*$/
  },
  {
    id: 2,
    title: 'Audible',
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?audible\.com\/?.*$/
  },
  {
    id: 3,
    title: 'BestBuy',
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?bestbuy\.(com|ca)\/?.*$/
  },
  {
    id: 4,
    title: 'Samsclub',
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?samsclub\.com\/?.*$/
  },
  {
    id: 5,
    title: 'Target',
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?target\.com\/?.*$/
  },
  {
    id: 6,
    title: 'Walmart',
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?walmart\.com\/?.*$/
  }
];

export const ReviewsPage = ({
  projectData,
  onAlert,
  sourcesInputs,
  setSourcesInputs,
  inputs,
  setInputs,
  repeat,
  setRepeat,
  repeatInterval,
  setRepeatInterval
}) => {
  const history = useHistory();
  const location = useLocation();

  const [step, setStep] = useState(1);
  const [sources, setSources] = useState(reviewsSources);

  const [errors, setErrors] = useState([]);

  const [requestInProcess, setRequestInProcess] = useState(false);

  const selectSource = title => {
    const sourcesWithSelected = sources.map(source => {
      if (source.title !== title) return source;

      source.selected = !source.selected;
      return source;
    });

    setSources(sourcesWithSelected);
  };

  const selectedSources = useMemo(
    () => sources.filter(source => source.selected),
    [sources]
  );

  const createProjectName = () => {
    const titles = selectedSources.map(source => source.title);
    return titles.join(' | ') + ' Scrape';
  };

  const successHandler = message => {
    onAlert({
      type: 'success',
      message
    });
    history.push(RoutePatterns.UPLOAD_STREAM_DATA);
  };

  const errorHandler = message => {
    onAlert({
      type: 'error',
      message
    });
  };

  const validateInputs = sourceInput => {
    const { inputs, setInputs, validation } = sourceInput;

    const validatedInputs = inputs.map(input => ({
      ...input,
      error: !validation.test(input.value)
        ? 'Please provide a valid Reviews URL.'
        : ''
    }));

    setInputs(validatedInputs);

    return !validatedInputs.some(({ error }) => error !== '');
  };

  const submitHandler = async event => {
    event.preventDefault();
    setRequestInProcess(true);

    try {
      const createdProject = await createProject(
        projectData.workspaceId,
        projectData.name || createProjectName(),
        projectData.language,
        projectData.description
      );

      if (!createdProject) {
        throw new Error('Unexpected issue during project creation');
      }

      const url_list = sourcesInputs
        .map(({ inputs }) => inputs.map(({ value }) => value))
        .flat();

      const taskResponse = await createReviewsTaskDashboard(
        createdProject.project_id,
        url_list,
        repeat,
        repeatInterval
      );
      if (
        taskResponse?.status_code === 200 &&
        taskResponse?.status === 'success'
      ) {
        successHandler('Reviews task successfully created!');
        setRequestInProcess(false);
      } else {
        throw new Error('Something went wrong!');
      }
    } catch (e) {
      setRequestInProcess(false);
      errorHandler(e.message);
    }
  };
  const checkProjectData = useCallback(() => {
    setErrors([]);
    if (!projectData.language) {
      setErrors(prevState => [
        ...prevState,
        "Please, select the dataset's language to continue"
      ]);
    }
    if (!projectData.workspaceId) {
      setErrors(prevState => [
        ...prevState,
        'Please, select the workspace to continue'
      ]);
    }
  }, [projectData.language, projectData.workspaceId]);

  const inputsWithError = () =>
    sourcesInputs.filter(input => !validateInputs(input));

  const nextStepHandler = () => {
    if (step === 2 && inputsWithError().length > 0) return;

    setStep(prevState => prevState + 1);
  };

  const goBackHandler = () => {
    if (step === 1) history.push(RoutePatterns.UPLOAD_STREAM_DATA);
    setStep(prevState => prevState - 1);
  };

  useEffect(() => {
    const updatedSourcesInputs = selectedSources.map(source => {
      switch (source.title) {
        case 'Amazon':
          return new SourceInput(
            source.id,
            inputs.amazonInputs,
            setInputs.setAmazonInputs,
            source.title,
            source.validation
          );

        case 'Audible':
          return new SourceInput(
            source.id,
            inputs.audibleInputs,
            setInputs.setAudibleInputs,
            source.title,
            source.validation
          );

        case 'BestBuy':
          return new SourceInput(
            source.id,
            inputs.bestBuyInputs,
            setInputs.setBestBuyInputs,
            source.title,
            source.validation
          );

        case 'Costco':
          return new SourceInput(
            source.id,
            inputs.costcoInputs,
            setInputs.setCostcoInputs,
            source.title,
            source.validation
          );

        case 'Samsclub':
          return new SourceInput(
            source.id,
            inputs.samsclubInputs,
            setInputs.setSamsclubInputs,
            source.title,
            source.validation
          );

        case 'Walmart':
          return new SourceInput(
            source.id,
            inputs.walmartInputs,
            setInputs.setWalmartInputs,
            source.title,
            source.validation
          );

        default:
          return new SourceInput(
            source.id,
            inputs.targetInputs,
            setInputs.setTargetInputs,
            source.title,
            source.validation
          );
      }
    });

    setSourcesInputs(updatedSourcesInputs);
  }, [
    selectedSources,
    inputs.amazonInputs,
    inputs.audibleInputs,
    inputs.bestBuyInputs,
    inputs.costcoInputs,
    inputs.samsclubInputs,
    inputs.targetInputs,
    inputs.walmartInputs
  ]);

  useEffect(() => {
    if (step === 1 && location.pathname !== SELECT_SOURCE_PATHNAME)
      history.push(SELECT_SOURCE_PATHNAME);

    if (step === 2 && location.pathname !== URLS_INPUT_PATHNAME)
      history.push(URLS_INPUT_PATHNAME);

    if (step === 3 && location.pathname !== SELECT_INTERVAL_PATHNAME)
      history.push(SELECT_INTERVAL_PATHNAME);
  }, [history, step]);

  useEffect(() => {
    if (location.pathname === SELECT_SOURCE_PATHNAME) setStep(1);

    if (location.pathname === URLS_INPUT_PATHNAME && selectedSources?.length)
      setStep(2);

    if (
      location.pathname === SELECT_INTERVAL_PATHNAME &&
      sourcesInputs.length &&
      inputsWithError().length === 0
    )
      setStep(3);
  }, [location.pathname, selectedSources.length]);

  useEffect(() => {
    if (location.pathname === URLS_INPUT_PATHNAME && !selectedSources.length)
      setStep(1);
  }, [location.pathname, selectedSources.length]);

  return (
    <>
      <StreamSourceLayout onGoBack={goBackHandler}>
        <Switch>
          <Route
            exact
            path={`${RoutePatterns.UPLOAD_STREAM_DATA}/select/reviews`}
          >
            <Redirect to={SELECT_SOURCE_PATHNAME} />
          </Route>
          <Route path={SELECT_SOURCE_PATHNAME}>
            <ReviewsSourceSelect
              sources={sources}
              selectSource={selectSource}
              nextIsDisabled={!selectedSources.length}
              onNext={nextStepHandler}
            />
          </Route>
          <Route path={URLS_INPUT_PATHNAME}>
            <ReviewsInputData
              sourcesInputs={sourcesInputs}
              errors={errors}
              checkProjectData={checkProjectData}
              onNext={nextStepHandler}
              projectData={projectData}
              onSuccess={successHandler}
              selectSource={selectSource}
            />
          </Route>

          <Route path={SELECT_INTERVAL_PATHNAME}>
            <OptionsPage
              isLoading={requestInProcess}
              submitHandler={submitHandler}
              repeat={repeat}
              setRepeat={setRepeat}
              setRepeatInterval={setRepeatInterval}
              repeatInterval={repeatInterval}
            />
          </Route>
        </Switch>
      </StreamSourceLayout>
    </>
  );
};
