import _ from 'lodash';

export default class JobTracker {
  constructor() {
    this.jobsByGroupKey = {};
    this._handleWorkerError = this._handleWorkerError.bind(this);
  }

  get workers() {
    return _.uniqBy(Object.values(this.jobsByGroupKey), 'id');
  }

  get hasWorkInProgress() {
    return !_.isEmpty(this.jobsByGroupKey);
  }

  clearJobs() {
    for (const worker of this.workers) {
      worker.terminate();
    }
    this.jobsByGroupKey = {};
  }

  runningJobForGroup(groupKey) {
    return this.jobsByGroupKey[groupKey] != null;
  }

  registerJobForGroup(groupKey, worker) {
    worker.registerErrorHandler(this._handleWorkerError);
    this.jobsByGroupKey[groupKey] = worker;
  }

  _handleWorkerError(workerId) {
    for (const [groupKey, worker] of Object.entries(this.jobsByGroupKey)) {
      if (worker.id === workerId) {
        this.markGroupDone(groupKey);
      }
    }
  }

  markGroupDone(groupKey) {
    const worker = this.jobsByGroupKey[groupKey];
    // Indicate that there's no longer a job running for the given group
    delete this.jobsByGroupKey[groupKey];
    // If there are no other jobs running in that worker, terminate it
    if (!this.workers.some(w => w.id === worker.id)) {
      worker.terminate();
    }
  }
}
