import _ from 'lodash';
import styled from '@emotion/styled';
import React from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Icon } from '../icons/Icon';
import { FEATURES } from '../icons/types';
import { Colors } from '../../styles';
import buildRoutePath from '../../utils/buildRoutePath';
import Tooltip from '../core/Tooltip';
import { encodeFilter, stringify, useSearchParams } from '../../search_params';

/**
 * @param {string} icon - Icon type
 * @param {string} routePattern - Route pattern to use for link
 * @returns {JSX.Element}
 */
export function NavigationLink({ icon, routePattern, label }) {
  const params = useParams();
  const path = buildRoutePath(routePattern, params);
  const active = useRouteMatch({ path, exact: true });
  const { searchParams } = useSearchParams();
  const paramsToPreserve = [
    'search',
    'filter',
    'concepts',
    'drivers_of',
    'drivers_count',
    'prevalent_count'
  ];
  const subqueryToPreserve = _.pick(searchParams, paramsToPreserve);
  subqueryToPreserve.filter = encodeFilter(subqueryToPreserve.filter);
  const search = stringify(subqueryToPreserve);

  const link = (
    <IconLink
      to={{ pathname: path, search }}
      active={active}
      aria-label={`Go to ${label}`}
    >
      <Indicator />
      <Container>
        <Icon type={icon} />
      </Container>
    </IconLink>
  );

  return (
    <Tooltip position="right" anchor={link}>
      {label}
    </Tooltip>
  );
}

NavigationLink.propTypes = {
  icon: PropTypes.oneOf(FEATURES).isRequired,
  routePattern: PropTypes.string.isRequired
};

const Indicator = styled.div`
  height: 100%;
  width: 0.3rem;
  border-radius: 0 3rem 3rem 0;
`;

const Container = styled.div`
  display: grid;
  width: 3rem;
  height: 3rem;
  place-content: center;
  place-items: center;
  background: transparent;
  border-radius: 0.25rem;
`;

const IconLink = styled(Link)`
  padding: 0;
  display: grid;
  grid-template-columns: 0.5rem auto;
  justify-content: space-between;
  gap: 0.5rem;
  width: 3.75rem;
  height: 3rem;
  transition: all ease-in-out 100ms;

  ${Indicator} {
    background: ${props => (props.active ? Colors.blue5 : 'transparent')};
  }

  ${Container} {
    color: ${props => (props.active ? Colors.blue7 : Colors.gray9)};
    background: ${props => (props.active ? Colors.blue1 : 'transparent')};
  }

  &:hover {
    ${Container} {
      color: ${Colors.blue7};
      background: ${props => (props.active ? Colors.blue1 : Colors.blue0)};
    }
  }
`;
