// This component handles setting a document title.
// Based on React Document Title by Dan Abramov (https://github.com/gaearon/react-document-title)
import PropTypes from 'prop-types';

import { useDocumentTitle } from '../utils/hooks';

export default function DocumentTitle({ children, title }) {
  useDocumentTitle(title);
  return children;
}

DocumentTitle.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};
